import React from 'react'

const LiveText = ({style}) => {
  return (
    <div className='live-text' style={{...style}}>
      <div style={{borderRadius: "50%", background: "white", width: "8px", height: "8px"}}/>
      <span style={{fontWeight: "bold", color: "white"}}>LIVE</span>
    </div>
  )
}

export default LiveText