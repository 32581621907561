import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const OpenDataAirHeader = () => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
     if (general) return translate(t, textId);
     return translate(t, textId, viewNames.OPEN_DATA_AIR_QUALITY_VIEW);
  };

  return (
    <div className='view-header'>
      <span className='title'>{handleTranslate('HEADER')}</span>
    </div>
  )
}

export default OpenDataAirHeader;