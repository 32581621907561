import { Slider, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'

const CustomSlider = styled(Slider)(({theme}) => ({
  color: '#fff',
  height: 4,
  '& .MuiSlider-thumb': {
    width: 8,
    height: 8,
    '&::before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px 'rgb(255 255 255 / 16%)'
      }`,
    },
    '&.Mui-active': {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
  }
}));

// duration in seconds
const SliderPlayer = ({style, videoDuration=0, value, onChange, disableLabels=false}) => {
  const [duration, setDuration] = useState(videoDuration);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    setDuration(videoDuration);
  }, [videoDuration])

  useEffect(() => {
    setPosition(value);
  }, [value])

  const formatDuration = (value) => {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.round(value) - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handleChange = (value) => {
    setPosition(value);
    onChange && onChange(value);
  }

  return (
    <div style={style}>
      <CustomSlider
        aria-label="time-indicator"
        size="small"
        translate='yes'
        value={position}
        min={0}
        step={0.1}
        max={duration}
        onChange={(_, value) => handleChange(value)}
      />
      {
      !disableLabels &&
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: ".5rem", mt: -2}}>
        <span style={{color: "white", opacity: .6}}>{formatDuration(position)}</span>
        <span style={{color: "white", opacity: .6}}>-{formatDuration(duration - position)}</span>
      </Box>
      }
    </div>
  )
}

export default SliderPlayer