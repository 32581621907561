import { Grid, ListItemButton, ListItemText, styled } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { routesNames } from 'app/configs/routesNames'
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import NoDataMessage from '../SharingComponents/NoDataMessage'
import { getStationMeasures, getStationSensors } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions'
import { isMeasureMultimedia, isSensorMultimedia } from 'app/store/evocsSlice/sensors/sensorsActions'
import useLoadingDevices from 'src/app/hooks/useLoadingDevices'
import LoadingGif from '../FileManager/LoadingGif'
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const CustomItem = styled(ListItemButton)(({theme}) => ({
  position: "relative",
  background: "white",
  borderRadius: "1rem",
  textAlign: "center",
  height: "200px",
  fill: "black",
  boxShadow: "0px 0px 15px -10px black",
  "&:hover": {
    background: "#242a30",
    color: "white",
    fill: "white",
  }
}));

const StationItem = ({station, onClick}) => {
  const multimediaTypes = useMemo(() => {
    const types = [];
    getStationMeasures(station.id).forEach((measureId) => {
      const multimedia = isMeasureMultimedia(measureId)
      if(multimedia) types.push(multimedia);
    });
    return types;
  }, [station])
  const icons = useMemo(() => {
    const mediaIcons = [];
    if(multimediaTypes.includes("image")) mediaIcons.push(<CameraAltIcon fontSize='small'/>);
    if(multimediaTypes.includes("video")) mediaIcons.push(<VideocamIcon fontSize='small'/> );
    return mediaIcons;
    // <svg style={{width: "20px", height: "20px"}} fill='inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" role="img"><path d="M12 22A10.011 10.011 0 012 12 10.011 10.011 0 0112 2a10.011 10.011 0 0110 10 10.011 10.011 0 01-10 10zm5.406-12.793a2.767 2.767 0 00-1.424.357 2.349 2.349 0 00-.932 1.027 3.565 3.565 0 00-.324 1.561 3.212 3.212 0 00.66 2.17 2.4 2.4 0 001.906.756 4.058 4.058 0 001.57-.293v-1.016a4.542 4.542 0 01-1.456.3c-.951 0-1.434-.643-1.434-1.91a2.408 2.408 0 01.375-1.432 1.241 1.241 0 011.059-.513 1.949 1.949 0 01.636.113 6.457 6.457 0 01.644.266l.391-.985a3.86 3.86 0 00-1.67-.401zm-6.907.082V15h3.289v-1H11.71v-1.473h1.934v-.992H11.71v-1.254h2.078v-.992zm-4.071 3.52h.645L8.436 15H9.78c-.263-.378-.843-1.239-1.679-2.488a1.838 1.838 0 00.785-.616 1.52 1.52 0 00.278-.9 1.519 1.519 0 00-.559-1.287 2.861 2.861 0 00-1.722-.424H5.219V15H6.43v-2.19zm.391-.985h-.391v-1.543h.367a1.707 1.707 0 01.866.172.629.629 0 01.279.579.686.686 0 01-.274.6 1.5 1.5 0 01-.846.192z"></path></svg>
  }, [multimediaTypes]);

  return(
    <CustomItem onClick={() => onClick(station)}>
      <ListItemText primary={station.serial}/>
      <Stack sx={{position: "absolute", alignItems: "center", bottom: "0.5rem", right: "0.5rem"}} direction="row" spacing={1}>
        {icons.map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>)}
      </Stack>
    </CustomItem>
  )
}

const StationsList = memo(({list}) => {
  const navigate = useNavigate();

  const handleClickItem = (station) => {
    navigate(`${routesNames.MULTIMEDIA_VIEW}/${station.serial}`, {state: {station}});
  };

  
  return (
    <Grid container spacing={1}>
      {list?.map((station) => (
        <Grid key={station.id} item xs={12} sm={6} md={3} lg={2}>
          <StationItem station={station} onClick={handleClickItem}/>
        </Grid>
      ))}
    </Grid>
  )
})

const MultimediaContent = () => {
  const loadingDevices = useLoadingDevices();
  const stations = useSelector((state) => state.stationsMongo.list);
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.MULTIMEDIA_VIEW);
  };

  const filteredStations = useMemo(() => {
    const ids = availableStations.filter((stationId) => getStationSensors(stationId).some((sensorId) => isSensorMultimedia(sensorId)));
    // console.log(ids)
    return ids.map((id) => ({id, ...stations[id]}));
    //return availableStations.map((stationId) => ({id: stationId, ...stations[stationId]} ));
  }, [loadingDevices, stations, availableStations]);

  return (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      {!loadingDevices ?
        filteredStations?.length > 0 ?
          <StationsList list={filteredStations}/>
          : <NoDataMessage message={handleTranslate('NO_STATIONS_AVAILABLE')}/>
        :
        <LoadingGif />
      }
    </Box>
  )
}

export default MultimediaContent