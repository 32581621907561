import { useEffect, useMemo, useState } from 'react';
import { Paper } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { useMediaQuery } from 'react-responsive';


const VesselsGraph = ({ portTraffic, graphBackgroundColor, graphBorderColor }) => {
  const { t } = useTranslation("evocs");
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const isPhone = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (!portTraffic?.shipCounter) return;
    const newData = [], newLabels = [];
    Object.keys(portTraffic.shipCounter).forEach((key) => {
      newData.push(portTraffic.shipCounter[key]);
      newLabels.push(handleTranslate(`VESSEL_TYPE_${key}`, true));
    });
    setData(newData);
    setLabels(newLabels);
  }, [t, portTraffic])


  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.VESSELS_VIEW);
  };

  const portName = "Taranto";

  return (
    <Paper sx={{ height: "100%", padding: "1rem" }}>
      <Doughnut data={{
        labels: labels,
        datasets: [{
          label: handleTranslate("SHIPS_NUMBER"),
          data: data,
          backgroundColor: graphBackgroundColor,
          borderColor: graphBorderColor,
          borderWidth: 1,
        }]
      }}
        options={{
          maintainAspectRatio: isPhone ? true : false,
          responsive: true,
          hoverOffset: 30,
          plugins: {
            title: {
              display: true,
              color: "black",
              text: handleTranslate('SHIPS_OF_PORT') + portName,
              font: {
                size: 20,
                weight: 3,
              },
            },
            legend: {
              position: "bottom",
              maxHeight: 150,
              maxWidth: 115,
              align: "center",
              labels: {
                font: {
                  size: 11,
                },
              },
            },
          },
        }}
      />
    </Paper>
  )
}

export default VesselsGraph