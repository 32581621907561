import { Box, Grid, IconButton, Stack } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logoTilebytes from "src/app/asset/images/LogoGreenTilebytes.png";
// import logoTilebytes from "src/images/logoTilebytes.png";
import { sharingComponentNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";

const Footer = () => {
  const { t, i18n } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, sharingComponentNames.FOOTER_COMPONENT);
  };

  const socialMediaLinks = [
    { icon: <FacebookIcon />, url:  "https://www.facebook.com/Tilebytes.srl?locale=it_IT" },
    { icon: <InstagramIcon />, url: "https://www.instagram.com/tilebytes.srl/" },
    { icon: <LinkedInIcon />, url:  "https://www.linkedin.com/company/tilebytes/?originalSubdomain=it" },
  ];

  return (
    <Box sx={{ color: "white" }} padding={{ xs: "1rem", sm: "2rem 5rem" }}>
      <img
        src={logoTilebytes}
        alt=""
        style={{
          alignSelf: "start",
          objectFit: "contain",
          userSelect: "none",
          pointerEvents: "none",
          width: "200px",
          marginBottom: "2rem",
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h3
            style={{
              fontWeight: "bold",
              marginBottom: "0.5rem",
              color: "#92c870",
            }}
          >
            {handleTranslate('COLUMN_1_TITLE')}
          </h3>
          <p>
            {handleTranslate('COLUMN_1_TEXT')}
          </p>
        </Grid>
        <Grid item xs={12} md={3}>
          <h3
            style={{
              fontWeight: "bold",
              marginBottom: "0.5rem",
              color: "#92c870",
            }}
          >
            {handleTranslate('COLUMN_2_TITLE')}
          </h3>
          <p>Via Medaglie D'Oro - 119, 74121 Taranto - Italy</p>
          <p>+39 099373910</p>
          <p>info@tilebytes.com</p>
          <p>{handleTranslate('MONDAY_SHORT')} - {handleTranslate('FRIDAY_SHORT')} : {handleTranslate('OPENING_HOURS')}</p>
        </Grid>
        <Grid item xs={12} md={3}>
          <h3
            style={{
              fontWeight: "bold",
              marginBottom: "0.5rem",
              color: "#92c870",
            }}
          >
            {handleTranslate('COLUMN_3_TITLE')}
          </h3>
          <p>Privacy Policy</p>
          <p>Cookie Policy</p>
        </Grid>
        <Grid item xs={12} md={3}>
          <h3
            style={{
              fontWeight: "bold",
              marginBottom: "0.5rem",
              color: "#92c870",
            }}
          >
            {handleTranslate('COLUMN_4_TITLE')}
          </h3>
          <Stack direction={"row"}>
            {socialMediaLinks.map((link, index) => (
              <IconButton
                key={index}
                component="a"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ background: "none !important", border: "none !important",width: "fit-content", height: "fit-content" }}
              >
                {link.icon}
              </IconButton>

            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
