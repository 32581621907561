import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux"

const useLoadingDevices = () => {
    const loadingStations = useSelector((state) => state.stationsMongo.loading);
    const loadingCus = useSelector((state) => state.controlUnits.loading);
    const loadingSensors = useSelector((state) => state.sensors.loading);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(loadingSensors || loadingCus || loadingSensors) setIsLoading(true);
        else setIsLoading(false);
    }, [loadingStations, loadingCus, loadingSensors]);

    return isLoading;
}

export default useLoadingDevices