import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getMongoFile = createAsyncThunk(
  "multimedia/getMongoFile",
  async (payload) => {
    const {fileId, fileIds, typeFile, resize} = payload;
    const data = await axios.get("/api/py/readMongoFile", {
      // responseType: 'arraybuffer',
      // headers: {
      //   'Content-Type': 'video/mp4'
      // },
      params: {
        fileId: fileId,
        fileIds: fileIds,
        typeFile: typeFile,
        resize: resize
      }
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      throw error;
    });

    return data;
  }
);

const getMongoFileInfo = createAsyncThunk(
  "multimedia/readMongoFileInfo",
  async (payload) => {
    const {userId, tenantId, sensorIds, startDate, endDate, typeFile} = payload;
    const data = await axios.post("/api/py/readMongoFileInfo", {
      userId: userId,
      tenantId: tenantId,
      sensorIds: sensorIds,
      startDate: startDate,
      endDate: endDate,
      typeFile: typeFile, // video or image
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      throw error;
    });

    return data;
  }
);

const getAxisCameraLiveStream = createAsyncThunk(
  "multimedia/getAxisCameraLiveStream",
  async (camera) => {
    const params = {
      camera: camera,
    };
    try {
      const currentAuthHeader = axios.defaults.headers.common['Authorization'];
      delete axios.defaults.headers.common['Authorization'];
      const stream = await axios.get("/api/py/stream/getAxisCameraLiveStream", { params: params }, { responseType: 'stream' })
        .then((res) => {
          return res
        }).catch((error) => {
          console.log("Error: ", error);
          throw error;
        });
      if (currentAuthHeader) {
        axios.defaults.headers.common['Authorization'] = currentAuthHeader;
      }
      return stream;
    } catch (error) {
      console.log("Error: ", error);
      throw error;
    }
  }
);

const getAxisCameraAuthHeader = createAsyncThunk(
  "multimedia/getAxisCameraAuthHeader",
  async (camera) => {
    const params = {
      camera: camera,
    }
    const stream = await axios.get("/api/py/getAxisCameraAuthHeader", { params: params })
      .then((res) => {
        let data = res["data"]
        const { realm, nonce, uri, qop, nc, cnonce, response } = data["headers"]["Authorization"];
        const username = data["headers"]["Authorization"]["Digest username"];
        const digest = `Digest username="${username}", realm="${realm}", nonce="${nonce}", uri="${uri}", response="${response}", qop=${qop}, nc="${nc}", cnonce="${cnonce}"`;
        return { "header": digest, "url": data["video_url"] }
      }).catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return stream;
  }
);

const getAxisCameraImage = createAsyncThunk(
  "multimedia/getAxisCameraImage",
  async (payload) => {
    const { camera, resolution } = payload;
    const params = {
      camera: camera,
      resolution: resolution,
    }
    const stream = await axios.get("/api/py/getAxisCameraImage", { params: params })
      .then((res) => {
        return "data:image/jpeg;base64," + res.data
      }).catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
    return stream;
  }
);

// const getAxisRecordingVideo = createAsyncThunk(
//   "multimedia/getAxisRecordingVideo",
//   async (payload) => {
//     const { diskid, recordingid } = payload;
//     const params = {
//       diskid: diskid,
//       recordingid: recordingid,
//     };

//     try {
//       // Salva l'intestazione corrente di autorizzazione
//       const currentAuthHeader = axios.defaults.headers.common['Authorization'];
//       // Rimuove temporaneamente l'intestazione di autorizzazione
//       delete axios.defaults.headers.common['Authorization'];

//       console.log('Daje video');
      
//       const response = await axios.get("/api/py/stream/getAxisRecordingVideo", {
//         params: params,
//         responseType: 'arraybuffer',
//         headers: {
//           'Content-Type': 'video/x-matroska'
//         }
//       });

//       // Ripristina l'intestazione di autorizzazione se era presente
//       if (currentAuthHeader) {
//         axios.defaults.headers.common['Authorization'] = currentAuthHeader;
//       }

//       console.log('Ritorno video');
//       return response.data;
      
//     } catch (error) {
//       throw error;
//     }
//   }
// );

const getAxisRecordingVideo = createAsyncThunk(
  "multimedia/getAxisRecordingVideo",
  async (payload) => {
    const {diskid, recordingid} = payload;
    const data = await axios.get("/api/py/stream/getAxisRecordingVideo", {
      params: {
        diskid: diskid,
        recordingid: recordingid,
      },
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'video/x-matroska'
      }
    }).then((res) => {
      const buffer = res.data;
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
      console.log("Pippo: ", res)
      return res.data;
    }).catch((error) => {
      throw error;
    });

    return data;
  }
);

const getAxisRecordingInfo = createAsyncThunk(
  "multimedia/getAxisRecordingInfo",
  async (payload) => {
    const {userId, tenantId, sensorIds, recordingid} = payload;
    const data = await axios.post("/api/py/readAxisRecordingInfo", {
      userId: userId,
      tenantId: tenantId,
      sensorIds: sensorIds,
      recordingid: recordingid
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      throw error;
    });
    return data;
  }
);


const startRecording = createAsyncThunk(
  "multimedia/startRecording",
  async (payload) => {
    const {camera, discid, resolution, videocodec} = payload;
    const data = await axios.get("/api/py/startRecording", {params: {
      camera: camera,
      discid: discid,
      resolution: resolution,
      videocodec: videocodec
    }}).then((res) => {
      return res.data;
    }).catch((error) => {
      throw error;
    });
    return data;
  }
);

const stopRecording = createAsyncThunk(
  "multimedia/stopRecording",
  async (payload) => {
    const {profile} = payload;
    const data = await axios.get("/api/py/stopRecording", {params: {
      profile: profile
    }}).then((res) => {
      return res.data;
    }).catch((error) => {
      throw error;
    });
    return data;
  }
);

const deleteRecording = createAsyncThunk(
  "multimedia/deleteRecording",
  async (payload) => {
    const {recordingid} = payload;
    const data = await axios.get("/api/py/deleteRecording", {params: {
      recordingid: recordingid
    }}).then((res) => {
      return res.data;
    }).catch((error) => {
      throw error;
    });
    return data;
  }
);

const multimediaSlice = createSlice({
    name: "multimedia",
    initialState: {
      loadingRecordingList: false,
      loadingImageList: false,
      loadingRecord: null,
      loadingRecordExport: null,
      loadingRecordDelete: null,
    },
    reducers: {
      setLoadingRecordingList: (state, action) => {
        state.loadingRecordingList = action.payload;
      },
      setLoadingImageList: (state, action) => {
        state.loadingImageList = action.payload;
      },
      setLoadingRecord: (state, action) => {
        state.loadingRecord = action.payload;
      },
      setLoadingRecordExport: (state, action) => {
        state.loadingRecordExport = action.payload;
      },
      setLoadingRecordDelete: (state, action) => {
        state.loadingRecordDelete = action.payload;
      }
    }
});


export const multimediaSliceActions = {
    ...multimediaSlice.actions,
    getMongoFile,
    getMongoFileInfo,
    getAxisCameraLiveStream,
    getAxisCameraImage,
    getAxisCameraAuthHeader,
    getAxisRecordingVideo,
    getAxisRecordingInfo,
    startRecording, 
    stopRecording,
    deleteRecording
}

export default multimediaSlice.reducer;