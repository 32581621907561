import {useState, useEffect, useLayoutEffect, isValidElement } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import DownloadComponent from "./DownloadComponent/DownloadComponent";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const SearchInput = ({value, defaultValue="", onChange, onSearch}) => {
  const [val, setValue] = useState(defaultValue);
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    value && setValue(value);
  }, [value])

  useEffect(() => {
    if(!isChanged) {
      setChanged(true);
      return;
    }

    if(val === "") {
      onSearch && onSearch();
      return;
    }
    
    const timeout = onSearch && setTimeout(() => onSearch(val), 700);

    return () => {
      onSearch && clearTimeout(timeout);
    }
  }, [val])

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue);
  }

  const { t } = useTranslation("evocs");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <TextField type="search" label={handleTranslate("SEARCH", true) + '...'} value={val} onChange={(e) => handleChange(e.target.value)}/>
  )
}

const stringToDOM = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  console.log(doc?.body)
  return doc?.body;
};

const TableHeadContent = ({userActions, tableTitle, columns, selectable, editMode, canDelete, downloadFileProps, onChangeEditMode, onDeleteRow, onSearch, disabledSearch}) => {
  const handleDownloadFile = downloadFileProps?.handleDownloadFile;
  const fileName = downloadFileProps?.fileName;


  return (
    <>
    {(tableTitle || selectable || !disabledSearch) &&
    <TableRow>
      <TableCell align="left" colSpan={Object.keys(columns).length} >
        {tableTitle && 
        <div style={{display: "flex", gap: "1rem", alignItems: "center", marginBottom: "0.5rem"}}>
          <h3>{tableTitle}</h3>
          {downloadFileProps && <DownloadComponent fromTable userActions={userActions} onDownloadFile={handleDownloadFile} fileName={fileName} />}
        </div>}
        <div style={{display: "flex"}}>
        {!disabledSearch && <SearchInput onSearch={onSearch}/>}
        {selectable && (
          editMode ? 
            <div>
              <Button sx={{color: "red"}} disabled={!canDelete} onClick={onDeleteRow}>Elimina</Button>
              <Button onClick={() => onChangeEditMode(false)}>Annulla</Button>
            </div>
          :
          <IconButton onClick={() => onChangeEditMode(true)}><EditIcon /></IconButton>
        )}
        {!tableTitle && downloadFileProps && <DownloadComponent fromTable userActions={userActions} onDownloadFile={handleDownloadFile} fileName={fileName} />}
        </div>
      </TableCell>
    </TableRow>
    }
    <TableRow>
      {selectable && editMode && <TableCell align="left" valign="middle"></TableCell>}
      {Object.keys(columns).map((columnId) => (
        <TableCell key={columnId} align="left" valign="middle" sx={{ fontWeight: "bold", paddingTop: tableTitle ? "0rem" : "2.5rem", whiteSpace: "nowrap"}}>{columns[columnId]}</TableCell>
      ))}
    </TableRow>
    </>
  )
}

const TableBodyContent = ({ data, rows, pageNumber, columns, clickable, editMode=false, selectedRows, onClickRow, onClickCell, onSelectRow }) => {
  const className = clickable ? "station-item-list close" : "";
  const offset = pageNumber * rows.length;

  const getCell = (columnId, index, offset) => {
    const cellElement = data[index + offset]?.[columnId];
    if(!cellElement) return null;
    if(isValidElement(cellElement)) {
      return cellElement;
    }
    return String(cellElement);

  };

  const handleClickRow = (rowIndex) => {
    clickable && onClickRow && onClickRow(rowIndex)
  };

  const handleClickCell = (rowIndex, columnId, index) => {
    clickable && onClickCell && onClickCell(rowIndex, columnId, index)
  };

  const isRowChecked = (index) => {
    return data[index].id ? selectedRows.includes(data[index].id) : selectedRows.includes(index)
  };

  return data?.length > 0 && rows.map((e, index) => data[index+offset] ? (
    <TableRow key={index + offset} className={className} sx={{ cursor: clickable && "pointer" }} selected={selectedRows.includes(index + offset)} onClick={() => handleClickRow(index + offset)}>
      {editMode &&
        <TableCell sx={{ color: "inherit"}} align="left" onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={isRowChecked(index + offset)} onChange={(e, value) => onSelectRow(data[index+offset], index + offset, value)} />
        </TableCell>
      }
      {Object.keys(columns).map((columnId, i) => (
        <TableCell key={i} sx={{ color: "inherit", whiteSpace: "nowrap" }} align="left" onClick={() => handleClickCell(index + offset, columnId, i)}>{getCell(columnId, index, offset)}</TableCell>
      ))}
    </TableRow>
  ): (
    <TableRow key={index + offset}>
      {Object.keys(columns).map((columnId, i) => (
        <TableCell key={i} sx={{padding: "2.5rem", whiteSpace: "nowrap"}} align="left"/>
      ))}
    </TableRow>
  )
  )
}

const TableComponent = ({style={}, userActions, columns, data, clickable=false, selectable=false, selectedRows, onClickRow, onClickCell, onSelectRows, tableTitle, downloadFileProps, onDeleteRows, onSearch, rowsForPage=10, paper=true, disabledSearch=false}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [totPageNumber, setTotPageNumber] = useState(0);
  const rowsTable = new Array(rowsForPage).fill(0);
  const [selected, setSelected] = useState([])
  const [editMode, setEditMode] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    setPageNumber(0);
    calculateTotPageNumber();
  }, [data]);

  useLayoutEffect(() => {
    selectedRows !== undefined && setSelected(selectedRows);
  }, [selectedRows])

  useLayoutEffect(() => {
    if (editMode === false) {
      setSelected([]);
      onSelectRows && onSelectRows([]);
    }
  }, [editMode])

  useLayoutEffect(() => {
    if (selected.length > 0) setCanDelete(true);
    else setCanDelete(false);
  }, [selected])

  const calculateTotPageNumber = () => {
    const n = Math.ceil(data?.length / rowsTable.length);
    setTotPageNumber(n);
  };

  const changePage = (value) => {
    if (pageNumber === 0 && value < 0 || pageNumber === totPageNumber - 1 && value > 0) return;
    setPageNumber((n) => n + value);
  };

  const handleSelectRow = (rowElement, rowIndex, checked) => {
    let newSelected = [];
    if (checked) {
      newSelected = rowElement.id ? [...selected, rowElement.id] : [...selected, rowIndex];
    } else {
      newSelected = rowElement.id ? selected.filter((id) => id !== rowElement.id) : selected.filter((index) => index !== rowIndex);
    }
    setSelected(newSelected);
    onSelectRows && onSelectRows(newSelected)
  };

  const handleDeleteRow = () => {
    setEditMode(false);
    onDeleteRows && onDeleteRows(selected);
  };


  return (
    <TableContainer component={paper ? Paper : null} sx={{...style, position: "relative"}}>
      <Table>
        <TableHead>
          <TableHeadContent userActions={userActions} tableTitle={tableTitle} columns={columns} selectable={selectable} editMode={editMode} 
          canDelete={canDelete} downloadFileProps={downloadFileProps} onChangeEditMode={setEditMode} onDeleteRow={handleDeleteRow} onSearch={onSearch} disabledSearch={disabledSearch}/>
        </TableHead>
        <TableBody>
          <TableBodyContent data={data} rows={rowsTable} pageNumber={pageNumber} columns={columns} clickable={clickable} editMode={editMode} selectedRows={selected}
            onClickRow={onClickRow} onClickCell={onClickCell} onSelectRow={handleSelectRow} />
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TableCell colSpan={Object.keys(columns).length} align="center">
             
            </TableCell>
          </TableRow>
        </TableFooter> */}
      </Table>
      <div style={{position: "sticky", left: "50%", transform: "translateX(-50%)", width: "fit-content"}}>
        <ArrowBackIosIcon className='arrow' onClick={() => changePage(-1)} />
        <span>{`${pageNumber + 1}/${totPageNumber}`}</span>
        <ArrowForwardIosIcon className='arrow' onClick={() => changePage(1)} />
      </div>
    </TableContainer>
  );
}

export default TableComponent;