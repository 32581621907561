import FuseNavigation from "@fuse/core/FuseNavigation";
import clsx from "clsx";
import { memo, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNavigation } from "src/app/store/fuse/navigationSlice";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { navbarCloseMobile } from "src/app/store/fuse/navbarSlice";
import useActiveViews from "src/app/hooks/useActiveViews";

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const [activeViews] = useActiveViews();
  const activeGroups = useMemo(() => {
    const groups = [];
    activeViews?.forEach((view) => {
      if(groups.includes(view.groupId)) return;
      groups.push(view.groupId);
    });
    return groups;
  }, [activeViews])
  const filteredViews = useMemo(() => {
    const filtered = navigation.filter((element) => {
      if(element.type === "group") return activeGroups?.includes(element.id);
      else return activeViews?.find((view) => view.url === element.url);
    });
    return filtered;
  }, [navigation, activeViews]);


  return useMemo(() => {
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <FuseNavigation
        className={clsx("navigation", props.className)}
        navigation={filteredViews}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  }, [
    dispatch,
    isMobile,
    filteredViews,
    props.active,
    props.className,
    props.dense,
    props.layout,
  ]);
}

Navigation.defaultProps = {
  layout: "vertical",
};

export default memo(Navigation);
