import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    MONDAY_SHORT: "Lun",
    FRIDAY_SHORT: "Ven",
    OPENING_HOURS: "09:00 - 17:00",
    COLUMN_1_TITLE: "Chi siamo",
    COLUMN_2_TITLE: "Contatti",
    COLUMN_3_TITLE: "Supporto",
    COLUMN_4_TITLE: "Seguici sui social",
    COLUMN_1_TEXT: "Iscritta al Registro delle imprese Startup e \
    PMI Innovative dal 2019, forniamo soluzioni innovative per \
    supportare il decision making, tramite big-data \
    ambientali ottenuti attraverso la nostra rete sensoristica",
};

const FOOTER_IT = createLanguageObject(IT, sharingComponentNames.FOOTER_COMPONENT);

export default FOOTER_IT;