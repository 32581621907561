import { Divider, Grid, Stack, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import LoadingGif from '../FileManager/LoadingGif'
import InfiniteScroll from 'react-infinite-scroll-component'
import DefaultThumbnail from 'src/app/asset/images/default_thumbnail.png';
import ToggleButton from "../SharingComponents/Inputs/ToggleButton"
import ReorderIcon from '@mui/icons-material/Reorder';
import { PiGridFourFill } from "react-icons/pi";
import { grey } from '@mui/material/colors'
import AccordionComponent from '../SharingComponents/AccordionComponent'
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import NoDataMessage from '../SharingComponents/NoDataMessage'

const SwitchDisplayStack = ({ grid = false, onChange }) => {

  const handleChange = (value) => {
    onChange && onChange(value)
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_MULTIMEDIA_VIEW);
  };

  return (
    <Stack sx={{ marginLeft: "auto" }} direction="row" alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
      <ToggleButton enabled={!grid} onChange={() => handleChange(false)}><ReorderIcon /></ToggleButton>
      <ToggleButton enabled={grid} onChange={() => handleChange(true)}><PiGridFourFill /></ToggleButton>
      {grid && <Typography variant="caption">({handleTranslate("PREVIW_NOT_AVAILABLE")})</Typography>}
    </Stack>
  )
}



const ImageElement = ({ item, selected, disableThumbnail = false, onSelect }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_MULTIMEDIA_VIEW);
  };

  const accordionTitle = useMemo(() => {
    return (
      <label style={{ cursor: "inherit", fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{item.filename}</label>
    )
  }, []);

  return !disableThumbnail ? (
    <img src={DefaultThumbnail} style={{ height: "70px", background: "black", cursor: "pointer", border: selected ? "2px solid #92c870" : "none" }} onClick={() => onSelect(item)} />
  ) : (
    <AccordionComponent style={{ background: selected ? grey[200] : "white" }} openOnClickIcon title={accordionTitle} onClick={() => onSelect(item)}>
      <div style={{ display: "flex", gap: "1rem", fontWeight: "normal" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "0.1rem", color: "grey" }}>
          <label style={{ marginBottom: "8px" }}>ID:</label>
          <label> {handleTranslate("EXTENSION", true)} :</label>
          <label> {handleTranslate("RESOLUTION", true)} :</label>
          <label> {handleTranslate("SIZE", true)} :</label>
        </div>
        <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "0.1rem" }}>
          <label style={{ width: "200px", overflow: "scroll" }}>{item.displayId}</label>
          <label>{item.typefile}</label>
          <label>{item.resolution}</label>
          <label>{item.filesize}</label>
        </div>
      </div>
    </AccordionComponent>
  )
}

const PhotoList = ({ list, selected, height, displayGrid = true, hasMore, onSelectImage, onScrollNext }) => {

  return displayGrid ? (
    <InfiniteScroll height={height} scrollThreshold={1} dataLength={list?.lenght || 0} next={onScrollNext} hasMore={hasMore}>
      <Grid container spacing={0.2}>
        {list?.map((element) => (
          <Grid key={element.id} item xs={4}>
            <ImageElement disableThumbnail={!displayGrid} item={element} selected={selected?.id === element.id} onSelect={onSelectImage} />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  ) : (
    <div style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
      {list?.map((element) => (
        <ImageElement disableThumbnail={!displayGrid} key={element.id} item={element} selected={selected?.id === element.id} onSelect={onSelectImage} />
      ))}
    </div>
  )
}


const SingleMultimediaPhotoList = ({ height, list, selectedImage, onSelectImage, onScrollNext }) => {
  const loadingImageList = useSelector((state) => state.multimedia.loadingImageList);
  const [displayGrid, setDisplayGrid] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  return (
    <div>
      <SwitchDisplayStack grid={displayGrid} onChange={setDisplayGrid} />
      {!loadingImageList ?
        list.lenght > 0 ?
          <PhotoList list={list} selected={selectedImage} height={height} displayGrid={displayGrid} hasMore={hasMore} onSelectImage={onSelectImage} onScrollNext={onScrollNext} />
          : <NoDataMessage />
        :
        <LoadingGif />
      }
    </div>
  )
}

export default SingleMultimediaPhotoList