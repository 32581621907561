import LogoEvocs from "../../../app/asset/images/logoEvocsBlack.png"


function Logo({ logoOnClickHandler }) {
  return (
    <div style={{userSelect: "none"}}>
      <img
        style={{
          maxWidth: "90%",
          textAlign: "center",
          alignSelf: "center",
          userSelect: "none",
          pointerEvents: "none"
        }}
        src={LogoEvocs}
        alt="LOGO EVOCS"
      />
    </div>
  );
}

export default Logo;
