import WithAuth from "./WithAuth";
import OpenDataMeteoContent from "src/components/OpenDataMeteo/OpenDataMeteoContent";
import { viewNames } from "app/configs/routesNames";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";
import OpenDataMeteoHeader from "src/components/OpenDataMeteo/OpenDataMeteoHeader";

const OpenDataMeteo = () => {
  return (
    <WithAuth
    viewName={viewNames.OPEN_DATA_METEOROLOGY_VIEW}
      component={
        <CustomPageCarded
          header={<OpenDataMeteoHeader />}
          content={<OpenDataMeteoContent />}
          footer={<Footer />}
        />
      }
    />
  );
};

export default OpenDataMeteo;
