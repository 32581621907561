import DashboardContent from "src/components/Dashboard/DashboardContent";
import DashboardHeader from "src/components/Dashboard/DashboardHeader";
import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";


const Dashboard = () => {
  return (
    <WithAuth
      viewName={viewNames.DASHBOARD_VIEW}
      component={
        <CustomPageCarded
          header={<DashboardHeader />}
          content={<DashboardContent />}
          footer={<Footer />}
        />
      }
    />
  );
};

export default Dashboard;
