import moment from "moment";
import { openDataTimeRangeInterval, timeRangeInterval } from "src/components/StationHandling/utils";

export const DATA_TYPE_KEY = {TUTTI: "TUTTI", AQ: "AQ", METEO: "METEO"};

export const OPEN_DATA_FILTERS = {
    timeRangeInterval: [
        {
          value: "Ultime 6 ore",
          maxRangeValue: 6,
          id : 0,
          isHour : true,
        },
        {
          value: "Ultime 12 ore",
          maxRangeValue: 12,
          id : 1,
          isHour : true,
        },
        {
          value: "Ultime 24 ore",
          maxRangeValue: 24,
          id : 2,
          isHour : true,
        },
    ],
    dataType: {
        [DATA_TYPE_KEY.TUTTI]: "Tutti",
        [DATA_TYPE_KEY.AQ]: "AQ",
        [DATA_TYPE_KEY.METEO]: "METEO"
    },
    source: {
        TUTTI: "Tutti",
        [DATA_TYPE_KEY.AQ]: {    
            ARPA: "Arpa",
            EEA: "EEA",
        },
        [DATA_TYPE_KEY.METEO]: {
            PROT_CIV: "Protezione Civile",
            MISTRAL: "Mistral",
            RON: "Rete ondametrica",
            RMN: "Rete mareografica"
        }    
    },
    measure: {
        TUTTI: "Tutti",
        [DATA_TYPE_KEY.AQ]: {
            AQ1: "AQ1",
            PM10: "Pm 10",
            PM25: "Pm 2.5",
            C6H6: "C6H6",
            O3: "O3",
            NO2: "NO2"
        },
        [DATA_TYPE_KEY.METEO]: {
            WIND: "Vento",
            TEMP: "Temperatura",
            PRESSURE: "Pressione",
            HUMIDITY: "Umidità",
            RAIN: "Pioggia",
        }      
    }
};

export const openDataSourceInAPIString = (source) => {
    switch(source) {
        case OPEN_DATA_FILTERS.source.TUTTI:
          return null;
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.AQ].ARPA:
          return "arpa"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.AQ].EEA:
          return "EEA"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].MISTRAL:
          return "mistral"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].PROT_CIV:
          return "prot_civ";
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].RON:
          return "ron";
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].RMN:
          return "rmn";
        case null: 
         return null;
        default: 
          return source;
    }
}

export const openDataTypeInAPIString = (type) => {
    switch(type) {
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.TUTTI]:
          return null;
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.AQ]:
          return "AQ"
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.METEO]:
          return "METEO"
        default: 
          return type;
    }
}

export const OPEN_DATA_EXPIRATION_TIME = 60000 * 30;


  



export const toUTC = (startingDate) => {
  // Ottieni il timestamp della data locale
  const timestamp = startingDate.getTime();
  // Ottieni il fuso orario locale
  console.log("toUtc");
  const offset = startingDate.getTimezoneOffset();
  // Converti il timestamp in UTC
  const utcTimestamp = timestamp + offset * 60000;
  // Crea un nuovo oggetto Date con il timestamp UTC
  const utcDate = new Date(utcTimestamp);
  // Ritorna l'oggetto Date in UTC
  return utcDate;
};

export const isTimeDifferenceGreaterThanHours = (
  timestamp1,
  timestamp2,
  hours
) => {
  const time1 = null;
  const time2 = null;

  if (oggetto instanceof Date) {
    time1 = new Date(timestamp1).getTime();
    time2 = new Date(timestamp2).getTime();
  } else if (typeof oggetto === "number" && !isNaN(oggetto)) {
    time1 = timestamp1;
    time2 = timestamp2;
  } else {
    return null;
  }
  // Calcolo la differenza in millisecondi
  const timeDifference = Math.abs(time2 - time1);
  // Calcolo il numero di ore dalla differenza in millisecondi
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  // Verifico se la differenza è maggiore delle ore specificate
  return hoursDifference > hours;
};

// export const differenceInHours = (timestamp1, timestamp2) => {
//   let time1 = null;
//   let time2 = null;

//   if (timestamp1 === null || timestamp2 === null) return null;

//   if (timestamp1 instanceof Date) {
//     time1 = new Date(timestamp1).getTime();
//   } else if (typeof timestamp1 === "number" && !isNaN(timestamp1)) {
//     time1 = timestamp1;
//   }

//   if (timestamp2 instanceof Date) {
//     time2 = new Date(timestamp2).getTime();
//   } else if (typeof timestamp2 === "number" && !isNaN(timestamp2)) {
//     time2 = timestamp2;
//   }

//   if (time1 === null || time2 === null) return null;

//   // Calcolo la differenza in millisecondi
//   const timeDifference = Math.abs(time2 - time1);
//   // Calcola la differenza in ore
//   const ore = timeDifference / (1000 * 60 * 60);
//   return ore;
// };

export const convertUtcArrayToLocalTimezone = (utcDatesArray) => {
  if (!Array.isArray(utcDatesArray)) {
    throw new Error("Input deve essere un array di oggetti Date.");
  }
  const convertedDates = utcDatesArray.map((date) => {
    if (!(date instanceof Date)) {
      throw new Error("Gli elementi dell'array devono essere oggetti Date.");
    }
    const localDates = [];
    for (const utcDate of utcDatesArray) {
      const localDate = moment(utcDate).toLocalTime();
      localDates.push(localDate);
    }
    return localDates;
  });

  return convertedDates;
};

export const convertUtcDateToLocalTimezone = (utcElem) => {

  if (!(utcElem instanceof Date)) {
    throw new Error("L'elemento in input deve essere un oggetto Date");
  }
  return moment(utcElem).local().toDate();
};

export const subtractDays = (date, days) => {
  return moment(date).subtract(days, "days").toDate();
}
export const subtractHours = (date, hours) => {
  return moment(date).subtract(hours, "hours").toDate();
}

export const getNewStartEndDate = (id, openData=false) => {
  try {
    // console.log('getNewStartEndDate');
    const intervalObj = openData === true ? OPEN_DATA_FILTERS.timeRangeInterval.filter((e) => e.id === id)[0]: timeRangeInterval.filter((e) => e.id === id)[0];
    const {isHour, maxRangeValue} = intervalObj;
    const end = new Date();
    const start = isHour? subtractHours(end, maxRangeValue) : subtractDays(end, maxRangeValue)
    return {start: start, end: end};
  } catch (error) {
    console.log(error);
    throw new Error(
      `Impossibile calcore le nuove date per l'intervallo selezionato`
    );
  }
};

export const differenceInHours = (data1, data2) => {
  const firstDate = moment(data1);
  const secondDate = moment(data2);
  const diff = secondDate.diff(firstDate, 'hours');
  return diff;
}

export const vesselStatus = {
  WITH_DESTINATION_PORT_AND_ARRIVE : 0,
  WITH_DESTINATION_PORT_NOT_YET_ARRIVED : 1,
  IN_PORT_AREA_WITH_A_DESTINATION_DIFFERENT_FROM_THE_PORT:2, 
  SHIPS_IN_PORT_ANCHORED_OR_IN_THE_AREA: 3, //case 0 union case 2
}

export const italianPortsId = {
  "Taranto": 10187, //aishub id 2217
  "Sabaudia": null, //aishub id 2448
  "Livorno": 10006, //aishub id 2394
  "Cagliari": 9950, //aishub id 2656
  "Licata": 10032, //aishub id 2873
  "Milazzo": 10054, // aishub id 2189
  "La Spezia": 10176, // aishub id 2169
  "Lecce": null, // aishub id 3326
  "Palermo": 10107 , // aishub id 2098
  "Mondello, Palermo": 10056, // aishub id 2986
  "Venezia": 10210 , // aishub id 2573
  "Gela": 10000 , // aishub id 2521
  "Napoli": 10070 , // aishub id 2525
  "Chioggia": 9960, // aishub id 2851
  "Ischia": 10018, // aishub id 2914
  "San Benedetto del Tronto": 10158 , // aishub id 3224
  "Genova": 10006, // aishub id 2489
  "Porto d'Ischia": 10092,
  "Barletta-Andria-Trani": 9945 ,
  "Brindisi": 9941,
  "Gallipoli": 9999, 
  "Bari": 9948,

};

export const navigationalStatus = {
  0: "in navigazione con motore",
  1: "all'ancora",
  2: "senza comando",
  3: "manovra limitata",
  4: "limitato dal suo pescaggio",
  5: "ormeggiato",
  6: "incagliato",
  7: "impegnato nella pesca",
  8: "in navigazione a vela",
  9: "riservato per futura modifica dello stato di navigazione per navi che trasportano merci pericolose, sostanze nocive o inquinanti marini, o categorie di pericolo o inquinanti IMO C, natanti ad alta velocità (HSC)",
  10: "riservato per futura modifica dello stato di navigazione per navi che trasportano merci pericolose (DG), sostanze nocive (HS) o inquinanti marini (MP), o categorie di pericolo o inquinanti IMO A, ala in superficie (WIG)",
  11: "imbarcazione a motore che traina a poppa (uso regionale)",
  12: "imbarcazione a motore che spinge avanti o traina a fianco (uso regionale)",
  13: "riservato per uso futuro",
  14: "AIS-SART (attivo), MOB-AIS, EPIRB-AIS",
  15: "indefinito = predefinito (usato anche da AIS-SART, MOB-AIS ed EPIRB-AIS in fase di prova)",
};

export const getActualDateAsTimestamp = () => {
  const currentDateTimeUTC = new Date();
  const timestamp = Math.floor(currentDateTimeUTC.getTime() / 1000);
  return timestamp;
}

function getCountryCodeFromIMO(imoNumber) {
  // Estrai le prime tre cifre dall'IMO number
  return countryCode = imoNumber.toString().substring(0, 3);
}

export const getApiTimestamp = (timestampOrDate) => {
  let timestamp;

  if (timestampOrDate instanceof Date) {
    timestamp = timestampOrDate.getTime();
  } else if (typeof timestampOrDate === 'number') {
    timestamp = timestampOrDate; 
  } else {
    return null;
  }

  if(timestamp > 9999999999) {
    return Math.floor(timestamp / 1000);
  }else return timestamp;


  // if (timestamp % 1000 !== 0) {
  //   return Math.floor(timestamp / 1000);
  // } else {
  //   return timestamp;
  // }
}

export const bytesToSize = (bytes, seperator = "") => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes}${seperator}${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
}

export const shipTypeEng = 
{
  0: 'Not available (default)',
  1: 'Reserved for future use',
  2: 'Reserved for future use',
  3: 'Reserved for future use',
  4: 'Reserved for future use',
  5: 'Reserved for future use',
  6: 'Reserved for future use',
  7: 'Reserved for future use',
  8: 'Reserved for future use',
  9: 'Reserved for future use',
  10: 'Reserved for future use',
  11: 'Reserved for future use',
  12: 'Reserved for future use',
  13: 'Reserved for future use',
  14: 'Reserved for future use',
  15: 'Reserved for future use',
  16: 'Reserved for future use',
  17: 'Reserved for future use',
  18: 'Reserved for future use',
  19: 'Reserved for future use',
  20: 'Wing in ground (WIG), all ships of this type',
  21: 'Wing in ground (WIG), Hazardous category A',
  22: 'Wing in ground (WIG), Hazardous category B',
  23: 'Wing in ground (WIG), Hazardous category C',
  24: 'Wing in ground (WIG), Hazardous category D',
  25: 'Wing in ground (WIG), Reserved for future use',
  26: 'Wing in ground (WIG), Reserved for future use',
  27: 'Wing in ground (WIG), Reserved for future use',
  28: 'Wing in ground (WIG), Reserved for future use',
  29: 'Wing in ground (WIG), Reserved for future use',
  30: 'Fishing',
  31: 'Towing',
  32: 'Towing: length exceeds 200m or breadth exceeds 25m',
  33: 'Dredging or underwater ops',
  34: 'Diving ops',
  35: 'Military ops',
  36: 'Sailing',
  37: 'Pleasure Craft',
  38: 'Reserved',
  39: 'Reserved',
  40: 'High speed craft (HSC), all ships of this type',
  41: 'High speed craft (HSC), Hazardous category A',
  42: 'High speed craft (HSC), Hazardous category B',
  43: 'High speed craft (HSC), Hazardous category C',
  44: 'High speed craft (HSC), Hazardous category D',
  45: 'High speed craft (HSC), Reserved for future use',
  46: 'High speed craft (HSC), Reserved for future use',
  47: 'High speed craft (HSC), Reserved for future use',
  48: 'High speed craft (HSC), Reserved for future use',
  49: 'High speed craft (HSC), No additional information',
  50: 'Pilot Vessel',
  51: 'Search and Rescue vessel',
  52: 'Tug',
  53: 'Port Tender',
  54: 'Anti-pollution equipment',
  55: 'Law Enforcement',
  56: 'Spare - Local Vessel',
  57: 'Spare - Local Vessel',
  58: 'Medical Transport',
  59: 'Noncombatant ship according to RR Resolution No. 18',
  60: 'Passenger, all ships of this type',
  61: 'Passenger, Hazardous category A',
  62: 'Passenger, Hazardous category B',
  63: 'Passenger, Hazardous category C',
  64: 'Passenger, Hazardous category D',
  65: 'Passenger, Reserved for future use',
  66: 'Passenger, Reserved for future use',
  67: 'Passenger, Reserved for future use',
  68: 'Passenger, Reserved for future use',
  69: 'Passenger, No additional information',
  70: 'Cargo, all ships of this type',
  71: 'Cargo, Hazardous category A',
  72: 'Cargo, Hazardous category B',
  73: 'Cargo, Hazardous category C',
  74: 'Cargo, Hazardous category D',
  75: 'Cargo, Reserved for future use',
  76: 'Cargo, Reserved for future use',
  77: 'Cargo, Reserved for future use',
  78: 'Cargo, Reserved for future use',
  79: 'Cargo, No additional information',
  80: 'Tanker, all ships of this type',
  81: 'Tanker, Hazardous category A',
  82: 'Tanker, Hazardous category B',
  83: 'Tanker, Hazardous category C',
  84: 'Tanker, Hazardous category D',
  85: 'Tanker, Reserved for future use',
  86: 'Tanker, Reserved for future use',
  87: 'Tanker, Reserved for future use',
  88: 'Tanker, Reserved for future use',
  89: 'Tanker, No additional information',
  90: 'Other Type, all ships of this type',
  91: 'Other Type, Hazardous category A',
  92: 'Other Type, Hazardous category B',
  93: 'Other Type, Hazardous category C',
  94: 'Other Type, Hazardous category D',
  95: 'Other Type, Reserved for future use',
  96: 'Other Type, Reserved for future use',
  97: 'Other Type, Reserved for future use',
  98: 'Other Type, Reserved for future use',
  99: 'Other Type, no additional information'
}

export const shipTypeIta = {
  0: 'Non disponibile (predefinito)',
  1: 'Riservato per uso futuro',
  2: 'Riservato per uso futuro',
  3: 'Riservato per uso futuro',
  4: 'Riservato per uso futuro',
  5: 'Riservato per uso futuro',
  6: 'Riservato per uso futuro',
  7: 'Riservato per uso futuro',
  8: 'Riservato per uso futuro',
  9: 'Riservato per uso futuro',
  10: 'Riservato per uso futuro',
  11: 'Riservato per uso futuro',
  12: 'Riservato per uso futuro',
  13: 'Riservato per uso futuro',
  14: 'Riservato per uso futuro',
  15: 'Riservato per uso futuro',
  16: 'Riservato per uso futuro',
  17: 'Riservato per uso futuro',
  18: 'Riservato per uso futuro',
  19: 'Riservato per uso futuro',
  20: 'Wing in ground (WIG), tutte le navi di questo tipo',
  21: 'Wing in ground (WIG), Categoria A pericolosa',
  22: 'Wing in ground (WIG), Categoria B pericolosa',
  23: 'Wing in ground (WIG), Categoria C pericolosa',
  24: 'Wing in ground (WIG), Categoria D pericolosa',
  25: 'Wing in ground (WIG), Riservato per uso futuro',
  26: 'Wing in ground (WIG), Riservato per uso futuro',
  27: 'Wing in ground (WIG), Riservato per uso futuro',
  28: 'Wing in ground (WIG), Riservato per uso futuro',
  29: 'Wing in ground (WIG), Riservato per uso futuro',
  30: 'Pesca',
  31: 'Rimorchio',
  32: 'Rimorchio: lunghezza superiore a 200 m o larghezza superiore a 25 m',
  33: 'Dragaggio o operazioni subacquee',
  34: 'Operazioni di immersione',
  35: 'Operazioni militari',
  36: 'Vela',
  37: 'Imbarcazione da diporto',
  38: 'Riservato',
  39: 'Riservato',
  40: 'Imbarcazione ad alta velocità (HSC), tutte le navi di questo tipo',
  41: 'Imbarcazione ad alta velocità (HSC), Categoria A pericolosa',
  42: 'Imbarcazione ad alta velocità (HSC), Categoria B pericolosa',
  43: 'Imbarcazione ad alta velocità (HSC), Categoria C pericolosa',
  44: 'Imbarcazione ad alta velocità (HSC), Categoria D pericolosa',
  45: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  46: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  47: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  48: 'Imbarcazione ad alta velocità (HSC), Riservato per uso futuro',
  49: 'Imbarcazione ad alta velocità (HSC), Nessuna informazione aggiuntiva',
  50: 'Nave pilota',
  51: 'Nave di soccorso',
  52: 'Rimorchiatore',
  53: 'Rimorchiatore portuale',
  54: 'Attrezzatura antinquinamento',
  55: 'Forze dell\'ordine',
  56: 'Riserva - Nave locale',
  57: 'Riserva - Nave locale',
  58: 'Trasporto medico',
  59: 'Nave non combattente secondo la risoluzione RR n. 18',
  60: 'Passeggeri, tutte le navi di questo tipo',
  61: 'Passeggeri, Categoria A pericolosa',
  62: 'Passeggeri, Categoria B pericolosa',
  63: 'Passeggeri, Categoria C pericolosa',
  64: 'Passeggeri, Categoria D pericolosa',
  65: 'Passeggeri, Riservato per uso futuro',
  66: 'Passeggeri, Riservato per uso futuro',
  67: 'Passeggeri, Riservato per uso futuro',
  68: 'Passeggeri, Riservato per uso futuro',
  69: 'Passeggeri, Nessuna informazione aggiuntiva',
  70: 'Cargo, tutte le navi di questo tipo',
  71: 'Cargo, Categoria A pericolosa',
  72: 'Cargo, Categoria B pericolosa',
  73: 'Cargo, Categoria C pericolosa',
  74: 'Cargo, Categoria D pericolosa',
  75: 'Cargo, Riservato per uso futuro',
  76: 'Cargo, Riservato per uso futuro',
  77: 'Cargo, Riservato per uso futuro',
  78: 'Cargo, Riservato per uso futuro',
  79: 'Cargo, Nessuna informazione aggiuntiva',
  80: 'Nave cisterna, tutte le navi di questo tipo',
  81: 'Nave cisterna, Categoria A pericolosa',
  82: 'Nave cisterna, Categoria B pericolosa',
  83: 'Nave cisterna, Categoria C pericolosa',
  84: 'Nave cisterna, Categoria D pericolosa',
  85: 'Nave cisterna, Riservato per uso futuro',
  86: 'Nave cisterna, Riservato per uso futuro',
  87: 'Nave cisterna, Riservato per uso futuro',
  88: 'Nave cisterna, Riservato per uso futuro',
  89: 'Nave cisterna, Nessuna informazione aggiuntiva',
  90: 'Altri tipi, tutte le navi di questo tipo',
  91: 'Altri tipi, Categoria A pericolosa',
  92: 'Altri tipi, Categoria B pericolosa',
  93: 'Altri tipi, Categoria C pericolosa',
  94: 'Altri tipi, Categoria D pericolosa',
  95: 'Altri tipi, Riservato per uso futuro',
  96: 'Altri tipi, Riservato per uso futuro',
  97: 'Altri tipi, Riservato per uso futuro',
  98: 'Altri tipi, Riservato per uso futuro',
  99: 'Altri tipi, Nessuna informazione aggiuntiva'
};



// "MMSI": 538006130,
// "TIME": "2023-10-12 18:22:29 GMT",
// "LONGITUDE": 17.19449,
// "LATITUDE": 40.47252,
// "COG": 109.2,
// "SOG": 0,
// "HEADING": 162,
// "ROT": 0,
// "NAVSTAT": 5,
// "IMO": 9708215,
// "NAME": "ARDMORE SEAFOX",
// "CALLSIGN": "V7JZ6",
// "TYPE": 80,
// "A": 150,
// "B": 33,
// "C": 8,
// "D": 24,
// "DRAUGHT": 8.6,
// "DEST": "IT TAR",
// "ETA": "10-12 03:00"



export const createLanguageObject = (originalObject, prefix) => {
  const newObject = {};
  
  for (const key in originalObject) {
      if (originalObject.hasOwnProperty(key)) {
          const newKey = `${prefix}_${key}`;
          newObject[newKey] = originalObject[key];
      }
  }
  return newObject;
};

export const translate = (t, textId, viewName) => {
  if (viewName) return t(`${viewName}_${textId}`);
  else return t(textId);
};