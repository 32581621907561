import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const styles = {
  bullet: {
    borderRadius: "50%",
    width: "10px",
    height: "10px",
    cursor: "pointer"
  }
}

const Carousel = ({style={}, containerStyle={}, value, defaultValue=0, children, onChange, disableArrows=true}) => {
  const graphScrollRef = useRef();
  const [smoothBehaviour, setSmoothBehaviour] = useState(false);
  const [childrens, setChildrens] = useState();
  const [currentIndex, setCurrentIndex] = useState(defaultValue);
  const [childrenCount, setChildrenCount] = useState(0)
 
  useEffect(() => {
    setSmoothBehaviour(true);
  }, [])

  useLayoutEffect(() => {
    setChildrens(children);
  }, [children])

  useLayoutEffect(() => {
    value !== null && value !== undefined && setCurrentIndex(value);
  }, [value])

  useLayoutEffect(() => {
    setChildrenCount(() => {
      if(Array.isArray(children)) {
        const filterChildren = children.filter((e) => e);
        return filterChildren.length;
      }
      else if(children) return 1;
      else return 0;
    })
  }, [children])

  useLayoutEffect(() => {
    if(childrenCount > 0 && currentIndex >= childrenCount) setCurrentIndex(childrenCount-1);
  }, [childrenCount])

  useEffect(() => {
    const left = graphScrollRef.current.clientWidth * currentIndex;
    graphScrollRef.current.scrollTo({left: left, behavior: smoothBehaviour ? "smooth" : "instant"})
    onChange && onChange(currentIndex)
  }, [currentIndex]);

  useEffect(() => {
    const onScrollEnd = (event) => {
      const clientWidth = event.currentTarget.clientWidth;
      const scrollLeft = event.currentTarget.scrollLeft;
      setCurrentIndex(Math.round(scrollLeft / clientWidth));
    }
    graphScrollRef.current?.addEventListener("scrollend", onScrollEnd)

    return () => {
      graphScrollRef.current?.removeEventListener("scollend", onScrollEnd);
    }
  }, [graphScrollRef])


  return (
    <div style={{...style, display: "flex", flexDirection: "column", height: "100%"}}>
      <div ref={graphScrollRef} style={{...containerStyle, display: "flex", gap: "1rem", overflow: "auto", scrollSnapType: "x mandatory", scrollBehavior: "smooth", height: "100%", scrollbarWidth: "none"}}>
        {childrenCount > 1 ?
        childrens.map((element, index) => (
          <div key={index} style={{minWidth: "100%", scrollSnapAlign: "start"}}>
            {element}
          </div>
        ))
        : 
        <div style={{minWidth: "100%", scrollSnapAlign: "start"}}>
          {childrens}
        </div>
        }
      </div>
      {childrenCount > 1 && 
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-around", gap: "1rem", marginTop: "1rem"}}>
        {!disableArrows && <ArrowBackIcon sx={currentIndex > 0 ? {opacity: 1, pointerEvents: "auto", cursor: "pointer"} : {opacity: 0, pointerEvents: "none"}} onClick={() => setCurrentIndex((prev) => prev-1)}/>}
        <div style={{display: "flex", gap: "1rem"}}>
          { new Array(childrenCount).fill(0).map((e, index) => (
            <div key={index} style={{...styles.bullet, background: index === currentIndex ? "black" : "rgba(0, 0, 0, 0.3)"}} onClick={() => setCurrentIndex(index)}/>
          ))}
        </div>
        {!disableArrows && <ArrowForwardIcon sx={currentIndex < childrenCount-1 ? {opacity: 1, pointerEvents: "auto", cursor: "pointer"} : {opacity: 0, pointerEvents: "none"}} onClick={() => setCurrentIndex((prev) => prev+1)}/>}
      </div> }      
    </div>
  )
}

export default Carousel