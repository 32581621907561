import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Multimedia",
};

const MULTIMEDIA_EN = createLanguageObject(EN, viewNames.MULTIMEDIA_VIEW);


export default MULTIMEDIA_EN;