import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import useMobile from 'src/app/hooks/useMobile';
import { useEffect, useState } from 'react';
import { Box, Drawer } from '@mui/material';


const drawerWidth = 380;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
  // background: "none",
  // boxShadow: "none",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerFooter = styled('div')(({ theme }) => ({
  position: "sticky",
  left: 0,
  bottom: 0,
  width: "100%",
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  background: "white",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const CustomDrawer = styled(Drawer)(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    }),
  }),
);

export default function SingleMultimediaSideBar({children, isOpen, onOpen, onClose}) {
  const [open, setOpen] = useState();
  const [xs, sm, md, lg, xl] = useMobile();

  useEffect(() => {
    if(lg === undefined || xl === undefined || open != undefined) return;
    if(lg || xl) handleDrawerOpen();
    else handleDrawerClose();
  }, [lg, xl])

  useEffect(() => {
    isOpen !== undefined && setOpen(isOpen);
  }, [isOpen])

  const handleDrawerOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const getVaraint = () => {
    if(xs || sm || md) return  "temporary";
    else return  "permanent"
  }


  return (
    <>
    <CustomDrawer variant={getVaraint()} anchor='right' open={open} onClose={handleDrawerClose}>
      <Box sx={{pt: {xs: 0, lg: "64px"}}}/>
      <div style={{ opacity: open ? 1 : 0, overflowY: "scroll", height: "100%"}}>
        {children}
      </div>
      <DrawerFooter>
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <MenuOpenIcon sx={{transform: open ? "scaleX(-1)" : "scaleX(1)"}}/>
        </IconButton>
      </DrawerFooter>
    </CustomDrawer> 
    {!open &&
    <IconButton sx={{position: "fixed", bottom: "0.5rem", right: "0.5rem"}} onClick={handleDrawerOpen}>
      <MenuOpenIcon fontSize='large'/>
    </IconButton>}
    </>
  );
}
