import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Portale navale",
    SHIPS_OF_PORT: "Navi nel porto di ",
    SHIPS_NUMBER: "Numero di navi",
    COUNTRY: "Paese",
};

const VESSELS_IT = createLanguageObject(IT, viewNames.VESSELS_VIEW);

export default VESSELS_IT;