import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Creative laboratory",
};

const TEST_PAGE_EN = createLanguageObject(EN, viewNames.TEST_PAGE_VIEW);



export default TEST_PAGE_EN;