import { Box } from "@mui/system";
import { viewNames } from "app/configs/routesNames";
import MapComponent from "../SharingComponents/MapComponent";
import useOpendata from "src/app/hooks/useOpendata";
import { useSelector } from "react-redux";
import MeteoTable from "./MeteoTable";
import LoadingGif from "../FileManager/LoadingGif";
import OpenDataMeteoContentHeader from "./OpenDataMeteoContentHeader";
import { useCallback, useEffect, useState } from "react";
import { getOpenDataLastRelevations } from "app/store/evocsSlice/openaData/openaDataActions";
import { getOpenDataFiles } from "app/store/evocsSlice/reports/reportsActions";
import useFilters from "src/app/hooks/useFilters";
import { Grid, Paper } from "@mui/material";
import NoDataMessage from "../SharingComponents/NoDataMessage";
import useMobile from "src/app/hooks/useMobile";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const viewName = viewNames.OPEN_DATA_METEOROLOGY_VIEW;

const OpenDataMeteoContent = () => {
  const [xs] = useMobile();
  const measures = useSelector((state) => state.configuration.constants.measure);
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const sourceSelect = filters.open_source;

  const openSource = useSelector((state) => state.configuration.constants.open_source);
  const [popupsContent, setPoupsContent] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [selectedMarker, setSelecteMarker] = useState();
  const fileName = 'open_data_meteo';
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.OPEN_DATA_METEOROLOGY_VIEW);
  };


  const [data, markers, loadingMarkers, loadingData] = useOpendata(timeRangeInView.startDate, timeRangeInView.endDate, "METEO", sourceSelect.selected);

  useEffect(() => {
    if (!markers || !measures) return;
    setPoupsContent(markers.map((element, index) => {
      const newElement = { ...element };
      delete newElement.lat; delete newElement.lon; delete newElement.icon;
      const measuresLabels = [];
      newElement.measures?.forEach((measure) => {
        const measureId = Object.keys(measures).find((id) => measures[id].measure === measure);
        measuresLabels.push(handleTranslate(`MEASURE_${measures[measureId]?.measure.toUpperCase() || measure.toUpperCase()}`, true));
      })
      const openSourceId = Object.keys(openSource).find((id) => openSource[id].open_source === newElement.source);
      newElement.title = openSource[openSourceId].label;
      newElement.source = { label: handleTranslate("SOURCE", true), value: openSource[openSourceId].label };
      newElement.station = { label: handleTranslate("STATION", true), value: newElement.station };
      newElement.type = { label: handleTranslate("TYPE", true), value: newElement.type };
      newElement.measures = { label:  handleTranslate("MEASURE", true), value: measuresLabels };
      // console.log('newElement', newElement);
      return newElement;
    }))
  }, [markers, measures, t]);

  // useEffect(() => {
  //   if (!markers || !measures) return;
  //   setPoupsContent(markers.map((element, index) => {
  //     const newElement = { ...element };
  //     delete newElement.lat; delete newElement.lon; delete newElement.icon;
  //     const measuresLabels = [];
  //     newElement.measures?.forEach((measure) => {
  //       const measureId = Object.keys(measures).find((id) => measures[id].measure === measure);
  //       measuresLabels.push(measures[measureId]?.label || measure);
  //     })
  //     const openSourceId = Object.keys(openSource).find((id) => openSource[id].open_source === newElement.source);
  //     newElement.title = openSource[openSourceId].label;
  //     newElement.source = { label: "Sorgente", value: openSource[openSourceId].label };
  //     newElement.station = { label: "Stazione", value: newElement.station };
  //     newElement.type = { label: "Tipo", value: newElement.type };
  //     newElement.measures = { label: "Misure", value: measuresLabels };
  //     return newElement;
  //   }))
  // }, [markers, measures]);

  useEffect(() => {
    if (!selectedMarker) return;
    getOpenDataLastRelevations(markers[selectedMarker].station).then((res) => {
      getNewPopups(res);
    });
  }, [selectedMarker])

  const getNewPopups = (lastRelevations) => {
    if (!lastRelevations || lastRelevations.length === 0) return;
    const newPopups = [...popupsContent];
    lastRelevations.forEach((el) => {
      const index = newPopups?.findIndex((popup) => popup.station.value === el.station);
      newPopups[index].lastRelevations = { title: handleTranslate("LAST_RELEVATIONS", true)};
      // newPopups[index].lastRelevations = { title: "Ultime rilevazioni" };

      Object.keys(el.detections).forEach((measure) => {
        const measureId = measures && Object.keys(measures).find((id) => measure === measures[id].measure);
        const label = measures[measureId]?.label || measure;
        newPopups[index][label] = el.detections[measure];
      })
    });

    setPoupsContent(newPopups);
  }

  const handleClickMarker = useCallback((marker, popup, index) => {
    setSelecteMarker(index);
  }, [popupsContent])


  const handleClickTableRow = (rowIndex) => {
    const dataElement = data[rowIndex];
    const mIndex = markers.findIndex((m) => m.station === dataElement.station && m.source === dataElement.source);
    const newCenter = { lat: markers[mIndex].lat, lon: markers[mIndex].lon };
    // window.scrollTo({top: -1000, behavior: "smooth"});
    setMapCenter(newCenter);
    setSelecteMarker(mIndex);
  };


  const handleDownloadFile = async (fileType) => {
    const fileData = await getOpenDataFiles(timeRangeInView.startDate, timeRangeInView.endDate, sourceSelect.selected, null, null, 'METEO', fileType, fileName);
    return fileData;
  };


  return (
    <Box padding={{ xs: "1rem", sm: "2rem 5rem" }}>
      <OpenDataMeteoContentHeader />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8}>
          {loadingData ?
            <Paper sx={{ display: "flex", alignItems: "center", height: "100%", minHeight: "40vh" }}><LoadingGif /></Paper>
            :
            data?.length > 0 ?
              <MeteoTable data={data} onClickRow={handleClickTableRow} downloadFileProps={{ handleDownloadFile: handleDownloadFile, fileName: fileName }} />
              :
              <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", minHeight: "40vh" }}>
                <NoDataMessage />
              </Paper>
          }
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MapComponent height={xs ? "40vh" : "77vh"} cluster center={mapCenter} markers={markers} popupsContent={popupsContent} selectedMarker={selectedMarker} onClickMarker={handleClickMarker} />
        </Grid>
      </Grid>

    </Box>
  )
}

export default OpenDataMeteoContent;