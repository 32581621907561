import { Box } from "@mui/system";
import React from "react";
import { convertHexToRGBA } from "src/components/StationHandling/utils";

const OverlayCustom = ({
  visible = false,
  hexBackColor,
  opacity = 0.8,
  linearGradient,
  children,
}) => {
  const defaultGradient = "linear-gradient(135deg, #27AF60, #222F3D)";
  let bgCol = "rgba(0, 0, 0, 0.5)";

  if (hexBackColor) {
    bgCol = convertHexToRGBA(hexBackColor, opacity);
  }

  let backgroundStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundColor: bgCol,
    top: 0,
    left: 0,
    zIndex: 999,
    visibility: visible ? "visible" : "hidden",
    width: "100%",
    height: "100%",
  };

  if (linearGradient !== undefined) {
    if (linearGradient === "default")
      backgroundStyle.background = defaultGradient;
    else backgroundStyle.background = linearGradient;
  }

  return visible && <Box style={backgroundStyle}>{children}</Box>;
};

export default OverlayCustom;
