import { viewNames } from 'app/configs/routesNames';
import { getLastStationPosition, getStationRoute } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import MapComponent from '../SharingComponents/MapComponent';
import { useSelector } from 'react-redux';
import { CircleMarker, Polyline, Popup } from 'react-leaflet';
import { TIME_FORMAT, formatDate } from '../StationHandling/utils';
import LoadingGif from '../FileManager/LoadingGif';
import { useLocation } from 'react-router-dom';
import useFilters from 'src/app/hooks/useFilters';
import mkrIcon from 'src/app/asset/markers/Blue.png';
import { MdGpsFixed } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const viewName = viewNames.SINGLE_STATION_VIEW;

const styles = {
  mapModalView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 1000,
    background: "rgba(0, 0, 0, .5)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};




const NoPositionView = ({ loading = false, visible = false }) => {
  return (
    <div style={{ ...styles.mapModalView, opacity: visible ? 1 : 0, pointerEvents: visible ? "auto" : "none" }}>
      {!loading ?
        <h2 style={{ color: "white", fontWeight: "bold" }}>Posizione o percorso non disponibile</h2>
        : <LoadingGif />}
    </div>
  )
};

const CustomPolyline = ({ markers }) => {
  const polylines = useMemo(() => {
    return markers.map((m) => [m[1], m[2]]);
  }, [markers])

  // const getColor = useCallback((index) => {
  //   console.log("length: ", markers.length)
  //   return index === markers.length - 1 ? "red" : "white"; //"#1588D9"
  //   // return index === 0 ? "black" : index === markers.length-1  ? "red" : "blue"
  // }, [markers.length])

  const getColor = useCallback((index) => {
    return index === 0 ? "black" : index === markers.length-1 ? "red" : "white"; 
  }, [markers.length]);

  const getFillColor = useCallback((index) => {
    console.log("length: ", markers.length)
    return index === 0 ? "white" : index === markers.length-1 ? "red" : "white"; 
    // return index === 0 ? "black" : index === markers.length-1  ? "red" : "blue"
  }, [markers.length]);


  const getTitle = useCallback((index) => {
    return index === 0 ? handleTranslate('DEPARTURE', true) : index === markers.length - 1 ? handleTranslate('ARRIVAL', true) : `Step ${index}`
  }, [markers.length]);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };

  return (
    <>
      <Polyline color="#0f53ff" positions={polylines} weight={8} smoothFactor={1.5} />
      {markers?.map((m, index) => (
        <CircleMarker key={index} pathOptions={{color: getColor(index), fillColor: getFillColor(index)}} center={polylines[index]} 
        weight={index === 0 ? 2 : 0} radius={index === 0 || index === polylines.length-1 ? 8 : 2}  fillOpacity={1}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
        }}>
          <Popup closeButton={false}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>{getTitle(index)}</h3>
              <label>{handleTranslate('MEASURE_LAT', true)}: {m[1]}</label>
              <label>{handleTranslate('MEASURE_LON', true)}: {m[2]}</label>
              <label>{handleTranslate('ON_THE_DATE_OF', true)}: {formatDate(new Date(m[0] * 1000), TIME_FORMAT.COMPLETE_DATE)}</label>
            </div>
          </Popup>
        </CircleMarker>
      ))}
    </>

  )
}

const SingleStationHandlingMap = ({ stationId, displayMap, enableLastPosition, enableRoute }) => {
  const location = useLocation();
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const [markers, setMarkers] = useState([]);
  const [popupsContent, setPopupsContent] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [loadingMap, setLoadingMap] = useState(true);
  const [polylines, setPolylines] = useState([]);
  const [isMapAvailable, setMapAvailable] = useState(false);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };

  const getLastPosition = useCallback((timeout = 0) => {
    setLoadingMap(true);
    const timeoutId = setTimeout(() => {
      getLastStationPosition(stationId, timeRangeInView.startDate, timeRangeInView.endDate).then((res) => {
        const stationPosition = res?.[0];
        setLoadingMap(false);
        if (!stationPosition?.lat || !stationPosition?.lon) {
          setMarkers([]);
          return;
        }
        setMapCenter({ lat: stationPosition.lat, lon: stationPosition.lon });
        const position = { ...stationPosition, icon: mkrIcon, iconType: <MdGpsFixed /> };
        setMarkers([position]);
      })
    }, timeout)

    return timeoutId;
  }, [stationId, timeRangeInView.startDate, timeRangeInView.endDate]);

  const getRoute = useCallback((timeout = 0) => {
    setLoadingMap(true);
    const timeoutId = setTimeout(() => {
      getStationRoute([stationId], timeRangeInView.startDate, timeRangeInView.endDate).then((res) => {
        clearTimeout(timeoutId);
        setLoadingMap(false);
        setPolylines(res[stationId] || []);
      })
    }, timeout);
    return timeoutId;
  }, [stationId, timeRangeInView.startDate, timeRangeInView.endDate]);

  useEffect(() => {
    let timeout1, timeout2;
    if (enableLastPosition) timeout1 = getLastPosition(location.state.timeout);
    if (enableRoute) timeout2 = getRoute(location.state.timeout);
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    }
  }, [stationId]);

  useEffect(() => {
    let timeout1, timeout2;
    if (enableLastPosition) timeout1 = getLastPosition();
    else setMarkers([]);

    if (enableRoute) timeout2 = getRoute();
    else setPolylines([]);
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    }
  }, [enableLastPosition, enableRoute, timeRangeInView.startDate, timeRangeInView.endDate])


  useEffect(() => {
    setPopupsContent(() =>
      markers.map((m) => ({
        title: handleTranslate('LAST_POSITION', true),
        time: {
          label: m.time ? handleTranslate('ON_THE_DATE_OF', true) : handleTranslate('BASE_LOCATION', true),
          value: m.time || handleTranslate('CURRENT', true)
        },
      }))
    )
  }, [markers, t])

  useEffect(() => {
    let available = true;
    if (loadingMap) available = false;
    else if (enableLastPosition && enableRoute) {
      if (markers?.length <= 0 || polylines?.length <= 0) available = false;
    } else if (enableLastPosition) {
      if (markers?.length <= 0) available = false;
    } else if (enableRoute) {
      if (polylines?.length <= 0) available = false;
    }
    setMapAvailable(available);
  }, [loadingMap, markers, polylines, enableLastPosition, enableRoute])

  return displayMap && (
    <MapComponent style={{ zIndex: 0 }} center={mapCenter} markers={markers} popupsContent={popupsContent}>
      <NoPositionView loading={loadingMap} visible={!isMapAvailable} />
      {enableRoute && <CustomPolyline markers={polylines} />}
    </MapComponent>
  )
}

export default SingleStationHandlingMap