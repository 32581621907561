import DASHBOARD_EN from "src/components/Dashboard/i18n/en";
import SIGN_IN_EN from "src/components/Sign-in/i18n/en";
import ADMINISTRATION_EN from "src/components/Administration/i18n/en";
import ALERTS_EN from "src/components/Alerts/i18n/en";
import CONFIGURATION_EN from "src/components/Configuration/i18n/en";
import ODOR_REPORTS_EN from "src/components/OdorReports/i18n/en";
import OPEN_DATA_AIR_QUALITY_EN from "src/components/OpenDataAir/i18n/en";
import OPEN_DATA_METEOROLOGY_EN from "src/components/OpenDataMeteo/i18n/en";
import SINGLE_MULTIMEDIA_EN from "src/components/SingleMultimedia/i18n/en";
import SINGLE_STATION_EN from "src/components/SingleStationHandling/i18n/en";
import STATIONS_EN from "src/components/StationHandling/i18n/en";
import USER_PROFILE_EN from "src/components/UserProfile/i18n/en";
import MULTIMEDIA_EN from "src/components/Multimedia/i18n/en";
import VESSELS_EN from "src/components/VesselsTraffic/i18n/en";
import TEST_PAGE_EN from "src/components/TestPage/i18n/en";
import FOOTER_EN from "src/components/SharingComponents/i18n/Footer/en";
import { MONGO_CONSTANTS_EN } from "./mongoCostants/constants_en";
import { navbarGroupsNamesEn } from "./navBarGroups/groups_en";
import SHARING_COMPONENTS_EN from "src/components/SharingComponents/i18n/en";
import MODAL_COMPONENTS_EN from "src/components/Modals/i18n/en";
import INPUT_COMPONENTS_EN from "src/components/SharingComponents/Inputs/i18n/en";
import { SELF_CONTAINED_VIEWS_EN } from "./self_contained_views/views_en";
import { MODALS_EN } from "./modals/modals_en";

const colors = {
	YELLOW: "Yellow",
	GREEN: "Green",
	BLUE: "Blue",
	RED: "Red",
	GRAY: "Grey",
}

const generalTerms = {
	STATION : "Station",
	STATIONS : "Stations",
	MONITORING_STATIONS : "Monitoring stations",
	STATISTICS: "Statistics",
	THRESHOLD: "Threshold",
	THRESHOLDS: "Thresholds",
	VALUE: "Value",
    AVERAGE: "Mean",
    CATEGORY: "Category",
    LAST_RELEVATION: "Latest detection",
	SELECT_STATION: "Select a station",
	NO_SENSORS_INSTALLED: 'There are no sensors installed',
	CONTROL_UNIT: "Control unit",
	MEDIAN: "Median",
	NO_DATA: "No data",
	CONTROL_UNITS_INSTALLED: "Control units installed",
	SENSOR: "Sensor",
	SENSORS: "Sensors",
	SENSORS_INSTALLED: "Sensors installed",
	SENSORS_TYPE: "Sensor type",
	STATION_TYPE: "Station type",
	TYPE: "Type",
	LEVEL: "Level",
	MEASURE : "Measure",
	SOURCE : "Source",
	ALL: "All",
	INTERVAL: "Interval",
	SERIAL : "Serial",
	NAME: "Name",
	TRAFFIC: "Traffic",
	AVAILABLE_STATIONS: "Available stations",
	UNAVAILABLE_STATIONS: "Unavailable stations",
	CONTROL_UNITS: "Control units",
	MEASURES: "Measures",
	ADD: "Add",
	DELETE: "Delete",
	ODOUR_LEVEL: "Level",
	DATE: 'Date',
	ON_THE_DATE_OF: "On",
	DEPARTURE: "Departure",
	ARRIVAL: "Arrival",
	BASE_LOCATION: "Default position",
	LAST_POSITION: "Last position",
	CURRENT: 'Current',
	POSITION: "Position",
	NEW : "New",
	EXISTING: "Existing",
	SAVE: "Save",
	CONFIRM: "Confirm",
	CANCEL: "Annulla",
	LAST_RELEVATIONS: "Last detections",
	SAVING: "Saving",
	CANCEL_CHANGES: "Cancel changes",
	CHANGE: "Change",
	INSTALL: "Install",
	INSTALL_ON: "Install on",
	INSTALLED_ON: "Installed on",
	ACTIVE : "Active",
	ACTIVABLE : "Activable",
	DEACTIVATE: "Deactivate",
	SENSOR_TYPE: "Sensor type",
	NO_THRESHOLDS: "No thresholds",
	HISTORY: "History",
	GENERAL: "General",
	HEADQUARTER: "Headquarter",
	START_DATE: "Start date",
	END_DATE: "End date",
	SUCCESS: "Success",
	ERROR: "Error",
	SURE: "Are you sure",
	LANGUAGE: "Language",
	SESSION_EXPIRED: "Session expired",
	GO_TO_LOGIN : "Go to login",
	WELCOME: "Welcome",
	TENANTS: "Tenants",
	USERS: "Users",
	ROLE: "Role",
	ROLES: "Roles",
	FILTERS: "Filters",
	GLOBAL_VARIABLES: "Global variables",
	SHARING: "Sharing",
	DESCRIPTION: "Description",
	EDIT: "Edit",
	IDENTIFIER: "Identifier",
	MEASUREMENT_UNIT: "Measurement unit",
	COLOR: "Color",
	TITLE: "Title",
	MESSAGE: "Message",
	TIME: "Time",
	ACTION: "Action",
	ACTIONS: "Actions",
	NOTIFICATION: "Notification",
	NOTIFICATIONS: "Notifications",
	NOTIFICATION_TYPE: "Notification type",
	EXTENSION : "Extension",
    RESOLUTION : "Resolution",
    SIZE : "Size",
	IMAGES: "Images",
	STATE: "State",
	EXPORT: "Export",
	SECONDS: "Seconds",
	NO_STATIONS_AVAILABLE: "No stations available",
	DATA: "Data",
	MAINTANCE: "Maintenance",
	OTHERS: "Others",
	DESTINATION: "Destination",
	POLAR_LEGEND_1: "This is a polar plot derived from the integration of VOC and meteorological data from the last 24 hours. From",
    POLAR_LEGEND_2: "(lowest probability) to",
    POLAR_LEGEND_3: "(highest probability) represents an increasing probability of finding the source of pollution.", 
	FLAG: "Flag",
	SEARCH: "Search",
	LATITUDE: "Latitude",
	LONGITUDE: "Longitude",
	ACTION_GROUP: "Action group",
	FILTERS_IN_VIEW: "Filters in view",
};

const vesselTypes = {
	VESSEL_TYPE_1: "Reserved for future use",
	VESSEL_TYPE_9: "Reserved for future use",
	VESSEL_TYPE_21: "Wing In Ground (Hazardous category A)",
	VESSEL_TYPE_22: "Wing In Ground (Hazardous category B)",
	VESSEL_TYPE_23: "Wing In Ground (Hazardous category C)",
    VESSEL_TYPE_24: "Wing In Ground (Hazardous category D)",
	VESSEL_TYPE_36: "Sailing",
	VESSEL_TYPE_50: "Pilot Vessel",
	VESSEL_TYPE_52: "Tug",
	VESSEL_TYPE_55: "Law Enforcement",
	VESSEL_TYPE_60: "Passenger",
	VESSEL_TYPE_70: "Cargo",
	VESSEL_TYPE_80: "Tanker",
	VESSEL_TYPE_81: "Tanker (Hazardous category A)",
    VESSEL_TYPE_82: "Tanker (Hazardous category B)",
    VESSEL_TYPE_83: "Tanker (Hazardous category C)",
    VESSEL_TYPE_84: "Tanker (Hazardous category D)",
	VESSEL_TYPE_89: "Tanker",
	VESSEL_TYPE_90: "Other Types",
    VESSEL_TYPE_91: "Other Types (Hazardous category A)",
    VESSEL_TYPE_92: "Other Types (Hazardous category B)",
    VESSEL_TYPE_93: "Other Types (Hazardous category C)",
    VESSEL_TYPE_94: "Other Types (Hazardous category D)",
	VESSEL_TYPE_99: "Other Types (no information)",
}


const locale = {
	...SIGN_IN_EN,
	...DASHBOARD_EN,
	...ADMINISTRATION_EN,
	...ALERTS_EN,
	...CONFIGURATION_EN,
	...ODOR_REPORTS_EN,
	...OPEN_DATA_AIR_QUALITY_EN,
	...OPEN_DATA_METEOROLOGY_EN,
	...SINGLE_MULTIMEDIA_EN,
	...SINGLE_STATION_EN,
	...STATIONS_EN,
	...USER_PROFILE_EN,
	...MULTIMEDIA_EN,
	...VESSELS_EN,
	...TEST_PAGE_EN,
	...FOOTER_EN,
	...generalTerms,
	...colors,
	...navbarGroupsNamesEn, 
	...MONGO_CONSTANTS_EN,
	...SHARING_COMPONENTS_EN,
	...MODAL_COMPONENTS_EN,
	...INPUT_COMPONENTS_EN,
	...SELF_CONTAINED_VIEWS_EN,
	...MODALS_EN,
	...vesselTypes
};

// console.log('locale en', locale);


export default locale;