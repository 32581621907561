import React, { useEffect, useMemo, useState } from 'react'
import AccordionComponent from '../SharingComponents/AccordionComponent';
import { Button, CircularProgress, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const RecordingElement = ({recording, defaultPlay=false, hideDownload=false, hideDelete=false, hideButtons=false, isLoading=false, isLoadingExport=false, isLoadingDelete=false, disabled=false, disabledPlay=false, disabledExport=false, disabledDelete=false, isPlaying, onPlay, onStop, onExport, onDelete}) => {
  const { t } = useTranslation("evocs");
  const [play, setPlay] = useState(defaultPlay); 

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_MULTIMEDIA_VIEW);
  };

  const handlePlay = (e) => {
    e.stopPropagation();
    setPlay(true);
    onPlay && onPlay(recording);
  };

  const handleStop = (e) => {
    e.stopPropagation();
    setPlay(false);
    onStop && onStop(recording);
  };

  const accordionTitle = useMemo(() => {
    const duration = recording.stoptime ? recording.stoptime - recording.starttime : null;
    return (
      <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
        {!isLoading ? (
          !play ? 
          <IconButton sx={{height: "fit-content"}} disabled={disabledPlay} onClick={handlePlay}> 
            <PlayArrowIcon />
          </IconButton>
          :
          <IconButton sx={{height: "fit-content"}} disabled={disabledPlay} onClick={handleStop}>
            <StopIcon />
          </IconButton>
          ):
          <CircularProgress />
        }
        <div style={{display: "flex", flexDirection: "column", gap: ".1rem",}}>
          <label style={{maxWidth: "250px", fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>{recording.filename}</label>
          {recording.source && <label>{recording.source}</label>}
          <label>
            {recording.recordingstatus === "pending" ? "pending..." : duration !== null ? duration + " " + handleTranslate("SECONDS", true).toLowerCase() : "loading...."}
          </label>
        </div>
      </div>
    )
  }, [recording.stoptime, play, isLoading, disabledPlay]);

  useEffect(() => {
    isPlaying !== undefined && setPlay(isPlaying);
  }, [isPlaying])


  const handleExport = () => {
    if(isLoadingExport || isLoadingDelete) return;
    onExport && onExport(recording);
  };

  const handleDelete = () => {
    if(isLoadingDelete) return;
    onDelete && onDelete(recording);
  };

  return(
    <AccordionComponent disabled={disabled} title={accordionTitle}>
      <div style={{display: "flex", gap: "1rem"}}>
        <div style={{display: "flex", flexDirection: "column", gap: "0.1rem", color: "grey"}}>
          <label style={{marginBottom: "8px"}}>{handleTranslate("RECORDING_ID")}:</label>
          {recording.recordingstatus && <label>{handleTranslate("STATE", true)}:</label>}
          {recording.diskid && <label>{handleTranslate("DISK_ID")}:</label>}
          <label>{handleTranslate("VIDEO_ENCODING")}:</label>
          <label>{handleTranslate("RESOLUTION", true)}:</label>
          {recording.filesize && <label>{handleTranslate("SIZE", true)}:</label>}
        </div>
        <div style={{display: "flex", width: "100%", flexDirection: "column", gap: "0.1rem"}}>
          <label style={{width: "200px", overflow: "scroll"}}>{recording.displayId}</label>
          {recording.recordingstatus && <label>{recording.recordingstatus}</label>}
          {recording.diskid && <label>{recording.diskid}</label>}
          <label>{recording.videotype}</label>
          <label>{recording.resolution}</label>
          {recording.filesize && <label>{recording.filesize}</label>}
        </div>
      </div>
      {!hideButtons && 
      <div style={{marginTop: "1rem", display: "flex", gap: "1rem", justifyContent: "space-between"}}>
        {!hideDownload && <Button disabled={disabled || disabledExport} variant="outlined" endIcon={isLoadingExport && <CircularProgress size={20}/>} onClick={handleExport}>{handleTranslate("EXPORT", true)}</Button>}
        {!hideDelete && recording.axis && <Button sx={{color: "red"}} disabled={disabled || disabledDelete} variant='outlined' endIcon={isLoadingDelete && <CircularProgress size={20}/>} onClick={handleDelete}><DeleteIcon /></Button>}
      </div>}
    </AccordionComponent>
  )
}

export default RecordingElement