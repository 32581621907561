import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Meteo",
    TABLE_TITLE: "Meteorological open data",
};

const OPEN_DATA_METEOROLOGY_EN = createLanguageObject(EN, viewNames.OPEN_DATA_METEOROLOGY_VIEW);


export default OPEN_DATA_METEOROLOGY_EN;