import { Button, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLayoutEffect, useMemo, useState } from "react";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import { updateSensor } from "app/store/evocsSlice/sensors/sensorsActions";
import { useSelector } from "react-redux";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";


const containerStyle = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#2d3740",
  padding: "1rem",
  borderRadius: "1rem",
  height: "fit-content",
  maxHeight: "700px",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column"
};

const FormComponent = ({ measureId, measure, onFinally }) => {
  const sensors = useSelector((state) => state.sensors.list);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const sensorOptions = useMemo(() => {
    if (!sensors) return [];
    const options = [];
    Object.keys(sensors).forEach((sensorId) => {
      if (!sensors[sensorId].measures.includes(measureId))
        options.push(sensorId)
    });
    return options;
  }, [measureId, sensors])

  useLayoutEffect(() => {
    if ((selectedSensors?.length === 0)) setButtonDisabled(true);
    else setButtonDisabled(false)
  }, [selectedSensors]);


  const handleConfirm = async () => {
    try {
      const fetch = [];
      selectedSensors.forEach((sensorId) => {
        const measures = [...sensors[sensorId].measures, measureId];
        fetch.push(updateSensor(sensorId, undefined, undefined, undefined, measures));
      });
      await Promise.all(fetch);
      setGlobalSuccess("Misure aggiunte con successo");
    } catch (error) {
      setGlobalError(true);
    }
    onFinally && onFinally();
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate('ADD', true)} {handleTranslate(`MEASURE_${measure?.measure.toUpperCase()}`, true)} {handleTranslate('TO_SENSORS')} </h2>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <span style={{ minWidth: "100px" }}> {handleTranslate('SELECT_SENSORS')} </span>
        <SensorAutocomplete disablePortal multiple limitTags={2} sensorsId={sensorOptions} value={selectedSensors} onChange={setSelectedSensors} />
      </div>
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }}
        disabled={buttonDisabled}
        onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const AddMeasureToSensorModal = ({measureId, isOpen, onFinally, onClose }) => {
  const measures= useSelector((state) => state.configuration.constants?.measure);
  return (
    <Modal open={isOpen}>
      <div style={containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormComponent measureId={measureId} measure={measures[measureId]} onFinally={onFinally} />
        </div>
      </div>
    </Modal>
  );
}

export default AddMeasureToSensorModal;