import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";
import SingleMultimediaContent from "src/components/SingleMultimedia/SingleMultimediaContent";
import CustomPageCarded from "./CustomPageCarded";
import SingleMultimediaHeader from "src/components/SingleMultimedia/SingleMultimediaHeader";

const SingleMultimedia = () => {
  return (
    <WithAuth
      viewName={viewNames.MULTIMEDIA_VIEW}
      component={
        <CustomPageCarded
          header={<SingleMultimediaHeader />}
          content={<SingleMultimediaContent/>}
        />
      }
    />
  );
};

export default SingleMultimedia;
