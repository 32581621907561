import { calcGraphPoints } from "app/store/evocsSlice/dataset/datasetActions";
import { useCallback, useEffect, useState } from "react";


const useMongoData = (startDate, endDate, sensorIds=null, stationId=null, timeout=0) => {
    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(endDate);
    const [sensors, setSensors] = useState([]);
    const [station, setStation] = useState(stationId);
    const [loading, setLoading] = useState(true);
    const graphPoints = 240;

    const getStationDataset = useCallback(async() => {
        if(stationId && stationId !== station) {
            await calcGraphPoints(startDate, endDate, sensorIds, graphPoints);
            return;
        }
        if(startDate === start && endDate === end) {
            const missed = sensorIds.filter((e, index) => !sensors.includes(e));
            if(missed.length > 0) await calcGraphPoints(startDate, endDate, sensorIds, graphPoints);
            // calcGraphPoints(startDate, endDate, sensorIds, graphPoints);
        }else {
            await calcGraphPoints(startDate, endDate, sensorIds, graphPoints);
        }
    }, [startDate, endDate, sensorIds, stationId])

    useEffect(() => {
        setStart(startDate);
    }, [startDate]);

    useEffect(() => {
        setEnd(endDate);
    }, [endDate]);

    useEffect(() => {
        setSensors(sensorIds);
    }, [sensorIds]);

    useEffect(() => {
        stationId && setStation(stationId);
    }, [stationId]);


    useEffect(() => {
        if(!startDate || !endDate) return;
        let loading = true;
        setLoading(true);
        const timeoutId = setTimeout(() => {
            getStationDataset().then(() => loading && setLoading(false))
        }, timeout);
       
        return () => {
            clearTimeout(timeoutId)
            loading = false;
        }
    }, [getStationDataset]);

    return [loading];
}

// const useMongoData = (startDate, endDate, sensorIds=null, timeout=0) => {
//     const [loading, setLoading] = useState(true);
//     const graphPoints = 240;

//     const getStationDataset = useCallback(() => {
//         calcGraphPoints(startDate, endDate, sensorIds, graphPoints).then(() => setLoading(false));
//     }, [startDate, endDate, sensorIds])

    
//     useEffect(() => {
//         if(!startDate || !endDate) return;
//         setLoading(true);
//         const timeoutId = setTimeout(() => getStationDataset(), timeout);
       
//         return () => {
//             clearTimeout(timeoutId)
//         }
//     }, [getStationDataset]);

//     return [loading];
// }

export default useMongoData;