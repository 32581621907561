import React, { useCallback, useLayoutEffect, useState } from 'react'
import MapComponent from '../SharingComponents/MapComponent'
import { IconButton } from '@mui/material'
import { useSelector } from 'react-redux';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import mkrElevato from "src/app/asset/markers/Red.png";
import mkrModerato from "src/app/asset/markers/Ocra.png";
import mkrBasso from "src/app/asset/markers/Green.png";
import mkrNullo from "src/app/asset/markers/DarkGrey.png";
import { PiCellSignalFullFill } from "react-icons/pi";
import { PiCellSignalLowFill } from "react-icons/pi";
import { PiCellSignalMediumFill } from "react-icons/pi";
import { PiCellSignalSlashDuotone } from "react-icons/pi";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { MONGO_CONSTANTS_EN } from 'src/views/i18n/mongoCostants/constants_en';

const OdorReportsMap = ({ enabledActions, reports, mapCenter, selectedMarker, onAddMarker, onClosePopup }) => {
  const odourLevels = useSelector((state) => state.configuration.constants.odour_level);
  const odourTypes = useSelector((state) => state.configuration.constants.odour_type);
  const [markers, setMarkers] = useState([]);
  const [popupsContent, setPopupsContent] = useState([]);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const getMarkerColor = useCallback((level) => {
    switch (level) {
      case MONGO_CONSTANTS_EN.ODOUR_LEVEL_HIGH:
        return mkrElevato;
      case MONGO_CONSTANTS_EN.ODOUR_LEVEL_MEDIUM:
        return mkrModerato;
      case  MONGO_CONSTANTS_EN.ODOUR_LEVEL_LOW:
        return mkrBasso;
      case  MONGO_CONSTANTS_EN.ODOUR_LEVEL_ABSENT:
        return mkrNullo;
    }
  }, []);

  const getIconType = useCallback((level) => {
    switch (level) {
      case MONGO_CONSTANTS_EN.ODOUR_LEVEL_HIGH:
        return <PiCellSignalFullFill />;
      case  MONGO_CONSTANTS_EN.ODOUR_LEVEL_MEDIUM:
        return <PiCellSignalMediumFill />;
      case MONGO_CONSTANTS_EN.ODOUR_LEVEL_LOW:
        return <PiCellSignalLowFill />;
      case MONGO_CONSTANTS_EN.ODOUR_LEVEL_ABSENT:
        return <PiCellSignalSlashDuotone />;
    }
  }, []);

  useLayoutEffect(() => {
    const popups = [], newMarkers = [];
    reports?.forEach((element) => {
      popups.push({
        title: handleTranslate(`ODOUR_TYPE_${odourTypes[element.type]?.odour_type.toUpperCase()}`, true),
        level: {
          label: handleTranslate('ODOUR_LEVEL', true),
          value: handleTranslate('ODOUR_LEVEL_' + odourLevels[element.level]?.odour_level.toUpperCase(), true)
        },
        date: {
          label: handleTranslate('ON_THE_DATE_OF', true),
          value: element.date
        },
      });
      newMarkers.push({
        ...element,
        icon: getMarkerColor(odourLevels[element.level].odour_level),
        iconType: getIconType(odourLevels[element.level].odour_level)
      });
    })
    setPopupsContent(popups);
    setMarkers(newMarkers);
  }, [reports, t])

  return (
    <MapComponent style={{ marginBottom: "1rem" }} markers={markers} center={mapCenter} selectedMarker={selectedMarker} popupsContent={popupsContent}
      onClosePopup={onClosePopup} onClickMarker={onClosePopup} onDoubleClick={onAddMarker} copernicusLogo={false}>
      {enabledActions.includes("odour-signal") && <div style={{ position: "absolute", zIndex: 1000, bottom: "1rem", left: "1rem" }}>
        <IconButton sx={{ color: "#3bcf33" }} onClick={onAddMarker}><AddLocationAltIcon sx={{ width: "60px", height: "60px" }} fontSize="large" /></IconButton>
      </div>}
    </MapComponent>
  )
}

export default OdorReportsMap