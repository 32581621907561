import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import { TIME_FORMAT, formatDate } from "../StationHandling/utils";
import LoadingGif from "../FileManager/LoadingGif";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import DateTimeInput from "../SharingComponents/Inputs/DateTimeInput";
import DownloadComponent from "../SharingComponents/DownloadComponent/DownloadComponent";
import * as XLSX from 'xlsx';
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import { downloadCuHistory, getCuHistory, updateCuHistory } from "app/store/evocsSlice/controlUnits/cuActions";
import { useSelector } from "react-redux";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";


const EditableRow = ({ rowElement, deviceParent, errors, onChange, onDelete, onError }) => {
  const { t } = useTranslation("evocs");
  const minDate = useMemo(() => new Date(rowElement.start * 1000).setSeconds(0, 0), [rowElement.start]);
  const [dateError, setDateError] = useState();

  useEffect(() => {
    return () => onError(null)
  }, [])

  useEffect(() => {
    const newErrors = [];
    if (!rowElement.device) newErrors.push("null device");
    if (!rowElement.start) newErrors.push("null startDate");
    // if (deviceParent && !rowElement.end) newErrors.push("null endDate");
    if (rowElement.newElement && !rowElement.end) newErrors.push("null endDate");
    if (dateError) newErrors.push(dateError);
    onError(newErrors.length > 0 ? newErrors : null);
  }, [rowElement, dateError])


  const handleChangeInput = (key, value) => {
    if (key === "type" && value !== rowElement.type) {
      onChange({ ...rowElement, [key]: value, device: null });
      return;
    }
    if (value && (key === "start" || key === "end")) value = Math.floor(new Date(value).valueOf() / 1000);
    onChange({ ...rowElement, [key]: value });
  };

  const getFormattedDate = useCallback((dateValue) => {
    return dateValue ? new Date(dateValue * 1000) : null;
  }, []);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  return (
    <TableRow>
      <TableCell>
        <StationSelect disablePortal disabled={rowElement.editLimitaion} error={errors?.includes("null device")} value={rowElement.device} onChange={(value) => handleChangeInput("device", value)} />
      </TableCell>
      <TableCell>
        <select id="type-select" disabled={rowElement.editLimitaion} value={rowElement.type} onChange={(e) => handleChangeInput("type", e.target.value)}>
          <option value="cu"> {handleTranslate("STATION", true)} </option>
        </select>
      </TableCell>
      <TableCell>
        <DateTimeInput disablePortal disableFuture error={errors?.includes("null startDate")} value={getFormattedDate(rowElement.start)} onChange={(value) => handleChangeInput("start", value)} onError={setDateError} />
      </TableCell>
      <TableCell>
        <DateTimeInput disablePortal disableFuture error={errors?.includes("null endDate")} minDate={minDate} value={getFormattedDate(rowElement.end)} onChange={(value) => handleChangeInput("end", value)} onError={setDateError} />
      </TableCell>
      <TableCell>
        <IconButton sx={{ color: "red" }} disabled={rowElement.editLimitaion} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const HistoryTable = ({ cuId, history, disableEdit, rowsErrors, onChangeRow, onAddRow, onDeleteRow, onRowError }) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const getFormattedDate = useCallback((dateValue) => {
    return dateValue ? formatDate(new Date(dateValue * 1000), TIME_FORMAT.COMPLETE_DATE) : "Attualmente installata";
  }, []);

  const getDeviceParent = useCallback((rowElement) => {
    if (!rowElement.newElement) return null;
    return controlUnits[cuId]?.deviceParent;
  }, [controlUnits]);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };


  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ fontWeight: "bold" }}>
            <TableCell> {handleTranslate("SERIAL", true)} </TableCell>
            <TableCell> {handleTranslate("TYPE", true)} </TableCell>
            <TableCell> {handleTranslate("START_DATE", true)} </TableCell>
            <TableCell> {handleTranslate("END_DATE", true)} </TableCell>
          </TableRow>
          {!disableEdit &&
            <TableRow>
              <TableCell colSpan={5} align="center"> <Button onClick={onAddRow}>+ {handleTranslate("ADD", true)}</Button></TableCell>
            </TableRow>
          }
        </TableHead>
        <TableBody>
          {history?.map((element, index) => disableEdit ? (
            <TableRow key={element.id}>
              <TableCell>{stations[element.device]?.serial}</TableCell>
              <TableCell>  {handleTranslate("STATION", true)} </TableCell>
              <TableCell>{getFormattedDate(element.start)}</TableCell>
              <TableCell>{getFormattedDate(element.end)}</TableCell>
            </TableRow>
          ) :
            <EditableRow key={element.id} rowElement={element} deviceParent={getDeviceParent(element)} errors={rowsErrors[element.id]} onChange={(newRow) => onChangeRow(newRow, index)} onDelete={() => onDeleteRow(element, index)} onError={(errors) => onRowError(element, index, errors)} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const CuHistoryChip = ({ cuId }) => {
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const [cuHistory, setCuHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableEdit, setDisableEdit] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [isChanged, setChanged] = useState(false);

  const [deletedList, setDeletedList] = useState([]);
  const [createdList, setCreatedList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorText, setErrorText] = useState("");

  const hasEditLimitation = useCallback((historyElement) => {
    if (historyElement.end) return false;
    return controlUnits[cuId].deviceParent ? true : false;
  }, [controlUnits])

  useEffect(() => {
    if (!cuId) return;
    setLoading(true);
    getCuHistory(cuId).then((res) => {
      setCuHistory(res.map((element, index) => ({ ...element, id: index, editLimitaion: hasEditLimitation(element), newElement: false })));
      setLoading(false);
    });
  }, [cuId]);

  useEffect(() => {
    setHistory([...cuHistory]);
  }, [cuHistory])


  const getErrorText = useCallback((text) => {
    switch (text) {
      case "null device":
        return "Il seriale non può essere nullo";
      case "null startDate":
        return 'Il campo "Data inizio" non può essere nullo';
      case "null endDate":
        return 'Il campo "Data fine" non può essere nullo';
      case "minDate":
        return "La data di fine non può antecedente a quella di inizio";
      default:
        return "";
    }
  }, []);

  useEffect(() => {
    console.log(history);
  }, [history])

  useEffect(() => {
    if (isChanged) setDisableConfirm(Object.keys(errors).length > 0 ? true : false);
    else setDisableConfirm(true)
  }, [isChanged, errors])

  useEffect(() => {
    const keys = Object.keys(errors);
    const text = getErrorText(errors[keys[0]]?.[0])
    setErrorText(text)
  }, [errors])

  const createListElement = (oldElement) => {
    let newElement = { ...oldElement };
    delete newElement.id; delete newElement.editLimitaion; delete newElement.newElement;
    return newElement;
  }

  const handleChangeRow = (newRow, index) => {
    setHistory((prev) => prev.toSpliced(index, 1, newRow));
    if (!newRow.newElement && !updatedList.find((id) => id === newRow.id)) setUpdatedList((prev) => [...prev, newRow.id]);
    setChanged(true);
  }

  const handleAddRow = () => {
    const newId = history.length > 0 ? (createdList.length > 0 ? createdList[createdList.length-1] + 1 : history[history.length-1].id + 1) : 0;
    const newHistoryElement = {
      id: newId,
      device: null,
      type: "station",
      start: null,
      end: null,
      editLimitaion: false,
      newElement: true
    }
    setHistory((prev) => [newHistoryElement, ...prev]);
    setCreatedList((prev) => [...prev, newHistoryElement.id]);
    setChanged(true);
  }

  const handleDeleteRow = (element, index) => {
    setHistory((prev) => prev.toSpliced(index, 1));
    if (!element.newElement) {
      setDeletedList((prev) => [...prev, element.id]);
      setUpdatedList((prev) => prev.filter((id) => id !== element.id));
    }
    else setCreatedList((prev) => prev.filter((id) => id !== element.id));
    setChanged(true);
  }

  const handleCancelChanges = () => {
    setChanged(false);
    setDisableEdit(true);
    setHistory([...cuHistory]);
  }

  const handleSave = () => {
    const old = updatedList.map((newElement) => createListElement(cuHistory.find((oldElement) => oldElement.id === newElement)));
    const created = createdList.map((id) => createListElement(history.find((element) => element.id === id)));
    const deleted = deletedList.map((id) => createListElement(cuHistory.find((element) => element.id === id)));
    const updated = updatedList.map((id) => createListElement(history.find((element) => element.id === id)));
    // console.log("history: ", history);
    // console.log("createdList: ", created);
    // console.log("deletedList: ", deleted);
    // console.log("updatedList: ", updated);
    // console.log("oldList: ", old);
    setChanged(false);
    setDisableEdit(true);
    updateCuHistory(cuId, old, updated, deleted, created)
    .then((res) => setGlobalSuccess(true, "MODAL_SUCCESS_UPDATE_CU_HISTORY"))
    .catch(() => {
      setHistory([...cuHistory])
      setGlobalError(true)
    })
  }

  const handleRowError = (row, index, rowErrors) => {
    if (rowErrors) setErrors((prev) => ({ ...prev, [row.id]: rowErrors }));
    else {
      setErrors((prev) => {
        let newErrors = { ...prev };
        delete newErrors[row.id];
        return newErrors;
      });
    }
  }

  const handleDownloadFile = (fileType) => {
    downloadCuHistory(cuId, fileType).then((res) => {
      if (fileType === "csv") {
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          const url = URL.createObjectURL(blob);
          link.style.display = 'none';
          link.href = url;
          // // the filename you want
          link.download = `History ${controlUnits[cuId].serial}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      } else {
        // Crea un nuovo foglio di lavoro
        const worksheet = XLSX.utils.json_to_sheet(res.data);
        // Crea un nuovo libro di lavoro
        const workbook = XLSX.utils.book_new();

        // Aggiungi il foglio di lavoro al libro di lavoro
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Foglio1');

        // Crea un file XLSX e salva
        XLSX.writeFile(workbook, `History ${controlUnits[cuId].serial}.xlsx`);
      }
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem", paddingBottom: "7rem" }}>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <h2 style={{ fontWeight: "bold" }}>Centralina: {controlUnits[cuId]?.serial}</h2>
        <DownloadComponent fromTable onDownloadFile={handleDownloadFile} />
        {disableEdit && <IconButton onClick={() => setDisableEdit(false)}><EditIcon /></IconButton>}
      </div>
      {loading ? <LoadingGif />
        : <HistoryTable cuId={cuId} history={history} disableEdit={disableEdit} rowsErrors={errors} onChangeRow={handleChangeRow} onAddRow={handleAddRow} onDeleteRow={handleDeleteRow} onRowError={handleRowError} />
      }
      {!disableEdit &&
        <div style={{ position: "absolute", padding: "1rem 2rem", left: 0, bottom: 0, width: "100%", display: "flex", alignItems: "center", gap: ".5rem", background: "white", zIndex: 10 }}>
          <Button sx={{ color: "red" }} onClick={handleCancelChanges}>Annulla</Button>
          <Button disabled={disableConfirm} onClick={handleSave}>Salva</Button>
          {errorText !== "" && <label style={{ color: "red" }}>Errore: {errorText}</label>}
        </div>
      }
    </div>
  );
}

export default CuHistoryChip