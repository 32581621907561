import React, { useEffect, useMemo } from 'react'
import RangeTimeFilter_v2 from '../SharingComponents/RangeTimeFilter_v2'
import { viewNames } from 'app/configs/routesNames'
import { useSelector } from 'react-redux';
import { setFilter, setTimeRangeFilter } from 'app/store/evocsSlice/filters/filtersActions';
import OpenDataSourceSelect from '../SharingComponents/Inputs/OpenDataSourceSelect';
import useFilters from 'src/app/hooks/useFilters';

const viewName = viewNames.OPEN_DATA_METEOROLOGY_VIEW;

const OpenDataMeteoContentHeader = () => {
  const user = useSelector((state) => state.user)
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const sourceSelect = filters.open_source;

  const {enabledIntervals, defaultInterval} = useMemo(() => {
    const intervals = [];
    let defaultId = null;
     timeRangeInView.list.forEach((element) => {
      if(element.active===1) intervals.push(element._id);
      if(element.default===1) defaultId = element._id;
     });
    defaultId = timeRangeInView.selected ? timeRangeInView.selected : defaultId;
    return {enabledIntervals: intervals, defaultInterval: defaultId}
  }, [user.selectedTenant, timeRangeInView.list])

  useEffect(() => {
    return () => {
      setTimeRangeFilter(viewName, timeRangeInView.id, undefined, null, null);
    }
  }, [])

  const handleChangeInterval = (intervalId, startDate, endDate) => {
    setTimeRangeFilter(viewName, timeRangeInView.id, intervalId, startDate, endDate);
  }

  const handleChangeSource = (value) => {
    setFilter(viewName, sourceSelect.id, value);
  }

  return (
    <div style={{display: "flex", gap: "1rem", marginBottom: "1rem"}}>
        <RangeTimeFilter_v2 withSlider={false} intervals={enabledIntervals} 
        onChangeInterval={handleChangeInterval}
        selectedInterval={timeRangeInView.selected} defaultInterval={defaultInterval}
        />
      <OpenDataSourceSelect defaultValue={sourceSelect.selected} onChange={handleChangeSource} aq={false}/>
    </div>
  )
}

export default OpenDataMeteoContentHeader;