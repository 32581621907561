import store from "../..";
import { userSliceActions } from "./userSlice";
import { changeLanguage } from "app/store/i18nSlice/i18nActions";

const getSqlIdFromStorage = () => {
  try {
    const sqlId = window.localStorage.getItem("sql_id");
    if (!sqlId) throw new Error("usersAction: sql_id not found");
    return sqlId;
  } catch (error) {
    console.log("usersAction: error while getting sql_id");
    throw new Error(error);
  }
};

export const getUsers = async (tenantIds) => {
  if (!tenantIds) throw new Error("tenantIds cannot be null");
  const data = await store.dispatch(userSliceActions.getTenantsUsers(tenantIds)).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};

export const getMyUser = async () => {
  let sqlId = getSqlIdFromStorage();
  const data = await store.dispatch(userSliceActions.getMyUser(sqlId)).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });

  let selectedTenant = window.localStorage.getItem("selectedTenant");
  let roleId = window.localStorage.getItem("roleId");
  if (!selectedTenant || !roleId) {
    selectedTenant = data.tenantId;
    roleId = data.roleId;
  }
  setUserTenant(selectedTenant);
  setUserRole(roleId);
  const evocsLang = window.localStorage.getItem("evocs_lang");
  if (!evocsLang) {
    changeLanguage(data?.language);
  } else {
    if (data?.language && data?.sqlId && data?.profileImage && data?.language != evocsLang){
      updateUser(data.sqlId, evocsLang, data.profileImage);
      // window.removeItem("evocs_lang");
    }
  }
  
  return data;
};

export const updateUser = async (sqlId = undefined, language = undefined, profileImage = undefined, password = undefined) => {
  changeLanguage(language);
  const data = await store.dispatch(userSliceActions.updateUser({ sqlId, language, profileImage, password })).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};

export const inviteUser = async (email, username, tenantId, language, roleId) => {
  await store.dispatch(userSliceActions.inviteUser({ email, username, language})).then(({ payload, error }) => {
    if (error) throw error;
  });
  const data = await store.dispatch(userSliceActions.createInvitedUser({ email, tenantId, roleId })).then(({ payload, error }) => {
    if (error) throw error;
  });
  return data;
};

export const checkRecaptchaV3 = async (token) => {
  const data = await store.dispatch(userSliceActions.checkRecaptchaV3({ token })).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  })
  return data;
};

export const activateUser = async (email, username, password, secureCode, language, userImage = null) => {
  const data = await store.dispatch(userSliceActions.activateUser({ email, username, password, secureCode, language, userImage })).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};

export const setUserTenant = (tenantId) => {
  store.dispatch(userSliceActions.setSelectedTenant(tenantId));
  window.localStorage.setItem("selectedTenant", tenantId);
};

export const setUserRole = (roleId) => {
  store.dispatch(userSliceActions.setRole(roleId));
  window.localStorage.setItem("roleId", roleId);
};

export const setUserLanguage = (language) => {
  store.dispatch(userSliceActions.setLanguage(language));
};

export const setLastUserTenant = (tenantId) => {
  store.dispatch(userSliceActions.setLastSelectedTenant(tenantId));
};

const setLoading = (loading) => {
  store.dispatch(userSliceActions.setLoading(loading));
};

export const readAlerts = async (alertIds, lang="en") => {
  const data = await store.dispatch(userSliceActions.readAlerts({alertIds, lang})).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};

export const readAlertsByUser = async (userId, alertIds) => {
  const data = await store.dispatch(userSliceActions.readAlertsByUser({ userId, alertIds })).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
};

export const deleteAlertsByUser = async (userId, alertIds) => {
  const data = await store.dispatch(userSliceActions.deleteAlertsByUser({ userId, alertIds })).then(({ payload, error }) => {
    if (error) {
      // setGenericError(true)
      throw error;
    }
    return payload;
  });
  return data;
};

export const passwordForget = async (email) => {
  const data = await store.dispatch(userSliceActions.passwordForget({ email })).then(({ payload, error }) => {
    if (error) {
      // setGenericError(true)
      throw error;
    }
    return payload;
  });
  return data;
};


export const updateCookies = async(dictUpdate) => {
  if(!dictUpdate) throw new Error("dictUpdate is required.")
  const user = store.getState().user;
  const data = await store.dispatch(userSliceActions.updateCookies({ userId: user.id, tenantId: user.tenantId, dictUpdate })).then(({ payload, error }) => {
    if (error) throw error;
    return payload;
  });
  return data;
} 