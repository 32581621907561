import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import ItemIcon from "./ItemIcon";
import { Paper, Tooltip } from "@mui/material";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { downloadMonthCSV } from "app/store/evocsSlice/fileSlice/fileSlice";
import { downloadAutoReports } from "app/store/evocsSlice/reportsSlice";
import {
  downloadFile,
  getDayReportsFileNames,
} from "app/store/evocsSlice/fileSlice/fileActions";
/* CodeInfo: Componente per il render del placeholder dei file, da agganciare la richiesta di download */

const FileItem = ({ item, sharedType }) => {
  const getFileNameWithoutExtension = (filename) => {
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1 && lastDotIndex !== 0) {
      return filename.substring(0, lastDotIndex);
    } else {
      return filename;
    }
  };
  const dispatch = useDispatch();

  const handleFileClick = () => {
    if (sharedType === "monthly") dispatch(downloadMonthCSV(item));
    if (sharedType === "daily"){
      downloadFile(item.name);
    }
  };

  return (
    item && (
      <Paper
        sx={{
          p: "1rem",
          display: "flex",
          margin: "0 auto",
          gap: "2rem",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleFileClick()}
      >
        <ItemIcon className="" type={item.type.toUpperCase()} />
        <p
          style={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {item.name}
        </p>
      </Paper>
    )
  );
};

export default FileItem;
