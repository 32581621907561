import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux"
import AccordionComponent from "../SharingComponents/AccordionComponent";
import { Box } from "@mui/system";
import { Button} from "@mui/material";
import { getUsers } from "app/store/evocsSlice/user/userAction";
import TableComponent from "../SharingComponents/TableComponent";
import UserConfigurationModal from "../Modals/UserConfigurationModal";
import CreateUserModal from "../Modals/CreateUserModal";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import LoadingGif from "../FileManager/LoadingGif";
import { setGlobalWarning, setServerError } from "app/store/evocsSlice/globalError/errorActions";
import { ModalContext } from "src/views/WithAuth";


const UserTable = ({ enabledActions, tenantId, users, selectedRows, onSelectRow, onUpdateUser }) => {
  const { t } = useTranslation("evocs");
  const modalContext = useContext(ModalContext);
  const [data, setData] = useState({})
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const tableColumns = useMemo(() => {
    return {
      email: "Email",
      username: "Username",
      role: handleTranslate("ROLE", true),
      language: handleTranslate("LANGUAGE", true)
    }
  }, [t]);

  useEffect(() => {
    if(!users) {
      setData([]);
      return;
    }
    if (!searchText || searchText === "") {
      setData(users);
      return;
    }
    setData(searchUser(searchText));
  }, [users, searchText])

  const searchUser = (text) => {
    return users.filter((element) => Object.keys(element).find((key) => element[key]?.toString().toLowerCase().includes(text.toLowerCase())))
  }

  const handleClickRow = (rowIndex) => {
    setSelectedUser(users[rowIndex]);
    setOpenModal(true);
  }

  const handleCloseModal = (saved=true) => {
    if(!saved) {
      setGlobalWarning(true, "Tutte le modifiche andranno perse");
      modalContext.setConfirmCallback(() => setOpenModal(false));
    }else {
      setOpenModal(false);
      setSelectedUser(null);
    }
  }

  const handleUpdateUser = (updatedUser) => {
    onUpdateUser && onUpdateUser(selectedUser.id, updatedUser);
    handleCloseModal();
  }

  return (
    <>
      <TableComponent paper={false} tableTitle={"Utenti"} columns={tableColumns} data={data} clickable={enabledActions.includes("update-info-user")} selectable={enabledActions.includes("update-info-user")}
        selectedRows={selectedRows} onClickRow={handleClickRow} onSelectRows={onSelectRow} onSearch={setSearchText}/>
      <UserConfigurationModal isOpen={openModal} user={selectedUser} onClose={handleCloseModal} onFinally={handleUpdateUser} />
    </>
  )

}

const SingleTenant = ({ tenantId, enabledActions }) => {
  const tenants = useSelector((state) => state.tenants.list);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usersTable, setUsersTable] = useState([]);
  const [edit, setEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [selectedTableRows, setSelectedTableRow] = useState([]);

  useEffect(() => {
    let canSet = true;
    setLoading(true);
    getUsers([tenantId]).then((res) => {
      if(!canSet) return;
      setLoading(false);
      setUsers(res);
    }).catch(() => setServerError(true));

    return () => canSet = false;
  }, [tenantId])

  useEffect(() => {
    setUsersTable(() => {
      return users.map((element) => ({
        ...element,
        role: tenants[tenantId].roles.find((role) => role._id === element.roleId).roleName
      }));
    })
  }, [users])


  useEffect(() => {
    if (selectedTableRows.length > 0) setCanDelete(true);
    else setCanDelete(false);
  }, [selectedTableRows])

  const handleUpdateUser = (userId, updatedUser) => {
    setUsers((prev) => {
      const newData = [...prev];
      const index = prev.findIndex((user) => user.id === userId);
      newData[index] = { ...prev[index], ...updatedUser };
      return newData;
    });
  };

  const handleDeleteUser = () => {
    const usersToDelete = selectedTableRows.map((rowNumber) => users[rowNumber]);
    setEdit(false);
  };

  return (
    <AccordionComponent title={tenants[tenantId].name}>
      {!loading ? 
        <UserTable enabledActions={enabledActions} tenantId={tenantId} users={usersTable} selectedRows={selectedTableRows} onSelectRow={setSelectedTableRow} onUpdateUser={handleUpdateUser} />  
      :
        <LoadingGif />
      }
    </AccordionComponent>
  )
}

const UsersConfiguration = ({ selectedTenants, enabledActions }) => {
  const { t } = useTranslation("evocs");
  const [openModal, setOpenModal] = useState(false);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };




  return (
    <Box sx={{ width: "100%", padding: "1rem" }}>
      {enabledActions.includes("invite-user") &&
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <Button sx={{ marginLeft: "auto" }} onClick={() => setOpenModal(true)}>+ {handleTranslate("INVITE_USER")} </Button>
        </div>}
      {selectedTenants.map((tenantId) => (
        <SingleTenant key={tenantId} tenantId={tenantId} enabledActions={enabledActions} />
      ))}
      <CreateUserModal isOpen={openModal} onClose={() => setOpenModal(false)} onFinally={() => setOpenModal(false)} />
    </Box>
  )
}

export default UsersConfiguration