export const DATA_TYPE_KEY = {TUTTI: "TUTTI", AQ: "AQ", METEO: "METEO"};

export const OPEN_DATA_FILTERS = {
    timeRangeInterval: [
        {
          value: "Ultime 6 ore",
          maxRangeValue: 6,
          id : 0,
          isHour : true,
        },
        {
          value: "Ultime 12 ore",
          maxRangeValue: 12,
          id : 1,
          isHour : true,
        },
        {
          value: "Ultime 24 ore",
          maxRangeValue: 24,
          id : 2,
          isHour : true,
        },
    ],
    dataType: {
        [DATA_TYPE_KEY.TUTTI]: "Tutti",
        [DATA_TYPE_KEY.AQ]: "Qualità aria",
        [DATA_TYPE_KEY.METEO]: "Meteo"
    },
    source: {
        TUTTI: "Tutti",
        [DATA_TYPE_KEY.AQ]: {    
            ARPA: "Arpa",
            EEA: "EEA",
        },
        [DATA_TYPE_KEY.METEO]: {
            PROT_CIV: "Protezione Civile",
            MISTRAL: "Mistral",
            RON: "Rete ondametrica",
            RMN: "Rete mareografica"
        }    
    },
    measure: {
        TUTTI: "Tutti",
        [DATA_TYPE_KEY.AQ]: {
            AQ1: "AQ1",
            PM10: "Pm 10",
            PM25: "Pm 2.5",
            C6H6: "C6H6",
            O3: "O3",
            NO2: "NO2"
        },
        [DATA_TYPE_KEY.METEO]: {
            WIND: "Vento",
            TEMP: "Temperatura",
            PRESSURE: "Pressione",
            HUMIDITY: "Umidità",
            RAIN: "Pioggia",
        }      
    }
};

export const openDataSourceInAPIString = (source) => {
    switch(source) {
        case OPEN_DATA_FILTERS.source.TUTTI:
          return null;
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.AQ].ARPA:
          return "arpa"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.AQ].EEA:
          return "EEA"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].MISTRAL:
          return "mistral"
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].PROT_CIV:
          return "prot_civ";
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].RON:
          return "ron";
        case OPEN_DATA_FILTERS.source[DATA_TYPE_KEY.METEO].RMN:
          return "rmn";
        case null: 
         return null;
        default: 
          return source;
    }
}

export const openDataTypeInAPIString = (type) => {
    switch(type) {
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.TUTTI]:
          return null;
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.AQ]:
          return "AQ"
        case OPEN_DATA_FILTERS.dataType[DATA_TYPE_KEY.METEO]:
          return "METEO"
        default: 
          return null;
    }
}

export const OPEN_DATA_EXPIRATION_TIME = 60000 * 30;


  