import { useCallback, useMemo, useState } from 'react'
import { Box } from '@mui/system';
import DashboardMap from './DashboardMap';
import { Grid } from '@mui/material';
import GraphsStateChip from './GraphsStateChip';
import { useSelector } from 'react-redux';
import useMobile from 'src/app/hooks/useMobile';
import DashboardTab from './DashboardTab';
import { viewNames } from 'app/configs/routesNames';
import useActions from 'src/app/hooks/useActions';
import useDashboardData from 'src/app/hooks/useDashboardData';

const viewName = viewNames.DASHBOARD_VIEW;

const DashboardContent = () => {  
  const [selectedStation, setSelectedStation] = useState();
  const {loadingAliveSensors, loadingMinMax, loadingThresholds, aliveSensors, maxMinMeasures, thresholds} = useDashboardData(selectedStation);
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const [xs, sm, md, lg, xl] = useMobile();
  const userActions = useActions(viewName);
  const enabledActions = useMemo(() => {
    return userActions?.map((action) => action?.name);
  }, [userActions]);

  const handleSelectStation = (stationId) => {
    setSelectedStation(stationId);
  };

  const handleClickMarker = useCallback((marker, popupContent) => {
    const stationId = popupContent.id;
    setSelectedStation(() => {
      if (availableStations.includes(stationId)) return stationId;
      else return null;
    });
  }, [availableStations]);

  const handleClosePopup = useCallback((marker, popupContent) => {
    handleSelectStation(null);
  }, [availableStations]);

  const graphHeight = () => {
    if (xs || sm || md) return "400px";
    else return "400px"
  };


  return (
    <Box padding={{ xs: "1rem", sm: "2rem 5rem" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={8}>
          <GraphsStateChip style={{ height: graphHeight() }} loading={loadingAliveSensors} aliveSensors={aliveSensors} selectedStation={selectedStation} onSelectStation={handleSelectStation}/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <DashboardMap enabledActions={enabledActions} aliveSensors={aliveSensors} selectedStation={selectedStation} onClickMarker={handleClickMarker} onClosePopup={handleClosePopup} />
        </Grid>
       {aliveSensors &&
        <Grid item xs={12} lg={12}>
          <DashboardTab enabledActions={enabledActions} stationId={selectedStation} 
          loadingMinMax={loadingMinMax} loadingThresholds={loadingThresholds} maxMinMeasures={maxMinMeasures} thresholds={thresholds}/>
        </Grid>
        }
      </Grid>
    </Box>
  )
}


export default DashboardContent