

export const navbarGroupsNamesEn = {
	STATION_GROUP : "Data center", 
	MULTIMEDIA_GROUP : "Multimedia", 
	ODOUR_PORTAL_GROUP : "Odours portal",
	OPEN_DATA_PORTAL_GROUP : "Open data portal",
	VESSELS_GROUP : "Naval portal",
	ALERTS_AND_REPORTS_GROUP : "Alerts and reports",
	CONFIGURATION_GROUP : "Control room", 
	TEST : "Test sections",
};