import {useEffect, useLayoutEffect, useMemo, useState } from 'react'
import RangeTimeFilter_v2 from '../SharingComponents/RangeTimeFilter_v2';
import { useSelector } from 'react-redux';
import { viewNames } from 'app/configs/routesNames';
import { setFilter, setTimeRangeFilter } from 'app/store/evocsSlice/filters/filtersActions';
import MeasureAutocomplete from '../SharingComponents/Inputs/MeasureAutocomplete';
import { getStationMeasures, hasStationGps } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Paper } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FaMapLocationDot } from "react-icons/fa6";
import { FaRoute } from "react-icons/fa";
import ToggleButton from '../SharingComponents/Inputs/ToggleButton';
import useFilters from 'src/app/hooks/useFilters';
import useLoadingDevices from 'src/app/hooks/useLoadingDevices';

const viewName = viewNames.SINGLE_STATION_VIEW;

const SwitchStation = ({style, stationList, value, defaultValue=undefined, onChange}) => {
  const stations = useSelector((state) => state.stationsMongo.list);
  const [selected, setSelected] = useState(defaultValue);

  useLayoutEffect(() => {
    value && setSelected(value);
  }, [value])

  const handleClickArrow = (direction) => {
    const index = stationList.indexOf(selected) + direction;
    if(index < 0 || index >= stationList.length) return;
    setSelected(stationList[index]);
    onChange && onChange(stationList[index]);
  }

  return(
    <Paper sx={{...style, display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", width: "100%", maxWidth: "300px", padding: "1rem"}}>
      <IconButton disabled={selected === stationList[0]} onClick={() => handleClickArrow(-1)}><ArrowBack /></IconButton>
      <h3 style={{textAlign: "center"}}>{stations[selected]?.serial}</h3>
      <IconButton disabled={selected === stationList[stationList.length-1]} onClick={() => handleClickArrow(1)}><ArrowForward /></IconButton>
    </Paper>
  )
}


const SingleStationHandlingContentHeader = ({enableLastPositionMap, enableMapRoute, onToggleLastPosition, onToggleMapRoute, onRefresh, startDate, endDate}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const loadingDevices = useLoadingDevices();
  const stationId = location.state.stationId;
  const loadingDatasets = useSelector((state) => state.dataset.loading);
  const user = useSelector((state) => state.user);
  const stations = useSelector((state) => state.stationsMongo.list);
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const measureSelect = filters.measure;
  const [disalbledGPS, setDisabledGPS] = useState(true);

  const {enabledIntervals, defaultInterval} = useMemo(() => { 
    const intervals = [];
    let defaultId = null;
     timeRangeInView?.list.forEach((element) => {
      if(element.active===1) intervals.push(element._id);
      if(element.default===1) defaultId = element._id;
     });
    defaultId = timeRangeInView?.selected ? timeRangeInView.selected : defaultId;
    return {enabledIntervals: intervals, defaultInterval: defaultId}
  }, [user.selectedTenant, timeRangeInView.list])


  const stationsMeasures = useMemo(() => {
    return getStationMeasures(stationId, true);
  }, [stationId]);


  useEffect(() => {
    setFilter(viewName, measureSelect.id, [], stationsMeasures);
  }, [stationId])

  useEffect(() =>  {
    if(loadingDevices) return;
    setDisabledGPS(!hasStationGps(stationId));
  }, [loadingDevices, stationId])

  useEffect(() => {
    if(enableMapRoute && disalbledGPS) {
      onToggleMapRoute(false);
    }
  }, [stationId, disalbledGPS])


  const handleChangeMeasureSelect = (measureId) => {
    setFilter(viewName, measureSelect.id, measureId);
  };


  const handleChangeStation = (stationId) => {
    navigate(`/stations/${stations[stationId].serial}`, {state: {stationId, timeout: 800}}) 
  };

  const handleChangeInterval = (intervalId, startDate, endDate) => {
    setTimeRangeFilter(viewName, timeRangeInView.id, intervalId, startDate, endDate);
  };

  const handleChangeSlider = (startDate, endDate, value) => {
    setTimeRangeFilter(viewName, timeRangeInView.id, undefined, startDate.valueOf(), endDate.valueOf());
  };


  return (
    <div style={{marginBottom: "2rem"}}>
      <SwitchStation style={{margin: "0 auto 1rem auto"}} stationList={availableStations} defaultValue={stationId} onChange={handleChangeStation}/>
      <div style={{display: "flex", gap: "1rem", alignItems: "center", flexWrap: "wrap"}}>
        <div style={{flexGrow: 1}}>
         <MeasureAutocomplete multiple limitTags={1} measuresId={measureSelect.list} value={measureSelect.selected || undefined} onChange={handleChangeMeasureSelect}/>
        </div>
        <div style={{flexGrow: 1}}>
          <RangeTimeFilter_v2 withDatePicker intervals={enabledIntervals} 
          startDate={startDate} endDate={endDate}
          onChangeInterval={handleChangeInterval} onChangeSlider={handleChangeSlider}
          selectedInterval={timeRangeInView.selected} defaultInterval={defaultInterval}
          />
        </div>
        <div style={{flexGrow: 1}}>
          <IconButton disabled={loadingDatasets} sx={{height: "fit-content", color: "black"}} onClick={onRefresh}>
            <RefreshIcon fontSize='large'/>
          </IconButton>
          <ToggleButton enabled={enableLastPositionMap} onChange={onToggleLastPosition}>
            <FaMapLocationDot />
          </ToggleButton>
          {!disalbledGPS &&
          <ToggleButton enabled={enableMapRoute} onChange={onToggleMapRoute}>
            <FaRoute/>
          </ToggleButton>}
        </div>
      </div>
    </div>
  )
}

export default SingleStationHandlingContentHeader;