import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./app/App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./styles/app-base.css";
import "./styles/app-components.css";
import "./styles/app-utilities.css";
import store from "./app/store";
import axios from "axios";
import { ArcElement, Chart, registerables } from 'chart.js';

Chart.register(...registerables);
// Chart.register(ArcElement);

// axios.defaults.baseURL = process.env.REACT_APP_API_HOST;  
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();

serviceWorker.unregister();
