import { Button, IconButton, TextField } from "@mui/material"
import { Box } from "@mui/system"
import CreateRoleModal from "../Modals/CreateRoleModal"
import { useCallback, useEffect, useMemo, useState } from "react";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import { useSelector } from "react-redux";
import ActionSelect from "../SharingComponents/Inputs/ActionSelect";
import { deleteTenantRole, updateActionsRole, updateTenantRole } from "app/store/evocsSlice/tenants/tenantsActions";
import ViewSelect from "../SharingComponents/Inputs/ViewSelect";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../Modals/WarningModal";
import AlertCategorySelect from "../SharingComponents/Inputs/AlertCategorySelect";
import GroupActionSelect from "../SharingComponents/Inputs/GroupActionSelect";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const CustomTitle = ({ title, visible = false, isEdit, onClickEdit, onClickCancel }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
      <label>{title}</label>
      {visible && (
        !isEdit ? <IconButton onClick={(e) => { e.stopPropagation(); onClickEdit() }}><EditIcon /></IconButton>
          : <Button onClick={(e) => { e.stopPropagation(); onClickCancel() }}> {handleTranslate("CANCEL", true)} </Button>)}
    </div>
  )
};

const AllowedActions = ({ activeViews, roleActions, selectedGroupActions, selectedActions, disabled, onChangeGroupAction, onChangeAction, getDefaultSelectedActions, getDefaultSelectedGroupActions }) => {
  const actions = useSelector((state) => state.configuration.constants.action);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const [actionList, setActionList] = useState({});
  const [groupsActionList, setGroupActionList] = useState({});
  const [oldSelectedGroupActions, setOldSelectedGroupActions] = useState({});
  const [oldActiveViews, setOldActiveViews] = useState([]);


  const getGroupActionList = useCallback(() => {
    let groupList = {};
    activeViews?.forEach((viewId) => {
      groupList[viewId] = [];
      Object.keys(roleActions[viewId]).forEach((actionId) => !groupList[viewId].includes(actions[actionId].groupId) && groupList[viewId].push(actions[actionId].groupId));
    })
    return groupList;
  }, [activeViews, roleActions]);

  const getActionList = useCallback(() => {
    let list = {};
    activeViews.forEach((viewId) => {
      list[viewId] = Object.keys(roleActions[viewId]).filter((actionId) => selectedGroupActions[viewId]?.includes(actions[actionId].groupId));
    })
    return list;
  }, [activeViews, roleActions, selectedGroupActions]);


  useEffect(() => {
    setOldSelectedGroupActions(selectedGroupActions);
  }, [selectedGroupActions])

  useEffect(() => {
    setOldActiveViews(activeViews);
  }, [activeViews])


  useEffect(() => {
    setGroupActionList(getGroupActionList());
  }, [getGroupActionList])



  useEffect(() => {
    const list = getActionList();
    const changedGroupActions = Object.keys(selectedGroupActions).filter((viewId) => oldSelectedGroupActions[viewId] && selectedGroupActions[viewId]?.length !== oldSelectedGroupActions[viewId]?.length);
    let newSelectedActions = { ...selectedActions };
    changedGroupActions.forEach((viewId) => {
      newSelectedActions[viewId] = list[viewId].filter((actionId) => selectedActions[viewId]?.includes(actionId) || !actionList[viewId]?.includes(actionId))
    });

    setActionList(list);
    onChangeAction(newSelectedActions, false);
  }, [selectedGroupActions])

  useEffect(() => {
    const newViews = activeViews.filter((viewId) => !oldActiveViews?.includes(viewId));
    const defaultGroupsSelected = newViews.length > 0 && getDefaultSelectedGroupActions();
    const defaultActionsSelected = newViews.length > 0 && getDefaultSelectedActions();
    let newSelectedGroupActions = { ...selectedGroupActions };
    let newSelectedActions = { ...selectedActions };

    // Elimina tutti gli input delle view che sono state deselezionate
    Object.keys(selectedGroupActions).filter((viewId) => !activeViews.includes(viewId)).forEach((id) => delete newSelectedGroupActions[id] && delete newSelectedActions[id]);

    newViews.forEach((viewId) => {
      newSelectedGroupActions[viewId] = defaultGroupsSelected[viewId] || [];
      newSelectedActions[viewId] = defaultActionsSelected[viewId] || [];
    });
    onChangeGroupAction(newSelectedGroupActions);
    onChangeAction(newSelectedActions);
  }, [activeViews])

  const handleChangeGroupAction = (viewId, value) => {
    onChangeGroupAction({ ...selectedGroupActions, [viewId]: value });
  };

  const handleChangeAction = (viewId, value) => {
    onChangeAction({ ...selectedActions, [viewId]: value });
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {activeViews?.map((viewId) => (
        <div key={viewId} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <label style={{ width: "100%", maxWidth: "200px" }}>{views[viewId].view_name}</label>
          <GroupActionSelect multiple disabled={disabled} groupsId={groupsActionList[viewId]} value={selectedGroupActions[viewId] || []} onChange={(value) => handleChangeGroupAction(viewId, value)} />
          <ActionSelect multiple disabled={disabled ? disabled : actionList[viewId]?.length === 0} actionsId={actionList[viewId]} value={selectedActions[viewId] || []} onChange={(value) => handleChangeAction(viewId, value)} />
        </div>
      ))}
    </div>
  );
}

const SingleRole = ({ role, tenantId, onDelete }) => {
  const actions = useSelector((state) => state.configuration.constants?.action);
  const viewNames = useSelector((state) => state.configuration.constants?.view_name);
  const requiredInputs = ["name"];
  const activableViews = useMemo(() => {
    if (!viewNames) return [];
    return Object.keys(viewNames).filter((viewId) => viewNames[viewId].activable === 1);
  }, [viewNames])
  const visibleViews = useMemo(() => {
    return role.visibleViews.filter((viewId) => viewNames[viewId].activable === 1);
  }, [role.visibleViews])
  const [roleInputs, setRoleInputs] = useState({
    name: role.roleName,
    description: role.descr,
    categoryAlerts: role.categoryIds,
    activeViews: visibleViews,
    groupActions: {},
    actions: {}
  });
  const [isEdit, setEdit] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [madeChanges, setMadeChanges] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const viewsWithActions = useMemo(() => {
    if (!roleInputs?.activeViews) return [];

    const reverseActiveViews = [...roleInputs.activeViews].reverse();
    return reverseActiveViews.filter((viewId) => Object.keys(role.actions[viewId]).length > 0);
  }, [roleInputs.activeViews, role.actions]);

  const getDefaultSelectedActions = useCallback(() => {
    let selected = {};
    role.visibleViews.forEach((viewId) => {
      selected[viewId] = Object.keys(role.actions[viewId]).filter((actionId) => (role.actions[viewId][actionId] === 1));
    });
    return selected;
  }, [role.actions]);

  const getDefaultSelectedGroupActions = useCallback(() => {
    let selected = {};
    role.visibleViews.forEach((viewId) => {
      selected[viewId] = [];
      Object.keys(role.actions[viewId]).forEach((actionId) => {
        if (role.actions[viewId][actionId] === 1 && !selected[viewId].includes(actions[actionId].groupId))
          selected[viewId].push(actions[actionId].groupId);
      });
    });
    return selected;
  }, [role.actions])

  useEffect(() => {
    setRoleInputs((prev) => ({ ...prev, groupActions: getDefaultSelectedGroupActions() }));
  }, [getDefaultSelectedGroupActions])

  useEffect(() => {
    setRoleInputs((prev) => ({ ...prev, actions: getDefaultSelectedActions() }));
  }, [getDefaultSelectedActions])


  useEffect(() => {
    if (!madeChanges) return;
    if (requiredInputs.some((key) => roleInputs[key] === "")) setCanSave(false);
    else setCanSave(true);
  }, [roleInputs, madeChanges])

  useEffect(() => {
    !accordionOpen && handleCancelChanges();
  }, [accordionOpen])

  const handleChangeInputs = (key, value, changes = true) => {
    setRoleInputs((prev) => ({ ...prev, [key]: value }))
    setMadeChanges(changes);
  };

  const handleCancelChanges = () => {
    setRoleInputs({
      name: role.roleName,
      description: role.descr,
      categoryAlerts: role.categoryIds,
      activeViews: visibleViews,
      groupActions: getDefaultSelectedGroupActions(),
      actions: getDefaultSelectedActions()
    });
    setEdit(false);
    setCanSave(false);
    setMadeChanges(false);
  };

  const handleDelete = () => {
    onDelete && onDelete(role);
  };

  const handleConfirm = () => {
    const allCalls = [
      updateTenantRole(role._id, roleInputs.name, roleInputs.description, roleInputs.activeViews, roleInputs.categoryAlerts),
      updateActionsRole(tenantId, role._id, roleInputs.actions)
    ];

    Promise.all(allCalls).then(() => {
      setEdit(false);
      setCanSave(false);
      setMadeChanges(false);
    });
  };


  return (
    <AccordionComponent
      title={<CustomTitle title={role.roleName} visible={accordionOpen} isEdit={isEdit} onClickEdit={() => setEdit(true)} onClickCancel={handleCancelChanges} />}
      expanded={accordionOpen}
      onChange={setAccordionOpen}
    >
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <TextField sx={{ width: "100%", maxWidth: "300px" }} required label="Nome" disabled={!isEdit} value={roleInputs.name} onChange={(e) => handleChangeInputs("name", e.target.value)} />
        <TextField sx={{ width: "100%", maxWidth: "300px" }} label="Descrizione" disabled={!isEdit} value={roleInputs.description} onChange={(e) => handleChangeInputs("description", e.target.value)} />
        <AlertCategorySelect multiple disabled={!isEdit} value={roleInputs.categoryAlerts} onChange={(value) => handleChangeInputs("categoryAlerts", value)} />
      </div>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center", flexWrap: "wrap", margin: "1rem 0" }}>
        <label>Pagine attive: </label>
        <ViewSelect multiple disabled={!isEdit} viewsId={activableViews} value={roleInputs.activeViews} onChange={(value) => handleChangeInputs("activeViews", value)} />
      </div>
      <h3 style={{ fontWeight: "bold" }}>Azioni consentite</h3>
      <AllowedActions activeViews={viewsWithActions} roleActions={role.actions} selectedGroupActions={roleInputs.groupActions} selectedActions={roleInputs.actions} disabled={!isEdit}
        onChangeGroupAction={(value) => handleChangeInputs("groupActions", value)} onChangeAction={(value, madeChanges) => handleChangeInputs("actions", value, madeChanges)}
        getDefaultSelectedActions={getDefaultSelectedActions} getDefaultSelectedGroupActions={getDefaultSelectedGroupActions} />
      {isEdit && <div style={{ marginLeft: "auto" }}>
        <Button disabled={!canSave} onClick={handleConfirm}>Salva</Button>
        <Button sx={{ color: "red" }} onClick={handleDelete}>Elimina</Button>
      </div>}
    </AccordionComponent>
  )
};

const SingleTenant = ({ tenantId, roles, onDeleteRole }) => {
  const tenant = useSelector((state) => state.tenants.list?.[tenantId]);

  return (
    <AccordionComponent title={tenant.name}>
      <Box>
        {roles?.map((role) => (
          <SingleRole key={role._id} role={role} tenantId={tenantId} onDelete={onDeleteRole} />
        ))}
      </Box>
    </AccordionComponent>
  )
};

const RolesInfo = ({ selectedTenants, onDeleteRole }) => {
  const tenants = useSelector((state) => state.tenants.list);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {selectedTenants.map((tenantId, index) => (
        <SingleTenant key={tenantId} tenantId={tenantId} roles={tenants[tenantId]?.roles} onDeleteRole={onDeleteRole} />
      ))}
    </div>
  );
};

const RolesConfiguration = ({ selectedTenants }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);

  const handleDeleteTenantRole = () => {
    deleteTenantRole(roleToDelete.tenantId, roleToDelete._id).then(() => {
      setRoleToDelete(null);
    });
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <Button sx={{ marginLeft: "auto" }} onClick={() => setIsModalOpen(true)}>+ {handleTranslate("NEW_ROLE")} </Button>
        </div>
        <RolesInfo selectedTenants={selectedTenants} onDeleteRole={setRoleToDelete} />
      </Box>
      <CreateRoleModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <DeleteModal isOpen={roleToDelete ? true : false} onClose={() => setRoleToDelete(null)} onConfirm={handleDeleteTenantRole} title={`${handleTranslate("WARNING_DELETE_ROLE")} ${roleToDelete?.roleName}?`} />
    </div>
  )
};

export default RolesConfiguration