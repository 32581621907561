import { Box, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import StationsStateGraph from './StationsStateGraph';
import SensorsStateGraph from './SensorsStateGraph';
import { useNavigate } from 'react-router-dom';
import { lightGreen, red, yellow, grey } from '@mui/material/colors';
import LoadingGif from '../FileManager/LoadingGif';
import Carousel from '../SharingComponents/Carousel';
import { routesNames } from 'app/configs/routesNames';
import CusStateGraph from './CusStateGraph';
import InfoButton from '../SharingComponents/InfoButton';
import useTouch from 'src/app/hooks/useTouch';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { viewNames } from 'app/configs/routesNames';

const StationTooltipText = ({fontsize=12}) => {
  const pixelSize = `${fontsize}px`;
  const { t } = useTranslation('evocs');
const handleTranslate = (textId, general = false) => {
	if (general) return (translate(t, textId));
	return (translate(t, textId,  viewNames.DASHBOARD_VIEW));
};

  return (
    <>
      <Typography variant="body1" gutterBottom style={{fontSize: pixelSize}}>
        {handleTranslate('PIE_LEGEND_1')}
      </Typography>
      <List>
        <ListItem>
          <ListItemText>
            <Box component="span" sx={{ color: `#85cf57`, fontWeight: 'bold', fontSize: pixelSize, textTransform: 'uppercase' }}> {handleTranslate('GREEN', true)}:</Box> <span style={{fontSize: pixelSize}}>{handleTranslate('PIE_LEGEND_2')}</span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box component="span" sx={{ color: yellow[500], fontWeight: 'bold', fontSize: pixelSize, textTransform: 'uppercase' }}>{handleTranslate('YELLOW', true)}:</Box> <span style={{fontSize: pixelSize}}>{handleTranslate('PIE_LEGEND_3')}</span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box component="span" sx={{ color: red[500], fontWeight: 'bold', fontSize: pixelSize, textTransform: 'uppercase' }}>{handleTranslate('RED', true)}:</Box> <span style={{fontSize: pixelSize}}> {handleTranslate('PIE_LEGEND_4')}</span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Box component="span" sx={{ color: "black", fontWeight: 'bold', fontSize: pixelSize, textTransform: 'uppercase' }}>{handleTranslate('GREY', true)}:</Box> <span style={{fontSize: pixelSize}}> {handleTranslate('PIE_LEGEND_5')}</span>
          </ListItemText>
        </ListItem>
      </List>
      <Typography variant="body1" style={{fontSize: pixelSize}}>
        {handleTranslate('PIE_LEGEND_6')}
      </Typography>
    </>
  )
}

const GraphsStateChip = ({ style, aliveSensors, selectedStation, defaultStation, onSelectStation, loading=false}) => {
  const navigate = useNavigate();
  const allStations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const [stationSelected, setStationSelected] = useState(defaultStation);
  const [cuSelected, setCuSelected] = useState();
  const [stationsGraph, setStationsGraph] = useState();
  const [cusGraph, setCusGraph] = useState();
  const [sensorsGraph, setSensorsGraph] = useState();
  const [carouselValue, setCarouselValue] = useState();
  const { t } = useTranslation('evocs');
  const handleTranslate = (textId, general = false) => {
    if (general) return (translate(t, textId));
    return (translate(t, textId,  viewNames.DASHBOARD_VIEW));
  };

  const getColors = useCallback((status) => {
    switch (status) {
      case 0:
        // return { backgroundColor: `#df1628`, borderColor: `${red[600]}` };
        // return { backgroundColor: `#ed413e`, borderColor: `${red[600]}` };
        return { backgroundColor: `${red[500]}`, borderColor: `${red[700]}` };
      case 1:
        // return { backgroundColor: `${yellow['A400']}`, borderColor: `${yellow[600]}` };
        return { backgroundColor: `${yellow[500]}`, borderColor: `${yellow[700]}` };
      case 2:
        return { backgroundColor: `#85cf57`, borderColor: `${lightGreen[700]}` };
        // return { backgroundColor: `${lightGreen[400]}`, borderColor: `${lightGreen[600]}` };
        // return { backgroundColor: `#92C870`, borderColor: `${lightGreen[600]}` };
      default:
        return { backgroundColor: `${grey[500]}`, borderColor: `${grey[700]}` };
    }
  }, []);
  
  const calcStationsGraph = useCallback((res) => {
    let graph = {
      data: [],
      labels: [],
      backgroundColor: [],
      borderColor: [],
    }
    Object.keys(res).forEach((stationId) => {
      graph.labels.push(allStations[stationId]?.serial);
      const colors = getColors(res[stationId].status);
      graph.backgroundColor.push(colors.backgroundColor);
      graph.borderColor.push(colors.borderColor);
    });
    graph.data = new Array(graph.labels.length).fill(100 / graph.labels.length);
    return graph;
  }, [allStations])

  const calcCusGraph = useCallback(() => {
    let graph = {
      data: [],
      labels: [],
      backgroundColor: [],
      borderColor: [],
    }
    Object.keys(aliveSensors[stationSelected]).forEach((sensorId) => {
      if (sensorId === "status") return;
      if (sensorId === "cus") {
        Object.keys(aliveSensors[stationSelected].cus).forEach((cuId) => {
          graph.labels.push(controlUnits[cuId].serial);
          const colors = getColors(aliveSensors[stationSelected].cus[cuId].status);
          graph.backgroundColor.push(colors.backgroundColor);
          graph.borderColor.push(colors.borderColor);
        });
        return;
      }
      graph.labels.push(sensors[sensorId].serial);
      const colors = getColors(aliveSensors[stationSelected][sensorId]);
      graph.backgroundColor.push(colors.backgroundColor);
      graph.borderColor.push(colors.borderColor);
    });
    graph.data = new Array(graph.labels.length).fill(100 / graph.labels.length);
    return graph;
  }, [aliveSensors, stationSelected])

  const calcSensorsGraph = useCallback(() => {
    let graph = {
      data: [],
      labels: [],
      backgroundColor: [],
      borderColor: [],
    }
    const cu = aliveSensors[stationSelected].cus[cuSelected];
    Object.keys(cu).forEach((sensorId) => {
      if (sensorId === "status") return;
      graph.labels.push(sensors[sensorId].serial);
      const colors = getColors(cu[sensorId]);
      graph.backgroundColor.push(colors.backgroundColor);
      graph.borderColor.push(colors.borderColor);
    });
    graph.data = new Array(graph.labels.length).fill(100 / graph.labels.length);
    return graph;
  }, [aliveSensors, stationSelected, cuSelected])

  useEffect(() => {
    if (!aliveSensors) return;
    setStationsGraph(calcStationsGraph(aliveSensors));
  }, [aliveSensors])

  useEffect(() => {
    selectedStation !== undefined && setStationSelected(selectedStation);
  }, [selectedStation])

  useEffect(() => {
    setCuSelected(null);
    stationSelected ? setCarouselValue(1) : setCarouselValue(0);
  }, [stationSelected])

  useEffect(() => {
    if (!aliveSensors || !stationSelected) return;
    setCusGraph(calcCusGraph());
  }, [stationSelected, aliveSensors])

  useEffect(() => {
    if (!aliveSensors || !cuSelected) return;
    setSensorsGraph(calcSensorsGraph());
  }, [cuSelected, aliveSensors])




  const handleClickStationGraph = useCallback((serial) => {
    const stationId = Object.keys(allStations).find((id) => allStations[id].serial === serial);
    stationId ? setCarouselValue(1) : setCarouselValue(0);
    setStationSelected(stationId);
    onSelectStation && onSelectStation(stationId);
  }, [allStations])

  const handleClickSensorGraph = useCallback((serial) => {
    // const sensorId = Object.keys(sensors).find((id) => sensors[id].serial === serial);
    navigate(`/stations/${allStations[stationSelected].serial}`, {
      state: {
        stationId: stationSelected,
        // sensorId: sensorId,
        backView: routesNames.DASHBOARD_VIEW
      }
    });
  }, [sensors, stationSelected]);

  const handleClickCuGraph = useCallback((serial) => {
    const cuId = Object.keys(controlUnits).find((id) => controlUnits[id].serial === serial);
    if (cuId) setCarouselValue(2);
    else handleClickSensorGraph(serial);
    setCuSelected(cuId);
  }, [controlUnits, handleClickSensorGraph]);

  const [isTouchDevice] = useTouch();


  return (
    <Paper sx={{ ...style, position: "relative" }}>
      <InfoButton style={{ position: "absolute", right: "1rem", top: "1rem" }} fontSize='medium' placement='bottom' enableModal={isTouchDevice}>
        <StationTooltipText fontsize={12} />
      </InfoButton>
      <Carousel style={{ padding: "1rem" }} disableArrows={false} value={carouselValue} onChange={setCarouselValue}>
        {!loading ?
          <StationsStateGraph data={stationsGraph?.data} labels={stationsGraph?.labels} backgroundColor={stationsGraph?.backgroundColor} borderColor={stationsGraph?.borderColor} onClickPie={handleClickStationGraph} />
          :
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <LoadingGif />
          </Box>
        }
        {stationSelected && (
          !loading ?
            <CusStateGraph title={`${handleTranslate('STATION', true)}: ${allStations?.[stationSelected]?.serial}`} data={cusGraph?.data} labels={cusGraph?.labels} backgroundColor={cusGraph?.backgroundColor} borderColor={cusGraph?.borderColor} onClickPie={handleClickCuGraph} />
            :
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <LoadingGif />
            </Box>
        )}
        {cuSelected && (
          !loading ?
            <SensorsStateGraph title={`${handleTranslate('CONTROL_UNIT', true)}: ${controlUnits?.[cuSelected]?.serial}`} data={sensorsGraph?.data} labels={sensorsGraph?.labels} backgroundColor={sensorsGraph?.backgroundColor} borderColor={sensorsGraph?.borderColor} onClickPie={handleClickSensorGraph} />
            :
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <LoadingGif />
            </Box>
        )}
      </Carousel>
    </Paper>
  )
}

export default GraphsStateChip