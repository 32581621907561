import { getApiTimestamp } from "src/utilities/utils";
import store from "../.."
import { reportsSliceActions } from "./reportsSlice"

export const getAllReports = async(startDate, endDate, type=null, level=null) => {
    const data = await store.dispatch(reportsSliceActions.getAllReports({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), type, level})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const addOdourReport = async(userId, odourDate, odourLevelId, odourTypeId, baseLocationId=null, newLat=null, newLon=null) => {
    const date = getApiTimestamp(odourDate);
    const data = await store.dispatch(reportsSliceActions.addOdourReport({userId, odourDate: date, odourLevelId, odourTypeId, baseLocationId, newLat, newLon})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
} 

export const deleteOdourReport = async(odourId) => {
    const data = await store.dispatch(reportsSliceActions.deleteOdourReport({odourId})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
} 

export const getReportsPercentages = async(startDate, endDate, kind, type=null, level=null) => {
    const data = await store.dispatch(reportsSliceActions.getReportsPercentages({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), kind, type, level})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const getUnitFileData = async( unitId, startDate, endDate, unitType, fileType, tenantId, fileName) => {

    const data = await store.dispatch(
        reportsSliceActions.getUnitFileData({ 
            unitId:unitId, 
            startDate:startDate, 
            endDate:endDate, 
            unitType:unitType, 
            fileType:fileType, 
            tenantId:tenantId,
            fileName:fileName})
        ).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const getOdourFile = async(startDate, endDate, typi, level, fileType, fileName) => {
    console.log('startDate, endDate, typi, level, fileType, fileName', startDate, endDate, typi, level, fileType, fileName);
    const data = await store.dispatch(
        reportsSliceActions.getOdourFile({
            startDate:startDate, endDate:endDate, typi:typi, level:level, fileType:fileType, fileName:fileName
        })
        ).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};
export const getOpenDataFiles = async(startDate, endDate, sources, stationIds, fields, typeStation, fileType, fileName) => {
    const data = await store.dispatch(
        reportsSliceActions.getOpenDataFiles({
            startDate:startDate, endDate:endDate, sources:sources, stationIds:stationIds, fields:fields, typeStation:typeStation , fileType:fileType, fileName:fileName
        })
        ).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};
export const getSensorReportInfo = async(startDate, endDate, sensorIds) => {
    const data = await store.dispatch(
        reportsSliceActions.getSensorReportInfo({
            startDate:startDate, endDate:endDate, sensorIds:sensorIds
        })
        ).then(({payload, error}) => {
        if(error) throw error;
        // console.log('payload', payload);
        return payload;
    });
    return data;
};
export const getStationReportInfo = async(startDate, endDate, stationId) => {
    const data = await store.dispatch(
        reportsSliceActions.getStationReportInfo({
            startDate:startDate, endDate:endDate, stationId:stationId
        })
        ).then(({payload, error}) => {
        if(error) throw error;
        // console.log('payload', payload);
        return payload;
    });
    return data;
};
export const getCuReportInfo = async(startDate, endDate, cuId) => {
    const data = await store.dispatch(
        reportsSliceActions.getCuReportInfo({
            startDate:startDate, endDate:endDate, cuId:cuId
        })
        ).then(({payload, error}) => {
        if(error) throw error;
        // console.log('payload', payload);
        return payload;
    });
    return data;
};