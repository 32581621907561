import { Box } from "@mui/system";
import { viewNames } from "app/configs/routesNames";
import useOpendata from "src/app/hooks/useOpendata";
import OpenDataAirContentHeader from "./OpenDataAirContentHeader";
import LoadingGif from "../FileManager/LoadingGif";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AqTable from "./AqTable";
import MapComponent from "../SharingComponents/MapComponent";
import { getOpenDataLastRelevations } from "app/store/evocsSlice/openaData/openaDataActions";
import { getOpenDataFiles } from "app/store/evocsSlice/reports/reportsActions";
import useFilters from "src/app/hooks/useFilters";
import { Grid, Paper } from "@mui/material";
import NoDataMessage from "../SharingComponents/NoDataMessage";
import useMobile from "src/app/hooks/useMobile";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const viewName = viewNames.OPEN_DATA_AIR_QUALITY_VIEW;

const OpenDataAirContent = () => {
  const [xs] = useMobile();
  const measures = useSelector((state) => state.configuration.constants.measure);
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const sourceSelect = filters.open_source;
  const [popupsContent, setPoupsContent] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [selectedMarker, setSelecteMarker] = useState();
  const fileName = 'open_data_air_quality';
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.OPEN_DATA_AIR_QUALITY_VIEW);
  };

  const [data, markers, loadingMarkers, loadingData] = useOpendata(timeRangeInView.startDate, timeRangeInView.endDate, "AQ", sourceSelect.selected);
  useEffect(() => {
    if (!markers || !measures) return;
    setPoupsContent(markers.map((element, index) => {
      const newElement = { ...element };
      delete newElement.lat; delete newElement.lon; delete newElement.icon;
      const measuresLabels = [];
      newElement.measures?.forEach((measure) => {
        const measureId = Object.keys(measures).find((id) => measures[id].measure === measure);
        let labelTemp = measures[measureId]?.measure || measure
        measuresLabels.push(handleTranslate(`MEASURE_${labelTemp.toUpperCase()}`, true));
      })
      newElement.title = newElement.source;
      newElement.source = { label: `${handleTranslate("SOURCE", true)}`, value: newElement.source };
      newElement.station = { label: `${handleTranslate("STATION", true)}`, value: newElement.station };
      newElement.type = { label: `${handleTranslate("TYPE", true)}`, value: newElement.type };
      newElement.measures = { label: `${handleTranslate("MEASURES", true)}`, value: measuresLabels };
      return newElement;
    }))
  }, [markers, measures, t]);


  const getNewPopups = (lastRelevations) => {
    if (!lastRelevations || lastRelevations.length === 0) return;
    const newPopups = [...popupsContent];
    lastRelevations.forEach((el) => {
      const index = newPopups?.findIndex((popup) => popup.station.value === el.station);
      newPopups[index].lastRelevations = { title: handleTranslate("LAST_RELEVATIONS", true) };

      Object.keys(el.detections).forEach((measure) => {
        const measureId = measures && Object.keys(measures).find((id) => measure === measures[id].measure);
        const label = handleTranslate(`MEASURE_${measures[measureId]?.measure.toUpperCase()}`) || measure;
        newPopups[index][label] = el.detections[measure];
      })
    });

    setPoupsContent(newPopups);
  };

  const handleClickMarker = useCallback((marker, popup, index) => {
    setSelecteMarker(index);
    getOpenDataLastRelevations(popup.station.value).then((res) => {
      getNewPopups(res);
    });
  }, [popupsContent])

  const handleClickTableRow = (rowIndex) => {
    const dataElement = data[rowIndex];
    const mIndex = markers.findIndex((m) => m.station === dataElement.station && m.source === dataElement.source);
    const newCenter = { lat: markers[mIndex].lat, lon: markers[mIndex].lon };
    window.scrollTo({ top: -1000, behavior: "smooth" });
    setMapCenter(newCenter);
    setSelecteMarker(mIndex);
  };

  const handleDownloadFile = async (fileType) => {
    const fileData = await getOpenDataFiles(timeRangeInView.startDate, timeRangeInView.endDate, sourceSelect, null, null, 'AQ', fileType, fileName);
    return fileData;
  };


  return (
    <Box padding={{ xs: "1rem", sm: "2rem 5rem" }}>
      <OpenDataAirContentHeader />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8}>
          {loadingData ?
            <Paper sx={{ display: "flex", alignItems: "center", height: "100%", minHeight: "40vh" }}><LoadingGif /></Paper>
            :
            data?.length > 0 ?
              <AqTable data={data} onClickRow={handleClickTableRow} downloadFileProps={{ handleDownloadFile: handleDownloadFile, fileName: fileName }} />
              :
              <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", minHeight: "40vh" }}>
                <NoDataMessage />
              </Paper>
          }
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MapComponent height={xs ? "40vh" : "77vh"} cluster center={mapCenter} markers={markers} popupsContent={popupsContent} selectedMarker={selectedMarker}
            onClickMarker={handleClickMarker} />
        </Grid>
      </Grid>


    </Box>
  )
}

export default OpenDataAirContent;