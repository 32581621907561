import { useEffect, useState } from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';

const LightIconButton = styled(IconButton)(({recording}) => ({
  position: "relative",
  color: "red",
  fill: "red",
  borderRadius: "0px",
  "&.MuiIconButton-root": {
    background: recording === "true" ? "rgb(146, 200, 112)" : "none",
  },
  "&:hover": {
    background: recording === "false" ? "rgba(255, 255, 255, 0.25)" : "rgb(146, 200, 112)"
  }
}));


const RecIconButton = ({defaultRecording=false, recording, onRecording}) => {
  const [isRecording, setRecording] = useState(defaultRecording);

  useEffect(() => {
    recording !== undefined && setRecording(recording);
  }, [recording])

  const handleRegistration = () => {
    setRecording(!isRecording);
    onRecording && onRecording(!isRecording);
  }

  return (
    <Tooltip title={!isRecording ? "Avvia registrazione" : "Arresta registrazione"}>
      <LightIconButton recording={isRecording.toString()} onClick={handleRegistration}>
        <div style={{background: "white", borderRadius: "50%"}}>
          <svg style={{width: "24px", height: "24px", background: "none"}} fill='inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" role="img"><path d="M12 22A10.011 10.011 0 012 12 10.011 10.011 0 0112 2a10.011 10.011 0 0110 10 10.011 10.011 0 01-10 10zm5.406-12.793a2.767 2.767 0 00-1.424.357 2.349 2.349 0 00-.932 1.027 3.565 3.565 0 00-.324 1.561 3.212 3.212 0 00.66 2.17 2.4 2.4 0 001.906.756 4.058 4.058 0 001.57-.293v-1.016a4.542 4.542 0 01-1.456.3c-.951 0-1.434-.643-1.434-1.91a2.408 2.408 0 01.375-1.432 1.241 1.241 0 011.059-.513 1.949 1.949 0 01.636.113 6.457 6.457 0 01.644.266l.391-.985a3.86 3.86 0 00-1.67-.401zm-6.907.082V15h3.289v-1H11.71v-1.473h1.934v-.992H11.71v-1.254h2.078v-.992zm-4.071 3.52h.645L8.436 15H9.78c-.263-.378-.843-1.239-1.679-2.488a1.838 1.838 0 00.785-.616 1.52 1.52 0 00.278-.9 1.519 1.519 0 00-.559-1.287 2.861 2.861 0 00-1.722-.424H5.219V15H6.43v-2.19zm.391-.985h-.391v-1.543h.367a1.707 1.707 0 01.866.172.629.629 0 01.279.579.686.686 0 01-.274.6 1.5 1.5 0 01-.846.192z"></path></svg>
        </div>
        {isRecording && <div style={{position: "absolute", width: "7px", height: "7px", background: "red", borderRadius: "50%", top: "2px", left: "2px"}}/>}
      </LightIconButton>
      {/* <LightIconButton recording={isRecording} onClick={handleRegistration}><RadioButtonCheckedIcon /></LightIconButton> */}
    </Tooltip>
  )
}

export default RecIconButton