import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useActions = (viewName) => {
  const user = useSelector((state) => state.user);
  const tenant = useSelector((state) => state.tenants.list[user.selectedTenant]);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const actions = useSelector((state) => state.configuration.constants.action);
  // console.log('views', views, 'actions', actions, 'viewName', viewName, 'user', user, 'tenant', tenant);
  const viewId = useMemo(() => {
    if(!views) return null;
    if(Array.isArray(viewName))
        return viewName.map((name) => Object.keys(views).find((id) => views[id].view_name === name))
    else 
        return Object.keys(views).find((id) => views[id].view_name === viewName);
  }, [viewName, views]);

  const userActions = useMemo(() => {
    return tenant?.roles.find((role) => role._id === user.roleId)?.actions;
  }, [tenant]);

  const userViewActions = useMemo(() => {
    if(!viewId || !userActions) return;
    
    if(Array.isArray(viewId))
      return viewId.map((id, index) => ({
        viewId: id, 
        viewName: viewName[index], 
        actions: Object.keys(userActions[id]).map((actionId) => userActions[id][actionId] === 1 && ({id: actionId, name: actions[actionId].action, groupId: actions[actionId].groupId})).filter((e) => e)
      }));
    else return Object.keys(userActions[viewId]).map((actionId) =>  userActions[viewId][actionId] === 1 &&  ({id: actionId, name: actions[actionId].action, groupId: actions[actionId].groupId})).filter((e) => e);
  }, [userActions, viewId]);

  return userViewActions;
}

export default useActions