import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";
import MultimediaContent from "src/components/Multimedia/MultimediaContent";
import MultimediaHeader from "src/components/Multimedia/MultimediaHeader";
import CustomPageCarded from "./CustomPageCarded";

const Multimedia = () => {
  return (
    <WithAuth
      viewName={viewNames.MULTIMEDIA_VIEW}
      component={
        <CustomPageCarded
          header={<MultimediaHeader />}
          content={<MultimediaContent/>}
        />
      }
    />
  );
};

export default Multimedia;
