import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const EN = {
    HEADER : "Smart ports",
    SHIPS_OF_PORT: "Ships in the port of ",
    SHIPS_NUMBER: "Ships number",
    COUNTRY: "Country",
};

const VESSELS_EN = createLanguageObject(EN, viewNames.VESSELS_VIEW);



export default VESSELS_EN;