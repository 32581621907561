

const FILTER_CATEGORY_SELECT_IT = {
    INPUTS_TYPE_FILTER: "Tipo filtro",    
};

const CONSTANT_SELECT_IT = {
    INPUTS_CONSTANT_TYPE: "Tipo di costante",    
};

const NO_DATA_MESSAGE_IT = {
    INPUTS_NO_DATA: "Nessun dato disponibile",
};

const INFO_BUTTON_IT = {
    INPUTS_INSERT_INFO : "Inserisci qui le tue info",
};

const CATEGORY_ALERT_SELECT_IT = {
    INPUTS_NOTIF_TYPE: "Tipo notifica", 
};

const GROUP_ACTION_SELECT_IT = {
    INPUTS_GROUP_ACTION: "Categorie azioni",
};

const ALERT_CATEGORY_SELECT_IT = {
    INPUTS_NOTIFICATION_CATEGORY: "Categorie notifiche", 
};

const INPUT_COMPONENTS_IT = {
    ...FILTER_CATEGORY_SELECT_IT,
    ...CONSTANT_SELECT_IT,
    ...NO_DATA_MESSAGE_IT,
    ...INFO_BUTTON_IT,
    ...CATEGORY_ALERT_SELECT_IT,
    ...GROUP_ACTION_SELECT_IT,
    ...ALERT_CATEGORY_SELECT_IT,
};

export default INPUT_COMPONENTS_IT;