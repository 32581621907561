import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Button, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import AvatarImage from '../SharingComponents/Inputs/AvatarImage';
import LanguageSelect from '../SharingComponents/Inputs/LanguageSelect';
import EditCard from '../SharingComponents/EditCard';
import { updateUser } from 'app/store/evocsSlice/user/userAction';
import { useTranslation } from 'react-i18next';
import { translate } from 'src/utilities/utils';
import { viewNames } from 'app/configs/routesNames';
import { setGlobalError, setGlobalSuccess } from 'app/store/evocsSlice/globalError/errorActions';

const requiredInputs = ["username", "email", "tenantId", "language", "roleName"];

const ChangePasswordForm = ({isEdit, inputs, onChangeInput, passwordError}) => {
  const {t} = useTranslation("evocs");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_PROFILE_VIEW);
  };


  return (
    <div>
      <h3 style={{marginBottom: "1rem"}}>{handleTranslate("CHANGE", true) + " password"}</h3>
      <div style={{display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap"}}>
        <TextField sx={{width: "100%", maxWidth: "300px"}} error={passwordError ? true : false} helperText={passwordError} disabled={!isEdit} id='password' label={handleTranslate('NEW_PASSWORD')} value={inputs.password} onChange={(e) => onChangeInput?.("password", e.target.value)}/>
        <TextField sx={{width: "100%", maxWidth: "300px"}} disabled={!isEdit} id='confirmPassword' label={handleTranslate('CONFIRM', true) + " password"} value={inputs.confirmPassword} onChange={(e) => onChangeInput?.("confirmPassword", e.target.value)}/>
      </div>
    </div>
  )
}


const UserProfileContent = () => {
  const {t} = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const tenantId = user.tenantId;
  const roleName = tenants?.[tenantId]?.roles.filter((role) => role?.["_id"] !== user.roleId).map((role) => role?.["roleName"])[0];
  const defaulInputs = useMemo(() => {
    return {
      userImage: user.proPic,
      username: user.username,
      email: user.email,
      tenantId: tenantId,
      language: user.language || "", 
      roleName : roleName,
      password: "",
      confirmPassword: ""
    }
  }, [user]);

  const [loadingSave, setLoadingSave] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [inputs, setInputs] = useState(defaulInputs);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_PROFILE_VIEW);
  };

  useEffect(() => {
    if(!isInputsChanged || passwordError) {
      setCanSave(false);
      return;
    };
    const disabled = inputs.password !== inputs.confirmPassword || Object.keys(inputs).some((key) => (requiredInputs.includes(key) && (inputs[key] === "" || inputs[key]?.length === 0)));
    setCanSave(!disabled);
  }, [inputs, passwordError]);

  useEffect(() => {
    if(!loadingSave && !isEdit) {
      setInputs(defaulInputs);
      setInputsChanged(false);
      setShowChangePasswordForm(false);
    }
  }, [loadingSave, isEdit])

  useEffect(() => {
    if(!inputs) return "";
    if(inputs.password.length < 8) setPasswordError(handleTranslate("PASSWORD_ERROR_LENGTH"));
    else if(inputs.password !== inputs.confirmPassword) setPasswordError(handleTranslate("PASSWORD_NOT_MATCH"));
    else setPasswordError("");
  }, [t, inputs])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setInputsChanged(true); 
  };

  const handleCancelChanges = () => {
    setEdit(false);
  };

  const handleSave = () => {
    const password = !inputs.password ? undefined : inputs.password === "" ? undefined : inputs.password;
    setLoadingSave(true);
    setTimeout(() => {
      setGlobalSuccess(true)
      setLoadingSave(false);
    }, [2000]);
    updateUser(user.sqlId, inputs.language, inputs.userImage, password)
    .then(() => setGlobalSuccess(true))
    .catch(() => setGlobalError(true))
    .finally(() => setLoadingSave(false))
    setInputsChanged(false);
  };


  return (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <EditCard style={{width: "fit-content", margin: "0 auto"}} loading={loadingSave} isClosable={false} disableSave={!canSave} disableDelete onChangeEdit={setEdit} onCancelChanges={handleCancelChanges} onSave={handleSave}>
        <div style={{display: "flex", gap: "1rem", flexDirection: "column", alignItems: "center", margin: "0 auto"}}>
          <AvatarImage disabled={!isEdit} value={inputs.userImage} onChange={(value) => handleChangeInput("userImage", value)}/>
          <div style={{display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap"}}>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='username' label='Username' value={inputs.username} onChange={(e) => handleChangeInput("username", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='email' label='Email' value={inputs.email} onChange={(e) => handleChangeInput("email", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='tenant' label="Tenant" value={tenants[inputs.tenantId]?.name || ""} onChange={(e) => handleChangeInput("tenantId", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='roleName' label="Role" value={inputs.roleName || ""} onChange={(e) => handleChangeInput("roleId", e.target.value)}/>
            <LanguageSelect disabled={!isEdit} value={inputs.language} onChange={(value) => handleChangeInput("language", value)}/>
          </div>
          {showChangePasswordForm ? <ChangePasswordForm isEdit={isEdit} inputs={inputs} passwordError={passwordError} onChangeInput={handleChangeInput} />
          :<Button disabled={!isEdit} onClick={() => setShowChangePasswordForm(true)}>{handleTranslate("CHANGE_PASSWORD")}</Button>}
        </div>
      </EditCard>
    </Box>
  )
};

export default UserProfileContent;