// AuthProvider.js

import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import jwtService from "./services/jwtService";
import { setWaitAuthCheck } from "app/store/evocsSlice/login/loginActions";
import { routesNames } from "src/app/configs/routesNames";
import useData from "../hooks/useViewData";
import { useSelector } from "react-redux";
import { viewNames } from "src/app/configs/routesNames";

const AuthContext = React.createContext();

function AuthProvider({ children }) {

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  // const [isAuthenticated, setIsAuthenticated] = useState(null);
  const dispatch = useDispatch();
  // const stations = useSelector((state) => state?.stations?.stations);
  // const defaultViewName = viewNames.STATIONS_VIEW;
  // const defaultIntervalId = useSelector((state)=> state?.filters?.defaultIntervalId)
  // const [startingDate, setStartingDate] = useState((useSelector((state) => state?.filters[defaultViewName]?.timeRangeInterval?.minStartingDate)));
  // const [endingDate, setEndingDate] = useState((useSelector((state) => state?.filters[defaultViewName]?.timeRangeInterval?.maxEndingDate)));
  // const dataFetch = useData(startingDate, endingDate, defaultIntervalId, true);

  useEffect(() => {
    const onLogin = (user) => {
      // console.log('AuthProvider: (onLogin) set isAuthenticated to true and waitAuthCheck to false');
      // setIsAuthenticated(true);
      // setWaitAuthCheck(false);
      // window.location.replace(routesNames.DASHBOARD_VIEW);
      // window.location.replace(routesNames.TEST_PAGE_VIEW);
    };

    const onLogout = () => {
      // console.log('AuthProvider: (onLogout) set isAuthenticated to false and waitAuthCheck to false');
      // setIsAuthenticated(false);
      // setWaitAuthCheck(false);
    };

    const onRefresh = () => {
      // console.log('AuthProvider: (onRefresh) set isAuthenticated to true and waitAuthCheck to false');
      // setIsAuthenticated(true);
      // setWaitAuthCheck(false);

      // if (!stations) {
      //   dataFetch();
      // }

    };

    jwtService.on("onLogin", onLogin);
    jwtService.on("onLogout", onLogout);
    jwtService.on("onRefresh", onRefresh);

    // console.log('AuthProvider: Calling jwtService.init()');
    jwtService.init();

    return () => {
      // console.log('AuthProvider: Removing JwtService event listeners');
      jwtService.off("onLogin", onLogin);
      jwtService.off("onLogout", onLogout);
      jwtService.off("onRefresh", onRefresh);
    };
  }, [dispatch]);

  return  (
    // <AuthContext.Provider value={{ waitAuthCheck, isAuthenticated }}>
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
