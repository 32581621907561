import React, { useMemo } from 'react'
import Plot from 'react-plotly.js';

const theta = [
    "N",
    "NNE",
    "ENE",
    "E",
    "ESE",
    "SSE",
    "S",
    "SSW",
    "WSW",
    "W",
    "WNW",
    "NNW",
  ];
  const className = [
    "<0.5 m/s",
    "0.5-1.5 m/s",
    "1.5-2.5 m/s",
    "2.5-4.5 m/s",
    "4.5-6 m/s",
    "6-7 m/s",
    "7-8 m/s",
    ">8 m/s",
  ];
  const windColors = [
    "rgb(0,0,127)",
    "rgb(0,18,255)",
    "rgb(0,163,255)",
    "rgb(64,255,182)",
    "rgb(182,255,64)",
    "rgb(255,184,0)",
    "rgb(255,49,0)",
    "rgb(127,0,0)",
  ];

const windPlotLayout = {
    // title: "Regime anemometrico",
    autosize: true,
    font: { size: 12, color: "black", family: "Arial" },
    showlegend: true,
    margin: {t:20, l: 35, r: 35, b: 0},
    legend: {
      x: 0, //.8, 
      y: -.1,
      orientation: "h",
      // title: {
      //   text: `Regime anemometrico`, 
      //   y: 5,
      //   yref: "paper",
      //   font: {size: 12}
      // }, 
      font: { size: 11 } 
        // font: {size: 16}}, 
        // font: { size: 14 } 
      },
    polar: {
      hole: 0.05,
      barmode: "stack",
      color: "#242a44",
      radialaxis: { ticksuffix: "%", angle: 30.0, dtick: 5,  automargin: true, },
      angularaxis: {
      direction: "clockwise",
      color: "#242a44",
      griddash: "solid",
      linewidth: 0,
      gridwidth: 1,
      automargin: true,
    },
  },
};

const defaultData = [{
    r: [77.5, 72.5, 70.0, 45.0, 22.5, 42.5, 40.0, 62.5],
    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
    name: "11-14 m/s",
    marker: {color: "rgb(106,81,163)"},
    type: "barpolar"
    }, {
    r: [57.5, 50.0, 45.0, 35.0, 20.0, 22.5, 37.5, 55.0],
    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
    name: "8-11 m/s",
    marker: {color: "rgb(158,154,200)"},
    type: "barpolar"
    }, {
    r: [40.0, 30.0, 30.0, 35.0, 7.5, 7.5, 32.5, 40.0],
    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
    name: "5-8 m/s",
    marker: {color: "rgb(203,201,226)"},
    type: "barpolar"
    }, {
    r: [20.0, 7.5, 15.0, 22.5, 2.5, 2.5, 12.5, 22.5],
    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
    name: "< 5 m/s",
    marker: {color: "rgb(242,240,247)"},
    type: "barpolar"
}];

const WindRose = ({data=defaultData}) => {

  const dataFormat = useMemo(() => {
    return data.map((e, index) => ({
        r: e,
        theta: theta,
        type: "barpolar",
        name: className[index],
        marker: { color: windColors[index] },
    }))
  }, [data])

  return (
    <Plot
        style={{width: "100%"}}
        data={dataFormat}
        layout={windPlotLayout}
        config={{responsive: true}}
        useResizeHandler
    />
  )
}

export default WindRose