import React, { useMemo } from 'react'
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata';
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const NoDataMessage = ({fullWidth=false, message}) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
     if (general) return translate(t, textId);
     return translate(t, textId, inputsComponentsNames.INPUTS);
  };

  message = message ?? handleTranslate("NO_DATA");

  return (
    <div style={{display: "flex", width: fullWidth && "100%", height: "100%", flexDirection: "column", gap: "1rem", alignItems: "center", justifyContent: "center", color: "rgba(0, 0, 0, .5)"}}>
      <SignalCellularNodataIcon sx={{width: "70px", height: "70px"}} fontSize="large" />
      <h2>{message}</h2>
    </div>
  )
}

export default NoDataMessage