import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import CuConfigurationModal from "../Modals/CuConfigurationModal";
import StationConfigurationModal from "../Modals/StationConfigurationModal";
import SensorConfigurationModal from "../Modals/SensorConfigurationModal";
import SuccessModal from "../Modals/SuccessModal";
import WarningModal from "../Modals/WarningModal";
import CreateStationModal from '../Modals/CreateStationModal';
import CreateCuModal from '../Modals/CreateCuModal';
import CreateSensorModal from '../Modals/CreateSensorModal';
import AddMeasureToSensorModal from '../Modals/AddMeasureToSensorModal';
import AddUnitModal from '../Modals/AddUnitModal';
import { setGlobalWarning } from 'app/store/evocsSlice/globalError/errorActions';
import { ModalContext } from 'src/views/WithAuth';

const ConfigurationModals = ({enabledActions, device, modalType, onClose}) => {
  const modalContext = useContext(ModalContext);
  const [openDeviceModal, setOpenDeviceModal] = useState(null);
  // const { t } = useTranslation("evocs");
  // const handleTranslate = (textId, general = false) => {
  //   if (general) return translate(t, textId);
  //   return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  // };

  // const successText = useMemo(() => {
  //   switch(modalType) {
  //     case "create-station":
  //       return handleTranslate('STATION_CREATION_SUCCESS');
  //     case "create-cu":
  //       return handleTranslate('CONTROL_UNIT_CREATION_SUCCESS');
  //     case "create-sensor":
  //       return handleTranslate('SENSOR_CREATION_SUCCESS');
  //     case "config-station":
  //       return handleTranslate('STATION_UPDATE_SUCCESS');
  //     case "config-cu":
  //       return handleTranslate('CONTROL_UNIT_UPDATE_SUCCESS');
  //     case "config-sensor":
  //       return handleTranslate('SENSOR_UPDATE_SUCCESS');
  //   }
  // }, [modalType])


  useEffect(() => {
    setOpenDeviceModal(modalType);
  }, [modalType])


  const handleClose = (saved=true) => {
    if(!saved) {
      setGlobalWarning(true, "Tutte le modifiche andranno perse");
      modalContext.setConfirmCallback(onClose);
    }
    else {
      setOpenDeviceModal(null);
      onClose && onClose();
    }
  }


  return(
    <>
      <CreateStationModal isOpen={openDeviceModal === "create-station"} onFinally={handleClose} onClose={handleClose}/>
      <CreateCuModal isOpen={openDeviceModal === "create-cu"} onFinally={handleClose} onClose={handleClose}/>
      <CreateSensorModal isOpen={openDeviceModal === "create-sensor"} onFinally={handleClose} onClose={handleClose}/>
      <StationConfigurationModal enabledActions={enabledActions} isOpen={openDeviceModal === "config-station"} stationId={device?.id} onFinally={handleClose} onClose={handleClose}/>
      <CuConfigurationModal enabledActions={enabledActions} isOpen={openDeviceModal === "config-cu"} cuId={device?.id} onFinally={handleClose} onClose={handleClose}/>
      <SensorConfigurationModal enabledActions={enabledActions} isOpen={openDeviceModal === "config-sensor"} sensorId={device?.id} onFinally={handleClose} onClose={handleClose}/>
      <AddUnitModal unit={device} isOpen={openDeviceModal === "add-unit"} onFinally={handleClose} onClose={handleClose} /> 
      <AddMeasureToSensorModal measureId={device?.id} isOpen={openDeviceModal === "add-measure"} onFinally={handleClose} onClose={handleClose}/>
    </>
  )
}

export default ConfigurationModals