import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Qualità aria",
    TABLE_TITLE : "Dati di qualità dell'aria open",
};

const OPEN_DATA_AIR_QUALITY_IT = createLanguageObject(IT, viewNames.OPEN_DATA_AIR_QUALITY_VIEW);

export default OPEN_DATA_AIR_QUALITY_IT;