import { Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLayoutEffect, useMemo, useState } from "react";
import { UNIT_TYPE } from "../StationHandling/utils";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import SensorSelect from "../SharingComponents/Inputs/SensorSelect";
import MeasureAutocomplete from "../SharingComponents/Inputs/MeasureAutocomplete";
import { installCu } from "app/store/evocsSlice/controlUnits/cuActions";
import { installSensor, updateSensor } from "app/store/evocsSlice/sensors/sensorsActions";
import { useSelector } from "react-redux";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";

const inputs = {
  controlUnits: {
    label: "CONTROL_UNITS",
    value: undefined,
    required: false,
  },
  sensors: {
    label: "SENSORS",
    value: undefined,
    required: false,
  },
  measures: {
    label: "MEASURES",
    value: undefined,
    required: false,
  },
};

const FormComponent = ({unit, onFinally }) => {
  const measures = useSelector((state) => state.configuration.constants.measure);
  const [allInputs, setAllInputs] = useState(inputs);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const availableMeasures = useMemo(() => {
    if (unit.type !== "sensor") return [];
    return Object.keys(measures).filter((measureId) => !unit.measures.includes(measureId));
  }, [unit.type]);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  useLayoutEffect(() => {
    if (unit.type === "station") {
      if ((!allInputs.controlUnits.value || allInputs.controlUnits.value === "" || allInputs.controlUnits.value?.length === 0) && (!allInputs.sensors.value || allInputs.sensors.value === "" || allInputs.sensors.value?.length === 0)) setButtonDisabled(true);
      else setButtonDisabled(false)
    } else if (unit.type == "cu") {
      if (!allInputs.sensors.value || allInputs.sensors.value === "" || allInputs.sensors.value.length === 0) setButtonDisabled(true)
      else setButtonDisabled(false);
    } else {
      if (!allInputs.measures.value || allInputs.measures.value === "" || allInputs.measures.value.length === 0) setButtonDisabled(true)
      else setButtonDisabled(false);
    }
  }, [allInputs]);

  const handleChangeInput = (key, value) => {
    setAllInputs({ ...allInputs, [key]: { ...allInputs[key], value: value } });
    setButtonDisabled(false);
  };

  const getUnitTypeLabel = () => {
    return unit.type === UNIT_TYPE.station ? `${handleTranslate("STATION", true).toLowerCase()}` : unit.type === UNIT_TYPE.sensor? `${handleTranslate("SENSOR", true).toLowerCase()}` : `${handleTranslate("CONTROL_UNIT", true).toLowerCase()}`;
  };

  const handleConfirm = async () => {
    try {
      const fetch = [];
      if (unit.type === "station" && allInputs.controlUnits.value !== undefined) fetch.push(installCu(allInputs.controlUnits.value, unitId));
      if (allInputs.sensors.value !== undefined) fetch.push(installSensor(allInputs.sensors.value, unitId, unit.type));
      if (allInputs.measures.value !== undefined) {
        const measures = unit.measures.concat(allInputs.measures.value)
        fetch.push(updateSensor(unitId, undefined, undefined, undefined, measures));
      } 
      await Promise.all(fetch);
      setGlobalSuccess(true);
    } catch (error) {
      setGlobalError(true);
    }
    onFinally && onFinally();
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("INSTALL_ON", true)} {`${getUnitTypeLabel()}: ${unit.serial}`}</h2>
      {unit.type === "sensor" ?
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <span style={{ minWidth: "100px" }}> {handleTranslate("ADD", true)} {handleTranslate(allInputs.measures.label, true).toLowerCase()} </span>
          <MeasureAutocomplete disablePortal limitTags={2} multiple measuresId={availableMeasures} value={allInputs.measures.value} onChange={(value) => handleChangeInput("measures", value)} required={allInputs.measures.required} />
        </div>
        :
        <>
          {unit.type === "station" &&
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <span style={{ minWidth: "100px" }}> {handleTranslate("INSTALL", true)} {handleTranslate(allInputs.controlUnits.label, true).toLowerCase()} </span>
              <CuSelect disablePortal limitTags={2} multiple value={allInputs.controlUnits.value} onChange={(value) => handleChangeInput("controlUnits", value)} required={allInputs.controlUnits.required} uninstalled />
            </div>
          }
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <span style={{ minWidth: "100px" }}> {handleTranslate("INSTALL", true)} {handleTranslate(allInputs.sensors.label, true).toLowerCase()} </span>
            <SensorAutocomplete disablePortal limitTags={2} multiple value={allInputs.sensors.value} onChange={(value) => handleChangeInput("sensors", value)} required={allInputs.sensors.required} uninstalled />
          </div>
        </>
      }
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true).toLowerCase()} </Button>
    </div>
  );
}

const containerStyle = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#2d3740",
  padding: "1rem",
  borderRadius: "1rem",
  height: "fit-content",
  maxHeight: "700px",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column"
}

const AddUnitModal = ({ unit, isOpen, onFinally, onClose }) => {
  return (
    <Modal open={isOpen}>
      <div style={containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormComponent unit={unit} onFinally={onFinally} />
        </div>
      </div>
    </Modal>
  );
}

export default AddUnitModal;