import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const EN = {
    HEADER : "Monitoring stations",
    NO_ACTIVE_STATIONS: "No active stations",
};

const STATIONS_EN = createLanguageObject(EN, viewNames.STATIONS_VIEW);


export default STATIONS_EN;