import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'


const InfiniteScrollLoop = ({style={}, children}) => {
  const loopContainer = useRef()
  const contentRef = useRef();
  const [animationDuration, setDuration] = useState(0);

  useEffect(() => {
    // Funzione di callback chiamata quando la larghezza cambia
    
    // Creazione di un'istanza di ResizeObserver
    const resizeObserver = new ResizeObserver(handleResize);

    // Aggiunta dell'elemento al ResizeObserver
    resizeObserver.observe(contentRef.current);
    handleResize()

    // Cleanup: rimuovere l'observer quando il componente si smonta
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // L'array vuoto assicura che l'effetto si verifichi solo al montaggio e smontaggio del componente

  const handleResize = () => {
    //console.log(contentRef.current.offsetWidth, loopContainer.current.offsetWidth, loopContainer);
    if(!loopContainer.current) return;
    const containerWidth = loopContainer.current.offsetWidth;
    const contentWidth = contentRef.current.offsetWidth
    if(!contentWidth) return;
    const ripetizioni = contentWidth / containerWidth;
    const seconds = (containerWidth * ripetizioni / 50);
    //const seconds = (ripetizioni * 20);
    setDuration(seconds)
  };


  return (
    <div ref={loopContainer} className='infinite-scroll-loop' style={{...style, }}>
      <div ref={contentRef} className='content' style={{animationDuration: `${animationDuration}s`}}>
        {children}
      </div>
      <div className='content' style={{animationDuration: `${animationDuration}s`}}>
        {children}
      </div>
    </div>
  )
}

// const InfiniteScrollLoop = ({style={}, children}) => {
//   const scrollRef = useRef();
//   const [isOverflow, setIsOverflow] = useState(false);

//   useEffect(() => {
//     window.addEventListener("resize", checkIsOverflow);

//     return () => {
//       window.removeEventListener("resize", checkIsOverflow)
//     }
//   }, [isOverflow])

//   useLayoutEffect(() => {
//     checkIsOverflow();
//   })

//   const checkIsOverflow = () => {
//     if(!scrollRef) return;
//     const offsetHeight = scrollRef.current.offsetHeight;
//     const offsetWidth = scrollRef.current.offsetWidth;
//     const scrollHeight = !isOverflow ? scrollRef.current.scrollHeight : scrollRef.current.scrollHeight / 2;
//     const scrollWidth = !isOverflow ? scrollRef.current.scrollWidth : scrollRef.current.scrollWidth / 2;
//     if(offsetHeight < scrollHeight || offsetWidth < scrollWidth) 
//       setIsOverflow(true);
//     else setIsOverflow(false);
//   }

//   return (
//     <div ref={scrollRef} className='infinite-scroll-loop'>
//         <div className='content' style={{animationName: isOverflow ? "loopScroll" : "none"}}>
//             {children}
//         </div>
//         {isOverflow &&
//         <div className='content' style={{animationName: isOverflow ? "loopScroll" : "none"}}>
//             {children}
//         </div>
//         }
//     </div>
//   )
// }

export default InfiniteScrollLoop