import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useState } from "react";

const AxisCameraSelect = ({style, defaultValue, options, value, placeholder="Camera", required=false, onChange, multiple=false}) => {
  const [allOptions, setOptions] = useState(options || []);
  const [val, setVal] = useState(defaultValue ? defaultValue : multiple ? [] : "");

  useLayoutEffect(() => {
    options !== undefined && setOptions(options);
  }, [options]);

  useLayoutEffect(() => {
    value !== undefined && setVal(value);
  }, [value]);


  const handleChange = (selected) => {
    setVal(selected);
    if(onChange) onChange(selected)
  }
  
  return(
    <FormControl sx={{...style}} fullWidth>
      <InputLabel id="axis-camera-select-label">{required ? `${placeholder}*`: placeholder}</InputLabel>
      <Select
        sx={{maxWidth: "300px"}}
        labelId="axis-camera-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.join(", ") : selected}
        label={required ? `${placeholder}*`: placeholder}
        onChange={(e) => handleChange(e.target.value)}
      >
        {allOptions.map((option, index) => (
        <MenuItem key={index} value={option}>
          {multiple && <Checkbox checked={val?.indexOf(option) > -1} />}
          {option}
        </MenuItem>
        ))}
      </Select>
    </FormControl>     
  )
}

export default AxisCameraSelect;