import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Alerts",
    MARK_ALL_READ : "Mark all as read",
    MARK_READ: "Mark as read",
    MARK_AS_READ: "Mark as read",
};

const ALERTS_EN = createLanguageObject(EN, viewNames.ALERTS_VIEW);

export default ALERTS_EN;