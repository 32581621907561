import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const FilterCategorySelect = ({ style = {}, categoriesId, value, defaultValue, onChange, multiple = false, required = false, disabled = false, fullWidth = false }) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };
  const filtersCategory = useSelector((state) => state.configuration.constants?.filterCategory);
  const options = useMemo(() => {
    if (categoriesId) {
      return categoriesId;
    } else {
      return Object.keys(filtersCategory);
    }
  }, [categoriesId])
  const [val, setValue] = useState(defaultValue ? defaultValue : multiple ? [] : "")

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  return (
    <FormControl fullWidth={fullWidth} sx={!fullWidth && { ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="filter-category-select-label">{required ? `${handleTranslate("TYPE_FILTER")}*` : handleTranslate("TYPE_FILTER")}</InputLabel>
      <Select
        disabled={disabled}
        labelId="filter-category-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((categoryId) => filtersCategory[categoryId].filterCategory).join(", ") : filtersCategory[selected].filterCategory}
        label={required ?`${handleTranslate("TYPE_FILTER")}*` : handleTranslate("TYPE_FILTER")}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((categoryId, index) => (
          <MenuItem key={index} value={categoryId}>
            {multiple && <Checkbox checked={val?.indexOf(categoryId) > -1} />}
            {filtersCategory[categoryId].filterCategory}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FilterCategorySelect