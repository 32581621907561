import { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import MapComponent from '../SharingComponents/MapComponent';
import { useSelector } from 'react-redux';
import mkrDark from "src/app/asset/markers/EvocsThemeBlack.png";
import mkrWifiRed from "src/app/asset/markers/mkrWifiRed.png";
import mkrWifiYellow from "src/app/asset/markers/mkrWifiYellow.png";
import mkrWifiGreen from "src/app/asset/markers/mkrWifiGreen.png";
import mkrWifiGrey from "src/app/asset/markers/mkrWifiGrey.png";
import boaIcon from "src/app/asset/markers/boaIcon_yellow.png"
import boaRed from "src/app/asset/gif/boa_red2.gif";
import boaGreen from "src/app/asset/gif/boa_green.gif";
import boaYellow from "src/app/asset/gif/boa_yellow.gif";
import { MdOutlineWifi } from "react-icons/md";
import { MdOutlineWifi2Bar } from "react-icons/md";
import { MdOutlineWifiOff } from "react-icons/md";
import { CgUnavailable } from "react-icons/cg";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { getLastStationPosition, getPolarPlots } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import legendImage from 'src/app/asset/images/ciccioColorMap3.png';

const defaultCenter = [40.4774123507161, 17.2209236958397];

const styles = {
  popoupLegend: {
    position: 'absolute',
    bottom: '0.5rem',  // Adjusted to bottom
    right: '1rem',
    backgroundColor: 'white',
    padding: '0.5rem',
    borderRadius: '1rem',
    zIndex: 1000,  // You can adjust the z-index as needed
    width: '250px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add a shadow
    pointerEvents: "none"
  },
  imageLegend: {
    position: 'absolute',
    width: "50px",
    bottom: '5rem',  // Adjusted to bottom
    left: '0.8rem',
    zIndex: 1000,  // You can adjust the z-index as needed
    pointerEvents: "none"
  }
};


const getBoaIcon = (light) => {
  const getSrc = () => {
    switch(light) {
      case "red":
        return boaRed;
      case "yellow":
        return boaYellow;
      case "green":
        return boaGreen;
      default:
        return boaIcon;
    }
  }
  return light && (
    <div>
      <img style={{width: "30px"}} src={getSrc()} alt="Boa" />
      {/* <div style={{position: "absolute", boxShadow: "0px 0px 12px 10px red", top: "5px", left: "50%", transform: "translate(-50%, -50%)", zIndex: -1}}></div> */}
    </div>
  )
};

const DashboardMap = ({enabledActions, aliveSensors, selectedStation, defaultStation, onClickMarker, onClosePopup}) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const baseLocations = useSelector((state) => state.configuration.constants?.base_location);
  const [overlays, setOverlays] = useState([]);
  const [popups, setPopups] = useState([]);
  const [stationSelected, setStationSelected] = useState(defaultStation);
  const [mapCenter, setMapCenter] = useState();
  const [displayPolarLegend, setDisplayPolarLegend] = useState(false);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const getMarkerIcon = useCallback((stationId, status) => {
    
    switch (status) {
      case 0:
        return stationId !=="65a7bce7e8b2f26afc70747c" ? mkrWifiRed : getBoaIcon("red");
      case 1:
        return stationId !=="65a7bce7e8b2f26afc70747c" ? mkrWifiYellow : getBoaIcon("yellow");;
      case 2:
        return stationId !=="65a7bce7e8b2f26afc70747c" ? mkrWifiGreen : getBoaIcon("green");
      case null:
        return  mkrWifiGrey;
      default:
        return mkrDark;
    }
  }, []);

  const getIconType = useCallback((status) => {
    switch (status) {
      case 0:
        return <MdOutlineWifiOff />;
      case 1:
        return <MdOutlineWifi2Bar />;
      case 2:
        return <MdOutlineWifi />;
      default:
        return <CgUnavailable />;
    }
  }, []);
  
  useEffect(() => {
    if(!aliveSensors || !stations) return;
    const newOvrly = [
      {
        id: "available_station",
        name: handleTranslate("AVAILABLE_STATIONS", true),
        checked: true,
        markers: []
      },
      {
        id: "unavailable_station",
        name: handleTranslate("UNAVAILABLE_STATIONS", true),
        checked: false,
        markers: []
      }
    ]
    const endDate = new Date().valueOf();
    const startDate = endDate - 3600000;
    getLastStationPosition(Object.keys(stations), startDate, endDate).then((res) => {
      res.forEach((location) => {
        if(location) {
          const markerIcon = getMarkerIcon(location.stationId, aliveSensors[location.stationId]?.status);
          const markerType = getIconType(aliveSensors[location.stationId]?.status);
          const marker = {id: location.stationId, lat: location.lat, lon: location.lon, icon: markerIcon, iconType:markerType};
          if(stations[location.stationId].available === 1) newOvrly[0].markers.push(marker)
          else newOvrly[1].markers.push(marker);
        } 
      });
      setOverlays((prev) => {
        let newPrev = [...prev];
        const availableIndex = prev.findIndex((element) => element.id === "available_station");
        const unavailableIndex = prev.findIndex((element) => element.id === "unavailable_station");
        if(availableIndex >= 0)
          newPrev[availableIndex] = newOvrly[0];
        else newPrev.push(newOvrly[0])
        if(unavailableIndex >= 0)
          newPrev[unavailableIndex] = newOvrly[1];
        else newPrev.push(newOvrly[1]);
        return newPrev;
      });
    });
  }, [aliveSensors, stations])

  useEffect(() => {
    if(!enabledActions.includes("show-polarplot")) return;
    const endDate = new Date().valueOf();
    const startDate = endDate - 3600000*4;
    getPolarPlots(startDate, endDate).then((res) => {
      if(!res || !res.plot || res.plot === "") return;
      setOverlays((prev) => {
        const newPrev = [...prev];
        const polarPlotsOverlay = {
          id: "polarPlots",
          name: "Polar Plots",
          checked: false,
          imageOverlays: [{
            lat: 40.4769776766326,
            lon: 17.1974215834441,
            url: "data:image/png;base64,"+res.plot,
          }],
          onHover: () => setDisplayPolarLegend(true),
          onBlur: () => setDisplayPolarLegend(false)
        }
        const polarIndex = prev.findIndex((element) => element.id === "polarPlots");
        if(polarIndex >= 0)
          newPrev[polarIndex] = polarPlotsOverlay;
        else newPrev.push(polarPlotsOverlay)
        return newPrev;
      });
    });
  }, [enabledActions])

  useEffect(() => {
    if(!stations || !controlUnits || !sensors || !baseLocations) return;
    const allPopups = [];
    Object.keys(stations).forEach((stationId) => {
        let content = {};
        const station = stations[stationId];
        // const location = baseLocations?.[station.base_location];
        // if(!location) return;
        const listCu = station.list_cu.map((cuId) => controlUnits[cuId]?.serial);
        const listSensors = station.list_sensors.map((sensorId) => sensors[sensorId]?.serial);

        content.id = stationId;
        content.title = station.serial;
        if(listCu.length > 0) content.listCu = {label: handleTranslate('CONTROL_UNITS_INSTALLED', true), value: listCu};
        if(listSensors.length > 0) content.listSensors = {label: handleTranslate('SENSORS_INSTALLED', true), value: listSensors};
        allPopups.push(content);
    })
    setPopups(allPopups);
  }, [stations, controlUnits, sensors, baseLocations, t])


  useEffect(() => {
    selectedStation !== undefined && setStationSelected(selectedStation);
  }, [selectedStation]);

  useEffect(() => {
    if(!stationSelected || overlays.length === 0) return;
    const availableStationsOverlay = overlays.find((element) => element.id === "available_station");
    const marker = availableStationsOverlay?.markers.find((element) => element.id === stationSelected);
    marker && setMapCenter({lat: marker.lat, lon: marker.lon});
  }, [stationSelected]);

  useEffect(() => {
    const newOvrly = [...overlays];
    newOvrly.forEach((element) => {
      switch(element.id) {
        case "available_station": 
          element.name = handleTranslate("AVAILABLE_STATIONS", true);
          break;
        case "unavailable_station": 
          element.name = handleTranslate("UNAVAILABLE_STATIONS", true);
          break;
      }
    });
    setOverlays(newOvrly);
  }, [t])


  return (
    <MapComponent height="400px" cluster clusterColor='white' disableClusteringAtZoom={14} maxClusterRadius={20} defaultCenter={defaultCenter} center={mapCenter} overlays={overlays} selectedMarker={stationSelected} popupsContent={popups} onClickMarker={onClickMarker} onClosePopup={onClosePopup}>
      {displayPolarLegend && 
      <div>
        <div style={styles.popoupLegend}>
        <p>{handleTranslate("POLAR_LEGEND_1", true)}
          <label style={{ color: 'blue' }}> {handleTranslate("BLUE", true)} </label> 
          {handleTranslate("POLAR_LEGEND_2", true)} 
          <label style={{ color: 'red' }}> {handleTranslate("RED", true)} </label>
          {handleTranslate("POLAR_LEGEND_3", true)} 
        </p>
        </div>
        <div style={styles.imageLegend}>
          <img src={legendImage}/>
        </div>
      </div>
      }
    </MapComponent>
  )
};

export default DashboardMap