import React from 'react';
import { IconContext } from "react-icons";
import { FaFlag } from 'react-icons/fa'; // Usa l'import per l'icona della bandiera

function ShipCountryFlagIcon({ countryCode }) {
  return (
    <IconContext.Provider value={{ color: 'blue', size: '2em' }}>
      <div>
        <FaFlag />
        <span>{countryCode}</span>
      </div>
    </IconContext.Provider>
  );
}

export default ShipCountryFlagIcon;
