import "leaflet-velocity/dist/leaflet-velocity.css";
import "leaflet-velocity/dist/leaflet-velocity.js";
import { forwardRef, useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import axios from "axios";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const getWindLayer = async() => {
  const data = await axios.post("/api/py/windLayer", {param: "json"}).then((res) => {
    return res.data;
  });

  return data;
};

const LeafletVelocity = forwardRef((props, ref) => {
  const map = useMap();

  const { t } = useTranslation("evocs");
const handleTranslate = (textId, general = false) => {
   if (general) return translate(t, textId);
   return translate(t, textId, viewNames.DASHBOARD_VIEW);
};


  useEffect(() => {
    if (!map) return;
    let windGbrLayer;
    getWindLayer().then((data) => {
      windGbrLayer = L.velocityLayer({
        displayValues: true,
        displayOptions: {
          velocityType: "GBR Wind",
          position: "bottomleft",
          emptyString: "No wind data",
          showCardinal: true
        },
        data: data.list,
        maxVelocity: 10
      });
      ref.current && ref.current.addOverlay(windGbrLayer, handleTranslate('MEASURE_WS', true));
    });

    return () => {
      ref.current && ref.current.removeOverlay(windGbrLayer);
    }
  }, [map]);

  // useEffect(() => {
  //   if (!map) return;

  //   let mounted = true;
  //   let windGbrLayer;
  //   let waterGbrLayer;
  //   let windGlobalLayer;

  //   fetch("https://onaci.github.io/leaflet-velocity/wind-global.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (!mounted) return;

  //       windGlobalLayer = L.velocityLayer({
  //         displayValues: true,
  //         displayOptions: {
  //           velocityType: "GBR Water",
  //           position: "bottomleft",
  //           emptyString: "No water data"
  //         },
  //         data: data,
  //         maxVelocity: 0.6,
  //         velocityScale: 0.1 // arbitrary default 0.005
  //       });

  //       if (ref.current && windGlobalLayer)
  //         ref.current.addOverlay(windGlobalLayer, "Wind - Global");
  //     })
  //     .catch((err) => console.log(err));

  //   fetch("https://onaci.github.io/leaflet-velocity/wind-gbr.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (!mounted) return;
  //       console.log("Vento: ", data)
  //       windGbrLayer = L.velocityLayer({
  //         displayValues: true,
  //         displayOptions: {
  //           velocityType: "GBR Wind",
  //           position: "bottomleft",
  //           emptyString: "No wind data",
  //           showCardinal: true
  //         },
  //         data,
  //         maxVelocity: 10
  //       });

  //       if (ref.current && windGbrLayer)
  //         ref.current.addOverlay(windGbrLayer, "Wind - Great Barrier Reef");
  //     })
  //     .catch((err) => console.log(err));

  //   fetch("https://onaci.github.io/leaflet-velocity/water-gbr.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (!mounted) return;

  //       waterGbrLayer = L.velocityLayer({
  //         displayValues: true,
  //         displayOptions: {
  //           velocityType: "GBR Water",
  //           position: "bottomleft",
  //           emptyString: "No water data"
  //         },
  //         data: data,
  //         maxVelocity: 0.6,
  //         velocityScale: 0.1 // arbitrary default 0.005
  //       });

  //       if (ref.current && waterGbrLayer)
  //         ref.current.addOverlay(
  //           waterGbrLayer,
  //           "Ocean Current - Great Barrier Reef"
  //         );
  //     })
  //     .catch((err) => console.log(err));

  //   return () => {
  //     mounted = false;
  //     if (ref.current) {
  //       ref.current.removeOverlay(windGbrLayer);
  //       ref.current.removeOverlay(waterGbrLayer);
  //       ref.current.removeOverlay(windGlobalLayer);
  //     }
  //   };
  // }, [map]);

  return null;
});

export default LeafletVelocity;
