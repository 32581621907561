

const ADD_ODOUR_REPORT_MODAL_IT = {
    MODALS_ADD_NEW_ODOUR_SIGNAL: "Aggiungi una nuova segnalazione odorigena",
    MODALS_REPORT_DATE: "Data segnalazione",
};

const DELETE_TENANT_MODAL_IT = {
    MODALS_TENANT_SUCCESSFULLY_ELIMINATED: "Tenant eliminato con successo",
};

const CREATE_CU_MODAL_IT = {
    MODALS_NEW_STATION: "Nuova stazione",
    MODALS_ADD_DEFAULT_POSITION: "Aggiungi una posizione di default",
};

const CREATE_SENSOR_MODAL_IT = {
    MODALS_NEW_SENSOR: "Nuovo sensore",
    MODALS_THRESHOLDS_SETTINGS: "Gestione soglie",
    MODALS_SET_THRESHOLDS_ON: "Imposta soglie su",
};

const ADD_MEASURE_TO_SENSOR_MODAL_IT = {
    MODALS_SELECT_SENSORS: "Seleziona i sensori",
    MODALS_TO_SENSORS: "ai sensori",
};

const SENSOR_CONFIGURATION_MODAL_IT = {
    MODALS_SET_THRESHOLDS_ON: "Imposta soglie su",
    MODALS_THRESHOLDS_MANAGEMENT: "Gestione soglie",
};

const STATION_CONFIGURATION_MODAL_IT = {
    MODALS_INSTALLED: "Installati/e",
};

const CREATE_TENANT_MODAL_IT = {
    MODALS_NEW_TENANT: "Nuovo tenant",
};

const HISTORY_MODALS_IT = {
    MODALS_HIST_ERR_1: 'Il seriale non può essere nullo',
    MODALS_HIST_ERR_2: 'Il campo "Data inizio" non può essere nullo',
    MODALS_HIST_ERR_3: 'Il campo "Data fine" non può essere nullo',
    MODALS_HIST_ERR_4: 'La data di fine non può antecedente a quella di inizio',
    MODALS_SENSOR_HIST_INSTALLED: 'Attualmente installata',
};

const EXPIRED_TOKEN_MODAL_IT = {
    MODALS_SESSION_EXPIRED: "Sessione scaduta",
    MODALS_GO_TO_LOGIN: "Effettua nuovamente l'accesso",
};

// const ERROR_MODAL_EN = {}


const SUCCESS_MODAL_IT = {
    MODALS_DEFAULT_TITLE: "Azione eseguita con successo",
    MODALS_SUCCESS: "Successo",
};

const CREATE_USER_MODAL_IT = {
    MODALS_NEW_USER: "Nuovo utente",
};

const MODAL_COMPONENTS_IT = {
    ...SUCCESS_MODAL_IT,
    ...EXPIRED_TOKEN_MODAL_IT,
    ...HISTORY_MODALS_IT,
    ...CREATE_TENANT_MODAL_IT,
    ...STATION_CONFIGURATION_MODAL_IT,
    ...SENSOR_CONFIGURATION_MODAL_IT,
    ...ADD_MEASURE_TO_SENSOR_MODAL_IT,
    ...ADD_ODOUR_REPORT_MODAL_IT,
    ...DELETE_TENANT_MODAL_IT,
    ...CREATE_CU_MODAL_IT,
    ...CREATE_SENSOR_MODAL_IT,
    ...CREATE_USER_MODAL_IT,
};

export default MODAL_COMPONENTS_IT;