import React, { useMemo } from 'react'
import InfiniteScrollLoop from '../SharingComponents/InfiniteScrollLoop'
import { useSelector } from 'react-redux'
import useMobile from 'src/app/hooks/useMobile'
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const styles = {
  relevationChip: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid white",
    padding: "1rem",
    color: "white",
    whiteSpace: "nowrap"
  }
};

const RelevationChip = ({ relevation }) => {
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };


  return (
    <div style={styles.relevationChip}>
      <label style={{ fontWeight: "bold" }}>{handleTranslate(`MEASURE_${measures[relevation.measure]?.measure.toUpperCase()}`, true)}: {relevation.value} {relevation.unit}</label>
    </div>
  )
};

const LastRelevations = ({ relevations = [] }) => {
  const navbar = useSelector((state) => state.fuse.navbar.open);
  const [xs, sm, md] = useMobile();

  const getLeftSpace = () => {
    if (xs || sm || md) return "0";
    else return !navbar ? "0" : "280px"
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };


  return (
    <div style={{ position: "fixed", display: "flex", bottom: 0, left: getLeftSpace(), right: 0, background: "#242A30", zIndex: "20" }}>
      <div style={{ background: "#74c365", padding: "1rem" }}>
        <label style={{ color: "#242A30", textAlign: "center", fontWeight: "bold", whiteSpace: "nowrap" }}> {handleTranslate("LAST_RELEVATIONS", true)}: </label>
      </div>
      <InfiniteScrollLoop>
        {relevations?.length > 0 ?
          relevations.map((element, index) => (
            <RelevationChip key={index} relevation={element} />
          ))
          :
          <div style={styles.relevationChip}>
            <label style={{ fontWeight: "bold" }}> {handleTranslate("NO_LAST_REL")} </label>
          </div>
        }
      </InfiniteScrollLoop>
    </div>

  )
}

export default LastRelevations