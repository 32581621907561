import store from "../.."
import { fileSliceActions } from "./fileSlice"

export const getFilesNames = (folderName, type,  order=false) => {
    // console.log('getFilesNames action');
    store.dispatch(fileSliceActions.getFilesNames({folderName: folderName, type: type, order: order})).then(({payload}) => {
    })
};

export const downloadFile = (fileName) => {
    console.log('downloadFile action');
    store.dispatch(fileSliceActions.downloadFile({fileName: fileName})).then(({payload}) => {
    })
};

export const getDayReportsFileNames = (tenant="T000001") => {
    // console.log('getDayReportsFileNames action');
    store.dispatch(fileSliceActions.getDayReportsFileNames({tenant: tenant})).then(({payload}) => {
    })
}