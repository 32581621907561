import { useCallback, useEffect, useMemo, useState } from 'react'
import LineGraph from './LineGraph'
import { CircularProgress, FormControl, InputLabel, Paper, Select } from '@mui/material';
import SensorSelect from '../SharingComponents/Inputs/SensorSelect';
import { useSelector } from 'react-redux';
import WindRose from './WindRose';
import { createDatasetLineGraph, createTooltipLineGraph, getWindRoseData } from 'app/store/evocsSlice/dataset/datasetActions';
import LoadingGif from '../FileManager/LoadingGif';
import { TIME_FORMAT, formatDate } from './utils';
import MeasureSelect from '../SharingComponents/Inputs/MeasureSelect';
import LiveText from '../SharingComponents/LiveText';
import NoDataMessage from '../SharingComponents/NoDataMessage';
import DownloadComponent from '../SharingComponents/DownloadComponent/DownloadComponent';
import { getCorrelationMatrix, getSensorsMeasures, hasSensorWindMeasures, isMeasureWind } from 'app/store/evocsSlice/sensors/sensorsActions';
import AddIcon from '@mui/icons-material/Add';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ToggleButton from '../SharingComponents/Inputs/ToggleButton';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { getR2BySensors } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import { setGlobalError } from 'app/store/evocsSlice/globalError/errorActions';

const windRoseMeasure = "windrose";

const getSmallestValue = (values) => {
  if(!Array.isArray(values)) throw new Error("values must be array");
  let smallest = values[0];
  values.forEach((val) => {
    if(smallest > val) smallest = val
  });
  return smallest;
}

const getLargestValue = (values) => {
  if(!Array.isArray(values)) throw new Error("values must be array");
  let largest = values[0];
  values.forEach((val) => {
    if(largest < val) largest = val
  });
  return largest;
}

const MeasureSelection = ({userActions, cu, selectedSensor, selectedMeasure, onChangeSensor, onChangeMeasure, disableSensorFilter = false, compare, compareOptions, selectedCompare, onChangeCompare, onChangeCompareMeasure, disabledCompare  }) => {
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const { t } = useTranslation("evocs");
  

  const sensorsOptions = useMemo(() => {
    return cu.list_sensors.filter((id) => sensors[id].available === 1);
  }, []);

  const measuresOptions = useMemo(() => {
    if (!selectedSensor) return [];
    if (!disableSensorFilter) {
      if (userActions.find((e) => e.name === "show-windrose") && hasSensorWindMeasures(selectedSensor)) return [windRoseMeasure, ...getSensorsMeasures(selectedSensor, true)];
      else return getSensorsMeasures(selectedSensor, true);
    } else {
      const allMeasures = getSensorsMeasures(sensorsOptions, true);
      if (userActions.find((e) => e.name === "show-windrose") && allMeasures.some((measureId) => isMeasureWind(measureId))) {
        return [windRoseMeasure, ...allMeasures]
      } else return allMeasures;
    }
  }, [selectedSensor, sensorsOptions, disableSensorFilter]);

  const groups = useMemo(() => {
    if(!controlUnits || !sensors) return [];
    const parentIds = [...new Set(compareOptions.map((measureOption) => measureOption.sensor.deviceParent.type === "cu" ? measureOption.sensor.deviceParent.device : measureOption.sensor.sensorId))];
    let indexValue = 1;
    return parentIds.map((parentId) => {
      const groupOptions = [];
      compareOptions.forEach((measuresOption) => {
        if(measuresOption.sensor.deviceParent.device === parentId || measuresOption.sensor.sensorId === parentId) {
          groupOptions.push({groupId: parentId, index: indexValue, ...measuresOption});
          indexValue++;
        }
      })
      return {id: parentId, title: controlUnits[parentId]?.serial || sensors[parentId]?.serial, options: groupOptions}
    });

  }, [controlUnits, sensors, compareOptions]);

  const handleChangeSensor = (sensorId) => {
    onChangeSensor && onChangeSensor(sensorId);
    onChangeMeasure && onChangeMeasure(sensors[sensorId].measures[0])
  };

  const handleChangeMeasure = (measureId) => {
    const sensorId = sensorsOptions.find((id) => measureId === windRoseMeasure ? hasSensorWindMeasures(id) : sensors[id].measures.includes(measureId));
    if (sensorId !== selectedSensor) {
      onChangeSensor && onChangeSensor(sensorId);
    }
    onChangeMeasure && onChangeMeasure(measureId);
  };

  const handleChangeCompareMeasure = (value) => {
    const formattedValue = value === "" ? "" : Number(value);
    let item;
    groups.some((group) => {
      item = group.options.find((element) => element.index === formattedValue);
      return item;
    });
    onChangeCompareMeasure && onChangeCompareMeasure(item, formattedValue);
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
 };

 const getMeasureName = (measureId) => {
    return handleTranslate(`MEASURE_${measures[measureId].measure.toUpperCase()}`, true)
 }

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "1rem" }}>
      {!disableSensorFilter && <SensorSelect sensorsId={sensorsOptions} value={selectedSensor} onChange={handleChangeSensor} disabled={sensorsOptions.length <= 0} />}
      <MeasureSelect measuresId={measuresOptions} value={selectedMeasure} onChange={handleChangeMeasure} disabled={sensorsOptions.length <= 1} />
      {!disabledCompare &&
        <>
          <ToggleButton enabled={compare} onChange={onChangeCompare}>
            {!compare ? <AddIcon /> : <CompareArrowsIcon />}
          </ToggleButton>
          {compare &&
            <FormControl sx={{ width: "100%", minWidth: "100px", maxWidth: "300px"  }}>
              <InputLabel htmlFor="grouped-measure-select">Misura</InputLabel>
              <Select native id="grouped-measure-select" label="Misura" value={selectedCompare?.index || ""} onChange={(e) => handleChangeCompareMeasure(e.target.value)}>
                {/* <option aria-label="None" value="" /> */}
                <option aria-label="None" value="" />
                {groups.map((group) => (
                  <optgroup key={group.id} label={group.title}>
                    {group.options.map((measure) => (
                      <option key={measure.measureId} value={measure.index}>{getMeasureName(measure.measureId)}</option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            </FormControl>
          }
        </>
      }
    </div>
  )
}

const GraphInfo = ({loadingR2, lastDetections, r2Value}) => {

  const { t } = useTranslation("evocs");
  
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div style={{display: "flex", gap: "1.5rem", flexWrap: "wrap"}}>
      {lastDetections?.length > 0 &&
        <label style={{whiteSpace: "nowrap"}}>
          {handleTranslate("LAST_RELEVATION", true)}: <b>{lastDetections.join(", ")}</b>
        </label>
      }
      {!loadingR2 ?
        typeof r2Value === "number" && <label style={{whiteSpace: "nowrap"}}>R2: <b>{r2Value} %</b></label>
        :
        <label style={{whiteSpace: "nowrap"}}>R2: <CircularProgress size={"15px"}/></label>
      }
    </div>
  )
}

const CuGraph = ({ cuId, cu, startDate, endDate, selectedSens, selectedMeas, compareOptions, isCompare, selectedCompare, graphHeight = "400px", onChangeSensor, onChangeMeasure, onChangeCompare, onChangeSelectedCompare, withDownloads = false, disableSensorFilter = false, unitOnStation, isLive = false, userActions }) => {
  const datasets = useSelector((state) => state.dataset.list);
  const windRoseLoading = useSelector((state) => state.dataset.windRoseLoading);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.STATIONS_VIEW);
  };

  const [selectedSensor, setSelectedSensor] = useState(() => {
    if (selectedSens) return selectedSens;
    return cu.list_sensors.find((id) => sensors[id].available === 1);
  });
  const [selectedMeasure, setSelectedMeasure] = useState(() => {
    if (selectedMeas) return selectedMeas;
    return sensors[selectedSensor]?.measures[0];
  });
  const [compare, setCompare] = useState(isCompare);
  const [compareMeasuresOptions, setCompareOptions] = useState(compareOptions || []);
  const [selectedCompareMeasure, setSelectedCompare] = useState(selectedCompare || "");
  const [isWindRose, setWindRose] = useState(false);
  const [checkedMenu, setCheckedMenu] = useState([]);
  const [lastCheckedMenu, setLastCheckedMenu] = useState([]);
  const [loadingR2, setLoadingR2] = useState(false);
  const [loadingMatrix, setLoadingMatrix] = useState(false);
  const [r2Value, setR2Value] = useState();
  const [matrixImage, setMatrixImage] = useState();

  const isGraphMenuDisabled = useMemo(() => {
    if(!userActions) return true;
    return !userActions.find((element) => element.name === "show-graph-menu");
  }, [userActions]);

  const measuresUnit = useMemo(() => {
    const units = [];
    if (selectedMeasure) {
      if (selectedMeasure !== windRoseMeasure) {
        units.push(measures?.[selectedMeasure].unit);
        if (selectedCompareMeasure) units.push(measures?.[selectedCompareMeasure.measureId].unit);
      }
    }
    return units
  }, [selectedMeasure, selectedCompareMeasure]);

  // const graph = useMemo(() => {
  //   if (selectedMeasure === windRoseMeasure) {
  //     if (datasets[selectedSensor]?.[selectedMeasure]) {
  //       return { data: datasets[selectedSensor][selectedMeasure].map((e, index) => e[index]) };
  //     }
  //     getWindRoseData(startDate, endDate, [selectedSensor])
  //     return { data: [] };
  //   }
  //   const graphDatasets = [], tooltips = [], minMax=[], minMaxRange=[];
  //   let labels = [];
  //   if (selectedMeasure) {
  //     const yAxisId = "y0";
  //     const fill = isCompare && selectedCompareMeasure ? false : true;
  //     const data = [];
  //     const measureDataset = datasets[selectedSensor]?.[selectedMeasure];
  //     if(measureDataset) {
  //       minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
  //       minMax.push({min: measureDataset.min, max: measureDataset.max});
  //     }
  //     measureDataset?.data.forEach((dataset) => {
  //       labels.push(dataset[0]);
  //       data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
  //     });
  //     const graphColor = isCompare && selectedCompareMeasure ? "#92c870" : measures[selectedMeasure].color;
  //     graphDatasets.push(createDatasetLineGraph(measures[selectedMeasure].label, data, graphColor, graphColor, fill, yAxisId));
  //     tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedMeasure].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedMeasure].unit));
  //   }

  //   if (isCompare && selectedCompareMeasure) {
  //     const yAxisId = "y1";
  //     const data = [];
  //     const measureDataset = datasets[selectedCompareMeasure.sensor.sensorId]?.[selectedCompareMeasure.measureId];
  //     if(measureDataset) {
  //       minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
  //       minMax.push({min: measureDataset.min, max: measureDataset.max});
  //     }
  //     measureDataset?.data.forEach((dataset) => {
  //       labels.push(dataset[0]);
  //       data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
  //     })
  //     const graphColor = "#242a30";
  //     graphDatasets.push(createDatasetLineGraph(measures[selectedCompareMeasure.measureId].label, data, graphColor, graphColor, false, yAxisId));
  //     tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedCompareMeasure.measureId].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedCompareMeasure.measureId].unit));
  //     labels = [...new Set(labels)].sort((a, b) => a - b);
  //   }
  //   labels = labels.map((timestamp) => formatDate(new Date(timestamp * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS));

  //   return { labels, datasets: graphDatasets, tooltips, minMax, minMaxRange};
  // }, [datasets, selectedMeasure, isCompare, selectedCompareMeasure, t]);

  const graph = useMemo(() => {
    if (selectedMeasure === windRoseMeasure) {
      if (datasets[selectedSensor]?.[selectedMeasure]) {
        return { data: datasets[selectedSensor][selectedMeasure].map((e, index) => e[index]) };
      }
      getWindRoseData(startDate, endDate, [selectedSensor])
      return { data: [] };
    }
    const graphDatasets = [], tooltips = [], avg=[], minMax=[], minMaxRange=[];
    let labels = [];
    if(selectedMeasure) {
      const yAxisId = "y0";
      const fill = isCompare && selectedCompareMeasure ? false : true;
      const data = [];
      const measureDataset = datasets[selectedSensor]?.[selectedMeasure];
      measureDataset?.data.forEach((dataset) => {
        labels.push(dataset[0]);
        data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
      });
      const graphColor = isCompare && selectedCompareMeasure ? "#92c870" : measures[selectedMeasure].color;
      const graphLabel = isCompare && selectedCompareMeasure ? `${measures[selectedMeasure].label} (${cu.serial})` : measures[selectedMeasure].label;
      graphDatasets.push(createDatasetLineGraph(graphLabel, data, graphColor, graphColor, fill, yAxisId));
      tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedMeasure].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedMeasure].unit));
      if(isCompare && selectedCompareMeasure) {
        const yAxisId = "y1";
        const data = [];
        const compareMeasureDataset = datasets[selectedCompareMeasure.sensor.sensorId]?.[selectedCompareMeasure.measureId];
        compareMeasureDataset?.data.forEach((dataset) => {
          labels.push(dataset[0]);
          data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
        })
        const graphColor = "#242a30";
        let serial;
        if(selectedCompareMeasure.sensor.deviceParent.type === "cu")
          serial = controlUnits[selectedCompareMeasure.sensor.deviceParent.device].serial;
        else serial = selectedCompareMeasure.sensor.serial;
        const graphLabel = `${measures[selectedCompareMeasure.measureId].label} (${serial})`;
        graphDatasets.push(createDatasetLineGraph(graphLabel, data, graphColor, graphColor, false, yAxisId));
        tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedCompareMeasure.measureId].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedCompareMeasure.measureId].unit));
        labels = [...new Set(labels)].sort((a, b) => a - b);

        if(measureDataset && compareMeasureDataset) {
          avg.push(measureDataset.avg);
          avg.push(compareMeasureDataset.avg);
          minMax.push({min: measureDataset.min, max: measureDataset.max});
          minMax.push({min: compareMeasureDataset.min, max: compareMeasureDataset.max});
          if(measuresUnit[0] === measuresUnit[1]) {
            const rangeMin = getSmallestValue([measureDataset.rangeMin, compareMeasureDataset.rangeMin]);
            const rangeMax = getLargestValue([measureDataset.rangeMax, compareMeasureDataset.rangeMax]);
            minMaxRange.push({min: rangeMin, max: rangeMax});
            minMaxRange.push({min: rangeMin, max: rangeMax});
          }else {
            minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
            minMaxRange.push({min: compareMeasureDataset.rangeMin, max: compareMeasureDataset.rangeMax});
          }
        }
      }else if(measureDataset) {
        avg.push(measureDataset.avg);
        minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
        minMax.push({min: measureDataset.min, max: measureDataset.max});
      }
    }
    labels = labels.map((timestamp) => formatDate(new Date(timestamp * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS));

    return { labels, datasets: graphDatasets, tooltips, avg, minMax, minMaxRange};
  }, [datasets, selectedMeasure, isCompare, selectedCompareMeasure, t]);


  const lastDetections = useMemo(() => {
    if(!graph.datasets) return [];
    return graph.datasets.map((dataset, index) => {
      const data = dataset.data;
      return data[data.length-1]?.y ? (data[data.length-1].y + (measuresUnit[index] || "")) : null;
    }).filter((e) => e);
  }, [graph])


  useEffect(() => {
    selectedSens && setSelectedSensor(selectedSens);
  }, [selectedSens])

  useEffect(() => {
    selectedMeas && setSelectedMeasure(selectedMeas);
  }, [selectedMeas])

  useEffect(() => {
    compareOptions && setCompareOptions(compareOptions);
  }, [compareOptions])

  useEffect(() => {
    setSelectedCompare(selectedCompare);
  }, [selectedCompare])

  useEffect(() => {
    setCompare(isCompare);
  }, [isCompare])

  useEffect(() => {
    if (selectedMeasure === windRoseMeasure) {
      setWindRose(true);
      setLoadingR2(false);
      setLoadingMatrix(false);
      setR2Value(null);
      setMatrixImage(null);
    }
    else setWindRose(false);
  }, [selectedMeasure]);

  useEffect(() => {
    setLastCheckedMenu(checkedMenu);
    let canSetR2 = true, canSetMatrix = true;
    const newChecked = checkedMenu.filter((option1) => !lastCheckedMenu.find((option2) => option1.groupId === option2.groupId && option1.id === option2.id));
    const unckecked = lastCheckedMenu.filter((option1) => !checkedMenu.find((option2) => option1.groupId === option2.groupId && option1.id === option2.id));;
   
    // get R2
    if(compare && selectedCompareMeasure) {
      if(newChecked.find((option) => option.id === "r2")) {
        setLoadingR2(true);
  
        const dictSensors = selectedCompareMeasure.sensor.sensorId === selectedSensor ? {
          [selectedSensor]: [selectedMeasure, selectedCompareMeasure.measureId],
        }: {
          [selectedSensor]: [selectedMeasure],
          [selectedCompareMeasure.sensor.sensorId]: [selectedCompareMeasure.measureId]
        }
        getR2BySensors(dictSensors, startDate, endDate).then((res) => {
          if(!canSetR2) return;
          setLoadingR2(false);
          setR2Value(res?.r2);
        }).catch(() => {
          setGlobalError(true);
        })
      }else if(unckecked.find((option) => option.id === "r2")) {
        setLoadingR2(false);
        setR2Value(null);
      }
    }

    // get Matrix
    if(newChecked.find((option) => option.id === "matrix")) {
      setLoadingMatrix(true);
      getCorrelationMatrix(selectedSensor, startDate, endDate).then((res) => {
        if(!canSetMatrix) return;
        setMatrixImage(res);
        setLoadingMatrix(false);
      });
    }else if(unckecked.find((option) => option.id === "matrix")) {
      setMatrixImage(null);
      setLoadingMatrix(false);
    }

    return () => {
      canSetR2 = false;
      canSetMatrix = false;
    }
  }, [checkedMenu])

  useEffect(() => {
    let canSetR2 = true, canSetMatrix = true;
    if(checkedMenu.find((option) => option.id === "r2")) {
      if(!compare || !selectedCompareMeasure) {
        setLoadingR2(false);
        setR2Value(null);
      }else {
        setLoadingR2(true);
        const dictSensors = selectedCompareMeasure.sensor.sensorId === selectedSensor ? {
          [selectedSensor]: [selectedMeasure, selectedCompareMeasure.measureId],
        }: {
          [selectedSensor]: [selectedMeasure],
          [selectedCompareMeasure.sensor.sensorId]: [selectedCompareMeasure.measureId]
        }
        getR2BySensors(dictSensors, startDate, endDate).then((res) => {
          if(!canSetR2) return;
          setLoadingR2(false);
          setR2Value(res?.r2);
        }).catch(() => {
          setGlobalError(true);
        })
      }
    }
    if(checkedMenu.find((option) => option.id === "matrix")) {
      if(compare && selectedCompareMeasure) {
        setLoadingMatrix(false);
        setMatrixImage(null);
      }else {
        setLoadingMatrix(true);
        getCorrelationMatrix(selectedSensor, startDate, endDate).then((res) => {
          if(!canSetMatrix) return;
          setMatrixImage(res);
          setLoadingMatrix(false);
        });
      }
    }


    return () => {
      canSetR2 = false;
      canSetMatrix = false;
    }
  }, [selectedMeasure, compare, selectedCompareMeasure])



  const handleChangeSensor = (sensorId) => {
    setSelectedSensor(sensorId);
    onChangeSensor && onChangeSensor(sensorId);
  };

  const handleChangeMeasure = (measureId) => {
    setSelectedMeasure(measureId);
    onChangeMeasure && onChangeMeasure(measureId);
  };

  const handleChangeCompare = (compare) => {
    setCompare(compare);
    onChangeCompare && onChangeCompare(compare);
  };

  const handleChangeSelectedCompare = (compare) => {
    setSelectedCompare(compare);
    onChangeSelectedCompare && onChangeSelectedCompare(compare);
  };

  const handleChangeMenu = (checked, option, value) => {
    setCheckedMenu(checked);
  }

  return (
    <Paper sx={{ p: "1rem", boxShadow: "none" }}>
      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", flexWrap: "wrap", marginBottom: "1rem"}}>
        <div style={{ display: "flex", flex: 0.8, gap: "1rem", alignItems: "center"}}>
          <h3>{handleTranslate("CONTROL_UNIT", true)}: {cu.serial}</h3>
          {withDownloads && (
            <DownloadComponent
              userActions={userActions}
              startDate={startDate}
              endDate={endDate}
              cuId={cuId}
              unitSerial={cu?.serial}
            />
          )}
        </div>
        <div style={{display: "flex", flex: 1, gap: "1rem", alignItems: "center"}}>
          <GraphInfo loadingR2={loadingR2} lastDetections={lastDetections} r2Value={r2Value}/>
          {isLive && <LiveText style={{marginLeft: "auto"}}/>}
        </div>
      </div>
      <div style={{ display: "flex", height: graphHeight }}>
        {isWindRose ?
          !windRoseLoading ? (
            graph.data?.length > 0 ?
              <WindRose data={graph.data} />
              : <NoDataMessage fullWidth />
          )
            : <LoadingGif />
          :
          graph.datasets?.[0]?.data?.length > 0 ?
            <LineGraph
              disableMenu={isGraphMenuDisabled}
              graphShadow={false}
              datasets={graph.datasets}
              labels={graph.labels}
              tooltips={graph.tooltips}
              measuresUnit={measuresUnit}
              loadingMatrix={loadingMatrix}
              matrixImage={matrixImage}
              avg={graph.avg}
              minMax={graph.minMax}
              minMaxRange={graph.minMaxRange}
              checkedMenu={checkedMenu}
              onChangeMenu={handleChangeMenu}/>
            :
            <NoDataMessage fullWidth />
        }
      </div>
      <MeasureSelection
      userActions={userActions}
      disabledCompare={isWindRose}
      disableSensorFilter={disableSensorFilter} 
      cu={cu}
      compare={compare}
      compareOptions={compareMeasuresOptions}
      selectedCompare={selectedCompareMeasure}
      selectedSensor={selectedSensor}
      selectedMeasure={selectedMeasure}
      onChangeSensor={handleChangeSensor}
      onChangeMeasure={handleChangeMeasure}
      onChangeCompare={handleChangeCompare}
      onChangeCompareMeasure={handleChangeSelectedCompare}/>
    </Paper>
  )
}

export default CuGraph;