import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const IT = {
    HEADER : "Stazione:",
    NO_LAST_REL : "Non ci sono ultime rilevazioni negli ultimi 15 minuti",
};

const SINGLE_STATION_IT = createLanguageObject(IT, viewNames.SINGLE_STATION_VIEW);

export default SINGLE_STATION_IT;