import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const ActionSelect = ({ style, actionsId, defaultValue, disabled = false, value, onChange, required = false, multiple = false }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };
  const actions = useSelector((state) => state.configuration.constants?.action);
  const options = useMemo(() => {
    if (actionsId) {
      return actionsId;
    } else {
      return Object.keys(actions);
    }
  }, [actionsId]);
  const [val, setValue] = useState(defaultValue !== undefined ? defaultValue : multiple ? [] : "")

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  return (
    <FormControl disabled={disabled} sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="action-select-label">{required ? `${handleTranslate("ACTIONS", true)}*` : handleTranslate("ACTIONS", true)}</InputLabel>
      <Select
        labelId="action-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((actionId) => actions[actionId]?.action).join(", ") : actions[selected]?.action}
        label={required ? `${handleTranslate("ACTIONS", true)}*` : handleTranslate("ACTIONS", true)}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((actionId, index) => (
          <MenuItem key={index} value={actionId}>
            {multiple && <Checkbox checked={val?.indexOf(actionId) > -1} />}
            {actions[actionId]?.action}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};

export default ActionSelect