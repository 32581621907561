import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useDrop } from 'react-dnd';
import { uninstallCu } from 'app/store/evocsSlice/controlUnits/cuActions';
import { uninstallSensor, updateSensor } from 'app/store/evocsSlice/sensors/sensorsActions';
import { setGlobalError } from 'app/store/evocsSlice/globalError/errorActions';

const UninstallModal = ({isOpen, onDrop, onClose}) => {
  const [{isOver}, drop] = useDrop(() => ({
    accept: ["cu", "sensor", "measure"],
    drop: ({unit, parent}) => handleDrop(unit, parent),
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }), []);

  const handleUninstall = async(unit, parent) => {
    try {
      switch(unit.type) {
        case "cu":
          await uninstallCu(unit.id, parent.id, new Date());
          break;
        case "sensor": 
          await uninstallSensor(unit.id, new Date(), parent.id, parent.type)
          break;
        case "measure":
          const newMeasures = parent.measures.filter((measureId) => measureId!==unit.id)
          await updateSensor(parent.id, undefined, undefined, undefined, newMeasures);
          break;
      }
    } catch (error) {
      setGlobalError(true);
    }
  }

  const handleDrop = (unit, parent) => {
    if(!parent) return;
    onDrop && onDrop("uninstall", unit, () => handleUninstall(unit, parent));
  }


  const getBackgroundColor = () => {
    if(isOver) return "linear-gradient(red, transparent)";
    else return "linear-gradient(black, transparent)";
  }


  return (
    <div ref={drop} style={{position: "fixed", zIndex: 1, display: isOpen ? "flex" : "none", justifyContent: "center", alignItems: "end", top: 0, left: 0, width: "100vw", height: "120px", background: getBackgroundColor()}}>
      <IconButton>
        <CloseIcon fontSize='large'/>
      </IconButton>
    </div>
  )
}

export default UninstallModal