import { useEffect, useRef, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton } from "@mui/material";
import { BiImageAdd } from "react-icons/bi";

// const styles = {
//   avatarImage: {
//     width: "100%",
//     height: "auto",
//     maxWidth: "200px",
//     minWidth: "100px",
//     minHeight: "100px",
//     objectFit: "cover",
//     borderRadius: "50%",
//     pointerEvents: "none",
//   },
//   personIcon: {
//     width: "100%",
//     height: "auto",
//     maxWidth: "200px",
//     minWidth: "100px",
//     minHeight: "100px",
//   }
// }

const styles = {
  avatarImage: {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "50%",
    pointerEvents: "none",
  },
  personIcon: {
    width: "150px",
    height: "150px",
  }
}

const AvatarImage = ({style, disabled=false, value, onChange}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef();

  useEffect(() => {
    value !== undefined && setSelectedImage(value);
  }, [value])

  const handleClick = () => {
    fileInputRef.current.click();
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        onChange && onChange(reader.result);
      };

      reader.readAsDataURL(file);
    }else {
      setSelectedImage(null);
      onChange && onChange(null);
    }
  };


  return (
    <div style={{ position: "relative", width: "fit-content"}}>
      <div>
        {selectedImage ?
          <img style={{...styles.avatarImage, ...style}} src={selectedImage} alt='avatarImage' />
          :
          <PersonIcon sx={{...styles.personIcon, ...style}} />
        }
      </div>
      <input ref={fileInputRef} type="file" id="avatar" style={{ display: "none" }} onChange={handleImageChange}/>
      {!disabled && 
      <IconButton sx={{ position: "absolute", right: 0, top: 0, transform: "translate(50%, -20%)"}} onClick={handleClick}>
        <BiImageAdd />
      </IconButton>}
    </div>
  )
}

export default AvatarImage;
