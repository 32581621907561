import { getApiTimestamp } from "src/utilities/utils";
import store from "../..";
import { cuSliceActions } from "../controlUnits/cuSlice";
import { sensorsSliceActions } from "../sensors/sensorSlice";
import { stationsMongoSliceActions } from "./stationsMongoSlice";
import { getCuMeasures, setCusLoading, setCuState } from "../controlUnits/cuActions";
import { getSensorsMeasures, isSensorGps, setSensorsLoading, setSensorState } from "../sensors/sensorsActions";

export const setDevicesLoading = (loading=true) => {
    setStationsLoading(loading);
    setCusLoading(loading);
    setSensorsLoading(loading);
};

export const setStationsLoading = (loading=true) => {
    store.dispatch(stationsMongoSliceActions.setLoading(loading));
};

export const getStationInfo = async(tenantIds) => {
    const data = store.dispatch(stationsMongoSliceActions.getStationInfo(tenantIds)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    })
    return data;
};

export const createStation = async(serial, owner, stationType=[], desc=null, project=null, listCu=null, listSensors=null, baseLocationId=null, newLocation=null, available=false) => {
    const availableNumber = available ? 1 : 0;
    const data = await store.dispatch(stationsMongoSliceActions.createStation({serial, owner, stationType, desc, project, listCu, listSensors, baseLocationId, newLocation, available: availableNumber})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    })
    listCu?.forEach((cuId) => {
        store.dispatch(cuSliceActions.installOnStation({cuId, device: data.stationId, start: Math.floor(new Date() / 1000)}))
    })
    listSensors?.forEach((sensorId) => {
        store.dispatch(sensorsSliceActions.installOnDevice({sensorId, device: data.stationId, type: "station", start: Math.floor(new Date() / 1000)}))
    })
    return data;
};

export const updateStation = async(stationId, serial=undefined, owner=undefined, available=undefined, stationType=undefined, desc=undefined, project=undefined, listSensors=undefined, listCu=undefined, baseLocationId=undefined) => {
    const availableNumber = available === undefined ? undefined : (available ? 1 : 0);
    const startDate = getApiTimestamp(new Date());
    const data = await store.dispatch(stationsMongoSliceActions.updateStation({stationId, serial, owner, available: availableNumber, stationType, desc, project, listSensors, listCu, baseLocationId, operationTime: startDate})).then(({payload, error}) => {
        if(error) throw error;

        return payload;
    });
    
    data[stationId]?.forEach((element) => {
        const deviceId = element._id;
        const typeDevice = element.typeDevice;
        delete element._id; delete element.typeDevice;
        switch(typeDevice) {
            case "station":
                setStationState(deviceId, element);
                break;
            case "cu":
                setCuState(deviceId, element);
                break;
            case "sensor":
                setSensorState(deviceId, element);
                break;
        }
    });

    if(available !== undefined) {
        if(available) store.dispatch(stationsMongoSliceActions.activateStation(stationId));
        else store.dispatch(stationsMongoSliceActions.deactivateStation(stationId));
    }

    return data;
};

export const setStationState = (stationId, state) => {
    store.dispatch(stationsMongoSliceActions.setStationState({id: stationId, station: state}));
};

// export const updateStation = async(stationId, serial=undefined, owner=undefined, available=undefined, mobile=undefined, stationType=undefined, desc=undefined, project=undefined, listSensors=undefined, listCu=undefined, baseLocationId=undefined) => {
//     const stations = store.getState().stationsMongo.list;
//     const mobileNumber = mobile === undefined ? undefined: (mobile ? 1 : 0);
//     const availableNumber = available === undefined ? undefined : (available ? 1 : 0);
//     const startDate = getApiTimestamp(new Date());
//     const data = await store.dispatch(stationsMongoSliceActions.updateStation({stationId, serial, owner, available: availableNumber, mobile: mobileNumber, stationType, desc, project, listSensors, listCu, baseLocationId, operationTime: startDate})).then(({payload, error}) => {
//         if(error) throw error;

//         return payload;
//     });
    
//     if(available !== undefined) {
//         const devices = data[stationId];
//         devices.forEach((element) => {
//             const deviceId = element._id;
//             const typeDevice = element.typeDevice;
//             delete element._id; delete element.typeDevice;
//             switch(typeDevice) {
//                 case "station":
//                     activateStation(deviceId, available);
//                     break;
//                 case "cu":
//                     activateCu(deviceId, available);
//                     break;
//                 case "sensor":
//                     activateSensor(deviceId, available);
//                     break;
//             }
//         });
//     }
//     if(listCu) {
//         const uninstalled = stations[stationId].list_cu.filter((cuId) => !listCu.includes(cuId));
//         store.dispatch(stationsMongoSliceActions.setListCu({stationId, listCu}));
//         listCu.forEach((cuId) => {
//             store.dispatch(cuSliceActions.installOnStation({cuId, device: stationId, start: startDate}))
//         })
//         uninstalled.forEach((cuId) => {
//             store.dispatch(cuSliceActions.uninstallFromParent(cuId))
//         })
//     }
//     if(listSensors) {
//         const uninstalled = stations[stationId].list_sensors.filter((sensorId) => !listSensors.includes(sensorId));
//         store.dispatch(stationsMongoSliceActions.setListSensor({stationId, listSensors}));
//         listSensors.forEach((sensorId) => {
//             store.dispatch(sensorsSliceActions.installOnDevice({sensorId, device: stationId, type: "station", start: startDate}))
//         })
//         uninstalled.forEach((sensorId) => {
//             store.dispatch(sensorsSliceActions.uninstallFromParent(sensorId))
//         })
//     }

//     return data;
// };

export const activateStation = (stationId, active=true) => {
    if(active) store.dispatch(stationsMongoSliceActions.activateStation(stationId));
    else store.dispatch(stationsMongoSliceActions.deactivateStation(stationId));
};

export const deleteStation = async(stationId) => {
    const data = store.dispatch(stationsMongoSliceActions.deleteStation(stationId)).then(({payload, error}) => {
        if(error) throw error;

        return true;
    })
    return data;
};

export const getStationHistory = async(stationId) => {
    const deviceDict = {[stationId]: "station"}
    const data = await store.dispatch(stationsMongoSliceActions.getHistory(deviceDict)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const updateStationHistory = async(stationId, oldTimeList, updateTimeList, listDeleted, listAdded) => {
    if(!stationId) throw new Error("stationId is required");
    const data = await store.dispatch(stationsMongoSliceActions.updateHistory({stationId, oldTimeList, updateTimeList, listDeleted, listAdded})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const downloadStationHistory = async(stationId, form="csv") => {
    const data = await store.dispatch(stationsMongoSliceActions.downloadHistory({stationId, form})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const moveDevice = async(deviceId,
    deviceType,
    sourcType,
    destinationId,
    destinationType) => {
    const data = await store.dispatch(stationsMongoSliceActions.moveDevice(
        {
            deviceId : deviceId,
            deviceType : deviceType,
            sourcType : sourcType,
            destinationId : destinationId,
            destinationType : destinationType,
        }
    )).then(({payload, error}) => {
        if(error) return [];
        return payload;
    });

    return data;
};

export const getStationBySensor = (sensorId) => {
    if(!sensorId) throw new Error("sensorId is required");
    const stations = store.getState().stationsMongo.list;
    if(!stations) return null;
    return Object.keys(stations).find((stationId) => getStationSensors(stationId).includes(sensorId));
}


export const getStationSensors = (stationId) => {
    if(!stationId) throw new Error("stationId is required");
    const stations = store.getState().stationsMongo.list;
    const controlUnits = store.getState().controlUnits.list;
    if(Array.isArray(stationId)) {
        const sensorsList = [];
        stationId.forEach((id) => {
            stations[id]?.list_sensors.forEach((sensorId) => sensorsList.push(sensorId));
            stations[id]?.list_cu.forEach((cuId) => {
                controlUnits[cuId]?.list_sensors.forEach((sensorId) => sensorsList.push(sensorId))
            });
        });
        return sensorsList;
    }else {
        const sensorsList = [...stations[stationId]?.list_sensors];
        stations[stationId]?.list_cu.forEach((cuId) => {
            controlUnits[cuId]?.list_sensors.forEach((sensorId) => sensorsList.push(sensorId))
        });
        return sensorsList;
    }
};

export const getStationMeasures = (stationId, onlyGraphMeasures=false) => {
    if(!stationId) throw new Error("stationId is required");
    const stations = store.getState().stationsMongo.list;
    
    if(Array.isArray(stationId)) {
        const sensorsMeasures = [...new Set( stationId.map((id) =>  getSensorsMeasures(stations[id].list_sensors, onlyGraphMeasures)).flat())];
        const cusMeasures = [...new Set( stationId.map((id) =>  getCuMeasures(stations[id].list_cu, onlyGraphMeasures)).flat())];
        return [...new Set(sensorsMeasures.concat(cusMeasures))];
    }else {
        const sensorsMeasures = getSensorsMeasures(stations[stationId].list_sensors, onlyGraphMeasures);
        const cusMeasures = getCuMeasures(stations[stationId].list_cu, onlyGraphMeasures);
        return [...new Set(sensorsMeasures.concat(cusMeasures))];
    }
};

export const hasStationGps = (stationId) => {
    if(!stationId) throw new Error("stationId is required");
    return getStationSensors(stationId).some((sensorId) => isSensorGps(sensorId));
};

export const getStationsThreshold = async (tenantId, stationIds=[]) => {
    const data = await store.dispatch(stationsMongoSliceActions.getThreshold({tenantId, stationIds})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const shareSingleStation = async(stationId, tenatList) => {
    if (!stationId || !tenatList) throw new Error("Missing parameters");
    const dictShare = {[stationId]: tenatList}

    const data = await store.dispatch(stationsMongoSliceActions.createShareStation(dictShare)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    const stations=[], cus=[], sensors=[];
    data?.forEach((element) => {
        const newElement = {...element};
        const elementId = newElement._id;
        delete newElement._id;
        delete newElement.typeDevice;
        switch(element.typeDevice) {
            case "station":
                stations.push({id: elementId, station: newElement});
                break;
            case "cu":
                cus.push({id: elementId, cu: newElement});
                break;
            case "sensor":
                sensors.push({id: elementId, sensor: newElement});
                break;
        }
    });
    if(stations.length > 0) stations.forEach((element) => store.dispatch(stationsMongoSliceActions.setStationState(element)));
    if(cus.length > 0) cus.forEach((element) => store.dispatch(cuSliceActions.setCuState(element)));
    if(sensors.length > 0) sensors.forEach((element) => store.dispatch(sensorsSliceActions.setSensorState(element)));
    return data;
};

export const updateShareStation = async(stationId, tenantsList) => {
    const data = await store.dispatch(stationsMongoSliceActions.updateShareStation({stationId, tenantsList})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    }); 
    const stations=[], cus=[], sensors=[];
    data?.forEach((element) => {
        const newElement = {...element};
        const elementId = newElement._id;
        delete newElement._id;
        delete newElement.typeDevice;
        switch(element.typeDevice) {
            case "station":
                stations.push({id: elementId, station: newElement});
                break;
            case "cu":
                cus.push({id: elementId, cu: newElement});
                break;
            case "sensor":
                sensors.push({id: elementId, sensor: newElement});
                break;
        }
    });
    if(stations.length > 0) stations.forEach((element) => store.dispatch(stationsMongoSliceActions.setStationState(element)));
    if(cus.length > 0) cus.forEach((element) => store.dispatch(cuSliceActions.setCuState(element)));
    if(sensors.length > 0) sensors.forEach((element) => store.dispatch(sensorsSliceActions.setSensorState(element)));
    return data;
};

// interval is int minutes
export const getLastStationDetections = async(stationIds, tenantId, interval=undefined) => {
    const data = await store.dispatch(stationsMongoSliceActions.lastDetectionStation({stationIds, tenantId, interval})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    }); 
    return data;
};

export const getLastStationPosition = async(stationId, startDate, endDate) => {
    if(!stationId) throw new Error("stationId is required");
    const stations = store.getState().stationsMongo.list;
    const baseLocations = store.getState().configuration.constants?.base_location;
    const locations = [], gpsSensors = [];
    if(!Array.isArray(stationId)) {
        const base_location = baseLocations[stations[stationId].base_location];
        const hasGPS = getStationSensors(stationId).some((sensorId) => isSensorGps(sensorId) && gpsSensors.push({stationId, sensorId}));
        !hasGPS && base_location && locations.push({stationId, ...base_location});
    }else {
        stationId.forEach((id) => {
            const base_location = baseLocations[stations[id].base_location];
            getStationSensors(id).some((sensorId) => isSensorGps(sensorId) && gpsSensors.push({stationId: id, sensorId}));
            base_location && locations.push({stationId: id, ...base_location});
        })
    }

    if(gpsSensors?.length === 0) return locations;
    await store.dispatch(sensorsSliceActions.getLastPosition({sensorIds: gpsSensors.map((element) => element.sensorId), startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
        if(error) throw error;
        payload.forEach((location) => {
            if(!location || !location.lat || !location.lon) return;
            const stationID = gpsSensors.find((element) => element.sensorId === location.sensorId).stationId;
            const newLocation = {stationId: stationID, ...location};
            const locationIndex = locations.findIndex((element) => element.stationId === stationID);
            if(locationIndex >= 0) {
                delete newLocation.sensorId;
                locations[locationIndex] = newLocation;
            }
        });
    }); 
    return locations;
};

export const getStationLocation = (stationId) => {
    const stations = store.getState().stationsMongo.list;
    const baseLocations = store.getState().configuration.constants.base_location;
    if (!stationId || !stations || !baseLocations) return [];
    if(Array.isArray(stationId)) {
        const locations = [];
        stationId.forEach((id) => {
            if(baseLocations[stations[id].base_location]) 
                locations.push({id: stations[id].base_location, stationId: id, ...baseLocations[stations[id].base_location]});;
        });
        return locations;
    }else {
        
    }
};

export const getStationRoute = async(stationIds, startDate, endDate, minDistance=15) => {
    const data = await store.dispatch(stationsMongoSliceActions.getStationRoute({stationIds, startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), minDistance})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    }); 
    return data;
};

export const getPolarPlots = async(startDate, endDate) => {
    const data = await store.dispatch(stationsMongoSliceActions.getPolarPlots({startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    }); 
    return data;
};


export const getR2BySensors = async (dictSensors, startDate, endDate) => {
    const data = await store.dispatch(stationsMongoSliceActions.calculateR2Sensors({dictSensors, startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
        console.log("DIOO")
        if(error) throw error;
        return payload;
    });
    return data;
}

