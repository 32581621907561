import { useEffect, useMemo, useState } from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const styles = {
  menu: {
    position: "absolute",
    zIndex: "1000", 
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
    top: 0
  },
  menuContainer: {
    background: "rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(0.5rem)",
    height: "100%", 
    overflowX: "hidden",
  },
  menuButton: {
    position: "absolute", 
    top: 0,
    background: "white", 
    height: "fit-content",
    padding: ".5rem"
  }
}

const MenuButton = ({isOpen, rightPos, openIcon, closeIcon, onClick}) => {
  return rightPos ? (
    <button style={{...styles.menuButton, left: 0, transform: "translateX(-100%)", borderRadius: "1rem 0 0 1rem"}} onClick={onClick}>
      {!isOpen ? openIcon : closeIcon}
    </button>
  ): (
    <button style={{...styles.menuButton, right: 0, transform: "translateX(100%)", borderRadius: "0 1rem 1rem 0"}} onClick={onClick}>
      {isOpen ? openIcon : closeIcon}
    </button>
  )
}

const Menu = ({
  style, menuButtonStyle, children, open, defaultOpen=true, width="250px", height="80vh", animated=true, rightPos=false, 
  openIcon=<KeyboardArrowLeftIcon />, closeIcon=<KeyboardArrowRightIcon />
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  
  useEffect(() => {
    open !== undefined && setIsOpen(open);
  }, [open])

  return (
    <div style={{...styles.menu, ...style, width: isOpen ? width : "0px", height: height, right: rightPos && 0, transition: animated && "all 0.2s ease"}}>
      <div style={{position: "relative", width: "100%", height: "100%"}}>
        <div style={{...styles.menuContainer, borderRadius: rightPos ? "0 1rem 1rem 1rem" : "1rem 0 1rem 1rem"}}>
          {children}
        </div>
        <MenuButton rightPos={rightPos} isOpen={isOpen} onClick={() => setIsOpen((prev) => !prev)} openIcon={openIcon} closeIcon={closeIcon}/>
      </div>
    </div>
  )
    
  return (
    <div style={{display: "flex", height: "100%"}}>
      <div style={{...style, background: "white", height: "100%",  width: isOpen ? width : "0px", transition: animated && "all 0.2s ease", overflowX: "hidden", borderRadius: "1rem"}}>
        <div style={{height: "100%", width: width}}>          
          {children}
        </div>
      </div>
      <div style={{...menuButtonStyle, background: "white", marginTop: "1rem", height: "fit-content", borderRadius: "0 1rem 1rem 0"}}>
        <button style={{padding: ".5rem"}} onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </button>
      </div>
    </div>
  )
}

export default Menu;