import store from "../.."
import { errorSliceActions } from "./errorSlice"

export const setServerError = (error=true) => {
  store.dispatch(errorSliceActions.setServerError(error));
  if(error) {
    setGlobalSuccess(false, null);
    setGlobalWarning(false, null);
  }
};

export const setGlobalError = (error=true, message="") => {
  store.dispatch(errorSliceActions.setGlobalError(error));
  if(error) {
    setGlobalMessage(message);
    setGlobalSuccess(false, null);
    setGlobalWarning(false, null);
  }
};

export const setGlobalSuccess = (success=true, message="") => {
  store.dispatch(errorSliceActions.setGlobalSuccess(success));
  if(success) {
    setGlobalMessage(message);
    setGlobalError(false, null);
    setGlobalWarning(false, null);
  }
};

export const setGlobalWarning = (warning=true, message="") => {
  store.dispatch(errorSliceActions.setGlobalWarning(warning));
  if(warning) {
    setGlobalMessage(message);
    setGlobalSuccess(false, null);
    setGlobalError(false, null);
  }
};

const setGlobalMessage = (message) => {
  store.dispatch(errorSliceActions.setGlobalMessage(message));
};

