
const index = "/"
const user_profile_view = "user-profile";
const dashboard_view = "dashboard";
const stations_view = "stations";
const single_station_view = "single-station";
const multimedia_view = "multimedia-stations";
const single_multimedia_view = "single_multimedia-stations";
const odor_reports_view = "odor-reports";
const error_view = "404";
const sign_in_view = "sign-in";
const sign_up_view = "sign-up";
const user_invitation_view = "user-invitation";
const recover_password_view = "recover-password";
const monthly_reports_view = "monthly-reports";
const daily_reports_view = "daily-reports";
const port_state_view = "port-state";
const loading_view = "loading";
const station_history_view = "station-history";
const configuration_view = "configuration";
const administration_view = "administration";
const open_data_air_quality_view = "open-data-air";
const open_data_meteorology_view = "open-data-meteorology";
const vessels_traffic_view = "vessels-traffic";
const testPage = "test-page";
const alerts = "alerts";

//SHARING COMPONENTS NAME
const footer = "footer";
const stationSelect = "stationSelect";
const locationInput = "locationInput";
const noNotifMessage = "noNotifMessage";

//MODAL COMPONENTS NAME
const modals_components = "MODALS";
const inputs_components = "INPUTS";
const add_odor_report_modal = "modale_aggiunta_segnalazione";
const delete_tenant_modal = "modale_cancellazione_tenant";

export const groupIds = {
  DATA_CENTER: "data-center",
  MULTIMEDIA: "multimedia",
  PORT_ODOR: "port-odor",
  OPEN_DATA: "open-data",
  VESSELS: "vessels-service",
  REPORTS_AND_NOTIF: "reports-and-notif",
  CONFIG: "config",
  TEST: "test"
}

export const routesNames = {
  USER_PROFILE_VIEW: `/${user_profile_view}`,
  DASHBOARD_VIEW: `/${dashboard_view}`,
  STATIONS_VIEW: `/${stations_view}`,
  SINGLE_STATION_VIEW_WITH_PARAM: `/${stations_view}/:serial`,
  SINGLE_STATION_VIEW: `/${single_station_view}`,
  MULTIMEDIA_VIEW: `/${multimedia_view}`,
  SINGLE_MULTIMEDIA_VIEW: `/${multimedia_view}/:serial`,
  ODOR_REPORTS_VIEW: `/${odor_reports_view}`,
  ERROR_VIEW: `/${error_view}`,
  SIGN_IN_VIEW: `/${sign_in_view}`,
  SIGN_UP_VIEW: `/${sign_up_view}`,
  USER_INVITATION_VIEW: `/${user_invitation_view}`,
  RECOVER_PASSWORD_VIEW: `/${recover_password_view}`,
  MONTHLY_REPORTS_VIEW: `/${monthly_reports_view}`,
  DAILY_REPORTS_VIEW: `/${daily_reports_view}`,
  CONFIGURATION_VIEW: `/${configuration_view}`,
  ADMINISTRATION_VIEW: `/${administration_view}`,
  PORT_STATE_VIEW: `/${port_state_view}`,
  LOADING_VIEW: `${loading_view}`,
  STATION_HISTORY_VIEW: `/${station_history_view}`,
  OPEN_DATA_AIR_QUALITY_VIEW: `/${open_data_air_quality_view}`,
  OPEN_DATA_METEOROLOGY_VIEW: `/${open_data_meteorology_view}`,
  VESSELS_VIEW: `/${vessels_traffic_view}`,
  TEST_PAGE_VIEW: `/${testPage}`,
  ALERTS_VIEW: `/${alerts}`,
  ROOT_VIEW: `${index}`,
};


export const viewNames = {
  USER_PROFILE_VIEW: `${user_profile_view}`,
  DASHBOARD_VIEW: `${dashboard_view}`,
  STATIONS_VIEW: `${stations_view}`,
  SINGLE_STATION_VIEW: `${single_station_view}`,
  MULTIMEDIA_VIEW: `${multimedia_view}`,
  SINGLE_MULTIMEDIA_VIEW: `${single_multimedia_view}`,
  ODOR_REPORTS_VIEW: `${odor_reports_view}`,
  ERROR_VIEW: `${error_view}`,
  SIGN_IN_VIEW: `${sign_in_view}`,
  SIGN_UP_VIEW: `${sign_up_view}`,
  USER_INVITATION_VIEW: `${user_invitation_view}`, 
  RECOVER_PASSWORD_VIEW: `${recover_password_view}`, 
  MONTHLY_REPORTS_VIEW: `${monthly_reports_view}`,
  DAILY_REPORTS_VIEW: `${daily_reports_view}`,
  CONFIGURATION_VIEW: `${configuration_view}`,
  ADMINISTRATION_VIEW: `${administration_view}`,
  PORT_STATE_VIEW: `${port_state_view}`,
  LOADING_VIEW: `${loading_view}`,
  STATION_HISTORY_VIEW: `${station_history_view}`,
  OPEN_DATA_AIR_QUALITY_VIEW: `${open_data_air_quality_view}`,
  OPEN_DATA_METEOROLOGY_VIEW: `${open_data_meteorology_view}`,
  VESSELS_VIEW: `${vessels_traffic_view}`,
  TEST_PAGE_VIEW: `${testPage}`,
  ALERTS_VIEW: `${alerts}`,
  ROOT_VIEW: `${index}`,
};

export const sharingComponentNames = {
  FOOTER_COMPONENT: `${footer}`,
  SELECT_STATION_COMPONENT: `${stationSelect}`,
  LOCATION_INPUT_COMPONENT: `${locationInput}`,
  NO_NOTIFICATION_MESSAGE: `${noNotifMessage}`,
};

export const modalsComponentNames = {
  MODALS : `${modals_components}`
};

export const inputsComponentsNames = {
  INPUTS : `${inputs_components}`
};
