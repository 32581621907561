import { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";

// L'oggetto tabs è così strutturato
// tabs: {
//     tabId: {
//         header: "Tab 1",
//         content: <Content />
//     }
// }


const paperStyle = {
  borderRadius: "0 16px 16px 16px",
  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
}

const TabsComponent = ({ style = {}, contentStyle = {}, paper=false, tabs = {}, selectedTab, onChange, background = "white", unselectTextColor = "inherit", swipeable = false }) => {
  const [selected, setSelected] = useState(Object.keys(tabs)[0]);
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    if (!selectedTab) return;
    setTabPage(selectedTab);
  }, [selectedTab])

  const setTabPage = (tabId) => {
    const index = Object.keys(tabs).findIndex((id) => tabId === id);
    setSelected(tabId);
    setTabIndex(index);
  };

  const handleSelectTab = (tabId) => {
    setTabPage(tabId);
    onChange && onChange(tabId);
  };

  const handleChangeIndex = (index) => {
    const tabId = Object.keys(tabs)[index];
    setTabPage(tabId);
    onChange && onChange(tabId);
  };

  return (
    <div style={{display: "flex", flexDirection: "column", height: "100%", ...style}}>
      <div style={{ display: "flex", overflow: "auto"}}>
        {Object.keys(tabs).map((key, index) => (
          <button key={index} 
          style={{ 
            background: `${selected === key ? background : "none"}`, 
            color: `${selected === key ? "inherit" : unselectTextColor}`,
            fontWeight: "inherit",
            borderRadius: "1rem 1rem 0 0", 
            padding: "1rem" ,
          }} onClick={() => handleSelectTab(key)}>{tabs[key]?.header}</button>
        ))}
      </div>
      {swipeable ?
        <SwipeableViews style={{...contentStyle, height: "100%", borderRadius: paper && paperStyle.borderRadius, boxShadow: paper && paperStyle.boxShadow}} index={tabIndex} onChangeIndex={handleChangeIndex} containerStyle={{height: "100%"}}>
          {Object.keys(tabs).map((tabId) => (
            <div key={tabId} style={{ height: "100%", background: background, overflowY: "auto"}}>
              {tabs[tabId].content}
            </div>
          ))}
        </SwipeableViews>
        :
        <div style={{ ...contentStyle, height: "100%", overflowY: "auto", background: background, borderRadius: paper && paperStyle.borderRadius, boxShadow: paper && paperStyle.boxShadow}}>
          {tabs[selected]?.content}
        </div>
      }
    </div>
  );
}

export default TabsComponent;