import FOOTER_EN from "./Footer/en";
import LOCATION_INPUT_COMPONENT_EN from "./LocationInput/en";
import NO_NOTIFICATION_MESSAGE_EN from "./No notification message/en";

const SHARING_COMPONENTS_EN = {
    ...FOOTER_EN,
    ...LOCATION_INPUT_COMPONENT_EN,
    ...NO_NOTIFICATION_MESSAGE_EN,
};

export default SHARING_COMPONENTS_EN;