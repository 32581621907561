import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const IT = {
    NO_NOTIF_AVAILABLE: "Non ci sono nuove notifiche",
};

const NO_NOTIFICATION_MESSAGE_IT = createLanguageObject(IT, sharingComponentNames.NO_NOTIFICATION_MESSAGE);

export default NO_NOTIFICATION_MESSAGE_IT;