import { IconButton } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react'

const ToggleButton = ({children, enabled, defaultEnabled=true, onChange, onClick}) => {
  const [active, setActive] = useState(defaultEnabled);

  useLayoutEffect(() => {
    enabled !== undefined && setActive(enabled);
  }, [enabled])

  const handleChange = () => {
    //setActive(!active);
    onChange ? onChange(!active) : setActive(!active);
  }

  return (
    <IconButton sx={{color: active ? "black" : "grey"}} onClick={handleChange}>
      {children}
    </IconButton>
  )
}

export default ToggleButton