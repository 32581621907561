import AlertsHeader from 'src/components/Alerts/AlertsHeader';
import AlertsContent from 'src/components/Alerts/AlertsContent';
import WithAuth from './WithAuth';
import { viewNames } from 'app/configs/routesNames';
import CustomPageCarded from './CustomPageCarded';
import Footer from 'src/components/SharingComponents/Footer';

const Alerts = () => {
  return (
    <WithAuth
    viewName={viewNames.ALERTS_VIEW}
    component={
      <CustomPageCarded
        header={<AlertsHeader />}
        content={<AlertsContent />}
        footer={<Footer />}
      />
    }
  />
  )
}

export default Alerts