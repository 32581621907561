import { Box } from '@mui/system'
import SingleStationHandlingHeader from './SingleStationHandlingContentHeader';
import ChildrensList from './ChildrensList';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LastRelevations from './LastRelevations';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { getLastStationDetections, getStationSensors, } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import { calcIntervalDates } from '../StationHandling/utils';
import { setTimeRangeFilter } from 'app/store/evocsSlice/filters/filtersActions';
import { viewNames } from 'app/configs/routesNames';
import useMongoData from 'src/app/hooks/useMongoData';
import useFilters from 'src/app/hooks/useFilters';

const viewName = viewNames.SINGLE_STATION_VIEW;
const liveId = "660bc8f8e4db0b4972d2fb69";

const SingleStationHandlingContent = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const stations = useSelector((state) => state.stationsMongo.list);
  const measures = useSelector((state) => state.configuration.constants.measure);
  const timeRange= useSelector((state) => state.configuration.constants?.timeRange)
  const filters = useFilters(viewName);
  const timeRangeInView = filters.timeRange;
  const measureSelect = filters.measure;
  const stationId = location.state.stationId;


  const startDateLocation = location.state.startDate;
  const endDateLocation = location.state.endDate;
  const station = stations && stations[stationId];
  const [lastRelevations, setLastRelevations] = useState([]);
  const [filteredLastRelevations, setFilteredLastRelevations] = useState([]);
  const [displayMap, setDisplayMap] = useState(false);
  const [enableLastPositionMap, setEnableLastPositionMap] = useState(false);
  const [enableMapRoute, setEnableMapRoute] = useState(false);

  const [currentStation, setCurrentStation] = useState();
  const [stationSensors, setStationSensors] = useState([]);
  const [apiTimeout, setApiTimeout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLastRelevationsLoading, setIsLastRelevationsLoading] = useState(false);
  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [timeRangeStartDate, setTimeRangeStartDate] = useState();
  const [timeRangeEndDate, setTimeRangeEndDate] = useState();
  const [isLive, setLive] = useState(false);

  const [datasetLoading] = useMongoData(startDate, endDate, stationSensors, currentStation, apiTimeout)
  
  const getLastStationDatas = useCallback(async() => {
    const data = await getLastStationDetections([stationId], user.tenantId).then((res) => {
      const relevations = [];
      Object.keys(res).forEach((stationId) => {
        Object.keys(res[stationId]).forEach((sensorId) => {
          Object.keys(res[stationId][sensorId]).forEach((measureId) => {
            if(measureId === "time") return;
            if(!measureSelect.selected || measureSelect.selected?.length === 0 || (measureSelect.selected?.length > 0 && measureSelect.selected?.includes(measureId)) )
              relevations.push({measure: measureId, value: res[stationId][sensorId][measureId], unit: measures[measureId]?.unit})
          })
        })
      })
      return relevations;
      // setLastRelevations(relevations);
    });
    return data;
  }, [stationId, user.tenantId, measureSelect, measures])

  const handleRefresh = useCallback(async() => {
    let {start, end} = calcIntervalDates(timeRange[timeRangeInView.selected].timeRange);

    if(!start || !end) {
      start = startDate.valueOf(); end = endDate.valueOf();
    }
    setTimeRangeFilter(viewName, timeRangeInView.id, undefined, start, end);
    setStartDate(new Date(start));
    setEndDate(new Date(end));
    setTimeRangeStartDate(new Date(start));
    setTimeRangeEndDate(new Date(end));
    const lastRelevations = await getLastStationDatas();
    return lastRelevations;
  }, [timeRangeInView?.selected, startDate, endDate, getLastStationDatas])


  useEffect(() => {
    if(startDateLocation && endDateLocation) {
      setTimeRangeStartDate(new Date(startDateLocation));
      setTimeRangeEndDate(new Date(endDateLocation));
    }
  }, [])

  useEffect(() => {
    setApiTimeout(0);
  }, [apiTimeout])

  useEffect(() => {
    // console.log("datasetLoading: ", datasetLoading);
    if(!datasetLoading) {
      setIsLiveLoading(false);
      if(timeRangeInView.selected === liveId) setLive(true)
      else setLive(false);
    }
    setIsLoading(datasetLoading);
  }, [datasetLoading])

  useEffect(() => {
    // Per evitare che allo switch della stazione partano chiamate in ripetizione
    let canSet = true;
    setIsLastRelevationsLoading(true);
    const timeout = setTimeout(() => {
      getLastStationDatas().then((relevations) => {
        if(!canSet) return;
        setLastRelevations(relevations);
        setIsLastRelevationsLoading(false);
      });
    }, location.state.timeout);
    setCurrentStation(stationId);
    setStationSensors(getStationSensors(stationId));
    setApiTimeout(location.state.timeout);
    return () => {
      canSet = false;
      clearTimeout(timeout);
    }
  }, [stationId])

  useEffect(() => {
    let intervalId, canSet = true;
    if(timeRangeInView.selected === liveId) {
      intervalId = setInterval(() => {
        setIsLiveLoading(true);
        handleRefresh().then((relevations) => {
          if(!canSet) return;
          setLastRelevations(relevations); 
        })
      }, 1000*30) // 30 seconds 
    }else setLive(false);
    setIsLiveLoading(false);
    return () => {
      canSet = false;
      intervalId && clearInterval(intervalId);
    }
  }, [timeRangeInView.selected, stationId])

  useEffect(() => {
    if(timeRangeInView.startDate && timeRangeInView.endDate) {
      setStartDate(new Date(timeRangeInView.startDate));
      setEndDate(new Date(timeRangeInView.endDate));
    }
  }, [timeRangeInView.startDate, timeRangeInView.endDate])

  useEffect(() => {
    setFilteredLastRelevations(
      lastRelevations?.filter((relevation) => !measureSelect.selected || measureSelect.selected.length === 0 || (measureSelect.selected?.length > 0 && measureSelect.selected?.includes(relevation.measure)) )
    );
  }, [lastRelevations, measureSelect.selected])

  useLayoutEffect(() => {
    if(enableLastPositionMap || enableMapRoute) setDisplayMap(true);
    else setDisplayMap(false);
  }, [enableLastPositionMap, enableMapRoute])

  useLayoutEffect(() => {
    if(enableLastPositionMap) setEnableMapRoute(false);
  }, [enableLastPositionMap])

  useLayoutEffect(() => {
    if(enableMapRoute) setEnableLastPositionMap(false);
  }, [enableMapRoute])
  
  const handleClickRefresh = () => {
    setIsLastRelevationsLoading(true);
    handleRefresh().then((relevations) => {
      setIsLastRelevationsLoading(false);
      setLastRelevations(relevations);
    });
  }

  return Object.keys(stations).includes(stationId) ? (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <SingleStationHandlingHeader enableLastPositionMap={enableLastPositionMap} enableMapRoute={enableMapRoute} onToggleLastPosition={setEnableLastPositionMap} onToggleMapRoute={setEnableMapRoute} onRefresh={handleClickRefresh} 
      startDate={timeRangeStartDate} endDate={timeRangeEndDate}/>
      <ChildrensList stationId={stationId} station={station} displayMap={displayMap} enableLastPositionMap={enableLastPositionMap} enableMapRoute={enableMapRoute} isLive={isLive} isLoading={(isLoading && !isLiveLoading)}/>
      {!isLastRelevationsLoading && <LastRelevations relevations={filteredLastRelevations} filteredMeasures={measureSelect.selected}/>}
    </Box>
  ) : <Navigate to={"/stations"}/>
}

export default SingleStationHandlingContent