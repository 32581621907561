// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)

// const resources = {
//   en: {
//     translation: {
//       'Welcome to React': 'Welcome to React and react-i18next',
//     },
//   },
//   it: {
//     translation: {
//       'Welcome to React': 'Benvenuto in React e react-i18next',
//     },
//   },
// };

// const initI18n = (language) => {
//   i18n
//     .use(initReactI18next) // passes i18n down to react-i18next
//     .init({
//       resources,
//       lng: language,
//       fallbackLng: 'en',

//       keySeparator: false, // we do not use keys in form messages.welcome

//       interpolation: {
//         escapeValue: false, // react already safes from xss
//       },
//     });
// };

// export default initI18n;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "src/views/i18n/en.ts";
import it from "src/views/i18n/it.ts";
// import { selectUserLanguage } from 'app/store/evocsSlice/user/userSlice';

// the translations
// (tip move them in a JSON file and import them)


const resources = {
	en: {
		translation: {
			...en
		}
	},
	it: {
		translation: {
			...it
		}
	},
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: oldLang ?? 'en',
    lng: window.localStorage.getItem("evocs_lang") ?? 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
