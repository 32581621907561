import axios from "axios";
import { setLevelFilterLabels, setTypeFilterLabels } from "./filters/filtersSlice";
import { viewNames } from "app/configs/routesNames";
import { saveAs } from "file-saver";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllReports = createAsyncThunk(
  "reports/getAllReports",
  async (payload) => {
    const { intervalId, startingDate, endingDate } = payload;
    const data = await axios
      .get(
        `/api/rest/report/AppOdorData?cuSerialNumber=none&end=${endingDate}&field=none&flStation=0&orderBy=%2BTS_DATETIME&searchDatetime=true&start=${startingDate}&tenant=none`
        /*params: {
                cuSerialNumber: "none",
                end: 1684322039000,
                field: "none",
                flStation: 0,
                orderBy: "%2BTS_DATETIME",
                searchDatetime: true,
                start: 1681729980000,
                tenant: "none"
            }*/
      )
      .then((res) => {
        return res.data?.values;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return { intervalId, startingDate, endingDate, data };
  }
);

export const getReportsPercentages = createAsyncThunk(
  "reports/getReportsPercentages",
  async (payload, { dispatch }) => {
    const { startingDate, endingDate, type = null, level = null } = payload;
    const typeData = await axios
      .get(`/api/py/get_odour_pie_data`, {
        params: {
          start_date: Math.floor(startingDate / 1000),
          end_date: Math.floor(endingDate / 1000),
          kind: "type",
          conn: "sql",
          typi: type === "Tutti" ? null : type,
          level: level === "Tutti" ? null : level,
        },
      })
      .then((res) => {
        return res.data[0];
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    const levelData = await axios
      .get(`/api/py/get_odour_pie_data`, {
        params: {
          start_date: Math.floor(startingDate / 1000),
          end_date: Math.floor(endingDate / 1000),
          kind: "level",
          conn: "sql",
          typi: type === "Tutti" ? null : type,
          level: level === "Tutti" ? null : level,
        },
      })
      .then((res) => {
        return res.data[0];
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    if (type === "Tutti") {
      const typeFilterLabels = ["Tutti", ...typeData.labels];
      dispatch(
        setTypeFilterLabels({
          viewName: viewNames.ODOR_REPORTS_VIEW,
          labels: typeFilterLabels,
        })
      );
    }
    if (level === "Tutti") {
      const levelFilterLabels = ["Tutti", ...levelData.labels];
      dispatch(
        setLevelFilterLabels({
          viewName: viewNames.ODOR_REPORTS_VIEW,
          labels: levelFilterLabels,
        })
      );
    }
    return { typeData, levelData };
  }
);

export const getAutoReportsFileNames = createAsyncThunk(
  "reports/getAutoReportsFileNames",
  async (payload) => {
    console.log("getAutoReportsFileNames aync thunk");
    const { tenant } = payload;
    const data = await axios
      .get(`/api/py/get_day_reports_filenames`, { params: { tenant: tenant } })
      .then((res) => {
        console.log("res: ", res);
        if (res.status === 200 && res.data && res.data.length > 0) {
          return res.data;
        }
        return [];
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

export const getAutoReports = createAsyncThunk(
  "reports/getAutoReports",
  async (payload) => {
    const { start_date, end_date, stationId, tenant } = payload;
    const params = {
      start_date: start_date,
      end_date: end_date,
      station: stationId,
      tenant: tenant,
    };
    const data = await axios
      .get(`/api/py/get_auto_reports`, { params: params })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }
);


export const downloadAutoReports = createAsyncThunk(
    "reports/downloadAutoReports",
    async (payload) => {
      const { fileName, tenant, typology, extension } = payload;
      console.log('fileName: ', fileName);
      const params = {
        fileName: fileName,
        tenant: tenant,
        typology: typology,
      };
  
      try {
        const response = await axios.get(`/api/py/downloadAutoReports`, {
          params: params,
          responseType: "arraybuffer", // Set the responseType here
        });
  
        if (response.data) {
          const blob = new Blob([response.data], { type: `${extension}` });
          saveAs(blob, fileName);
        } else {
          console.log("Empty response data");
        }
      } catch (error) {
        console.error("Error: ", error);
        throw error; // You can throw the error to be handled by Redux Toolkit
      }
    }
  );
  

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    list: null,
    percentages: {
      type: {},
      level: {},
    },
    autoReports: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReports.fulfilled, (state, action) => {
      const { intervalId, startingDate, endingDate, data } = action.payload;
      const oldList = state.list || {};
      state.list = {
        ...oldList,
        [intervalId]: {
          startingDate: startingDate,
          endingDate: endingDate,
          values: data,
        },
      };
    });
    builder.addCase(getReportsPercentages.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { typeData, levelData } = action.payload;
      const type = {},
        level = {};
      typeData?.labels?.forEach((key, index) => {
        type[key.toLowerCase()] = {
          label: key,
          value: typeData.values[index],
        };
      });
      levelData?.labels?.forEach((key, index) => {
        level[key.toLowerCase()] = {
          label: key,
          value: levelData.values[index],
        };
      });
      state.percentages = {
        type: type,
        level: level,
      };
    });
    builder.addCase(getAutoReportsFileNames.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.autoReports = action.payload;
    });
    builder.addCase(downloadAutoReports.fulfilled, (state, action) => {
    //   if (!action.payload) return;
    //   const data = action.payload;
    //   console.log('data: ', data);
    //   const blob = new Blob([data], { type: 'docx' });
    //   saveAs(blob, 'ciao.docx');
    });
  },
});

export default reportsSlice.reducer;
