import store from "../.."
import { loginSliceActions, selectSessionExpired  } from "./loginSlice"
import { selectUserLanguage } from "../user/userSlice";


export const setDataLoading = (loading) => {
    if(loading === undefined || loading === null) throw new Error("loading param cannot be null.");
    store.dispatch(loginSliceActions.setDataLoading(loading));
};

export const login = async (email, password) => {
    if (!email || !password) {
        store.dispatch(setError(true));
        return;
    }
    try {
        const action = await store.dispatch(loginSliceActions.login({ email, password }));
        const isLogged = action.payload;
        return isLogged;
      } catch (error) {
        console.log('loginActions: error during login');
        return false;
      }
};

export const refresh = (newTokens=true) => {
    // console.log("loginActions: refresh");
    store.dispatch(loginSliceActions.refresh({newTokens:newTokens}));
};

export const logout = () => {
    // console.log('loginActions: logout');
    return store.dispatch(loginSliceActions.logout());
};

export const setWaitAuthCheck = (waitAuthCheck) => {
    // console.log('setWaitAuthCheck action');
    store.dispatch(loginSliceActions.setWaitAuthCheck({waitAuthCheck:waitAuthCheck}));
};

export const setError = (error) => {
    console.log('setError action');
    store.dispatch(loginSliceActions.setError({error:error}));
};

export const clearError = () => {
    console.log('clearError action');
    store.dispatch(loginSliceActions.clearError());
};

export const setSessionExpired = (isExpired) => {
    // console.log('setSessionExpired action, isExpired value: ', isExpired);
    if (typeof isExpired === 'boolean'){
        store.dispatch(loginSliceActions.setSessionExpired({isExpired:isExpired}));
    } else throw new Error('The value passed as input is not a boolean');
};

export const isSessionExpired = () => {
    const state = store.getState();
    const isExpired = selectSessionExpired(state);
    return isExpired;
};