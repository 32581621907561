import { viewNames } from 'app/configs/routesNames';
import TableComponent from '../SharingComponents/TableComponent';
import useMobile from 'src/app/hooks/useMobile';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { useEffect, useMemo, useState } from 'react';

const viewName = viewNames.OPEN_DATA_METEOROLOGY_VIEW;


const MeteoTable = ({ data, onClickRow, downloadFileProps }) => {
  const [xs] = useMobile();
  const { t } = useTranslation("evocs");
  const [tableData, setTableData] = useState([]);
  const [searchedText, setSearchedText] = useState();
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.OPEN_DATA_METEOROLOGY_VIEW);
  };
  const meteoTableColumns = useMemo(() => {
    return {
      time: handleTranslate("DATE", true),
      station: handleTranslate("STATION", true),
      source: handleTranslate("SOURCE", true),
      temp: handleTranslate("MEASURE_TEMP", true),
      pres: handleTranslate("MEASURE_PRES", true),
      rh: handleTranslate("MEASURE_RH", true),
      rain_int: handleTranslate("MEASURE_RAIN_INT", true),
      tot_rain: handleTranslate("MEASURE_TOT_RAIN", true),
      ws: handleTranslate("MEASURE_WS", true),
      wd: handleTranslate("MEASURE_WD", true),
      msl: handleTranslate("MEASURE_MSL", true),
      water_temp: handleTranslate("MEASURE_WATER_TEMP", true),
      wave_height: handleTranslate("MEASURE_SIGN_WAVE_HEIGHT", true),
      avg_wave_dir: handleTranslate("MEASURE_MEAN_WAVE_DIRECTION", true),
      mean_period: handleTranslate("MEASURE_AVG_PERIOD", true),
      peak_period: handleTranslate("MEASURE_PEAK_PERIOD", true),
    }
  }, [t]);

  useEffect(() => {
    if(!data || data.length === 0) {
      setTableData([]);
      return;
    };
    if(!searchedText || searchedText === "") {
      setTableData(data);
      return;
    }

    setTableData(() => {
      return data.filter((element) => Object.entries(element).find(([key, value]) => value.toString().toLowerCase().includes(searchedText.toLowerCase())));
    });
    
  }, [data, searchedText])




  return (
    <TableComponent
      style={{ height: "100%" }}
      rowsForPage={xs ? 4 : 10}
      columns={meteoTableColumns}
      data={tableData}
      clickable
      onClickRow={onClickRow}
      tableTitle={handleTranslate('TABLE_TITLE')}
      downloadFileProps={downloadFileProps}
      onSearch={setSearchedText}
    />
  );
}

export default MeteoTable;
