import { Button, FormControl, FormControlLabel, FormLabel, IconButton, Modal, Radio, RadioGroup, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useLayoutEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import LocationSelect from "../SharingComponents/Inputs/LocationSelect";
import OdourLevelSelect from "../SharingComponents/Inputs/OdourLevelSelect";
import OdourTypeSelect from "../SharingComponents/Inputs/OdourTypeSelect";
import { addOdourReport } from "app/store/evocsSlice/reports/reportsActions";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const LocationTypeSelect = ({ value, defaultValue = "", onChange }) => {
  const [val, setValue] = useState(defaultValue);

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value])

  const handleChange = (e, newValue) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  return (
    <FormControl>
      <FormLabel id="location-radio-buttons-group-label"> {handleTranslate("POSITION", true)} </FormLabel>
      <RadioGroup
        value={val}
        onChange={handleChange}
        row
        aria-labelledby="location-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="new" control={<Radio />} label={handleTranslate("NEW", true)} />
        <FormControlLabel value="existing" control={<Radio />} label={handleTranslate("EXISTING", true)} />
      </RadioGroup>
    </FormControl>
  )
}

const FormComponent = ({ lat, lon, onConfirm }) => {
  const user = useSelector((state) => state.user);
  const [allInputs, setAllInputs] = useState({
    date: {
      value: new Date(),
      required: true,
    },
    odourLevel: {
      value: "",
      required: true,
    },
    odourType: {
      value: "",
      required: true,
    },
    locationType: {
      value: lat ? "new" : "",
      required: true
    },
    lat: {
      value: lat,
      required: false,
    },
    lon: {
      value: lon,
      required: false,
    },
    baseLocation: {
      value: null,
      required: false,
    }
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);


  useLayoutEffect(() => {
    if (Object.keys(allInputs).some((key) => (allInputs[key].required && (!allInputs[key].value || allInputs[key].value === "")))) setButtonDisabled(true);
    else setButtonDisabled(false)
  }, [allInputs]);

  const handleChangeInput = (key, value) => {
    setAllInputs({ ...allInputs, [key]: { ...allInputs[key], value: value } });
    setButtonDisabled(false);
  };

  const handleConfirm = () => {
    const latNumber = allInputs.lat.value === "" ? null : Number(allInputs.lat.value);
    const lonNumber = allInputs.lon.value === "" ? null : Number(allInputs.lon.value);
    addOdourReport(user.id, allInputs.date.value, allInputs.odourLevel.value, allInputs.odourType.value, allInputs.baseLocation.value, latNumber, lonNumber).then((res) => {
      onConfirm && onConfirm(res);
    })
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const handleChangeLocationType = (value) => {
    if (value === "new")
      setAllInputs((prev) => ({
        ...prev,
        locationType: { ...prev.locationType, value },
        lat: { ...prev.lat, required: true },
        lon: { ...prev.lon, required: true },
        baseLocation: { ...prev.baseLocation, required: false },
      }));
    else
      setAllInputs((prev) => ({
        ...prev,
        locationType: { ...prev.locationType, value },
        lat: { ...prev.lat, required: false },
        lon: { ...prev.lon, required: false },
        baseLocation: { ...prev.baseLocation, required: true },
      }));
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("ADD_NEW_ODOUR_SIGNAL")} </h2>
      <DateTimePicker label={handleTranslate("REPORT_DATE")}
        disableFuture
        inputFormat="dd/MM/yyyy hh:mm"
        PopperProps={{ disablePortal: true }}
        onChange={(value) => handleChangeInput("date", value)}
        value={allInputs.date.value}
        renderInput={(params) => (
          <TextField sx={{ width: "100%", maxWidth: "300px" }} required {...params} />
        )} />
      <OdourLevelSelect required value={allInputs.odourLevel.value} onChange={(value) => handleChangeInput("odourLevel", value)} nullLabel={false} />
      <OdourTypeSelect required value={allInputs.odourType.value} onChange={(value) => handleChangeInput("odourType", value)} nullLabel={false} />
      <LocationTypeSelect value={allInputs.locationType.value} onChange={handleChangeLocationType} />
      {allInputs.locationType.value === "new" ?
        <>
          <TextField sx={{ width: "100%", maxWidth: "300px" }} required={allInputs.lat.required} label={handleTranslate(`MEASURE_LAT`, true)} type="number" value={allInputs.lat.value} onChange={(e) => handleChangeInput("lat", e.target.value)} />
          <TextField sx={{ width: "100%", maxWidth: "300px" }} required={allInputs.lon.required} label={handleTranslate('MEASURE_LON', true)} type="number" value={allInputs.lon.value} onChange={(e) => handleChangeInput("lon", e.target.value)} />
        </>
        : allInputs.locationType.value === "existing" && <LocationSelect required={allInputs.baseLocation.required} value={allInputs.baseLocation.value} onChange={(value) => handleChangeInput("baseLocation", value)} />
      }

      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const containerStyle = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#2d3740",
  padding: "1rem",
  borderRadius: "1rem",
  height: "80vh",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column"
}

const AddOdourReportModal = ({ isOpen, defaultLatLon, onClose, onConfirm }) => {

  return (
    <Modal disablePortal open={isOpen}>
      <Box sx={containerStyle}>
        <Box sx={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box sx={{ background: "white", borderRadius: "1rem", height: "100%", overflowY: "auto" }}>
          <FormComponent lat={defaultLatLon?.lat} lon={defaultLatLon?.lon} onConfirm={onConfirm} />
        </Box>
      </Box>
    </Modal>
  );
}

export default AddOdourReportModal;