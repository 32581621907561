import { Box } from "@mui/system";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import { UNIT_TYPE } from "../StationHandling/utils";
import { useSelector } from "react-redux";
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import ShareIcon from '@mui/icons-material/Share';
import { Button } from "@mui/material";
import { shareSingleSensor, updateShareSensor } from "../../app/store/evocsSlice/sensors/sensorsActions";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import { shareSingleCu, updateShareCu } from "app/store/evocsSlice/controlUnits/cuActions";
import { shareSingleStation, updateShareStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import LoadingGif from "../FileManager/LoadingGif";
import { getTenantCus, getTenantSensors, getTenantStations } from "app/store/evocsSlice/tenants/tenantsActions";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const SingleUnit = ({ unitId, unitType, unit, tenantId, selectedTenants, onChange }) => {
  
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };
  const tenants = useSelector((state) => state.tenants.list);
  const [tenantsList, setTenantsList] = useState([]);

  useLayoutEffect(() => {
    if (!tenants) return;
    const newList = Object.keys(tenants).filter((id) => id !== tenantId);
    setTenantsList(newList);
  }, [tenants]);


  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <p style={{ width: "100%", maxWidth: "200px" }}>{unit.serial}</p>
      <TenantSelect tenantsList={tenantsList} placeholder={handleTranslate("SHARE_WITH")} multiple value={selectedTenants} onChange={(value) => onChange(unitId, value)} />
    </div>
  )
}

const SharedUnitsList = memo(({ tenantId, unitType }) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };
  const units = unitType === "stations" ? useSelector((state) => state.stationsMongo.list) : useSelector((state) => state[unitType].list);
  const [shareState, setShareState] = useState({});
  const [canSave, setSave] = useState(false);
  const [updatingLoading, setUpdatingLoading] = useState(false);
  const sharedUnits = useMemo(() => {
    //return Object.keys(units).filter((id) => Object.keys(units[id].sharing).length > 0 && !Object.keys(units[id].sharing).includes(tenantId));
    return Object.keys(units).filter((id) => units[id].owner === tenantId && Object.keys(units[id].sharing).length > 0);
  }, [units]);

  const getDefaultShareState = useCallback(() => {
    if (!units) return {};
    let state = {};
    Object.keys(units).forEach((id) => {
      if (Object.keys(units[id].sharing).length === 0) return;
      state[id] = [...Object.keys(units[id].sharing)];
    });
    return state;
  }, [units])


  useEffect(() => {
    !updatingLoading && setShareState(getDefaultShareState());
  }, [units])


  const handleChangeSingleUnit = (unitId, tenantsId) => {
    setShareState({
      ...shareState,
      [unitId]: tenantsId
    });
    setSave(true);
  };

  const handleSave = () => {
    const units = Object.keys(shareState);
    setUpdatingLoading(true);
    const allFetch = [];
    units.forEach((unitId) => {
      if (unitType === UNIT_TYPE.stations) allFetch.push(updateShareStation(unitId, shareState[unitId]));
      else if (unitType === UNIT_TYPE.controlUnits) allFetch.push(updateShareCu(unitId, shareState[unitId]));
      else allFetch.push(updateShareSensor(unitId, shareState[unitId]))
    });
    Promise.all(allFetch).then(() => {
      setUpdatingLoading(false)
      setSave(false);
    });
  };


  return sharedUnits.length > 0 && (
    <>
      <h3 style={{ marginTop: "2rem", fontWeight: "bold" }}> {handleTranslate('IN_SHARING')} </h3>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {sharedUnits.map((unitId) => (
          <SingleUnit key={unitId} unitId={unitId} unitType={unitType} unit={units[unitId]} tenantId={tenantId} selectedTenants={shareState[unitId]} onChange={handleChangeSingleUnit} />
        ))}
      </div>
      <Button disabled={!canSave} onClick={handleSave}> {handleTranslate('SAVE', true)} </Button>
    </>
  );
});

const SingleUnitType = ({ tenantId, unitType, units, isUserTenant = false }) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };
  const tenants = useSelector((state) => state.tenants.list);
  const [tenantsList, setTenantsList] = useState([]);
  const [unitTypeLabel, setunitTypeLabel] = useState("");
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [shareEnabled, setShareEnabled] = useState(false);
  const unitsList = useMemo(() => {
    if (isUserTenant)
      return Object.keys(units);
    else
      return Object.keys(units).map((unitId) => ({ id: unitId, serial: units[unitId].serial }));
  }, [units, isUserTenant])


  useLayoutEffect(() => {
    if (!tenants) return;
    const newList = Object.keys(tenants).filter((id) => id !== tenantId);
    setTenantsList(newList);
  }, [tenants])

  useLayoutEffect(() => {
    const sharedTenants = selectedUnit ? Object.keys(units[selectedUnit].sharing) : [];

    if (sharedTenants.length > 0)
      setTenantsList(Object.keys(tenants).filter((id) => id !== tenantId && !sharedTenants.includes(id)))
    else setTenantsList(Object.keys(tenants).filter((id) => id !== tenantId))
  }, [selectedUnit]);

  useLayoutEffect(() => {
    setSelectedTenant((prev) => prev.filter((id) => tenantsList.includes(id)));
  }, [tenantsList])

  useLayoutEffect(() => {
    if (selectedUnit && selectedTenant.length > 0) setShareEnabled(true);
    else setShareEnabled(false);
  }, [selectedUnit, selectedTenant]);

  useLayoutEffect(() => {
    let label = "";
    switch (unitType) {
      case UNIT_TYPE.stations:
        label = handleTranslate("STATIONS", true);
        break;
      case UNIT_TYPE.controlUnits:
        label = handleTranslate("CONTROL_UNITS", true);
        break;
      case UNIT_TYPE.sensors:
        label = handleTranslate("SENSORS", true);
        break;
      default:
        label = "";
        break;
    };

    setunitTypeLabel(label);
  }, [unitType, t]);


  const handleShare = () => {
    if (unitType === UNIT_TYPE.stations) shareSingleStation(selectedUnit, selectedTenant).then(() => { setSelectedTenant([]); setSelectedUnit(null) });
    else if (unitType === UNIT_TYPE.controlUnits) shareSingleCu(selectedUnit, selectedTenant).then(() => { setSelectedTenant([]); setSelectedUnit(null) });
    else shareSingleSensor(selectedUnit, selectedTenant).then(() => { setSelectedTenant([]); setSelectedUnit(null) });
  };

  return (
    <AccordionComponent title={unitTypeLabel} titleStyle={{ fontWeight: "bold" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "0.5rem" }}>
        {unitType === UNIT_TYPE.stations ?
          <StationSelect isUserTenant={isUserTenant} stationsId={unitsList} value={selectedUnit} onChange={(value) => setSelectedUnit(value)} />
          : unitType === UNIT_TYPE.controlUnits ?
            <CuSelect isUserTenant={isUserTenant} cusId={unitsList} value={selectedUnit} onChange={(value) => setSelectedUnit(value)} />
            :
            <SensorAutocomplete isUserTenant={isUserTenant} sensorsId={unitsList} value={selectedUnit} onChange={(value) => setSelectedUnit(value)} />
        }
        <ShareIcon sx={{ color: shareEnabled ? "black" : "rgba(0,0,0,.3)" }} />
        <TenantSelect tenantsList={tenantsList} placeholder={handleTranslate('SHARE_WITH')} multiple value={selectedTenant} onChange={setSelectedTenant} />
      </div>
      {shareEnabled && <Button onClick={handleShare}> {handleTranslate('SHARE_WITH')} </Button>}
      <SharedUnitsList tenantId={tenantId} unitType={unitType} />
    </AccordionComponent>
  )
}

const SingleTenant = ({ tenantId }) => {
  const stationsList = useSelector((state) => state.stationsMongo.list);
  const cusList = useSelector((state) => state.controlUnits.list);
  const sensorsList = useSelector((state) => state.sensors.list);

  const tenant = useSelector((state) => state.tenants.list?.[tenantId]);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [stations, setStations] = useState({});
  const [cus, setCus] = useState({});
  const [sensors, setSensors] = useState({});

  useEffect(() => {
    if (tenantId === user.selectedTenant) {
      setStations(stationsList);
      setCus(cusList);
      setSensors(sensorsList);
      return;
    }
    setLoading(true);
    const tenantsArray = [tenantId];
    const fetchData = [
      getTenantStations(tenantsArray).then((data) => data && setStations(data.list)),
      getTenantCus(tenantsArray).then((data) => data && setCus(data.list)),
      getTenantSensors(tenantsArray).then((data) => data && setSensors(data.list))
    ];

    Promise.all(fetchData).then(() => {
      setLoading(false);
    });
  }, []);

  const getUnits = useCallback((unitType) => {
    switch (unitType) {
      case UNIT_TYPE.stations:
        return stations;
      case UNIT_TYPE.controlUnits:
        return cus;
      case UNIT_TYPE.sensors:
        return sensors;
    }
  }, [stations, cus, sensors])

  return (
    <AccordionComponent title={tenant.name}>
      <Box padding={{ xs: "0", md: "1rem 4rem" }}>
        {!loading ? (
          ["stations", "controlUnits", "sensors"].map((type, index) =>
            type !== "cu" && (
              <SingleUnitType key={type} isUserTenant={tenantId === user.selectedTenant} tenantId={tenantId} unitType={UNIT_TYPE[type]} units={getUnits(type)} />
            ))
        ) :
          <LoadingGif />
        }
      </Box>
    </AccordionComponent>
  )
}

const SharingInfo = ({ selectedTenants }) => {

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {selectedTenants.map((tenantId, index) => (
        <SingleTenant key={tenantId} tenantId={tenantId} />
      ))}
    </div>
  );
}

const SharingConfiguration = ({ selectedTenants }) => {
  return (
    <div>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <SharingInfo selectedTenants={selectedTenants} />
      </Box>
    </div>
  )
}

export default SharingConfiguration;