import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
    content: {
        margin: "8px 0",
        '&.Mui-expanded': {
            margin: "8px 0"
        }
    },
}));

const AccordionComponent = ({style, titleStyle, defaultExpanded=false, expanded=undefined, disabled=false, openOnClickIcon=false, title, children, onChange, onClick}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const classes = useStyles();

    useLayoutEffect(() => {
        expanded !== undefined && setIsExpanded(expanded);
    }, [expanded])

    const handleChange = (open) => {
        if(openOnClickIcon) return;
        setIsExpanded(open);
        onChange && onChange(open)
    }

    const handleClickIcon = (e) => {
        e.stopPropagation();
        setIsExpanded((prev) => !prev);
        onChange && onChange(!isExpanded)
    }


    return(
        <Accordion sx={{...style}} disabled={disabled} expanded={isExpanded} onChange={(e, value) => handleChange(value)} onClick={onClick}>
            <AccordionSummary classes={{content: classes.content}} expandIcon={<ExpandMoreIcon onClick={handleClickIcon}/>} >
                {React.isValidElement(title) ? title : <label style={{...titleStyle}}>{title}</label>}
            </AccordionSummary>
            <AccordionDetails sx={{pt: 0}}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordionComponent;