

export const calcIntervalDates = (interv) => {
  if(!interv) throw new Error("interv is required");
  let numberDigits = 0;
  [...interv].some((char) => {
    if(char === "0" || Number(char)) {
      numberDigits ++;
      return false;
    }
    return true;
  });
  const unit = interv.slice(numberDigits);
  const number = Number(interv.slice(0, numberDigits));
  if(!number) return {start: null, end: null};
  const end = new Date();
  let start = null;
  switch(unit) {
    case "mm":
      // start = end - (number * 1000);
      start = end - (number * 60000);
      break;
    case "h":
      start = end - (number * 3600000);
      break;
    case "d":
      start = end - (number * 86400000);
      break;
    case "m":
      start = end - (number * 2629743000);
      break;
  }
  return {start, end: end.valueOf()};
};

export const timeRangeInterval = [
  {
    value: "Ultime 3 ore",
    maxRangeValue: 3,
    id : 0,
    isHour : true,
  },
  {
    value: "Ultime 24 ore",
    maxRangeValue: 24,
    id : 1,
    isHour : true,
  },
  {
    value: "Ultime 48 ore",
    maxRangeValue: 48,
    id : 2,
    isHour : true,
  },
  {
    value: "Ultimi 7 giorni",
    maxRangeValue: 7*24,
    id : 3,
    isHour : true,
  },
  {
    value: "Ultimi 15 giorni",
    maxRangeValue: 15*24,
    id : 4,
    isHour : true,
  },
  {
    value: "Ultimi 30 giorni",
    maxRangeValue: 30*24,
    id : 5,
    isHour : true,
  },
  {
    value: "Personalizzato",
    maxRangeValue: null,
    id : 6,
    isHour: true,
  },
];

export const TIME_FORMAT = {
  DATE: "date",
  HOUR: "hour",
  DATE_AND_HOUR: "date_and_hour",
  DATE_AND_HOUR_SECONDS: "date_and_hour_seconds",
  CSV_DATE: "csv_date",
  COMPLETE_DATE: "complete_date",
  ISO_DATE: "iso_date",
  ISO_DATE_TIME: "iso_date_time"
};

export const modeStructure = [
  {
    value: "voc",
    selected: false,
    loaded: false,
  },
  {
    value: "gas",
    selected: false,
    loaded: false,
  },
  {
    value: "meteo",
    selected: false,
    loaded: false,
  },
  {
    value: "TILESTATION",
    selected: false,
    loaded: false,
  },
];

export const gasMoleculeStructure = {
  BENZENE: {
    selected: true,
    color: "#EAC435",
  },
  ETILBENZENE: {
    selected: false,
    color: "#03CEA4",
  },
  TOLUENE: {
    selected: false,
    color: "#FB4D3D",
  },
  XYLENE: {
    selected: false,
    color: "#CA1551",
  },
};

// export const tileMoleculeStructure = {

// };


export const formatDate = (date, timeFormat=null) => {
  const day = date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate();
  const month = date?.getMonth()+1 < 10  ? `0${date?.getMonth()+1}` : date?.getMonth()+1;
  const hour = date?.getHours() < 10 ? `0${date?.getHours()}` : date?.getHours();
  const minutes = date?.getMinutes() < 10 ? `0${date?.getMinutes()}` : date?.getMinutes();
  const seconds = date?.getSeconds() < 10 ? `0${date?.getSeconds()}` : date?.getSeconds(); // Aggiunto per i secondi
  const year = date?.getFullYear();


  switch(timeFormat) {
    case TIME_FORMAT.HOUR:
      return `${hour}:${minutes}`;
    case TIME_FORMAT.DATE:
     return `${day}/${month}`;
    case TIME_FORMAT.DATE_AND_HOUR:
      return `${day}/${month}\n${hour}:${minutes}`;
    case TIME_FORMAT.DATE_AND_HOUR_SECONDS:
      return `${day}/${month}\n${hour}:${minutes}:${seconds}`;
    case TIME_FORMAT.COMPLETE_DATE:
      return `${day}/${month}/${year} ${hour}:${minutes}`;
    case TIME_FORMAT.CSV_DATE:
      return `${day}_${month}_${year}_${hour}_${minutes}_${seconds}`;
    case TIME_FORMAT.ISO_DATE:
        return `${year}-${month}-${day}`;
    case TIME_FORMAT.ISO_DATE_TIME:
        return `${year}-${month}-${day}T${hour}:${minutes}`;
    default:
      return `${day}/${month}/${year}`;
  }
};

export const HEALTH_STATE = {
  bad: "bad",
  warning: "warning",
  good: "good"
};

export const UNIT_TYPE = {
  stations: "stations",
  station: "station",
  cu: "controlUnits",
  controlUnits: "controlUnits",
  sensors: "sensors",
  sensor: "sensor"
};

export const STATION_TYPE = {
  vessel: "vessel",
  buoy: "buoy",
  nest: "nest",
  // city: "city",
  // hospital: "hospital"
};

export const STATION_TYPE_LABELS = {
  vessel: "Nave",
  buoy: "Boa",
  nest: "Nido",
  // city: "Città",
  // hospital: "Ospedale"
};

export const PROJECT_TYPE = {
  smart_buoy: "smart_buoy",
  // smart_nest: "smart_nest",
  tilestation: "tilestation",
};

export const PROJECT_TYPE_LABELS = {
  smart_buoy: "Smart buoy",
  // smart_nest: "Smart nest",
  tilestation: "Tilestation",
};

export const STATION_DATA_TYPE = {
  voc: "voc",
  gas: "gas",
  meteo: "meteo",
  wr: "wr",
  ws: "ws",
  temp: "temp",
  wd: "wd",
  pres: "pres",
  rh: "rh",
  pp: "pp",
  sun: "sun",
  bvoc: "bvoc",
  co2: "co2",
  pm: "pm",
  pm25: "pm25",
  wave: "wave",
  water_colums: " water_colums",
};

export const STATION_DATA_TYPE_LABELS = {
  voc: "VOC",
  gas: "GAS",
  meteo: "METEO",
  wr: "Rosa dei venti",
  ws: "Velocità vento",
  temp: "Temperatura",
  wd: "Direzione vento",
  pres: "Pressione",
  rh: "Umidità",
  pp: "Precipitazioni",
  sun: "Radiazione solare",
  bvoc: "Bvoc",
  co2: "CO2",
  pm: "Pm",
  pm25: "Pm25",
  wave: "Wave",
  water_colums: "Water colums",
};

export const FILTER_STATION_DATA_TYPE = ["voc", "gas", "meteo", "bvoc", "co2", "pm25"];



export const defaultId = 1;
export const defaultIntervalLabel = timeRangeInterval[defaultId];

export const hexToRGB = hex => {
  try {
    hex = hex.replace("#", "");
    return [
        parseInt(hex.substring(0, 2), 16).toString(),
        parseInt(hex.substring(2, 4), 16).toString(),
        parseInt(hex.substring(4, 6), 16).toString()
    ];    
  } catch (error) {
    return undefined;
  }
};

export const defaultHexColor = "#242a30";
export const defaultColorArray = ["36", "42", "48"];
export const defaultTooltipBackAplha = 0.9;

export const convertHexToRGBA = (hexColor, alpha=1) => {
  if (!hexColor) return;
  const rgbaDefaultString = `rgba(${defaultColorArray[0]}, ${defaultColorArray[1]}, ${defaultColorArray[2]}, ${alpha})`;
  if (!hexColor) return rgbaDefaultString;
  const colorArray = hexToRGB(hexColor);
  if (!colorArray || !alpha || colorArray.length !== 3 ) return rgbaDefaultString;
  return `rgba(${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]}, ${alpha})`;
};