import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    ENTER_LATITUDE: "Inserisci latitudine",
    ENTER_LONGITUDE: "Inserisci longitudine",
    ENTER_VALID_COORDINATES: "Inserisci una coordinata valida",
    LOCATION_NAME : "Nome posizione",
    ENTER_LOCATION_NAME: "Inserisci il nome della posizione",
    ADD_NEW_LOCATION: "Aggiungi una nuova posizione",
};

const LOCATION_INPUT_COMPONENT_IT = createLanguageObject(IT, sharingComponentNames.LOCATION_INPUT_COMPONENT);

export default LOCATION_INPUT_COMPONENT_IT;