

const ADD_ODOUR_REPORT_MODAL_EN = {
    MODALS_ADD_NEW_ODOUR_SIGNAL: "Add new odor alert",
    MODALS_REPORT_DATE: "Report date",
};

const DELETE_TENANT_MODAL_EN = {
    MODALS_TENANT_SUCCESSFULLY_ELIMINATED: "Tenant successfully eliminated",
};

const CREATE_CU_MODAL_EN = {
    MODALS_NEW_STATION: "New station",
    MODALS_ADD_DEFAULT_POSITION: "Add a default position",
};

const CREATE_SENSOR_MODAL_EN = {
    MODALS_NEW_SENSOR: "New sensor",
    MODALS_THRESHOLDS_SETTINGS: "Threshold settings",
    MODALS_SET_THRESHOLDS_ON: "Set thresholds on",
};

const ADD_MEASURE_TO_SENSOR_MODAL_EN = {
    MODALS_SELECT_SENSORS: "Select sensors",
    MODALS_TO_SENSORS: "to sensors",
};

const SENSOR_CONFIGURATION_MODAL_EN = {
    MODALS_SET_THRESHOLDS_ON: "Set thresholds on",
    MODALS_THRESHOLDS_MANAGEMENT: "Threshold management",
};

const STATION_CONFIGURATION_MODAL_EN = {
    MODALS_INSTALLED: "Installed",
};

const CREATE_TENANT_MODAL_EN = {
    MODALS_NEW_TENANT: "New tenant",
};

const HISTORY_MODALS_EN = {
    MODALS_HIST_ERR_1: 'The serial number cannot be null',
    MODALS_HIST_ERR_2: 'The "Start Date" field cannot be null',
    MODALS_HIST_ERR_3: 'The "End Date" field cannot be null',
    MODALS_HIST_ERR_4: 'The end date cannot be earlier than the start date',
    MODALS_SENSOR_HIST_INSTALLED: 'Currently installed',
};

const EXPIRED_TOKEN_MODAL_EN = {
    MODALS_SESSION_EXPIRED: "Session expired",
    MODALS_GO_TO_LOGIN: "Please login again",
};

// const ERROR_MODAL_EN = {}


const SUCCESS_MODAL_EN = {
    MODALS_DEFAULT_TITLE: "Action performed successfully",
    MODALS_SUCCESS: "Success",
};

const CREATE_USER_MODAL_EN = {
    MODALS_NEW_USER: "New users",
};

const MODAL_COMPONENTS_EN = {
    ...SUCCESS_MODAL_EN,
    ...EXPIRED_TOKEN_MODAL_EN,
    ...HISTORY_MODALS_EN,
    ...CREATE_TENANT_MODAL_EN,
    ...STATION_CONFIGURATION_MODAL_EN,
    ...SENSOR_CONFIGURATION_MODAL_EN,
    ...ADD_MEASURE_TO_SENSOR_MODAL_EN,
    ...ADD_ODOUR_REPORT_MODAL_EN,
    ...DELETE_TENANT_MODAL_EN,
    ...CREATE_CU_MODAL_EN,
    ...CREATE_SENSOR_MODAL_EN,
    ...CREATE_USER_MODAL_EN,
};

export default MODAL_COMPONENTS_EN;