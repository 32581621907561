
const STATION_TYPE = {
	STATION_TYPE_VESSEL : "Vessel",
	STATION_TYPE_BUOY : "Buoy",
	STATION_TYPE_NEST : "Nest",
	STATION_TYPE_CAR : "Vehicle",
};

const SENSOR_TYPE = {
	SENSOR_TYPE_AIR : "Air quality",
	SENSOR_TYPE_WATER : "Water quality",
	SENSOR_TYPE_GROUND : "Ground quality",
	SENSOR_TYPE_BATTERY : "Battery",
	SENSOR_TYPE_GPS : "Gps",
	SENSOR_TYPE_CAMERA : "Camera",
};


const TIMERANGE = {
  TIMERANGE_LIVE: "Live (last hour)",
  TIMERANGE_1H: "Last hour",
  TIMERANGE_2H: "Last 2 hours",
  TIMERANGE_3H: "Last 3 hours",
  TIMERANGE_6H: "Last 6 hours",
  TIMERANGE_12H: "Last 12 hours",
  TIMERANGE_1D: "Last day",
  TIMERANGE_2D: "Last 2 days",
  TIMERANGE_7D: "Last 7 days",
  TIMERANGE_15D: "Last 15 days",
  TIMERANGE_30D: "Last 30 days",
  TIMERANGE_2M : "Last 2 months",
  TIMERANGE_3M : "Last 3 months",
  TIMERANGE_6M : "Last 6 months",
  TIMERANGE_2MM : "2 minutes",
  TIMERANGE_5MM : "5 minutes",
  TIMERANGE_10MM : "10 minutes",
  TIMERANGE_CUSTOM: "Custom",
  "TIMERANGE_*": "Custom",
};

const ODOUR_LEVEL = {
  ODOUR_LEVEL_HIGH: "High",
  ODOUR_LEVEL_MEDIUM: "Medium",
  ODOUR_LEVEL_LOW: "Low",
  ODOUR_LEVEL_ABSENT: "Absent",
};

const ODOUR_TYPE = {
  ODOUR_TYPE_HYDROCARBONS: 'Hydrocarbons',
  ODOUR_TYPE_ROASTING: 'Roasting',
  ODOUR_TYPE_SEWER: 'Sewer',
  ODOUR_TYPE_OTHER: 'Other',
  ODOUR_TYPE_WASTE: 'Waste',
  ODOUR_TYPE_ROTTEN_EGGS: 'Rotten Eggs',
  ODOUR_TYPE_GAS: 'Gas',
  ODOUR_TYPE_BURNT: 'Burnt',
  ODOUR_TYPE_AMMONIA: 'Ammonia',
  ODOUR_TYPE_ROTTEN_FISH: 'Rotten Fish',
  ODOUR_TYPE_PUNGENT_ACRID: 'Pungent Acrid',
  ODOUR_TYPE_MANURE: 'Manure',
  ODOUR_TYPE_SOLVENT: 'Solvent',
  ODOUR_TYPE_PUTRID: 'Putrid',
  ODOUR_TYPE_YEAST_LIKE: 'Yeast-like',
};

const MEASURE = {
  MEASURE_CO2: "Carbon dioxide",
  MEASURE_NO2: "Nitrogen dioxide",
  MEASURE_O3: "Ozone",
  MEASURE_VOC: "Volatile organic compounds (VOC)",
  MEASURE_BVOC: "Breathable VOC (BVOC)",
  MEASURE_TEMP: "Temperature",
  MEASURE_TEMP_SCD: "Temperature scd",
  MEASURE_WS: "Wind speed",
  MEASURE_WD: "Wind direction",
  MEASURE_PP: "Precipitation",
  MEASURE_SOLAR: "Solar energy",
  MEASURE_C6H6: "Benzene",
  MEASURE_TOLUENE: "Toluene",
  MEASURE_ETHYLBENZENE: "Ethylbenzene",
  MEASURE_XILENE: "Xylene",
  MEASURE_PH: "pH",
  MEASURE_O2_DISC: "Dissolved oxygen",
  MEASURE_COND_EL: "Electrical conductivity",
  MEASURE_WATER_TEMP: "Water temperature",
  MEASURE_PRES: "Atmospheric pressure",
  MEASURE_MAX_WAVE_HEIGHT: "Maximum wave height",
  MEASURE_SIGN_WAVE_HEIGHT: "Significant wave height",
  MEASURE_AVG_WAVE_HEIGHT: "Average wave height",
  MEASURE_PEAK_PERIOD: "Peak wave period",
  MEASURE_MEAN_WAVE_DIRECTION: "Mean wave direction",
  MEASURE_MEAN_SPREAD: "Mean wave spread",
  MEASURE_WAVE_SPECTRA32: "Wave spectrum 32",
  MEASURE_WAVE_SPECTRA64: "Wave spectrum 64",
  MEASURE_WAVE_SPECTRA128: "Wave spectrum 128",
  MEASURE_GAS_RES: "Gas electrical resistance",
  MEASURE_PM25: "Particulate 2.5",
  MEASURE_RH: "Relative humidity",
  MEASURE_RH_SCD: "Relative humidity scd",
  MEASURE_GAS_PERC: "Gas percentage",
  MEASURE_IAQ: "Air quality index",
  MEASURE_NO: "Nitric oxide",
  MEASURE_NOX: "Nitrogen oxides (NOx)",
  MEASURE_PM10: "Particulate 10",
  MEASURE_CO: "Carbon monoxide",
  MEASURE_SO2: "Sulfur dioxide",
  MEASURE_RAIN_INT: "Rain intensity",
  MEASURE_TOT_RAIN: "Total rainfall",
  MEASURE_MSL: "Water level",
  MEASURE_SALINITY: "Salinity",
  MEASURE_SOUND: "Sound waves",
  MEASURE_BATTERY_STATUS: "Battery status",
  MEASURE_BATTERY_CURRENT: "Battery current",
  MEASURE_CHARGING_STATUS: "Charging status",
  MEASURE_DISCHARGING_STATUS: "Battery discharging status",
  MEASURE_LAT: "Latitude",
  MEASURE_LON: "Longitude",
  MEASURE_PM1: "Particulate 1",
  MEASURE_ALT: "Altitude",
  MEASURE_BATTERY_SOC: "Battery state of charge",
  MEASURE_BATTERY_VOLTAGE: "Battery voltage",
  MEASURE_LOAD_CURRENT: "Load current",
  MEASURE_LOAD_VOLTAGE: "Load voltage",
  MEASURE_PV_VOLTAGE: "Solar panel voltage",
  MEASURE_PV_CURRENT: "Solar panel current",
  MEASURE_SPEC_SEA_WATER_GRAVITY: "Specific gravity of seawater",
  MEASURE_TOTAL_DISSOLVED_SOLID: "Total dissolved solids",
  MEASURE_TURBIDITY: "Turbidity",
  MEASURE_BASE_TURBIDITY: "Base turbidity",
  MEASURE_DISSOLVED_OXYGEN_PERC: "Dissolved oxygen [% air saturation]",
  MEASURE_ELEC_CONDUCTIVITY_25: "Electrical conductivity at 25 degrees Celsius",
  MEASURE_ELEC_CONDUCTIVITY_20: "Electrical conductivity at 20 degrees Celsius",
  MEASURE_PROBE_DEPTH: "Probe immersion depth",
  MEASURE_ELECTRICAL_RESISTIVITY: "Electrical resistivity",
  MEASURE_REDOX_POTENTIAL: "RedOx potential",
  MEASURE_SATELLITI: "Number of satellites",
  MEASURE_WINDROSE: "Wind rose",
  MEASURE_VIDEO: "Video",
  MEASURE_IMAGE: "Photos / Images",
  MEASURE_AVG_PERIOD: "Average period",
  MEASURE_WIND_CHILL: "Wind chill",
  MEASURE_UV: "UV radioations",
  MEASURE_DEW_P: "Dew point",
  MEASURE_UNDERWATER_NOISE: "Underwater noise",
  MEASURE_FREQ_END: "Final frequency ",
  MEASURE_FREQ_BEGIN: "Initial frequency",
  MEASURE_FREQ_MEAN: "Mean frequency",
  MEASURE_FREQ_MIN: "Minimum frequency",
  MEASURE_FREQ_MAX: "Maximum frequency",
  MEASURE_CLICK_NUMBER: "Click number",
};


export const MONGO_CONSTANTS_EN = {
    ...TIMERANGE,
    ...ODOUR_LEVEL,
    ...ODOUR_TYPE,
    ...MEASURE,
    ...STATION_TYPE,
    ...SENSOR_TYPE,
};
