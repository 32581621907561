import BrowserRouter from "@fuse/core/BrowserRouter";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import themeLayouts from "app/theme-layouts/themeLayouts";
import { selectMainTheme } from "src/app/store/fuse/settingsSlice";
import withAppProviders from "./withAppProviders";
import { AuthProvider } from "./auth/AuthContext";

/* 

CodeInfo: Il codice è così strutturato per sommi capi, entreremo nel dettaglio all'interno delle singole View / Componente / File

Cartelle esistenti: 

                  - app: In app troveremo tutti i file gestiti dal template, di cui quelli più importanti sono: 
                        - "auth" per la gestione delle autorizzazioni (qui troveremo il service per il JWT)
                        - "configs" per la configurazione dell'applicazione stessa (da non toccare a meno di routesConfig/navigationConfig per l
                          aggiunta / rimozione delle rotte)
                        - "hooks" qui troveremo degli hooks che ho creato al fine di semplificare lavoro svolto da molti componenti
                        - "main" contiene sempre file del template per la gestione dell'applicazione (da non toccare)
                        - "store" che contiene le informazioni messe a disposizione da Redux, ossia lo store centrale della nostra applicazione (più informazioni più avanti)

                  - components: Questa cartella contiene al suo interno tutti i Custom Component creati al fine di renderizzare le varie Views
                  - mockData: Questa cartella contiene dati di mock per il progetto, molto utile per le Views / Componenti che non hanno
                              ancora dati effettivi da poter utilizzare
                  - styles: Contiene i file stilistici del template, più il file app-base.css che viene utilizzato anche dagli altri componenti
                            come punto di modifica dello stile del progetto
                  - views: Questa cartella invece contiene le "Views" ossia i file che vengono agganciati ai vari endpoint e renderizzati
                          questi sfruttano i components al dì sotto (molto spesso una View utilizza i file che si trovano sotto
                          components in particolare all'interno di una cartella con lo stesso nome della View)

File importanti: 

              - i18n.js: Gestisce la lingua e le traduzioni
              - index.js: Punto di ingresso di ogni applicazione React, nonchè dichiarazione del tipo di endpoint utilizzato
                          sulla base dello script eseguito
              - .env.development.js: File d'ambiente per la fase di sviluppo
              - .env.stage.js: File d'ambiente per la fase di produzione 
              - .env.test.js: File d'ambiente per la fase di test

             
Come usare l'applicazione: 

              - Per poter semplicemente eseguire l'applicazione bisogna digitare il comando "npm start" il quale di default sfrutterà
                l'ambiente di sviluppo ed il proxy per effettuare correttamente le chiamate
              - Per poter buildare l'applicazione invece bisognerà utilizzare il comando "npm run build:stage" il quale produrrà
                una cartella di build da deployare sul server

*/

/* 

  CodeInfo: Ogni View è strutturata come: 

                            <FusePageCarded
                                header={<UnitManagementHeader />}
                                content={<UnitManagementContent />}
                            />
  
  Il componente FusePageCarded del Template permette di creare una pagina con le props Header (la parte al dì sopra) e Content (la parte al di
    sotto, il vero contenuto della pagina)

*/

/* CodeInfo: Rimango disponibile per qualsiasi chiarimento sulla struttura del codice nonchè consiglio */

const App = () => {
  const mainTheme = useSelector(selectMainTheme);
  return (
    <>
      <FuseTheme theme={mainTheme}>
        <AuthProvider>
          <BrowserRouter>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{
                containerRoot:
                  "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
              }}
            >
              <FuseLayout layouts={themeLayouts} />
            </SnackbarProvider>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </>
  );
};

export default withAppProviders(App)();
