import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDrag } from "react-dnd";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import LoadingGif from "../FileManager/LoadingGif";
import useMobile from "src/app/hooks/useMobile";
import { useSelector } from "react-redux";


const MeasureListItemMenu = ({ style, measure, onClickItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (itemAction, actionCallback = null) => {
    handleClose();
    onClickItem && onClickItem(itemAction, measure, actionCallback);
  };

  const stopPropagation = (e) => {
    e.stopPropagation()
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };


  return (
    <div style={{ ...style, width: "fit-content" }} onClick={stopPropagation}>
      <IconButton sx={style} onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClickItem("add-measure")}> {handleTranslate("ADD_ON")} </MenuItem>
      </Menu>
    </div>
  )
}

const MeasureListItem = ({ enabledActions, disableDrag, measure, onDrag, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const canDrag = useMemo(() => {
    return !disableDrag && enabledActions?.includes("manage-device")
  }, [enabledActions, disableDrag])
  const [{ isDragging }, drag] = useDrag({
    type: "measureList",
    item: { unit: measure },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: canDrag
  }, [measure]);

  useEffect(() => {
    if (!onDrag) return;
    if (isDragging) onDrag(measure);
    else onDrag(null);
  }, [isDragging])

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <div ref={drag} className="station-item-list close" style={{ display: "flex", padding: "1rem 0rem", alignItems: "center", borderTop: "1px solid grey", cursor: canDrag ? "grab" : "default" }}>
      {canDrag && <DragIndicatorIcon className="drag-icon" fontSize="small" />}
      <span style={{ fontWeight: "bold" }}>{handleTranslate(`MEASURE_${measure.measure.toUpperCase()}`, true)}</span>
      {enabledActions.includes("manage-device") && <MeasureListItemMenu style={{ marginLeft: "auto" }} measure={measure} onClickItem={onClickItemMenu} />}
    </div>
  )
}

const MeasuresList = memo(({list, enabledActions, onDragMeasure, onClickItemMenu }) => {
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const [xs, sm] = useMobile();
  const disableDrag = useMemo(() => xs || sm);

  const getMeasureElement = (measureId) => {
    return { id: measureId, type: "measure", ...measures[measureId] }
  }

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      {list?.map((measureId) => (
        <MeasureListItem key={measureId} enabledActions={enabledActions} disableDrag={disableDrag} measure={getMeasureElement(measureId)} onDrag={onDragMeasure} onClickItemMenu={onClickItemMenu} />
      ))}
    </div>
  )
});


const MeasuresManagement = ({ loading, enabledActions, onDragMeasure, onClickItemMenu }) => {
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const [filteredMeasures, setFilteredMeasures] = useState();
  const [visibleMeasures, setVisibleMeasures] = useState();

  useEffect(() => {
    setVisibleMeasures(() => {
      if (!filteredMeasures || filteredMeasures.length === 0) return Object.keys(measures);
      return filteredMeasures;
    });
  }, [measures, filteredMeasures])


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", height: "100%", overflowX: "visible" }}>
      {!loading ?
        <>
          <div style={{ display: "flex" }}>
            {/* <SensorAutocomplete multiple limitTags={1} value={filteredMeasures} onChange={setFilteredMeasures} /> */}
            {/* {enabledActions.includes("manage-device") && <Button sx={{ ml: "auto" }} onClick={() => setIsModalOpen(true)}>+ {handleTranslate("NEW_MEASURE")}</Button>} */}
          </div>
          <MeasuresList enabledActions={enabledActions} list={visibleMeasures} onDragMeasure={onDragMeasure} onClickItemMenu={onClickItemMenu} />
        </>
        :
        <LoadingGif />
      }
    </div>
  )
}

export default MeasuresManagement;
