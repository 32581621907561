import React from 'react'
import WithAuth from './WithAuth';
import UserProfileContent from 'src/components/UserProfile/UserProfileContent';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { viewNames } from 'app/configs/routesNames';
import UserProfileHeader from 'src/components/UserProfile/UserProfileHeader';


const viewName = viewNames.USER_PROFILE_VIEW;

const UserProfile = () => {
  return (
    <WithAuth
      viewName={viewName}
      component={
        <FusePageCarded
        header={<UserProfileHeader />}
        content={<UserProfileContent />}
      />
      }
    />
  );
}

export default UserProfile