import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const Root = styled("div")(({ theme }) => ({
  "& .username, & .email": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  "& .avatar": {
    background: theme.palette.background.default,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    "& > img": {
      borderRadius: "50%",
    },
  },
}));

function UserNavbarHeader(props) {
  return (
    <Root className="user relative flex flex-col items-center justify-center p-2 pb-2 shadow-0">
      <div className="flex items-center justify-center mb-24">
        <Avatar
          sx={{
            backgroundColor: "background.paper",
            color: "text.secondary",
          }}
          className="avatar text-16 font-bold w-64 h-64"
          src={user?.data?.photoURL}
          alt={user?.data?.displayName}
        >
          {user?.data?.displayName.charAt(0)}
        </Avatar>
      </div>
      <Typography className="username text-14 whitespace-nowrap font-medium">
        {user?.data?.displayName}
      </Typography>

      {/* <Typography
        className="email text-13 whitespace-nowrap font-medium"
        color="text.secondary"
      >
        {user.data.email}
      </Typography> */}
    </Root>
  );
}

export default UserNavbarHeader;
