import { useState, useEffect } from "react";

const useMobile = () => {
  const [xs, setXS] = useState(false);
  const [sm, setSM] = useState(false);
  const [md, setMD] = useState(false);
  const [lg, setLG] = useState(false);
  const [xl, setXL] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const setMobile = () => {
      if(window.innerWidth >= 1536) {
        setXL(true);
        setXS(false);
        setSM(false);
        setMD(false);
        setLG(false);
      }else if(window.innerWidth >= 1200) {
        setLG(true);
        setXS(false);
        setSM(false);
        setMD(false);
        setXL(false);
      }else if(window.innerWidth >= 900) {
        setMD(true);
        setXS(false);
        setSM(false);
        setLG(false);
        setXL(false);
      }else if(window.innerWidth >= 600) {
        setSM(true);
        setXS(false);
        setMD(false);
        setLG(false);
        setXL(false);
      }else {
        setXS(true);
        setSM(false);
        setMD(false);
        setLG(false);
        setXL(false);
      }
      setCompleted(true);
    }
    setMobile();
    window.addEventListener("resize", setMobile);

    return () => {
      window.removeEventListener("resize", setMobile);
    }
  }, []);
  return completed ? [xs, sm, md, lg, xl] : [];
};

export default useMobile;
