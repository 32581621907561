import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiTimestamp } from "src/utilities/utils";

const readData = createAsyncThunk(
  "dataset/readData",
  async (payload) => {
    const {
      start,
      end,
      sensorIds,
      cuIds,
      stationIds,
    } = payload;
    const data = await axios.post("/api/py/readData", {
      start_date: start,
      end_date: end,
      sensorIds: sensorIds,
      cuIds: cuIds,
      stationIds: stationIds,
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const calcGraphPoints = createAsyncThunk(
  'dataset/graphData',
  async (payload) => {
    const { sensorIds, start, end, pointNumber, requestStart } = payload;
    // const data = await axios.post("/api/py/newCalcGraphPoints", {
    const data = await axios.post("/api/py/calcGraphPoints", {
      sensorIds: sensorIds,
      start_date: getApiTimestamp(start),
      end_date: getApiTimestamp(end),
      point_number: pointNumber,
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return { requestStart, data };
  }
);

const newGraphPoints = createAsyncThunk(
  'dataset/newGraphPoints',
  async (payload) => {
    const { sensorIds, start, end, pointNumber, requestStart } = payload;
    const data = await axios.post("/api/py/newGraph", {
      sensorIds: sensorIds,
      start_date: start,
      end_date: end,
      point_number: pointNumber,
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return { requestStart, data };
  }
);

const readGraphPoints = createAsyncThunk(
  'dataset/readGraphPoints',
  async (payload) => {
    const { sensorIds, start, end, pointNumber, requestStart } = payload;
    const data = await axios.post("/api/py/readGraphPoints", {
      sensorIds: sensorIds,
      start_date: start,
      end_date: end,
      point_number: pointNumber,
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return { requestStart, data };
  }
);

const getWindRoseData = createAsyncThunk(
  'dataset/getWindRoseData',
  async (payload) => {
    const { sensorIds, start, end } = payload;
    const data = await axios.post("/api/py/windRose", {
      sensorIds: sensorIds,
      start_date: start,
      end_date: end,
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const getMinMaxAvg = createAsyncThunk(
  'dataset/readMinMaxAvg',
  async (payload) => {
    const { stationIds, sensorIds, start, end } = payload;
    const data = await axios.post("/api/py/readMinMaxAvg", {
      stationIds: stationIds,
      sensorIds: sensorIds,
      start_date: start,
      end_date: end,
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);




const datasetSlice = createSlice({
  name: "dataset",
  initialState: {
    list: {},
    loading: false,
    windRoseLoading: false,
    lastRequest: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setWindRoseLoading: (state, action) => {
      state.windRoseLoading = action.payload;
    },
    setLastRequest: (state, action) => {
      state.lastRequest = action.payload;
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(readData.fulfilled, (state, action) => {
    //     state.list = action.payload;
    // });
    builder.addCase(calcGraphPoints.fulfilled, (state, action) => {
      const { requestStart, data } = action.payload;
      if (requestStart >= state.lastRequest) {
        state.list = {};
        data && Object.keys(data).forEach((stationId) => {
          state.list[stationId] = data[stationId];
        })
        state.loading = false;
      }
      // Object.keys(action.payload).forEach((sensorId) => {
      //   state.list[sensorId] = {
      //     ...state.list[sensorId],
      //     ...action.payload[sensorId]
      //   }
      // })
    });


    builder.addCase(newGraphPoints.fulfilled, (state, action) => {
      const { requestStart, data } = action.payload;
      console.log('dentro newGraphPoints fulfilled');
      if (requestStart >= state.lastRequest) {
        state.list = data;
      }
    });



    builder.addCase(getWindRoseData.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach((sensorId) => {
        state.list[sensorId] = {
          ...state.list[sensorId],
          ["windrose"]: action.payload[sensorId]
        }
      })
    });
    // builder.addCase(getMinMaxAvg.fulfilled, (state, action) => {
    //     state.list = action.payload;
    // });
  }
});

export const datasetSliceActions = {
  ...datasetSlice.actions,
  readData,
  calcGraphPoints,
  newGraphPoints,
  readGraphPoints,
  getWindRoseData,
  getMinMaxAvg
}

export default datasetSlice.reducer;