import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const getOpenData = createAsyncThunk(
  "openData/getOpenData",
  async (payload) => {
    const {startDate, endDate, sources, stationIds, fields, typeStation} = payload;
    // possible typeStation values = ["AQ", "METEO"]
    // possible source values = ["EEA", "mistral", "prot_civ", "Arpa-Puglia", "rmn"]
    // possible field values = ["C6H6", "03", "NO", "NO2", "NOX", "PM2.5", "PM10", "CO2", "CO", "SO2", "rh", "temp", "rain_int", "tot_rain", "wd", "ws"]
    const data = await axios
      .post("/api/py/readOpenData", {
        start_date: startDate,
        end_date: endDate,
        sources: sources,
        stationIds: stationIds,
        fields: fields, 
        typeStation: typeStation
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        return [];
      });
    return data;
  }
);

const getOpenDataMarkers = createAsyncThunk(
    "openData/getOpenDataMarkers",
    async (payload, { dispatch, getState }) => {
      const {source, type} = payload;
      // console.log(payload)
      // possible source values = ["EEA", "mistral", "prot_civ", "arpa", "rmn"]
      // possible type values = ["METEO", "AQ"]
      const params = {source: source, type: type, };
      const data = await axios
        .get("/api/py/get_mdb_stations", {params: params})
        .then((res) => {
          return res.data;
          //res.data.value[0] || []
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
      return data;
    }
);

const getLastRelevations = createAsyncThunk(
  "openData/getLastRelevations",
  async (payload) => {
    const {lastDate, stationId} = payload;
      const data = await axios
        .get("/api/py/last_relevations", {params: {
          date_last: lastDate, 
          station: stationId
        }})
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log("Error: ", error);
          throw error;
        });
      return data;
  }
)

const openDataSlice = createSlice({
    name: "openData",
    initialState: {
        data: {},
        lastUpdate: null,
        loading: false
    },
    reducers: {
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
      setInterval: (state, action) => {
        const {data, interval} = action.payload;
        state.data[interval] = data;
      },
      setLastUpdate: (state, action) => {
        state.lastUpdate = action.payload;
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getOpenData.fulfilled, (state, action) => {
          
        });
    }
});

export const openDataSliceActions = {
  getOpenData, 
  getOpenDataMarkers,
  getLastRelevations,
  ...openDataSlice.actions
};

export default openDataSlice.reducer;