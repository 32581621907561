import {
  Modal,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useMemo, useCallback } from "react";
import LoadingGif from "../FileManager/LoadingGif";
import { updateCu } from "app/store/evocsSlice/controlUnits/cuActions";
import TabsComponent from "../SharingComponents/TabsComponent";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import EditIcon from "@mui/icons-material/Edit";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import CuHistoryChip from "./CuHistoryChip";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { useSelector } from "react-redux";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";

const styles = {
  containerStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "1200px",
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};

const requiredInputs = ["serial", "owner"];

const ConfigurationChip = ({ enabledActions, cuId, onPending, onFinally, onChangeInputs }) => {
  const { t } = useTranslation("evocs");
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const [disableEdit, setDisableEdit] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [changedInputs, setChangedInputs] = useState([]);
  const deviceParent = controlUnits[cuId]?.deviceParent;
  const getDefaultInputs = useCallback(() => {
    if (!controlUnits[cuId]) return {};
    return {
      serial: controlUnits[cuId].serial || "",
      available: controlUnits[cuId].available === 1 ? true : false,
      owner: controlUnits[cuId].owner || "",
      station: deviceParent?.device,
      sensors: controlUnits[cuId].list_sensors
    }
  }, []);
  const [inputs, setInputs] = useState(getDefaultInputs());

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const sensorOptions = useMemo(() => {
    if (!controlUnits || !sensors) return [];
    const options = [];
    Object.keys(sensors).forEach((sensorId) => {
      if (controlUnits[cuId].list_sensors.includes(sensorId) || !sensors[sensorId].deviceParent)
        options.push(sensorId);
    });
    return options;
  }, [controlUnits, sensors]);


  useEffect(() => {
    if (changedInputs.length === 0) { setDisableConfirm(true); return; }
    const disabled = Object.keys(inputs).some((key) => (requiredInputs.includes(key) && (inputs[key] === "" || inputs[key]?.length === 0)));
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setChangedInputs(prev => [...prev, key]);
  };

  const handleCancelChanges = () => {
    setChangedInputs([]);
    setDisableEdit(true);
    setInputs(getDefaultInputs());
    onChangeInputs([]);
  };
  

  const handleConfirm = () => {
    onPending()
    let uninstall;
    if (deviceParent) {
      if (inputs.station === null) uninstall = true;
      else uninstall = false;
    } else {
      uninstall = false;
    }
    let changed = { ...inputs };
    Object.keys(changed).forEach((key) => !changedInputs.includes(key) && (delete changed[key]));
    updateCu(cuId, changed.serial, changed.owner, changed.available, changed.station, changed.sensors, uninstall)
    .then(() => setGlobalSuccess(true, "MODAL_SUCCESS_UPDATE_CU"))
    .catch(() => setGlobalError(true))
    .finally(onFinally);
  };


  return inputs && (
    <>
      <div style={{ position: "relative", display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem", paddingBottom: "7rem" }}>
        <div style={{ display: "flex", gap: "5rem", alignItems: "center", justifyContent: "space-between" }}>
          <h2 style={{ fontWeight: "bold" }}>{handleTranslate("CONTROL_UNIT", true)}: {controlUnits[cuId]?.serial}
            {disableEdit && <IconButton sx={{ ml: "1rem" }} onClick={() => setDisableEdit(false)}><EditIcon /></IconButton>}
          </h2>
          {enabledActions?.includes("manage-device") && <SwitchComponent label={handleTranslate("ACTIVE", true)} disabled={disableEdit} checked={inputs.available} onChange={(value) => handleChangeInput("available", value)} />}
        </div>
        {enabledActions?.includes("update-info-device") &&
          <>
            <TextField
              sx={{ width: "100%", maxWidth: "300px" }}
              disabled={disableEdit}
              label={`${handleTranslate("SERIAL", true)}`}
              required
              className="rounded-2xl"
              value={inputs.serial}
              onChange={(e) => handleChangeInput("serial", e.target.value)}
            />
            <TenantSelect required disabled={disableEdit} value={inputs.owner} onChange={(value) => handleChangeInput("owner", value)} />
          </>
        }
        {enabledActions?.includes("manage-device") &&
          <>
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <span style={{ minWidth: "100px" }}> {handleTranslate("INSTALLED_ON", true)} </span>
              <StationSelect disablePortal disabled={disableEdit} value={inputs.station} onChange={(value) => handleChangeInput("station", value)} />
            </div>
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <span style={{ minWidth: "100px" }}> {handleTranslate("SENSORS_INSTALLED", true)} </span>
              <SensorAutocomplete disablePortal multiple disabled={disableEdit} limitTags={2} sensorsId={sensorOptions} value={inputs.sensors} onChange={(value) => handleChangeInput("sensors", value)} />
            </div>
          </>
        }
      </div>
      {!disableEdit &&
        <div style={{ position: "absolute", padding: "1rem 2rem", left: 0, bottom: 0, width: "100%", display: "flex", gap: ".5rem", background: "white" }}>
          <Button sx={{ color: "red" }} onClick={handleCancelChanges}> {handleTranslate("CANCEL", true)} </Button>
          <Button disabled={disableConfirm} onClick={handleConfirm}> {handleTranslate("SAVE", true)} </Button>
        </div>
      }
    </>
  );
}

const CuConfigurationModal = ({enabledActions, isOpen = false, cuId, onFinally, onClose }) => {
  const { t } = useTranslation("evocs");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);
  

  const handleFinally = () => {
    setUpdateLoading(false);
    onFinally && onFinally();
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };


  const tabs = useMemo(() => {
    return {
      generals: {
        header: `${handleTranslate("GENERAL", true)}`,
        content: <ConfigurationChip enabledActions={enabledActions} cuId={cuId} onPending={() => setUpdateLoading(true)} onFinally={handleFinally} onChangeInputs={setChangedInputs} />
      },
      history: {
        header: `${handleTranslate("HISTORY", true)}`,
        content: <CuHistoryChip cuId={cuId} />
      }
    }
  }, [cuId, enabledActions]);

  useEffect(() => {
    return () => setChangedInputs([]);
  }, [isOpen])


  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  };


  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ position: "relative", height: "calc(100% - 36px)"}}>
          <TabsComponent tabs={tabs} unselectTextColor={"white"} />
          {updateLoading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

export default CuConfigurationModal;
