export const headersNames  = {
    USER_PROFILE_HEADER : "Account",
    DASHBOARD_VIEW_HEADER : "Dashboard",
    STATIONS_VIEW_HEADER : "Monitoring stations" ,
    SINGLE_STATION_VIEW_HEADER : "Stazione:" ,
    MULTIMEDIA_VIEW_HEADER : "Multimedia" ,
    SINGLE_MULTIMEDIA_VIEW_HEADER : "Multimedia:" ,
    ODOR_REPORTS_VIEW_HEADER : "Segnalazioni odorigene" ,
    MONTHLY_REPORTS_VIEW_HEADER : "Report mensili" ,
    DAILY_REPORTS_VIEW_HEADER : "Report giornalieri",
    CONFIGURATION_VIEW_HEADER : "Configurazione" ,
    ADMINISTRATION_VIEW_HEADER : "Amministrazione" ,
    PORT_STATE_VIEW_HEADER : "Stato dei porti" ,
    STATION_HISTORY_VIEW_HEADER : "Storico stazioni" ,
    OPEN_DATA_AIR_QUALITY_VIEW_HEADER : "Qualità aria",
    OPEN_DATA_METEOROLOGY_VIEW_HEADER : "Meteo",
    VESSELS_TRAFFIC_VIEW_HEADER : "Traffico navale",
    TEST_PAGE_VIEW_HEADER : "Test",
    ALERTS_VIEW_HEADER : "Alerts",
  };
  