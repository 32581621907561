import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import { useNavigate } from "react-router-dom";
import earthImage2 from "src/app/asset/images/Earth2.png";
import { Box } from "@mui/system";
import logoEvocsWhite from "src/app/asset/images/logoEvocsWhite.png";
import { styled } from "@mui/material/styles";
import { login } from "app/store/evocsSlice/login/loginActions";
import { routesNames, viewNames } from "app/configs/routesNames";
import { useEffect, useState } from "react";
import { passwordForget } from "app/store/evocsSlice/user/userAction";
import LanguageSwitcher from "app/theme-layouts/shared-components/LanguageSwitcher";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import useMobile from "src/app/hooks/useMobile";

// FUNZIONANTE STANDARD
const CustomTextField = styled(TextField)({
  // "& label.Mui-focused": { color: "#74C365" },
  "& label.Mui-focused": { color: "#92c870" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "white" },
    "&:hover fieldset": { borderColor: "white" },
    // "&.Mui-focused fieldset": { borderColor: "#74C365" },
    "&.Mui-focused fieldset": { borderColor: "#92c870" },
  },
});

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const defaultInputs = {
  email: {
    required: true,
    value: ""
  },
  password: {
    required: true,
    value: ""
  },
}

const RecoverPasswordForm = ({ onBack }) => {
  const [email, setEmail] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { t } = useTranslation('evocs');
  const handleTranslate = (textId, general = false) => {
    if (general) return (translate(t, textId));
      return (translate(t, textId,  viewNames.SIGN_IN_VIEW));
  };

  useEffect(() => {
    if (!email || email === "") setDisableSubmit(true);
    else setDisableSubmit(false);
  }, [email])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(email)
    passwordForget(email);
  };

  return (
    <>
      <Box sx={{ position: "absolute", top: "2rem", right: "2rem" }}>
        <LanguageSwitcher />
      </Box>
      <form
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "2rem",
        }}
        name="loginForm"
        noValidate
        onSubmit={handleSubmit}
      >
        <CustomTextField InputProps={{ sx: { color: "white" }, autoComplete: "off" }} InputLabelProps={{ sx: { color: "white" } }} label="Email" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button sx={{ color: "white" }} onClick={onBack}>{handleTranslate("BACK")}</Button>
          <Button disabled={disableSubmit} className="custom-button" type="submit">Reset password</Button>
        </div>

      </form>
    </>
  )
}

const SignInForm = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState(defaultInputs);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [recoverPassword, setRecoverPassword] = useState(false);
  
  const { t } = useTranslation('evocs');
  const handleTranslate = (textId, general = false) => {
    if (general) return (translate(t, textId));
    return (translate(t, textId,  viewNames.SIGN_IN_VIEW));
  };

  useEffect(() => {
    if (Object.keys(inputs).some((key) => inputs[key].required && inputs[key].value === "")) setDisableSubmit(true);
    else setDisableSubmit(false);
  }, [inputs])


  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: { ...inputs[key], value: value } });
    setInputsChanged(true);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const isLogged = await login(inputs.email.value, inputs.password.value);
    if (isLogged && isLogged === true) {
      navigate(routesNames.DASHBOARD_VIEW);
    }
  };
  const handleGuestLogin = async (e) => {
    // e.preventDefault();
    const isLogged = await login("guest", "passwordguest");
    if (isLogged && isLogged === true) {
      navigate(routesNames.DASHBOARD_VIEW);
    }
  };

  return !recoverPassword ? (
    <>
      {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}> */}
      <Box sx={{ position: "absolute", top: "2rem", right: "2rem"}}>
        <LanguageSwitcher />
      </Box>
      <form
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "2rem",
          width: "100%",
        }}
        name="loginForm"
        noValidate
        onSubmit={handleSubmit}
      >
        <CustomTextField fullWidth InputProps={{ sx: { color: "white" }, autoComplete: "off" }} InputLabelProps={{ sx: { color: "white" } }} label={handleTranslate('USER_MAIL')} required value={inputs.email.value} onChange={(e) => handleChangeInput("email", e.target.value)} />
        <CustomTextField fullWidth InputProps={{ sx: { color: "white" }, autoComplete: "off" }} InputLabelProps={{ sx: { color: "white" } }} label="Password" type="password" required value={inputs.password.value} onChange={(e) => handleChangeInput("password", e.target.value)} />
        <Button fullWidth disabled={disableSubmit} className="custom-button" type="submit">Login</Button>
        {/* <Link style={{ marginTop: "2rem" }} to="" onClick={() => setRecoverPassword(true)}>{handleTranslate('LOST_PASSWORD')}</Link> */}
      </form>
      {/* <Button style={{ color: "white" }} onClick={handleGuestLogin}> {handleTranslate('LOG_AS_GUEST_1')}&nbsp; <Link to=""> {handleTranslate('LOG_AS_GUEST_2')}</Link></Button> */}
      {/* <Button style={{ color: "white" }} onClick={handleGuestLogin}>Non hai un account?&nbsp;<Link to=""> Entra come guest</Link></Button> */}
    </>
  ) : (
    <RecoverPasswordForm onBack={() => setRecoverPassword(false)} />
  )
}


const SignInPage = () => {
  const [xs, sm] = useMobile();

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
      <Box className="space-background" sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", gap: "1rem", overflow: "hidden"}}
        flexDirection={{ xs: "column-reverse", md: "row" }}
        padding={{xs: "0 1rem", md: "0 4rem"}}
      >
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem", userSelect: "none"}}
          // height={{ xs: "30%", md: "100%" }}
          // width={{ xs: "100%", md: "70%" }}
          justifyContent={{xs: "start", md: "center"}}
          height={{xs: "40%", md: "fit-content"}}
          width={{ xs: "100%", md: "70%" }}
        >
          <Box sx={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center"}}>
            {!xs && !sm && <p className="tilebytes-logo" style={{ width: "100%" }}/>}
            <img className="earth-login" src={earthImage2} />
          </Box>
        </Box>
        <Box sx={{ display: "flex"}}
          alignItems={{ xs: "start", md: "center" }}
          justifyContent={{ xs: "center", md: "start" }}
          height={{xs: "60%", md: "fit-content"}}
          width={{ xs: "100%", md: "30%" }}
          pt={{xs: "100px", md: "0"}}
        >
          <div style={{display: "flex", width: "100%", maxWidth: "400px", flexDirection: "column", gap: "4rem", alignItems: "center", userSelect: "none"}}>
            <img
              style={{width: "100%", maxWidth: "350px", userSelect: "none", pointerEvents: "none", opacity: "1"}}
              src={logoEvocsWhite}
              alt="LOGO EVOCS"
            />
            <SignInForm />
          </div>
          {/* <div style={{ userSelect: "none" }} className="login-div-form-img">
            <img
              style={{width: "300px", width: "100%", maxWidth: "350px", margin: "0 auto", userSelect: "none", pointerEvents: "none", opacity: "1"}}
              src={logoEvocsWhite}
              alt="LOGO EVOCS"
            />
            <SignInForm />
          </div> */}
        </Box>
      </Box>
    </div>
  );

};

export default SignInPage;
