import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const ViewSelect = ({style, viewsId, defaultValue, value, onChange, required=false, multiple=false, disabled=false}) => {
  const views = useSelector((state) => state.configuration.constants.view_name);
  const options = useMemo(() => {
    if(viewsId) {
      return viewsId;
    }else {
      return Object.keys(views);
    }
  }, [viewsId]);
  const [val, setValue] = useState(defaultValue !== undefined ? defaultValue : multiple ? [] : "")

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  }

  return (
    <FormControl sx={{...style, width: "100%", minWidth: "100px", maxWidth: "300px"}}>
      <InputLabel id="view-select-label">{required ? "Pagine*": "Pagine"}</InputLabel>
      <Select
        disabled={disabled}
        labelId="view-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((viewId) => views[viewId]?.view_name).join(", ") : views[selected]?.view_name}
        label={required ? "Pagine*": "Pagine"}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((viewId, index) => (
        <MenuItem key={index} value={viewId}>
        {multiple && <Checkbox checked={val?.indexOf(viewId) > -1} /> }
        {views[viewId]?.view_name}
        </MenuItem>
        ))}
      </Select>
    </FormControl>     
  )
}

export default ViewSelect;