import React, { useState, useRef, useEffect } from "react";
import styles from "./DownloadSet.module.css";
import { CSVLink } from "react-csv";
import { BsFiletypeCsv } from "react-icons/bs";
import { BsFiletypeXls } from "react-icons/bs";
import { BsFilePdf } from "react-icons/bs";
import { BsFiletypeJson } from "react-icons/bs";
import { Button } from "@mui/material";

const DownloadFileFromTable = ({ onDownloadFile, fileName, disableCsv=false, disableExcel=false, disableJson=false, disablePdf=false }) => {
  const [isRotated, setIsRotated] = useState(false);
  const [fileData, setFileData] = useState([]);
  const csvLinkRef = useRef(null);

  const handleClick = (event) => {
    setIsRotated(!isRotated);
  };

  // const handleDownload = async (fileType) => {
  //   if(!onDownloadFile) return;
  //   try {
  //     if (fileType === "excel" || fileType === "json") onDownloadFile(fileType);
  //     else {
  //       const res = await onDownloadFile(fileType);
  //       setFileData(res);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleDownload = (fileType) => {
    onDownloadFile && onDownloadFile(fileType)
  };


  useEffect(() => {
    if (fileData.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [fileData]);


  return (
    <>
      <div
        style={{
          height: "40px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <button
          className={styles.Btn}
          onClick={handleClick}
          style={{
            transform: isRotated ? "rotate(-90deg)" : "rotate(0deg)",
          }}
        >
          <svg
            className={styles.svgIcon}
            viewBox="0 0 384 512"
            height="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
          </svg>
          <span className={styles.icon2}></span>
          <span
            className={styles.tooltip}
            style={{
              opacity: isRotated ? 0 : null,
            }}
          >
            Download
          </span>
        </button>
        {!disableCsv && (
          <Button sx={{ padding: "0px", display: isRotated ? true : "none" }}>
            <BsFiletypeCsv
              fontSize={30}
              className={`${styles.FileCsv} ${isRotated && styles.visible
                }`}
              onClick={isRotated ? () => handleDownload("csv") : null}
            />
            <CSVLink
              ref={csvLinkRef}
              data={fileData}
              style={{ display: "none" }}
              filename={`${fileName}.csv`}
            >
              Scarica CSV
            </CSVLink>
          </Button>
        )}
        {!disableExcel && (
          <Button sx={{ padding: "0px", display: isRotated ? true : "none" }}>
            <BsFiletypeXls
              fontSize={30}
              className={`${styles.FileCsv} ${isRotated && styles.visible
                }`}
              onClick={isRotated ? () => handleDownload("excel") : null}
            />
          </Button>
        )}
        {!disableJson && (
          <Button sx={{ padding: "0px", display: isRotated ? true : "none" }}>
            <BsFiletypeJson
              fontSize={30}
              className={`${styles.FileCsv} ${isRotated && styles.visible
                }`}
              onClick={isRotated ? () => handleDownload("json") : null}
            />
          </Button>
        )}
        {!disablePdf && (
          <Button sx={{ padding: "0px", display: isRotated ? true : "none" }}>
            <BsFilePdf
              fontSize={30}
            />
          </Button>
        )}
      </div>
    </>
  );
};

export default DownloadFileFromTable;
