import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";
import VesselsTrafficHeader from "src/components/VesselsTraffic/VesselsTrafficHeader";
import VesselsTrafficContent from "src/components/VesselsTraffic/VesselsTrafficContent";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";

const VesselsTraffic = () => {
  return (
    <WithAuth
      viewName={viewNames.VESSELS_VIEW}
      component={
        <CustomPageCarded
          header={<VesselsTrafficHeader />}
          content={<VesselsTrafficContent />}
          footer={<Footer />}
        />
      }
    />
  );
};

export default VesselsTraffic;
