import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const getInstalledCuSensor = createAsyncThunk(
//     "controlUnits/getInstalledCuSensor",
//     async (payload) => {
//       // console.log("getInstalledCuSensor")
//       const {tenant} = payload;
//       const data = await axios.get("/api/py/getInstalledCuSensor", {params: {tenant:tenant}})
//       .then((res) => {
//         console.log('getInstalledCuSensor: ', res.data);
//         return res.data;
//       }).catch((error) => console.log("Error: ", error));
//       return data;
//     }
//   );

// const getUninstalledCuSensor = createAsyncThunk(
//     "controlUnits/getUninstalledCuSensor",
//     async (payload) => {
//         // console.log("getInstalledCuSensor")
//         const {tenant} = payload;
//         const data = await axios.get("/api/py/getUninstalledCuSensor", {params: {tenant:tenant}})
//         .then((res) => {
//         console.log('getInstalledCuSensor: ', res.data);
//         return res.data;
//         }).catch((error) => console.log("Error: ", error));
//         return data;
//     }
// );


const getCuInfo = createAsyncThunk(
  "controlUnits/getCuInfo",
    async (tenantIds) => {
      const data = await axios
        .post(`/api/py/readCuInfo`, {
          tenantIds: tenantIds
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log("Error: ", error);
          throw error;
        });
      return data;
    }
);


const getHistory = createAsyncThunk(
  "controlUnits/getHistory",
  async (deviceDict) => {
    const data = await axios.post("/api/py/readDeviceHistory", {
      deviceDict: deviceDict
    })
    .then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const updateHistory = createAsyncThunk(
  "controlUnits/updateHistory",
  async ({cuId, oldTimeList, updateTimeList, listDeleted, listAdded}) => {
    const data = await axios.post("/api/py/updateHistory", {
      deviceId: cuId,
      typeDevice: "cu",
      oldTimeList: oldTimeList,
      updateTimeList: updateTimeList,
      listDeleted: listDeleted,
      listAdded: listAdded,
    })
    .then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const downloadHistory = createAsyncThunk(
  "controlUnits/updateHistory",
  async ({cuId, form}) => {
    const data = await axios.post("/api/py/createHistoryFile", {
      deviceDict: {
        [cuId]: "cu"
      },
      form: form // csv o excel
    })
    .then((res) => {
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);


const createCu = createAsyncThunk (
  "controlUnits/createCu",
    async (payload) => {
      const { serial, owner, stationId, listSensors, available } = payload;
      const data = await axios
        .post(`/api/py/createCu`, {
          serial: serial,
          owner: owner,
          stationId: stationId,
          list_sensors: listSensors,
          available : available,
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
        const cuId = data._id;
        delete data._id;
        return {cuId, cu: data};
    }
);

const readCu =  createAsyncThunk(
  "controlUnits/readCu",
    async (payload, { dispatch, getState }) => {
      const { cuId } = payload;
      const body = [
        {
          cuId: cuId
        },
      ];
      const data = await axios
        .post(`/api/py/readCu`, body)
        .then((res) => {
          console.log("res: ", res);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
      return data;
    }
    );

const updateCu = createAsyncThunk(
  "controlUnits/updateCu",
    async (payload) => {
      const {cuId, serial, owner, available, stationId, listSensors, uninstall, operationTime} = payload;
      const data = await axios
        .post(`/api/py/updateCu`, {
          cuId: cuId,
          serial: serial,
          owner: owner,
          available: available,
          stationId: stationId,
          list_sensors: listSensors,
          uninstall: uninstall,
          operationTime: operationTime
        })
        .then((res) => {
          console.log("res: ", res);
          return res.data;
        })
        .catch((error) => {
          console.log("Error: ", error);
          throw error;
        });
      return data;
    }
);

const deleteCu = createAsyncThunk(
  "controlUnits/deleteCu",
    async (cuId) => {
      const data = await axios
        .post(`/api/py/deleteCu`, {
          cuId: cuId
        })
        .then((res) => {
          return cuId;
        })
        .catch((error) => {
          console.log("Error: ", error);
          throw error;
        });
      return data;
    }
);

const installCu = createAsyncThunk(
  "controlUnits/installCu",
    async (payload) => {
      const {cuIds, stationId, start} = payload;
      const data = await axios
      .post('/api/py/multipleInstallCu', {
        stationId: stationId,
        cuIds: cuIds,
        start: start
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log('error: ', error);
        throw error;
      });
      return {cuIds, data};
    }
);

const uninstallCu  = createAsyncThunk(
  "controlUnits/uninstallCu",
    async (payload) => {
      const {cuId, end} = payload;
      const data = await axios
      .post('/api/py/uninstallCu', {
        cuId: cuId,
        end: end
      })
      .then((res) => {
        console.log("Res: ", res);
        return cuId;
      })
      .catch((error) => {
        console.log('error: ', error);
        throw error;
      });
      return data;
    }
);

const moveCu = createAsyncThunk(
  "controlUnits/moveCu",
  async (payload) => {
    const {cuId, destinationId, start} = payload;
    const data = await axios.post("/api/py/moveDevice", {
      deviceId : cuId,
      deviceType : "cu",
      sourceType : "station",
      destinationId : destinationId,
      destinationType : "station",
      starting_date: start
    })
    .then((res) => {
      console.log("Move station: ", res)
      return payload;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const createShareCu = createAsyncThunk(
  "controlUnits/createShareCu",
  async (dictShare) => {
      const data = await axios
          .post(`/api/py/createShareCu`, {
              dictShare: dictShare
          })
          .then((res) => {
              return res.data;
          })
          .catch((error) => {
              console.log("Error: ", error);
              throw error;
          });
      return data;
  }
);

const updateShareCu = createAsyncThunk(
  "controlUnits/updateShareCu",
  async (payload) => {
    const {cuId, tenantsList} = payload;
      const data = await axios
        .post(`/api/py/updateShareCu`, {
            dictShare: {
              [cuId]: tenantsList
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
      return data;
  }
);


const cuSlice = createSlice({
  name: "controlUnits",
  initialState: {
    list: {},
    installed: [],
    uninstalled: [],
    loading: false
  },    
  reducers: {
    // installCu: (state, action) => {
    //   const {cuIds, data} = action.payload;
    //   console.log(action.payload)
    //   cuIds.forEach((cuId) => {
    //       state.list[cuId] = data[cuId];
    //       state.uninstalled = state.uninstalled.filter((id) => id!== cuId);
    //       state.installed.push(cuId);
    //   })
    // },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCuState: (state, action) => {
      const {id, cu} = action.payload;
      state.list[id] = cu;
    },
    installSensor: (state, action) => {
      const {cuId, sensorIds} = action.payload;
      sensorIds.forEach((id) => {
        state.list[cuId].list_sensors.push(id);
      })
    },
    uninstallSensor: (state, action) => {
      const {cuId, sensorId} = action.payload;
      state.list[cuId].list_sensors = state.list[cuId].list_sensors.filter((id) => id!==sensorId);
    },
    setListSensor: (state, action) => {
      const {cuId, listSensor} = action.payload;
      state.list[cuId].list_sensors = listSensor;
    },
    installOnStation: (state, action) => {
      const {cuId, device, start} = action.payload;
      state.list[cuId].deviceParent = {device, start};
      state.uninstalled = state.uninstalled.filter((id) => id !== cuId);
      state.installed.push(cuId);
    },
    uninstallFromParent: (state, action) => {
      const cuId = action.payload;
      delete state.list[cuId].deviceParent;
      state.installed= state.installed.filter((id) => id !== cuId);
      state.uninstalled.push(cuId);
    },
    addInstalledCu: (state, action) => {
      const cuId = action.payload;
      const index = state.uninstalled.indexOf(cuId);
      state.uninstalled = state.uninstalled.toSpliced(index, 1);
      state.installed.push(cuId);
    },
    addUninstalledCu: (state, action) => {
      const cuId = action.payload;
      const index = state.installed.indexOf(cuId);
      state.installed = state.installed.toSpliced(index, 1);
      state.uninstalled.push(cuId);
    },
    activateCu: (state, action) => {
      const cuId = action.payload;
      state.list[cuId].available = 1;
    },
    deactivateCu: (state, action) => {
      const cuId = action.payload;
      state.list[cuId].available = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCuInfo.fulfilled, (state, action) => {
      const { list, installed, uninstalled } = action.payload;
      state.list = list;
      state.installed = installed;
      state.uninstalled = uninstalled;
    });
    builder.addCase(createCu.fulfilled, (state, action) => {
      const {cuId, cu} = action.payload;
      state.list[cuId] = cu;
      if(cu.deviceParent) state.installed.push(cuId);
      else state.uninstalled.push(cuId);
    });
    builder.addCase(deleteCu.fulfilled, (state, action) => {
      const cuId = action.payload;
      if(state.list[cuId].deviceParent) state.installed = state.installed.filter((id) => id !== cuId);
      else state.uninstalled = state.uninstalled.filter((id) => id !== cuId);
      delete state.list[cuId];
    });
    builder.addCase(installCu.fulfilled, (state, action) => {
      const {cuIds, data} = action.payload;
      cuIds.forEach((cuId) => {
          state.list[cuId] = data[cuId];
          state.uninstalled = state.uninstalled.filter((id) => id!== cuId);
          state.installed.push(cuId);
      })
    })
    builder.addCase(uninstallCu.fulfilled, (state, action) => {
      const cuId = action.payload;
      delete state.list[cuId].deviceParent;
      state.installed = state.installed.filter((id) => id !== cuId);
      state.uninstalled.push(cuId);
    });
    builder.addCase(moveCu.fulfilled, (state, action) => {
      const {cuId, destinationId, start} = action.payload;
      state.list[cuId].deviceParent = {
          device: destinationId,
          type: "station",
          start: start
      }
    });
  }
});

export const cuSliceActions = {
  ...cuSlice.actions,
  getCuInfo,
  getHistory,
  updateHistory,
  downloadHistory,
  createCu,
  readCu,
  updateCu,
  deleteCu,
  installCu,
  uninstallCu,
  moveCu,
  createShareCu,
  updateShareCu
}

export default cuSlice.reducer;

