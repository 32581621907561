import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import useFilters from 'src/app/hooks/useFilters';
import { translate } from "src/utilities/utils";
import SelectComponent from '../SharingComponents/Inputs/SelectComponent';
import { useEffect, useMemo, useState } from 'react';
import TimeIntervalSelector from '../StationHandling/TimeIntervalSelector';
import { useSelector } from 'react-redux';
import { setFilter } from 'app/store/evocsSlice/filters/filtersActions';

const viewName = viewNames.DASHBOARD_VIEW;

const DashboardHeader = () => {
  const { t } = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const filtersInView = useFilters(viewName);
  const timeRangeInView = filtersInView?.timeRange;
  const {enabledIntervals, defaultInterval} = useMemo(() => { 
    const intervals = [];
    let defaultId = null;
    timeRangeInView?.list.forEach((element) => {
      if(element.active===1) intervals.push(element._id);
      if(element.default===1) defaultId = element._id;
    });
    defaultId = timeRangeInView.selected ? timeRangeInView.selected : defaultId;
    return {enabledIntervals: intervals, defaultInterval: defaultId}
  }, [user.selectedTenant, timeRangeInView?.list])
  const [selectedInterval, setSelectedInterval] = useState(defaultInterval);

  useEffect(() => {
    setFilter(viewName, timeRangeInView.id, selectedInterval);
  }, [selectedInterval])

  const handleTranslate = (textId, general = false) => {
     if (general) return translate(t, textId);
     return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const handleChangeInterval = (value, dates) => {
    setSelectedInterval(value);
  }

  return (
    <div className='view-header' style={{display: "flex", width: "100%"}}>
      <span className='title'>{handleTranslate('HEADER')}</span>
      {timeRangeInView && <TimeIntervalSelector style={{marginLeft: "auto", maxWidth: "200px"}} label="Intervallo di aggiornamento" intervals={enabledIntervals} defaultValue={defaultInterval} value={selectedInterval} onChange={handleChangeInterval}/>}
    </div>
  )
}

export default DashboardHeader