import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const SensorSelect = ({ style, sensorsId, installed = false, uninstalled = false, defaultValue, value, onChange, required = false, multiple = false, disabled = false }) => {
  const sensors = useSelector((state) => state.sensors);
  const options = useMemo(() => {
    if (sensorsId) {
      return sensorsId;
    } else if (installed) {
      return sensors.installed;
    } else if (uninstalled) {
      return sensors.uninstalled;
    } else {
      return Object.keys(sensors.list);
    }
  }, [sensorsId, installed, uninstalled]);
  const [val, setValue] = useState(defaultValue !== undefined ? defaultValue : multiple ? [] : "")
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  }

  return (
    <FormControl disabled={disabled} sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="sensor-select-label">{required ? `${handleTranslate('SENSORS', true)}*` : `${handleTranslate('SENSORS', true)}`}</InputLabel>
      <Select
        labelId="sensor-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((sensorId) => sensors.list[sensorId]?.serial).join(", ") : sensors.list[selected]?.serial}
        label={required ? `${handleTranslate('SENSORS', true)}*` : `${handleTranslate('SENSORS', true)}`}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((sensorId, index) => (
          <MenuItem key={index} value={sensorId}>
            {multiple && <Checkbox checked={val?.indexOf(sensorId) > -1} />}
            {sensors.list[sensorId]?.serial}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SensorSelect;