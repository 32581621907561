// import Pinwheel from "../../gif/Pinwheel.gif"
import { useEffect, useState } from "react";
import EvocsLoadingLow from "src/app/asset/gif/evocsLoading.gif"

const styles = {
  gifImg: {
    display: "block",
    // height: "fit-content",
    width: "200px",
    userSelect: "none",
    pointerEvents: "none"
  },
};

const LoadingGif = ({disableLogo=false}) => {
  const [points, setPoints] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      if(points.length < 3) setPoints(points + ".");
      else setPoints("");
    }, 500);
    
    return () => {
      clearTimeout(timeout);
    }
  }, [points])

  return(
      <div style={{display: "flex", flexDirection: "column", width: "fit-content", alignItems: "center", margin: "0 auto", userSelect: "none"}}>
        {!disableLogo && <img src={EvocsLoadingLow} style={styles.gifImg}/>}
        <p style={{fontWeight: "bold", width: "80px", margin: "1rem auto 0 auto", color: "rgba(0,0,0,0.7)"}}>LOADING<span>{points}</span></p>
      </div>
  );
}

export default LoadingGif;