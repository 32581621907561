import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import defaultThumb from "src/app/asset/images/TilebytesCopertina.png";
import ReactPlayer from "react-player";

function AxisLiveStreaming_V2({ camera = "1" }) {
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = React.useRef(null);
  const [test0, setTest0] = useState(false);
  const [test1, setTest1] = useState(false);
  const [test2, setTest2] = useState(false);
  const [test3, setTest3] = useState(false);
  const [test4, setTest4] = useState(false);
  const [test5, setTest5] = useState(false);
  const [test6, setTest6] = useState(false);
  const [test7, setTest7] = useState(false);
  const [test8, setTest8] = useState(false);

  const handleGetUrl = () => {
    setVideoUrl(
      `/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360`
    );
  };
  const handleClick = () => {
    console.log("Cliccato");
    videoRef.current.play();
  };

  const handleTest0 = () => {
    setTest0(!test0);
  };
  const handleTest1 = () => {
    setTest1(!test1);
  };
  const handleTest2 = () => {
    setTest2(!test2);
  };
  const handleTest3 = () => {
    setTest3(!test3);
  };
  const handleTest4 = () => {
    setTest4(!test4);
  };
  const handleTest5 = () => {
    setTest5(!test5);
  };
  const handleTest6 = () => {
    setTest6(!test6);
  };
  const handleTest7 = () => {
    setTest7(!test7);
  };
  const handleTest8 = () => {
    setTest8(!test8);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <Button onClick={handleTest0} variant="outlined">
          Test0
        </Button>

        <Button onClick={handleTest1} variant="outlined">
          Test1
        </Button>

        <Button onClick={handleTest2} variant="outlined">
          Test2
        </Button>

        <Button onClick={handleTest3} variant="outlined">
          Test3
        </Button>

        <Button onClick={handleTest4} variant="outlined">
          Test4
        </Button>
        <Button onClick={handleTest5} variant="outlined">
          Test5
        </Button>
        <Button onClick={handleTest6} variant="outlined">
          Test6
        </Button>
        <Button onClick={handleTest7} variant="outlined">
          Test7
        </Button>
        <Button onClick={handleTest8} variant="outlined">
          Test8
        </Button>
      </div>

      {/* <Button onClick={handleGetUrl} variant='outlined'>
        Get URL
      </Button> */}

      {/* <Button onClick={handleClick} variant='outlined'>
        Play
      </Button> */}

      <div style={{ width: "640px", height: "360px" }}>
      

      {test0 && (
        <>
          <p>Test 0</p>
          <ReactPlayer
            ref={videoRef}
            url={
              "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
            }
            width="640px"
            height="360px"
            controls
            playing={false}
            muted
            playsInline
          />
        </>
      )}

      {test1 && (
        <>
        <p>Test 1</p>
          <ReactPlayer
            ref={videoRef}
            url={
              "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
            }
            width="100%"
            height="100%"
            controls
            playing={false}
            muted
            playsInline
          />
        </>
      )}
      {test2 && (
        <>
          <p>Test 2</p>
          <video width={"auto"} height={"auto"} playsInline>
            <source
              src={
                "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      {test3 && (
        <>
          <p>Test 3</p>
          <video autoPlay={true} muted playsInline>
            <source
              src={
                "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      {test4 && (
        <>
          <p>Test 4</p>
          <video
            controls
            autoPlay={false}
            playsInline
            height={"auto"}
            width={"100%"}
            preload="yes"
          >
            <source
              src={
                "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      {test5 && (
        <>
          <p>Test 5</p>
          <video
            controls
            autoPlay={false}
            playsInline
            height={"auto"}
            width={"100%"}
          >
            <source
              src={
                "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      {test6 && (
        <>
          <p>Test 6</p>
          <video autoPlay={true} muted>
            <source
              src={
                "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      {test7 && (
        <>
          <p>Test 7</p>
          <video
            controls
            autoPlay={false}
            playsInline
            height={640}
            width={360}
          >
            <source
              src={
                "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      {test8 && (
        <>
          <p>Test 8</p>
          <video autoPlay={true} muted width={640} height={360}>
            <source
              src={
                "/stream-dj/api/getAxisCameraLiveStream?camera=1&resolution=640x360"
              }
              type="video/mp4"
            />
          </video>
        </>
      )}
      </div>
    </Box>
  );
}

export default AxisLiveStreaming_V2;
