


export const navbarGroupsNamesIt = {
	STATION_GROUP : "Data center", 
	MULTIMEDIA_GROUP : "Multimedia", 
	ODOUR_PORTAL_GROUP : "Portale odori",
	OPEN_DATA_PORTAL_GROUP : "Portale open data",
	VESSELS_GROUP : "Portale navale",
	ALERTS_AND_REPORTS_GROUP  : "Alert e report",
	CONFIGURATION_GROUP : "Control room", 
	TEST : "Sezioni di test",
};