import { useSelector } from 'react-redux';
import RangeTimeFilter_v2 from '../RangeTimeFilter_v2';
import MeasureSelect from './MeasureSelect';
import StationSelect from './StationSelect';
import TimeIntervalSelector from 'src/components/StationHandling/TimeIntervalSelector';

const FilterComponent = ({filterId, list, value, multiple=false, disabled=false, onChange, withSlider=true, label}) => {
  const filter = useSelector((state) => state.configuration.constants?.filterCategory?.[filterId]);

  const handleChange = (value) => {
    onChange && onChange(filterId, value);
  };

  switch(filter?.filterCategory) {
    case "timeRange": 
      return withSlider ? <RangeTimeFilter_v2 /> : <TimeIntervalSelector label={label} intervals={list} multiple={multiple} disabled={disabled} value={value} onChange={handleChange}/>
    case "measure":
      return <MeasureSelect measuresId={list} disabled={disabled} multiple={multiple} value={value} onChange={handleChange}/>
    case "stationName": 
      return <StationSelect mongo stationsId={list} disabled={disabled} multiple={multiple} value={value} onChange={handleChange}/>
    default: 
      return null;
  }
}

export default FilterComponent;