import { useSelector } from 'react-redux';
import CuGraph from './CuGraph';
import SensorGraph from './SensorGraph';

const GraphSwitcher = ({unit, startDate, endDate, compareOptions, graphHeight="400px", sensorGraphShadow=true, onChangeSensor, onChangeMeasure, onChangeCompare, onChangeSelectedCompare, disableSensorFilter=false, isLive=false, userActions}) => {
  const controlUnits = useSelector((state) => state.controlUnits.list); 
  const sensors = useSelector((state) => state.sensors.list); 
  const unitId = unit?.id;


  return unit && (
    controlUnits[unitId] ?
    <CuGraph withDownloads userActions={userActions} disableSensorFilter={disableSensorFilter} cuId={unitId} cu={controlUnits[unitId]} startDate={startDate} endDate={endDate} selectedSens={unit.selectedSensor} selectedMeas={unit.selectedMeasure} 
    graphHeight={graphHeight} compareOptions={compareOptions} isCompare={unit.isCompare} selectedCompare={unit.selectedCompare}
    onChangeSensor={(value) => onChangeSensor(unitId, value)} onChangeMeasure={(value) => onChangeMeasure(unitId, value)} 
    onChangeCompare={(value) => onChangeCompare(unitId, value)} onChangeSelectedCompare={(value) => onChangeSelectedCompare(unitId, value)} isLive={isLive}/>
    :
    <SensorGraph withDownloads userActions={userActions} sensorId={unitId} sensor={sensors[unitId]} graphHeight={graphHeight} graphShadow={sensorGraphShadow} startDate={startDate} endDate={endDate} 
    selectedMeas={unit.selectedMeasure} compareOptions={compareOptions} isCompare={unit.isCompare} selectedCompare={unit.selectedCompare} isLive={isLive} 
    onChangeMeasure={(value) => onChangeMeasure(unitId, value)} onChangeCompare={(value) => onChangeCompare(unitId, value)} onChangeSelectedCompare={(value) => onChangeSelectedCompare(unitId, value)}/>
  )
}

export default GraphSwitcher;