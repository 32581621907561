
const GENERAL_SUCCESS_MESSAGE = {
	MODAL_SUCCESS_GENERAL_MESSAGE : "Action performed successfully",
}

const GENERAL_WARNING_MESSAGE = {
    MODAL_NO_SAVE_EXIT_WARNING : "If you exit without saving, changes will be lost",
    MODAL_ALREADY_INSTALLED_WARNING : "This device is already installed on this unit",
}

const GENERAL_ERROR_MESSAGE = {
	MODAL_ERROR_GENERAL_MESSAGE : "Ops something went wrong",
}

const STATION_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_STATION : "Station created successfully",
    MODAL_SUCCESS_UPDATE_STATION : "Station updated successfully",
    MODAL_SUCCESS_DELETE_STATION : "Station deleted successfully",
    MODAL_SUCCESS_UPDATE_STATION_HISTORY : "Station history updated successfully",
}

const STATION_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_STATION : "Are you sure you want to delete the station?",
}

const STATION_ERROR_MESSAGE = {
    MODAL_ERROR_DELETE_STATION : "Unable to delete the station, there are units installed on it",
}

const CU_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_CU : "Control unit created successfully",
    MODAL_SUCCESS_UPDATE_CU : "Control unit updated successfully",
    MODAL_SUCCESS_DELETE_CU : "Control unit deleted successfully",
    MODAL_SUCCESS_UPDATE_CU_HISTORY : "Control unit history updated successfully",
}

const CU_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_CU : "Are you sure you want to delete the control unit?",
    MODAL_WARNING_UNINSTALL_CU : "Are you sure you want to uninstall the control unit?",
}

const CU_ERROR_MESSAGE = {
    MODAL_ERROR_DELETE_CU : "Unable to delete the control unit, it is installed on a station or there are sensors installed on it",
    MODAL_ERROR_INSTALL_CU : "This control unit is already installed in this station"
}

const SENSOR_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_SENSOR : "Sensor created successfully",
    MODAL_SUCCESS_UPDATE_SENSOR : "Sensor updated successfully",
    MODAL_SUCCESS_DELETE_SENSOR : "Sensor deleted successfully",
    MODAL_SUCCESS_UPDATE_SENSOR_HISTORY : "Sensor history updated successfully",
}

const SENSOR_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_SENSOR : "Are you sure you want to delete the sensor?",
    MODAL_WARNING_UNINSTALL_SENSOR : "Are you sure you want to uninstall the sensor?",
    MODAL_WARNING_REMOVE_MEASURE : "Are you sure you want to remove the measure?",
}

const SENSOR_ERROR_MESSAGE = {
    MODAL_ERROR_DELETE_SENSOR : "Unable to delete the sensor, it is installed on a unit",
    MODAL_ERROR_INSTALL_SENSOR : "This sensor is already installed in this unit"
}

const MEASURE_ERROR_MESSAGE = {
    MODAL_ERROR_ADD_MEASURE: "Impossible to add the measure, it is already present in the sensor"
}

const TENANT_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_TENANT : "Tenant created successfully"
}

const CONSTANT_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_CREATION_CONSTANT: "Contant created successfully"
}

const RECORDING_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_DELETE_RECORDING: "Recording deleted successfully"
}

const RECORDING_WARNING_MESSAGE = {
    MODAL_WARNING_DELETE_RECORDING: "Are you sure you want to remove the recording?"
}

const USER_INVITATION_SUCCESS_MESSAGE = {
    MODAL_SUCCESS_INVITED_USER: "User successfully invited",
}

export const MODALS_EN = {
    ...GENERAL_SUCCESS_MESSAGE,
    ...GENERAL_WARNING_MESSAGE,
    ...GENERAL_ERROR_MESSAGE,
    ...STATION_SUCCESS_MESSAGE,
    ...STATION_WARNING_MESSAGE,
    ...STATION_ERROR_MESSAGE,
    ...CU_SUCCESS_MESSAGE,
    ...CU_WARNING_MESSAGE,
    ...CU_ERROR_MESSAGE,
    ...SENSOR_SUCCESS_MESSAGE,
    ...SENSOR_WARNING_MESSAGE,
    ...SENSOR_ERROR_MESSAGE,
    ...MEASURE_ERROR_MESSAGE,
    ...TENANT_SUCCESS_MESSAGE,
    ...CONSTANT_SUCCESS_MESSAGE,
    ...RECORDING_SUCCESS_MESSAGE,
    ...RECORDING_WARNING_MESSAGE,
    ...USER_INVITATION_SUCCESS_MESSAGE
};
