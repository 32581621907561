import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { routesNames, viewNames } from "src/app/configs/routesNames";
import { useNavigate } from "react-router-dom";
import store from "app/store";
import { LOGOUT } from "app/store/actions";
import { getFilters, setStationSelect } from "app/store/evocsSlice/filters/filtersActions";
import { getTenants } from "app/store/evocsSlice/tenants/tenantsActions";
import { getStationInfo, setDevicesLoading } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { getCusInfo } from "app/store/evocsSlice/controlUnits/cuActions";
import { getSensorInfo } from "app/store/evocsSlice/sensors/sensorsActions";
import { readBaseLocations, readConstant } from "app/store/configurationSlice/configurationActions";
import { getMyUser, readAlertsByUser, setLastUserTenant } from "app/store/evocsSlice/user/userAction";
import useActiveViews from "src/app/hooks/useActiveViews";
import { logout, setDataLoading } from "app/store/evocsSlice/login/loginActions";
import CookiesModal from "src/components/SharingComponents/CookiesModal";
import ErrorModal from "src/components/Modals/ErrorModal";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess, setGlobalWarning, setServerError } from "app/store/evocsSlice/globalError/errorActions";
import SuccessModal from "src/components/Modals/SuccessModal";
import WarningModal from "src/components/Modals/WarningModal";
import ServerErrorModal from "src/components/Modals/ServerErrorModal";


const tileBytesTenantId = "6554a3a2af4b91cdd6da3285";
export const ModalContext = createContext();

const WithAuth = ({ component, viewName }) => {
  const { t } = useTranslation("evocs");
  const navigate = useNavigate();
  const dataLoading = useSelector((state) => state?.login?.dataLoading);
  const user = useSelector((state) => state.user);
  const isAuthenticated = useSelector((state) => state?.login?.isAuthenticated);
  const sessionExpired = useSelector((state) => state?.login?.sessionExpired);
  const navigation = useSelector((state) => state.fuse.navigation.entities);
  const {serverError, globalError, globalSuccess, globalWarning, globalMessage} = useSelector((state) => state.error);
  const [activeViews, isViewActive] = useActiveViews(viewName);
  const [showCookies, setShowCookies] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);

  const isInitialState = () => {

    const expectedInitialState = {
      stationsMongo: {
        list: {},
        available: [],
        unavailable: [],
        loading: false,
      },
      sensors: {
        list: {},
        installed: [],
        uninstalled: [],
        loading: false,
      },
      controlUnits: {
        list: {},
        installed: [],
        uninstalled: [],
        loading: false,
      }
    };

    const currentState = store.getState();
    for (const sliceKey in expectedInitialState) {
      if (JSON.stringify(currentState[sliceKey]) !== JSON.stringify(expectedInitialState[sliceKey])) {
        return false;
      }
    }
    return true;
  };

  const goOut = () => {
    store.dispatch({ type: LOGOUT });
    navigate(routesNames.SIGN_IN_VIEW);
  };

  const switchView = () => {
    if (!activeViews) return;
    if (activeViews.length === 0) goOut();
    else {
      const viewKey = Object.keys(navigation).find((viewKey) => navigation[viewKey].type === "item" && activeViews.find((view) => view.url === navigation[viewKey].url));
      viewKey && navigate(navigation[viewKey].url);
    }
  };

  // const getData = async () => {
  //   try {
  //     setDataLoading(true);
  //     setDevicesLoading(true);
  //     const tenantIds = [user.selectedTenant];
  //     readAlertsByUser(user.id);
  //     await Promise.all([
  //       getTenants(user.tenantId),
  //       getFilters(user.tenantId === tileBytesTenantId ? null : user.tenantId),
  //       getStationInfo(tenantIds).then((stations) => {
  //         setStationSelect(viewNames.STATIONS_VIEW, null, [...stations.available]);
  //       }),
  //       getCusInfo(tenantIds),
  //       getSensorInfo(tenantIds),
  //       readConstant(),
  //       readBaseLocations(),
  //     ]);
  //     console.log("sono qui")
  //     setLastUserTenant(user.selectedTenant);
  //     setDataLoading(false);
  //     setDevicesLoading(false);

  //   //   getTenants(user.tenantId),
  //   //   getFilters(user.tenantId === tileBytesTenantId ? null : user.tenantId),
  //   //   getStationInfo(tenantIds).then((stations) => {
  //   //     setStationSelect(viewNames.STATIONS_VIEW, null, [...stations.available])
  //   //   }),
  //   //   getCusInfo(tenantIds),
  //   //   getSensorInfo(tenantIds),
  //   //   readConstant(),
  //   //   readBaseLocations(),
  //   // ];
  //   // Promise.all(getUnitsInfo)
  //   //   .then(() => {
  //   //     setLastUserTenant(user.selectedTenant);
  //   //     setDataLoading(false);
  //   //     setDevicesLoading(false)
  //   //   })
  //   //   .catch((error) => {
  //   //     throw error;
  //   //   });
  //   } catch (error) {
  //     console.log("dioooo")
  //     throw error;
  //   }
  // };

  
  const getData = async () => {
    try {
      const tenantIds = [user.selectedTenant];
      readAlertsByUser(user.id).catch(() => setServerError(true))
      await Promise.all([
        getTenants(user.tenantId),
        getFilters(user.tenantId === tileBytesTenantId ? null : user.tenantId),
        getStationInfo(tenantIds).then((stations) => {
          setStationSelect(viewNames.STATIONS_VIEW, null, [...stations.available]);
        }),
        getCusInfo(tenantIds),
        getSensorInfo(tenantIds),
        readConstant(),
        readBaseLocations(),
      ]);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      if (isInitialState()) {
        getMyUser().catch((error) => { 
          setServerError(true);
        });
      }
    } else if (isAuthenticated === false) {
      goOut();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user.selectedTenant || user.selectedTenant === user.lastSelectedTenant) return;
    let canSet = true;
    setDataLoading(true);
    setDevicesLoading(true);
    getData().then(() => {
      if(!canSet) return;
      setLastUserTenant(user.selectedTenant);
      setDataLoading(false);
      setDevicesLoading(false);
    }).catch((error) => {
      console.error(error);
      setServerError(true);
    });

    return () => canSet = false
  }, [user.selectedTenant])

  useEffect(() => {
    if(!dataLoading && !isViewActive) switchView();
  }, [dataLoading, isViewActive])

  useEffect(() => {
    // user.cookies && console.log(user.cookies)
    // if(!user.cookies) {
    //   setShowCookies(true);
    // } else if(Object.values(user.cookies)[0] === null) setShowCookies(true);
    // else {
    //   setShowCookies(false);
    // }
  }, [user.cookies])


  // useEffect(() => {
  //   if (isViewActive !== undefined && isViewActive !== null && isViewActive === false) switchView();
  //   if (isAuthenticated === true) {
  //     if (isInitialState() === true) {
  //       getMyUser().catch((error) => { throw error; });
  //     }
  //     else setDataLoading(false);
  //   } else if (isAuthenticated === false) {
  //     goOut();
  //   }
  // }, [isAuthenticated, isViewActive]);

  // useEffect(() => {
  //   if (!user.selectedTenant) return;
  //   if (user.selectedTenant !== user.lastSelectedTenant) getData();
  // }, [user.selectedTenant])

  const handleConfirm = () => {
    setGlobalWarning(false);
    confirmCallback && confirmCallback();
    setConfirmCallback(null);
  }

  const handleSetConfirmCallback = (callback) => {
    setConfirmCallback(() => {
      return callback;
    });
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <>
      <ErrorModal title={handleTranslate('SESSION_EXPIRED', true)} confirmText={handleTranslate('GO_TO_LOGIN', true)} isOpen={sessionExpired} onConfirm={logout} />
      {!sessionExpired && <ServerErrorModal isOpen={serverError}/>}
      {dataLoading ? 
        <FuseSplashScreen />
      :
        <ModalContext.Provider value={{confirmCallback, setConfirmCallback: handleSetConfirmCallback}}>
          {component}
          <CookiesModal show={showCookies} onClose={() => setShowCookies(false)} />
          <SuccessModal confirmButton isOpen={globalSuccess} title={globalMessage} onConfirm={() => setGlobalSuccess(false)}/>
          <ErrorModal isOpen={globalError} title={globalMessage} onConfirm={() => setGlobalError(false)}/>
          <WarningModal isOpen={globalWarning} title={globalMessage} onConfirm={handleConfirm} onClose={() => setGlobalWarning(false)}/>
        </ModalContext.Provider>
      }
    </>
  )

  // return loading && (
  //  isViewActive ? (
  //   <>
  //     <ExpiredTokenModal open={sessionExpired} />
  //     {isAuthenticated === undefined || isAuthenticated === null || isAuthenticated === false && <FuseSplashScreen />}
  //     {isAuthenticated === true && !loading && component}
  //     {isAuthenticated === true && loading && <FuseSplashScreen />}
  //   </>
  // ) : <Navigate to={"/sign-in"}/>
  // )

}

export default WithAuth;
