import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Multimedia:",
    PREVIW_NOT_AVAILABLE : "Previews not available",
    VIDEO_ENCODING : "Video Encoding",
    RECORDING_ID : "Recording ID",
    DISK_ID : "Disk ID",
};

const SINGLE_MULTIMEDIA_EN = createLanguageObject(EN, viewNames.SINGLE_MULTIMEDIA_VIEW);


export default SINGLE_MULTIMEDIA_EN;