import { useLayoutEffect, useMemo, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const OpenDataSourceSelect = ({style, sourcesId, value, defaultValue, onChange, multiple=false, required=false, aq=true, meteo=true}) => {
  const openSource = useSelector((state) => state.configuration.constants.open_source);
  const options = useMemo(() => {
    if(sourcesId) return sourcesId;
    if(aq && meteo) return Object.keys(openAqSource);
    else if(aq) return Object.keys(openSource).filter((sourceId) => openSource[sourceId].type === "AQ");
    else if(meteo) return Object.keys(openSource).filter((sourceId) => openSource[sourceId].type === "METEO");;
  }, [sourcesId]);
  const [val, setValue] = useState(defaultValue ? defaultValue : value !== undefined ? value : multiple ? [] : "");

  const { t } = useTranslation("evocs");
const handleTranslate = (textId, general = false) => {
   if (general) return translate(t, textId);
   return translate(t, textId, viewNames.DASHBOARD_VIEW);
};
  
  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  }

  return(
    <FormControl sx={{...style, width: "100%", minWidth: "100px", maxWidth: "300px"}}>
        <InputLabel id="opena-data-source-select-label">{required ? `${handleTranslate('SOURCE', true)}*` : `${handleTranslate('SOURCE', true)}`}</InputLabel>
        <Select
            labelId="opena-data-source-select-label"
            value={val}
            multiple={multiple}
            renderValue={(selected) => multiple ? selected.map((sourceId) => openSource?.[sourceId].label).join(", ") :  openSource?.[selected].label}
            label={required ? `${handleTranslate('SOURCE', true)}*` : `${handleTranslate('SOURCE', true)}`}
            onChange={(e) => handleChange(e.target.value)}
        >
          <MenuItem value={""}>
            {multiple && <Checkbox checked={val?.indexOf(handleTranslate('ALL', true)) > -1} /> }
            <em>Tutte</em>
          </MenuItem>
          {options?.map((sourceId, index) => (
          <MenuItem key={index} value={sourceId}>
            {multiple && <Checkbox checked={val?.indexOf(sourceId) > -1} /> }
            { openSource?.[sourceId].label}
          </MenuItem>
          ))}
        </Select>
    </FormControl>     
  )
}

export default OpenDataSourceSelect;