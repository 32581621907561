import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Stazioni di monitoraggio",
    NO_ACTIVE_STATIONS: "Nessuna stazione attiva",
};

const STATIONS_IT = createLanguageObject(IT, viewNames.STATIONS_VIEW);

export default STATIONS_IT;