import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';

const useFilters = (viewName, tenantId=null) => {
  const user = useSelector((state) => state.user);
  const views = useSelector((state) => state.configuration.constants?.view_name);
  const tenant = useMemo(() => {
    if(tenantId) return tenantId;
    else return user.selectedTenant;
  }, [tenantId]);
  const viewId = useMemo(() => {
    return views && Object.keys(views).find((id) => views[id].view_name === viewName);
  }, [viewName]);
  const filtersCategory = useSelector((state) => state.configuration.constants?.filterCategory);
  const filters = useSelector((state) => state.filters[tenant]?.[viewId]);

  const allFiltersInView = useMemo(() => {
    let allFilters = {};
    filters && Object.keys(filters).forEach((filterId) => {
      const filterName = filtersCategory[filterId].filterCategory
      allFilters[filterName] = {id: filterId, ...filters?.[filterId]}
    });
    return allFilters;
  }, [filters])

  return allFiltersInView;
 
}

export default useFilters