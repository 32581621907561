import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtService from "src/app/auth/services/jwtService";

const login = createAsyncThunk(
  "loginSlice/login",
  async (payload, { dispatch }) => {
    try {
      const { email, password } = payload;
      // Esegue il login
      await jwtService.signInWithEmailAndPassword(email, password);

      // Aggiorna lo stato per indicare che la sessione non è scaduta
      await dispatch(setSessionExpired({ isExpired: false }));

      // Imposta l'autenticazione come avvenuta con successo
      await dispatch(setIsAuthenticated(true));

      // Ritorna true per indicare che il login è avvenuto con successo
      return true;
    } catch (error) {
      console.error("Errore durante il login:", error);

      // Se il login fallisce, imposta l'autenticazione a false
      await dispatch(setIsAuthenticated(false));

      // Imposta l'errore a true per indicare che c'è stato un problema
      await dispatch(setError(true));

      // Ritorna false per indicare che il login non è riuscito
      return false;
    }
  }
);

// const login = createAsyncThunk(
//   "loginSlice/login",
//   async (payload, { dispatch }) => {
//     try {
//       const { email, password } = payload;
//       await jwtService.signInWithEmailAndPassword(email, password);
//       await dispatch(setSessionExpired(false));
//       await dispatch(setIsAuthenticated(true));
//       return true;
//     } catch (error) {
//       console.log('errore in loginSlice login: ', error);
//       // console.log(error);
//       await dispatch(setIsAuthenticated(false));
//       await dispatch(setError(true));
//       return false;
//     }
//   }
// );

const refresh = createAsyncThunk(
  "loginSlice/refresh",
  async (payload, { dispatch }) => {
    const { newTokens } = payload;
    try {
      if (newTokens) {
        await jwtService.refreshToken();
      }
      await dispatch(setIsAuthenticated(true));
    } catch (error) {
      await dispatch(setError(true));
      return false;
    }
    return true;
  }
);

const logout = createAsyncThunk(
  "loginSlice/logout",
  async (data, { dispatch }) => {
    try {
      // console.log("logout action");
      await jwtService.logout();
      await dispatch(setIsAuthenticated(false));
      return true;
    } catch (error) {
      console.log(error);
      await dispatch(setError(true));
      return false;
    }
  }
);

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: {
    dataLoading: true,
    isAuthenticated: null,
    error: null,
    sessionExpired: false,
  },
  reducers: {
    setDataLoading: (state, action) => {
      state.dataLoading = action.payload;
    },
    /**
     * Sets the error state with the payload from the action.
     * If the variable error is set to true then the access denied popup will be shown
     *
     * @param {Object} state - The current state object.
     * @param {Object} action - The action object containing the payload.
     * @return {void} This function does not return anything.
     */
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setIsAuthenticated: (state, action) => {
      // console.log('setIsAuthenticated action');
      state.isAuthenticated = action.payload;
    },
    setSessionExpired: (state, action) => {
      if (!action.payload) return;
      const { isExpired } = action.payload;
      state.sessionExpired = isExpired;
      // console.log(
      //   "in loginSlice sessionExpired variable updated as: ",
      //   state.sessionExpired
      // );
    },
  },
  // extraReducers: (builder) => {},
});

export const selectSessionExpired = (state) => state.login.sessionExpired;

export const {
  setError,
  clearError,
  setIsAuthenticated,
  setSessionExpired,
  isSessionExpired,
} = loginSlice.actions;

export default loginSlice.reducer;

export const loginSliceActions = {
  refresh,
  login,
  logout,
  ...loginSlice.actions,
};
