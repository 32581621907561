import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useActiveViews = (viewName) => {
  const navigation = useSelector((state) => state.fuse.navigation.entities);
  const user = useSelector((state) => state.user);
  const tenant = useSelector((state) => state.tenants.list[user.selectedTenant]);
  const views = useSelector((state) => state.configuration.constants.view_name);
  const viewId = useMemo(() => {
    if(!views || !viewName) return null;
    return Object.keys(views).find((id) => views[id].view_name === viewName);
  }, [views, viewName]);

  let isUserActiveViewsReady = false;

  const userActiveViews = useMemo(() => {
    if(!tenant || !views || !navigation) return [];
    const visibleViews = tenant.roles?.find((role) => role._id === user.roleId)?.visibleViews.map((idView) => {
      const navigationId = Object.keys(navigation).find((id) => navigation[id].url === `/${views[idView].view_name}`);
      return navigationId ? {viewId: idView, ...navigation[navigationId]} : {viewId: idView, url: `/${views[idView].view_name}`};
    }).filter((e) => e);
    isUserActiveViewsReady = true;
    return visibleViews;
  }, [tenant, views, navigation]);

  const isViewActive = useMemo(() => {
    if (isUserActiveViewsReady === false) return undefined;
    return userActiveViews?.find((element) => element?.viewId === viewId) ? true : false;
  }, [userActiveViews, viewId])

  
  return [userActiveViews, isViewActive];
}

export default useActiveViews