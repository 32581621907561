import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    LOST_PASSWORD: 'Forgot password?',
    LOG_AS_GUEST_1: 'Don\'t have an account?',
    LOG_AS_GUEST_2: 'Sign in as guest',
    USER_MAIL : 'Username or email',
    BACK : 'Back',
    
};

const SIGN_IN_EN = createLanguageObject(EN, viewNames.SIGN_IN_VIEW);


export default SIGN_IN_EN;