import React, { useMemo } from 'react';
import TabsComponent from '../SharingComponents/TabsComponent';
import StationInfoChip from './StationInfoChip';
import ThresholdsChip from './ThresholdsChip';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const DashboardTab = ({enabledActions, stationId, loadingMinMax, loadingThresholds, maxMinMeasures, thresholds}) => {
  const { t, i18n } = useTranslation('evocs');
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const tabs = useMemo(() => {
    return {
      stationInfoChip: {
        header: handleTranslate('STATISTICS', true),
        content: <StationInfoChip stationId={stationId} loadingMinMax={loadingMinMax} maxMinMeasures={maxMinMeasures}/>
      },
      thresholdsChip: {
        header: handleTranslate('THRESHOLDS', true),
        content: <ThresholdsChip enabledActions={enabledActions} stationId={stationId} loadingThresholds={loadingThresholds} thresholds={thresholds}/> 
      }
    };
  }, [stationId, enabledActions, i18n.language, loadingMinMax, loadingThresholds]);

  return (
    <TabsComponent style={{maxHeight: "85vh", minHeight: "400px"}} paper tabs={tabs} swipeable/>
  );
}

export default DashboardTab;
