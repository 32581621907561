import WithAuth from "./WithAuth";
import ConfigurartionContent from "src/components/Configuration/ConfigurartionContent";
import ConfigurationHeader from "src/components/Configuration/ConfigurationHeader";
import { viewNames } from "app/configs/routesNames";
import CustomPageCarded from "./CustomPageCarded";
import Footer from "src/components/SharingComponents/Footer";

const Configuration = () => {
  return (
    <WithAuth
      viewName={viewNames.CONFIGURATION_VIEW}
      component={
        <CustomPageCarded
          header={< ConfigurationHeader/>}
          content={< ConfigurartionContent />}
          footer={<Footer />}
        />
      }
    />
  );
};

export default Configuration;
