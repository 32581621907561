import FusePageCarded from "@fuse/core/FusePageCarded";
import FileManagerHeader from "src/components/FileManager/FileManagerHeader";
import FileManagerList from "src/components/FileManager/FileManagerList";
import WithAuth from "./WithAuth";

const DailyReports = ({ sharedType }) => {
  return (
    <WithAuth
      component={
        <FusePageCarded
        header={<FileManagerHeader sharedType={sharedType} />}
        content={<FileManagerList sharedType={sharedType} />}
        />
      }
    />
  );
};

export default DailyReports;


