import FusePageCarded from "@fuse/core/FusePageCarded";
import WithAuth from "./WithAuth";
import AdministrationHeader from "src/components/Administration/AdministrationHeader";
import AdministrationContent from "src/components/Administration/AdministrationContent";
import { viewNames } from "app/configs/routesNames";

const Administration = () => {
  return (
    <WithAuth
      viewName={viewNames.ADMINISTRATION_VIEW}
      component={
        <FusePageCarded
          header={<AdministrationHeader/>}
          content={<AdministrationContent />}
        />
      }
    />
  );
};

export default Administration;
