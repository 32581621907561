import HelpIcon from '@mui/icons-material/Help';
import { Modal, Tooltip } from '@mui/material';
import { useState } from 'react';
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const styles = {
  containerStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "1rem",
    height: "fit-content",
    width: "95%",
    maxWidth: "800px",
    overflow: "hidden"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#2d3740",
    color: "white",
    fontWeight: "bold",
    padding: "2rem 1rem"
  },
  content: {
    background: "white",
    padding: "1rem"
  }
};

const InfoButton = ({ style, defaultopen = false, enableModal = false, fontSize = "medium", placement = "top", children }) => {
  const [open, setOpen] = useState(defaultopen);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };

  return (
    <div style={style}>
      <Tooltip title={enableModal ? "Info" : (children ? children : "Info")} placement={placement} arrow>
        <HelpIcon className='info-button' fontSize={fontSize} onClick={() => enableModal && setOpen(true)} />
      </Tooltip>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={styles.containerStyle}>
          <div style={styles.header}>
            <HelpIcon sx={{ color: "#74c365", mr: "1rem" }} fontSize='large' />
            <h2>Info</h2>
          </div>
          <div style={styles.content}>
            {children || <p> {handleTranslate('INSERT_INFO')}</p>}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InfoButton