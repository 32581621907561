import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectFuseCurrentLayoutConfig,
  selectToolbarTheme,
} from "src/app/store/fuse/settingsSlice";
import { selectFuseNavbar } from "src/app/store/fuse/navbarSlice";
import NavigationShortcuts from "../../shared-components/NavigationShortcuts";
import NavbarToggleButton from "../../shared-components/NavbarToggleButton";
import UserMenu from "../../shared-components/UserMenu";
import LogoCalliope from "../../../../app/asset/images/logoCalliope.png"
import AlertsMenu from "app/theme-layouts/shared-components/AlertsMenu";
import { getTenantsProPic } from "app/store/evocsSlice/tenants/tenantsActions";
import { readAlertsByUser } from "app/store/evocsSlice/user/userAction";
import useActiveViews from "src/app/hooks/useActiveViews";
import { routesNames } from "app/configs/routesNames";
import { Box } from "@mui/system";
import LanguageSwitcher from "app/theme-layouts/shared-components/LanguageSwitcher";

function ToolbarLayout1(props) {
  const dataLoading = useSelector((state) => state.login.dataLoading);
  const user = useSelector((state) => state.user);
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const [userActiveViews, isViewActive] = useActiveViews();
  const [tenantLogo, setTenantLogo] = useState();
  const sessionExpired = useSelector((state) => state?.login?.sessionExpired);
  const [alertIntervalId, setAlertIntervalId] = useState(null);

  const isAlertViewActive = useMemo(() => {
    if (!userActiveViews) return false;
    return userActiveViews.find((view) => view.url === routesNames.ALERTS_VIEW) ? true : false;
  }, [userActiveViews])


  useEffect(() => {
    user?.selectedTenant && getTenantsProPic([user.selectedTenant]).then((res) => {
      setTenantLogo("data:image/png;base64," + res.proPic)
    })
  }, [user.selectedTenant])

  useEffect(() => {
    if (!user) return;
    const intervalId = setInterval(() => {
      readAlertsByUser(user.id)
    }, 1000 * 60 * 5);
    setAlertIntervalId(intervalId);
    return () => {
      intervalId && clearInterval(intervalId);
    }
  }, [user])

  useEffect(() => {
    if (alertIntervalId && sessionExpired && sessionExpired == true) clearInterval(alertIntervalId);
  }, [sessionExpired])

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx("flex relative z-20 shadow-md", props.className)}
        color="default"
        sx={{
          height: "70px",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar sx={{ height: "100%"}}>
            {!dataLoading && config.navbar.display && config.navbar.position === "left" && (
              <>
                <Hidden lgDown>
                  {/* {(config.navbar.style === "style-3" ||
                    config.navbar.style === "style-3-dense") && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )} */}

                  {config.navbar.style === "style-1" && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}
            <div style={{display: "flex", height: "100%", width: "100%", maxWidth: "300px"}}>
              <img src={LogoCalliope} alt="" style={{ objectFit: "contain", userSelect: "none", pointerEvents: "none", width: "50%"}} />
              {tenantLogo && <img src={tenantLogo} alt="" style={{ objectFit: "contain", userSelect: "none", pointerEvents: "none", width: "50%" }} />}
            </div>

            <Hidden lgDown>
              <NavigationShortcuts />
            </Hidden>
            <div style={{display: "flex", marginLeft: "auto"}}>
              {isAlertViewActive && <AlertsMenu />}
              <UserMenu />
              <LanguageSwitcher />
            </div>
            {!dataLoading && config.navbar.display && config.navbar.position === "right" && (
              <>
                <Hidden lgDown>
                  {!navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
