import { Autocomplete, Checkbox, styled, TextField } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const CustomAutocomplete = styled(Autocomplete)(({ error }) => error && ({
  "& .MuiTextField-root": {
    "label.Mui-focused": { color: "red" },
  },
  "& .MuiOutlinedInput-root": {
    "fieldset": {
      borderColor: "red"
    },
    "&:hover fieldset": {
      borderColor: "red"
    },
    "&.Mui-focused fieldset": { borderColor: "red" },
  }
}));

const StationSelect = ({ style, stationsId, value, defaultValue, onChange, required = false, disabled = false, multiple = false, limitTags, isUserTenant = true, error = false, disablePortal=false}) => {
  const stations = useSelector((state) => state.stationsMongo.list);
  const [options, setOptions] = useState(stationsId || []);
  const [val, setValue] = useState(defaultValue ? defaultValue : multiple ? [] : null)

  useLayoutEffect(() => {
    setOptions(() => {
      if (!isUserTenant) {
        return stationsId?.map((station) => station.id);
      } else if (stationsId) {
        return stationsId;
      } else {
        return Object.keys(stations);
      }
    });
  }, [isUserTenant, stationsId])

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  }

  const getStationLabel = (option) => {
    if (isUserTenant) return stations[option]?.serial || "";
    else return stationsId.find((station) => station.id === option)?.serial || "";
  }

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  return (
    <CustomAutocomplete
      disablePortal={disablePortal}
      id="station-autocomplete"
      error={error}
      sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}
      limitTags={limitTags}
      disabled={disabled}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      onChange={(_, selected) => handleChange(selected)}
      options={options || []}
      value={val}
      getOptionLabel={(option) => getStationLabel(option)}
      renderInput={(params) => <TextField {...params} label={required ? `${handleTranslate('STATION', true)}*` : `${handleTranslate('STATION', true)}`} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && <Checkbox checked={selected} />}
          {getStationLabel(option)}
        </li>
      )}
    />
  )
}

export default StationSelect;