import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Meteo",
    TABLE_TITLE: "Dati meteorologici open",
};

const OPEN_DATA_METEOROLOGY_IT = createLanguageObject(IT, viewNames.OPEN_DATA_METEOROLOGY_VIEW);

export default OPEN_DATA_METEOROLOGY_IT;