import { useDispatch, useSelector } from "react-redux";
import FuseShortcuts from "@fuse/core/FuseShortcuts";
import { selectFlatNavigation } from "src/app/store/fuse/navigationSlice";

function NavigationShortcuts(props) {
  const { variant, className } = props;
  const dispatch = useDispatch();
  const navigation = useSelector(selectFlatNavigation);

  return (
    <FuseShortcuts
      className={className}
      variant={variant}
      navigation={navigation}
    />
  );
}

export default NavigationShortcuts;
