import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from "@mui/material";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const OdourTypeSelect = ({style, odourTypesId, value, defaultValue, onChange, multiple=false, required=false, nullLabel=true}) => {
  const odourTypes = useSelector((state) => state.configuration.constants?.odour_type);
  const options = useMemo(() => {
    if(odourTypesId) {
      return odourTypesId;
    }else {
      return Object.keys(odourTypes);
    }
  }, [odourTypesId])
  const [val, setValue] = useState(defaultValue ? defaultValue : value !== undefined ? value : multiple ? [] : "")

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);
  
  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const { t } = useTranslation("evocs");
const handleTranslate = (textId, general = false) => {
   if (general) return translate(t, textId);
   return translate(t, textId, viewNames.DASHBOARD_VIEW);
};

  return(
    <FormControl sx={{...style, width: "100%", minWidth: "100px", maxWidth: "300px"}}>
        <InputLabel id="odour-type-select-label">{required ? `${handleTranslate('TYPE', true)}*` : `${handleTranslate('TYPE', true)}`}</InputLabel>
        <Select
            labelId="odour-type-select-label"
            value={val}
            multiple={multiple}
            renderValue={(selected) => multiple ? selected.map((odourLevelId) =>handleTranslate(`ODOUR_TYPE_${odourTypes[odourTypeId]?.odour_type.toUpperCase()}`, true)).join(", ") : handleTranslate(`ODOUR_TYPE_${odourTypes[selected]?.odour_type?.toUpperCase()}`, true)}
            label={required ? `${handleTranslate('TYPE', true)}*`: `${handleTranslate('TYPE', true)}`}
            onChange={(e) => handleChange(e.target.value)}
        >
          {!multiple && nullLabel &&
            <MenuItem value={""}>
              <em>Tutti</em>
            </MenuItem>
          }
            {options?.map((odourTypeId, index) => (
            <MenuItem key={index} value={odourTypeId}>
                {multiple && <Checkbox checked={val?.indexOf(odourTypeId) > -1} /> }
                {`${handleTranslate(`ODOUR_TYPE_${odourTypes[odourTypeId]?.odour_type?.toUpperCase()}`, true)}`}
            </MenuItem>
            ))}
        </Select>
    </FormControl>     
  )
}

export default OdourTypeSelect;