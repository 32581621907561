import { Checkbox, CircularProgress, Divider, Paper, styled } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import useMobile from "src/app/hooks/useMobile";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LoadingGif from "../FileManager/LoadingGif";

const CustomMenu = styled(Paper)(({theme, icon}) => ({
  position: "relative",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "40px",
  ":hover": {
    '& div': { // Nome utilizzato per assegnare il colore del figlio all'hover
      display: "flex"
    },
  }
})); 

const MenuContent = styled(Paper)(({theme}) => ({
  position: "absolute",
  display: "none",
  flexDirection: "column",
  top: 0,
  right: 0,
  padding: "1rem",
  width: "fit-content",
})); 

// const labelsXOrientation = (isMobile, isTablet, unitOnStation) => {
//   let maxRotation = 0;
//   let minRotation = 0;
//   // if (isMobile || unitOnStation > 2 || (isTablet && unitOnStation > 1)) {
//   if (isMobile) {
//     maxRotation = 90;
//     minRotation = 90;
//   }

//   return { maxRotation, minRotation };
// };

// const chartOptions = (measureName, measureUnit, isMobile, unitOnStation, maxRotation, minRotation, color) => ({
//   maintainAspectRatio: false,
//   responsive: true,
//   normalized: true,
//   hitRadius: 20,
//   hoverRadius: 10,
//   cubicInterpolationMode: "monotone",
//   scales: {
//     y: {
//       ticks: {
//         callback: (value) => {
//           let unit = measureUnit? " " + measureUnit : "";
//           return (value ? value.toFixed(3).toString() + unit : "")
//         },
//         maxTicksLimit: 8,
//       },
//       grid: {
//         drawOnChartArea: true,
//         color: "#ccc",
//         lineWidth: 0.8,
//       },
//     },
//     x: {
//       ticks: {
//         // maxTicksLimit: 6,
//         maxRotation: maxRotation,
//         minRotation: minRotation,
//       },
//       afterBuildTicks: (axis) => {
//         if (axis.ticks.length > 2) {
//           let newTicks = axis.ticks;
//           const minXTicks = 6;
//           const firstElement = newTicks.shift();
//           const lastElement = newTicks.pop();
//           const step = Math.floor(newTicks.length / (minXTicks));
//           newTicks = newTicks.filter((elem, index) => {
//             if (index < step - 3) return false;
//             if (index >= (axis.ticks.length - step + 3)) return false;
//             return index % step === 0 && index / step < minXTicks;
//           });
//           newTicks = [firstElement, ...newTicks, lastElement];
//           axis.ticks = newTicks;
//         }
//       },
//       grid: {
//         drawOnChartArea: true,
//         color: "#ccc",
//         lineWidth: 0.7,
//       },
//     },
//   },
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {
//       backgroundColor: defaultTooltipBackAplha,
//       titleFont: {
//         size: 11,
//         weight: "bold",
//       },
//       titleColor: "white",
//       displayColors: false,
//       callbacks: {
//         title: (context) => measureName.toUpperCase(),
//         label: (context) => `Data: ${context?.label || ""}`,
//         footer: (context) => {
//           let unit = measureUnit? " " + measureUnit : "";
//           return `Valore: ${context?.[0].raw.toFixed(4).toString() + unit || ""}`
//         },
//       },
//     },
//   },
// });

const drawMinMaxLine = {
  id: "drawMinMaxLine",
  beforeDatasetsDraw(chart, args, options) {
    // console.log(options);
    const {ctx, chartArea: {top, right, bottom, left, width, height}, scales} = chart;
    // console.log("chart ", chart)
    // if(options.display?.length <= 0 || options.minMax?.length <= 0) return;

    ctx.save();

    Object.keys(options).forEach((yId, index) => {
      const yScale = options[yId];

      if(yScale.minMax !== null) {
        // draw min max line
        ctx.setLineDash([10, 20]);
        ctx.strokeStyle = "blue";
        ctx.strokeRect(left, scales[yId].getPixelForValue(yScale.minMax.min), width, 0);
        ctx.strokeStyle = "red";
        ctx.strokeRect(left, scales[yId].getPixelForValue(yScale.minMax.max), width, 0);
        ctx.restore();
        // draw text
        ctx.font = 'bold 12px "Microsoft Yahei"';
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        const minText = `MIN: ${yScale.minMax.min} ${yScale.measureUnit || ""}`, maxText = `MAX: ${yScale.minMax.max} ${yScale.measureUnit || ""}`;
        const minTextSpace = index === 0 ? ctx.measureText(minText).width/2 + left+10 : right - ctx.measureText(minText).width/2 - 10, maxTextSpace = index === 0 ? ctx.measureText(maxText).width/2 + left+10 : right - ctx.measureText(minText).width/2 - 10;
        ctx.fillText(minText, minTextSpace, scales[yId].getPixelForValue(yScale.minMax.min) - 4);
        ctx.fillText(maxText, maxTextSpace, scales[yId].getPixelForValue(yScale.minMax.max) - 4);
        ctx.restore();
      }
      if(yScale.avg !== null) {
        // draw avg line
        ctx.setLineDash([10, 20]);
        ctx.strokeStyle = "black";
        ctx.strokeRect(left, scales[yId].getPixelForValue(yScale.avg), width, 0);
        ctx.restore();
        // draw text
        ctx.font = 'bold 12px "Microsoft Yahei"';
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        const avgText = `AVG: ${yScale.avg} ${yScale.measureUnit || ""}`;
        const avgTextSpace = index === 0 ? ctx.measureText(avgText).width/2 + left+10 : right - ctx.measureText(avgText).width/2 - 10;
        ctx.fillText(avgText, avgTextSpace, scales[yId].getPixelForValue(yScale.avg) - 4);
        ctx.restore();
      }
    });
  }
}


const graphMenuGroups = {
  general: {
    title: "Generali",
    options: [
      {
        id: "matrix",
        groupId: "general",
        label: "Correlation matrix",
      },
      {
        id: "r2",
        groupId: "general",
        label: "R2"
      },
    ],
  },
  measure: {
    options: [
      {
        id: "minMax",
        label: "Min & Max"
      },
      {
        id: "avg",
        label: "Average"
      },
    ]
  }
};

const MenuGroup = ({title, options, checked, onChange}) => {

  const handleClickLabel = (item) => {
    const value = !checked.includes(item.id);
    onChange && onChange(item, value);
  }

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <label style={{fontSize: "0.9em", fontWeight: "bold", whiteSpace: "nowrap"}}>{title}</label>
      {options?.map((item, index) => (
        <div key={item.id} style={{display: "flex", alignItems: "center"}}>
          <Checkbox size="small" checked={checked?.includes(item.id) || false} onChange={(e, value) => onChange(item, value)}/>
          <label style={{cursor: "pointer", fontSize: "0.8em", whiteSpace: "nowrap"}} onClick={() => handleClickLabel(item)}>{item.label}</label>
        </div>
      ))}
    </div>
  )
}

const GraphMenu = ({style, groups, checked, onChange}) => {

  const getCheckedOptions = useCallback((groupId) => {
    const checkedIds = [];
    checked.forEach((element) => {
      if(element.groupId === groupId) checkedIds.push(element.id); 
    });
    return checkedIds;
  }, [checked]);


  return (
    <div style={style}>
      <CustomMenu>
        <FilterAltIcon />
        <MenuContent>
          {groups && Object.keys(groups).map((groupId, index) => groups[groupId].options.length > 0 && (
            <Fragment key={groupId}>
              <MenuGroup title={groups[groupId].title} options={groups[groupId].options} checked={getCheckedOptions(groupId)} onChange={(item, value) => onChange(item, value)}/>
              {index === 0 && Object.keys(groups).length > 1 && <Divider sx={{marginBottom: "0.5rem"}}/>}
              {/* {index < Object.keys(groups).length - 1 && <Divider sx={{marginBottom: "0.5rem"}}/>} */}
            </Fragment>
          ))}
        </MenuContent>
      </CustomMenu>
    </div>
  )
}

const CorrelationMatrix = ({style, display, image, loading}) => {

  return display && (
    <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0, 0, 0, 0.5)"}}>
      <div style={{display: "flex", height: "100%", alignItems: "center", justifyContent: "center"}}>
        {!loading ?
          <img style={{height: "100%", objectFit: "contain"}} src={image}/>
          :
          <CircularProgress sx={{color: "#92c870"}}/>
        }
      </div>
    </div>
  )
}

const LineGraph = memo(({ datasets = [], labels = [], tooltips=[], measuresUnit=[], disableMenu=false, loadingMatrix=false, matrixImage, avg=[], minMax=[], minMaxRange=[], graphShadow = true, checkedMenu=[], onChangeMenu}) => {
  const [xs, sm, md] = useMobile();
  const [menuGroups, setMenuGroups] = useState({});
  const [menuChecked, setMenuChecked] = useState([]);
  const chartRef = useRef();
  const [menuStyle, setMenuStyle] = useState();
  const displayMatrix = useMemo(() => {
    return menuGroups.general?.options.find((option) => option.id === "matrix") && menuChecked.find((option) => option.id === "matrix") ? true : false;
  }, [menuGroups, menuChecked])

  const getMenuPosition = (chart) => chart.chartArea && ({
    position: "absolute",
    top: `${Math.floor(chart.chartArea.top + 5)}px`,
    right: datasets.length > 1 ? "7rem" : (xs || sm) ? "2rem" : "5rem",
    zIndex: 10,
    // left: `${Math.floor(chart.chartArea.width + 20)}px`
  });

  useEffect(() => {
    let groups = {};
    const generalOptions = datasets?.length > 1 ? graphMenuGroups.general.options.filter((element) => element.id !== "matrix") : graphMenuGroups.general.options.filter((element) => element.id !== "r2")
    groups.general = {...graphMenuGroups.general, options: generalOptions};
    datasets.forEach((dataset) => {
      const axisOptions = [];
      graphMenuGroups.measure.options.forEach((element) => axisOptions.push({...element, groupId: dataset.yAxisID}));
      groups[dataset.yAxisID] = {title: dataset.label, options: axisOptions};
    });
    setMenuStyle(getMenuPosition(chartRef.current));
    setMenuGroups(groups);
  }, [datasets])

  useEffect(() => {
    setMenuChecked(checkedMenu);
  }, [checkedMenu])

  const handleResize = (chart, options) => {
    setMenuStyle(getMenuPosition(chart));
  };

  const getTooltip = (context) => {
    const index = Number(context.tooltipItems[0]?.dataset.yAxisID.slice(1)) || 0;
    return tooltips[index];
  };

  const handleChangeMenu = (item, value) => {
    let newChecked = [...menuChecked];
    if(value) {
      newChecked.push({id: item.id, groupId: item.groupId});
    }else {
      const elementIndex = menuChecked.findIndex((element) => element.groupId == item.groupId && element.id === item.id);
      newChecked = menuChecked.toSpliced(elementIndex, 1);
    }
    setMenuChecked(newChecked);
    onChangeMenu && onChangeMenu(newChecked, item, value);
  };


  return (
    <Paper sx={{position: "relative", boxShadow: !graphShadow && "none", width: "100%" }}>
      {!disableMenu && <GraphMenu style={menuStyle} groups={menuGroups} checked={menuChecked} onChange={handleChangeMenu}/>}
      <Line 
      ref={chartRef}
      data={{
        labels: labels,
        datasets: datasets
      }}
      plugins={[drawMinMaxLine]}
      options={{
        onResize: handleResize,
        maintainAspectRatio: false,
        responsive: true,
        normalized: true,
        hitRadius: 20,
        hoverRadius: 10,
        cubicInterpolationMode: "monotone",
        scales: {
          y0: {
            ticks: {
              callback: (value) => {
                const unit = measuresUnit[0] ? " " + measuresUnit[0] : "";
                return (value ? value.toFixed(3).toString() + unit : "")
              },
              maxTicksLimit: 8,
            },
            grid: {
              drawOnChartArea: true,
              color: "#ccc",
              lineWidth: 0.8,
            },
            min: minMaxRange[0]?.min,
            max: minMaxRange[0]?.max
          },
          y1: {
            ticks: {
              callback: (value) => {
                const unit = measuresUnit[1]? " " + measuresUnit[1] : "";
                return (value ? value.toFixed(3).toString() + unit : "")
              },
              maxTicksLimit: 8,
            },
            type: 'linear',
            display: datasets.length > 1,
            position: 'right',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            min: minMaxRange[1]?.min,
            max: minMaxRange[1]?.max
          },
          x: {
            // ticks: {
            //   maxRotation: maxRotation,
            //   minRotation: minRotation,
            // },
            afterBuildTicks: (axis) => {
              if (axis.ticks.length > 2) {
                let newTicks = axis.ticks;
                const minXTicks = 6;
                const firstElement = newTicks.shift();
                const lastElement = newTicks.pop();
                const step = Math.floor(newTicks.length / (minXTicks));
                newTicks = newTicks.filter((elem, index) => {
                  if (index < step - 3) return false;
                  if (index >= (axis.ticks.length - step + 3)) return false;
                  return index % step === 0 && index / step < minXTicks;
                });
                newTicks = [firstElement, ...newTicks, lastElement];
                axis.ticks = newTicks;
              }
            },
            grid: {
              drawOnChartArea: true,
              color: "#ccc",
              lineWidth: 0.7,
            },
          },
        },
        plugins: {
          legend: {
            display: datasets.length > 1,
            labels: {
              color: "black",
            },
            onClick: null
          },
          tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            titleFont: {
              size: 11,
              weight: "bold",
            },
            titleColor: "white",
            displayColors: false,
            callbacks: getTooltip,
          },
          drawMinMaxLine: {
            y0: {
              avg: menuChecked?.find((element) => element.groupId === "y0" && element.id === "avg") ? avg[0] : null,
              minMax: menuChecked?.find((element) => element.groupId === "y0" && element.id === "minMax") ? minMax[0] : null,
              measureUnit: measuresUnit[0]
            },
            y1: {
              avg: menuChecked?.find((element) => element.groupId === "y1" && element.id === "avg") ? avg[1] : null,
              minMax: menuChecked?.find((element) => element.groupId === "y1" && element.id === "minMax") ? minMax[1] : null,
              measureUnit: measuresUnit[1]
            }
          }
        },
      }}/>
      <CorrelationMatrix display={displayMatrix} loading={loadingMatrix} image={matrixImage}/>
    </Paper>
  );
});

export default LineGraph;
