// import Pinwheel from "../../gif/Pinwheel.gif"
import { point } from "leaflet";
import { useEffect, useState } from "react";
import LoadReport from 'src/app/asset/gif/loadReport.gif';

const styles = {
  gifImg: {
    display: "block",
    height: "50px",
    width: "50px",
    userSelect: "none",
    pointerEvents: "none"
  },
};

const LoadingReportGif = ({ visible = true }) => {

  return (
    <>
      {visible &&
        <div style={{ display: "flex", flexDirection: "column", width: "fit-content", alignItems: "center", margin: "0 auto", userSelect: "none" }}>
          <img src={LoadReport} style={styles.gifImg} />
        </div>
      }
    </>
  );
}

export default LoadingReportGif;