import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Odor Reports",
    ODOUR_DISTRIBUTION: "Odour Distribution",
    ODOUR_REPORTS_DETECTED: "Odour Reports Detected",
};

const ODOR_REPORTS_EN = createLanguageObject(EN, viewNames.ODOR_REPORTS_VIEW);


export default ODOR_REPORTS_EN;