import FusePageCarded from "@fuse/core/FusePageCarded";
import OdorReportsContent from "src/components/OdorReports/OdorReportsContent";
import OdorReportsHeader from "src/components/OdorReports/OdorReportsHeader";
import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";

const OdorReports = () => {
  return (
    <WithAuth
    viewName={viewNames.ODOR_REPORTS_VIEW}
      component={
        <FusePageCarded
          header={<OdorReportsHeader />}
          content={<OdorReportsContent />}
        />
      }
    />
  );
};

export default OdorReports;
