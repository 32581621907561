import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const EN = {
    HEADER : "My user profile",
    NEW_PASSWORD: "New password",
    PASSWORD_NOT_MATCH: "The passwords do not match",
    PASSWORD_ERROR_LENGTH: "The password must be at least 8 characters",
    CHANGE_PASSWORD: "Change password",
};

const USER_PROFILE_EN = createLanguageObject(EN, viewNames.USER_PROFILE_VIEW);



export default USER_PROFILE_EN;