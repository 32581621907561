import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    NO_NOTIF_AVAILABLE: "There are no new notifications",
};

const NO_NOTIFICATION_MESSAGE_EN = createLanguageObject(EN, sharingComponentNames.NO_NOTIFICATION_MESSAGE);

export default NO_NOTIFICATION_MESSAGE_EN;