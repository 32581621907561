import { Box } from "@mui/system"
import TabsComponent from "../SharingComponents/TabsComponent"
import TenantsConfiguration from "./TenantsConfiguration"
import RolesConfiguration from "./RolesConfiguration"
import UsersConfiguration from "./UsersConfiguration"
import { useCallback, useState } from "react"
import Menu from "./Menu"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import LoadingGif from "../FileManager/LoadingGif"
import { IconButton } from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SharingConfiguration from "./SharingConfiguration"
import FiltersConfiguration from "./FiltersConfiguration"
import ParamsConfiguration from "./ParamsConfiguration"
import EngineeringIcon from "@mui/icons-material/Engineering";
import CloseIcon from '@mui/icons-material/Close';
import useActions from "src/app/hooks/useActions"
import { viewNames } from "app/configs/routesNames"
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";
const viewName = viewNames.ADMINISTRATION_VIEW;

const TenantListItem = ({ tenantId, selected, onClick }) => {
  const tenants = useSelector((state) => state.tenants.list);

  const className = useMemo(() => {
    if (!selected) return "station-item-list close";
    return "station-item-list selected"
  }, [selected]);

  return (
    <div className={className} style={{ display: "flex", alignItems: "center", padding: "1rem", cursor: "pointer" }} onClick={() => onClick(tenantId)}>
      <IconButton style={{ marginRight: "auto" }}>
        <ArrowBackIosIcon sx={{ color: "inherit" }} fontSize="small" />
      </IconButton>
      <span>{tenants[tenantId].name}</span>
    </div>
  )
}

const TenantsList = ({ selectedTenants, onClickItem }) => {
  const tenants = useSelector((state) => state.tenants.list);
  const loading = useSelector((state) => state.tenants.loading);
  const { t } = useTranslation("evocs");
  
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div>
      {loading ? <LoadingGif />
        :
        tenants && Object.keys(tenants).length > 0 ? Object.keys(tenants).map((tenantId, index) => (
          <TenantListItem key={index} tenantId={tenantId} selected={selectedTenants?.includes(tenantId)} onClick={onClickItem} />
        ))
          :
          <label> {handleTranslate('NO_TENANTS')} </label>
      }

    </div>
  );
}

const AdministrationContent = () => {
  const user = useSelector((state) => state.user);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };
  const userActions = useActions(viewName);
  const enabledActions = useMemo(() => {
    return userActions.map((action) => action.name);
  }, [userActions]);
  const [selectedTenants, setSelectedTenants] = useState([user.selectedTenant]);

  const handleCloseTenant = useCallback((tenantId) => {
    let newSelected = [...selectedTenants.reverse()];
    newSelected = newSelected.filter((id) => id != tenantId);
    setSelectedTenants(newSelected.reverse());
  }, [selectedTenants]);

  const handleClickTenant = useCallback((tenantId) => {
    let newSelected = [...selectedTenants.reverse()];
    if (selectedTenants.includes(tenantId)) {
      newSelected = newSelected.filter((id) => id !== tenantId);
    } else {
      newSelected.push(tenantId);
    }
    setSelectedTenants(newSelected.reverse());
  }, [selectedTenants])

  const tabs = useMemo(() => ({
    info: {
      header: handleTranslate('TENANTS', true),
      content: <TenantsConfiguration selectedTenants={selectedTenants} enabledActions={enabledActions} handleCloseTenant={handleCloseTenant} />
    },
    users: {
      header: handleTranslate('USERS', true),
      content: <UsersConfiguration selectedTenants={selectedTenants} enabledActions={enabledActions} />
    },
    roles: {
      header:  handleTranslate('ROLES', true),
      content: <RolesConfiguration selectedTenants={selectedTenants} />
    },
    filters: {
      header: handleTranslate('FILTERS', true),
      content: <FiltersConfiguration selectedTenants={selectedTenants} />
    },
    params: {
      header: handleTranslate('GLOBAL_VARIABLES', true),
      content: <ParamsConfiguration />
    },
    sharing: {
      header: handleTranslate('SHARING', true),
      content: <SharingConfiguration selectedTenants={selectedTenants} />
    },
  }), [selectedTenants, enabledActions, t]);

  const enabledTabs = useMemo(() => {
    const allTabs = { ...tabs };
    if (user.tenantId !== tilebytesTenantId) delete allTabs.params;
    if (!enabledActions.includes("manage-role")) delete allTabs.roles;
    if (!enabledActions.includes("manage-page-filters")) delete allTabs.filters;
    if (!enabledActions.includes("sharing")) delete allTabs.sharing;
    if (!enabledActions.includes("update-info-user") && !enabledActions.includes("invite-user")) delete allTabs.users;
    return allTabs;
  }, [tabs]);


  return (
    <Box padding={{ xs: "1rem", sm: "2rem 5rem" }} overflow={"visible"}>
      <div style={{ display: "flex", gap: "1rem", height: "80vh" }}>
        {user.selectedTenant === tilebytesTenantId &&
          <Menu defaultOpen={false} openIcon={<EngineeringIcon />} closeIcon={<CloseIcon sx={{ color: "red" }} />} rightPos>
            <TenantsList selectedTenants={selectedTenants} onClickItem={handleClickTenant} />
          </Menu>
        }
        <TabsComponent style={{ height: "80vh", width: "100%" }} paper tabs={enabledTabs} />
      </div>

    </Box>
  )
}

export default AdministrationContent;