
export const fileTypes = {

    DAILY_REPORT: 'dailyReports',
    MONTHLY_REPORT: 'monthlyReports',
    GENERIC: 'generic',
}

export const getFileExtension = (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) return ""; // No extension found
    return filename.substring(lastDotIndex + 1);
  }