import UserInvitation from "src/views/UserInvitation";
import { routesNames } from "app/configs/routesNames";

const UserInvitationConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      path: routesNames.USER_INVITATION_VIEW,
      element: <UserInvitation />,
    },
  ],
};

export default UserInvitationConfig;
