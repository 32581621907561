import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import GraphSwitcher from '../StationHandling/GraphSwitcher';
import LoadingGif from '../FileManager/LoadingGif';
import { viewNames } from 'app/configs/routesNames';
import SingleStationHandlingMap from './SingleStationHandlingMap';
import useFilters from 'src/app/hooks/useFilters';
import useActions from 'src/app/hooks/useActions';
import { getCuMeasures } from 'app/store/evocsSlice/controlUnits/cuActions';
import { getStationSensors } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import { isMeasureGps, isMeasureMultimedia, isSensorGps, isSensorMultimedia } from 'app/store/evocsSlice/sensors/sensorsActions';

const viewName = viewNames.SINGLE_STATION_VIEW;

const ChildrensList = ({stationId, station, displayMap, enableLastPositionMap, enableMapRoute, isLoading=false, isLive=false}) => {
  const userActions = useActions(viewNames.STATIONS_VIEW);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const filters = useFilters(viewName);
  const [childrens, setChildrens] = useState([]);
  const allStationMeasures = useMemo(() => {
    const measures = [];
    getStationSensors(stationId).forEach((sensorId) => {
      sensors[sensorId]?.measures.forEach((measureId) => !isMeasureGps(measureId) && !isMeasureMultimedia(measureId) && measures.push({
        measureId: measureId,
        sensor: {sensorId, ...sensors[sensorId]}
      }));
    });
    return measures;
  }, [stationId])

  const timeRangeInView = filters.timeRange;
  const measureSelect = filters.measure;

  const newCuElement = useCallback((cuId, isCompare=false, selectedCompare=null, selectedSensor=null, selectedMeasure=null) => {
    return {id: cuId, isCompare, selectedCompare, selectedSensor, selectedMeasure};
  }, []);
  
  const newSensorElement = useCallback((sensorId, isCompare=false, selectedCompare=null, selectedMeasure=null) => {
    return {id: sensorId, isCompare, selectedCompare, selectedMeasure};
  }, []);

  useEffect(() => {
    const list = [];
    station.list_sensors.forEach((sensorId) => {
      if(sensors[sensorId]?.available === 1 && !isSensorGps(sensorId) && !isSensorMultimedia(sensorId)) {
        const childrenElement = childrens.find((element) => element.id === sensorId);
        if (!measureSelect.selected || measureSelect.selected.length === 0) {
          if (childrenElement) list.push(childrenElement);
          else list.push(newSensorElement(sensorId));
        }else if(measureSelect.selected.every((measureId) => sensors[sensorId]?.measures.includes(measureId))) {
          const measureSelected = measureSelect.selected[measureSelect.selected.length - 1];
          if(childrenElement) {
            childrenElement.selectedMeasure = measureSelected;
            list.push(childrenElement);
          }
          else list.push(newSensorElement(sensorId, false, null, measureSelected));
        }
      }
    });
    station.list_cu.forEach((cuId) => {
      if(controlUnits[cuId]?.available === 0) return;
      const childrenElement = childrens.find((element) => element.id === cuId);
      if(!measureSelect.selected || measureSelect.selected.length === 0) {
        if(childrenElement) list.push(childrenElement);
        else list.push(newCuElement(cuId));
        return;
      }
      const cuMeasures = getCuMeasures(cuId, true);
      if(measureSelect.selected.every((measureId) => cuMeasures.includes(measureId))) {
        const measureSelcted = measureSelect.selected[measureSelect.selected.length-1];
        const sensorSelected = controlUnits[cuId].list_sensors.find((sensorId) => sensors[sensorId]?.measures.includes(measureSelcted));
        if(childrenElement) {
          childrenElement.selectedSensor = sensorSelected;
          childrenElement.selectedMeasure = measureSelcted;
          list.push(childrenElement);
        }else 
          list.push(newCuElement(cuId, false, null, sensorSelected, measureSelcted));
      }
    });
    setChildrens(list);
  }, [measureSelect.selected])

  const handleChangeSensor = (unitId, sensorId) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedSensor = sensorId;
    setChildrens(newChildrens);
  }

  const handleChangeMeasure = (unitId, measureId) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedMeasure = measureId;
    setChildrens(newChildrens);
  }

  const handleChangeCompare = (unitId, isCompare) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].isCompare = isCompare;
    setChildrens(newChildrens);
  }

  const handleChangeSelectedCompare = (unitId, compare) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedCompare = compare;
    setChildrens(newChildrens);
  }
  
  return(
    <div style={{display: "flex", flexDirection: "column", gap: "2rem", marginBottom: "4rem"}}>
    <SingleStationHandlingMap stationId={stationId} displayMap={displayMap} enableLastPosition={enableLastPositionMap} enableRoute={enableMapRoute}/>
    {
    !isLoading ? childrens.map((unit) => (
    <GraphSwitcher key={unit.id} disableSensorFilter userActions={userActions} unit={unit} startDate={timeRangeInView.startDate} endDate={timeRangeInView.endDate} sensorGraphShadow={false}
    compareOptions={allStationMeasures} onChangeSensor={handleChangeSensor} onChangeMeasure={handleChangeMeasure} onChangeCompare={handleChangeCompare} onChangeSelectedCompare={handleChangeSelectedCompare} isLive={isLive}/>
    )):
    <LoadingGif />
    }
    </div>
  )
}

export default ChildrensList;
  