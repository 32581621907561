import { headersNames } from "src/utilities/headersNames";

const FileManagerHeader = ({ sharedType }) => {
  return (
    <div className='view-header'>
      {
      sharedType === "monthly" ? 
      <span className='title'>{headersNames.MONTHLY_REPORTS_VIEW_HEADER}</span>
      : sharedType === "daily" ?
      <span className='title'>{headersNames.DAILY_REPORTS_VIEW_HEADER}</span>
      : <></>
    }
      
    </div>
  );
};

export default FileManagerHeader;
