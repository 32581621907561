import { useState, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Popover, MenuItem, Button } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import styles from "./DownloadSet.module.css";
import styles from "./DownloadSet.module.css";
import { getUnitFileData } from "app/store/evocsSlice/reports/reportsActions";
import { CSVLink } from "react-csv";
import { TIME_FORMAT } from "src/components/StationHandling/utils";
import { formatDate } from "src/components/StationHandling/utils";
import { display } from "@mui/system";
import { getStationReportInfo, getCuReportInfo, getSensorReportInfo } from 'app/store/evocsSlice/reports/reportsActions';
import DynamicReportPDF from 'src/components/DynamicFile/DynamicReportPDF';
import { PDFDownloadLink } from "@react-pdf/renderer";
import LoadingReportGif from "../../FileManager/LoadingReportGif";
import { BsFiletypeCsv, BsFiletypeXls, BsFilePdf, BsFiletypeJson } from "react-icons/bs";

const DownloadSet = ({
  startingDate,
  endingDate,
  stationId,
  cuId,
  sensorId,
  unitSerial,
  // disableCsv = false, disableExcel = false, disableJson = false, disablePdf = sensorId || stationId ? false : true
  disableCsv = false, disableExcel = false, disableJson = false, disablePdf = false
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const tenantId = useSelector((state) => state.user.selectedTenant);
  const isStation = stationId ? true : false;
  const [isRotated, setIsRotated] = useState(false);
  const [data, setData] = useState([]);
  const csvLinkRef = useRef(null);
  const [reportsData, setReportsData] = useState();
  const [onLoadPdf, setOnloadPdf] = useState(false);


  const memoizedValues = useMemo(() => {
    const unitType = stationId
      ? "station"
      : cuId
        ? "cu"
        : sensorId
          ? "sensor"
          : null;
    const unitTypeExtended = unitType === "cu" ? "control_unit" : unitType;
    const unitId = stationId
      ? stationId
      : cuId
        ? cuId
        : sensorId
          ? sensorId
          : null;
    const startingDateString = formatDate(
      new Date(startingDate),
      TIME_FORMAT.CSV_DATE
    );
    const endingDateString = formatDate(
      new Date(endingDate),
      TIME_FORMAT.CSV_DATE
    );
    const fileName = `${unitTypeExtended}_${unitSerial}_from_${startingDateString}_to_${endingDateString}`;

    return {
      unitType,
      unitTypeExtended,
      unitId,
      startingDateString,
      endingDateString,
      fileName,
    };
  }, [startingDate, endingDate, stationId, cuId, sensorId, unitSerial]);

  const { unitType, unitId, fileName } = memoizedValues;


  const handleClick = (event) => {
    if (isStation) event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsRotated(!isRotated);
  };

  useEffect(() => {
    if (data.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [data]);

  const handleDownload = (event, fileExtension) => {
    if (isStation) event.stopPropagation();
    if (!unitType) return;

    getUnitFileData(
      unitId,
      startingDate,
      endingDate,
      unitType,
      fileExtension,
      tenantId,
      fileName
    ).then((responseData) => {
      if (fileExtension === "csv") setData(responseData);
    });
  };

  const handleDownloadPdf = (event) => {
    event.stopPropagation();
    setOnloadPdf(true);
    if (sensorId) {
      getSensorReportInfo(startingDate, endingDate, [sensorId])
        .then(data => setReportsData(data))
        .catch(error => {console.log('Error in getSensorReportInfo', error); setOnloadPdf(false)})
        // .finally(() => setOnloadPdf(false));

    } else if (cuId) {
      getCuReportInfo(startingDate, endingDate, cuId)
        .then(data => setReportsData(data))
        .catch(error => {console.log('Error in getCuReportInfo', error); setOnloadPdf(false)})
        // .finally(() => setOnloadPdf(false));
    } else {
      getStationReportInfo(startingDate, endingDate, stationId)
        .then(data => setReportsData(data))
        .catch(error => {console.log('Error in getStationReportInfo', error); setOnloadPdf(false)})
        // .finally(() => setOnloadPdf(false));
    }
  };

  // useEffect(() => {
  //   console.log('reportsData useEffect', reportsData);
  // }, [reportsData])
  
  // useEffect(() => {
  //   console.log('onLoadPdf useEffect', onLoadPdf);
  // }, [onLoadPdf])

  const handleBlobChange = (blob, indirizzo, loading) => {
    console.log('handleBlobChange', onLoadPdf, blob, indirizzo, loading);
    if (!onLoadPdf || !blob || loading === true) return;
    // const pdfDefaultSize = sensorId ? 1413859: stationId ? 1414006 : null;
    try{
      const pdfDefaultSize = 1413865;
      if (!pdfDefaultSize) return;
      if (!reportsData || !reportsData.sensors) return;
      if (! 'size' in blob) return;
      if (blob.size <= pdfDefaultSize && Object.keys(reportsData.sensors).length > 0) return;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Evocs dynamic report.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch(err) { console.log('err: ', err);}
    finally { setOnloadPdf(false);}
  };

  return (
    <>
      <div
        style={{
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          className={isStation ? styles.BtnStation : styles.Btn}
          onClick={handleClick}
          style={{
            transform: isRotated ? "rotate(-90deg)" : "rotate(0deg)",
          }}
        >
          <svg
            className={isStation ? styles.svgIconStation : styles.svgIcon}
            viewBox="0 0 384 512"
            height="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
          </svg>
          <span
            className={isStation ? styles.icon2Station : styles.icon2}
          ></span>
          <span
            className={isStation ? styles.tooltipStation : styles.tooltip}
            style={{
              opacity: isRotated ? 0 : null,
            }}
          >
            Download
          </span>
        </button>
        {
          !disableCsv && <Button sx={{ padding: "0px", display: isRotated ? true : "none", marginHorizontal: "0px" }}>
            <BsFiletypeCsv
              fontSize={30}
              className={`${styles.FileCsv} ${isRotated && styles.visible}`}
              onClick={isRotated ? (event) => handleDownload(event, "csv") : null}
            />
            <CSVLink
              ref={csvLinkRef}
              data={data}
              style={{ display: "none" }}
              filename={`${fileName}.csv`}
            >
              Scarica CSV
            </CSVLink>
          </Button>
        }
        {!disableExcel && <Button sx={{ padding: "0px", display: isRotated ? true : "none", marginHorizontal: "0px" }}>
          <BsFiletypeXls
            fontSize={30}
            className={`${styles.FileCsv} ${isRotated && styles.visible}`}
            onClick={
              isRotated ? (event) => handleDownload(event, "excel") : null
            }
          />
        </Button>
        }
        {!disableJson && <Button sx={{ padding: "0px", display: isRotated ? true : "none", marginHorizontal: "0px" }}>
          <BsFiletypeJson
            fontSize={30}
            className={`${styles.FileCsv} ${isRotated && styles.visible}`}
            onClick={isRotated ? (event) => handleDownload(event, "json") : null}
          />
        </Button>}
        {!disablePdf && isRotated && !onLoadPdf && (
          <>
            <Button sx={{ padding: "0px", display: isRotated ? true : "none", marginHorizontal: "0px" }} onClick={(event) => handleDownloadPdf(event)}>
              <BsFilePdf
                fontSize={25}
              />
            </Button>
          </>
        )}
        {!disablePdf && reportsData &&
          <div id="pdfParent" display="none">
              <PDFDownloadLink document={<DynamicReportPDF reportsData={reportsData} type={stationId ? "station" : cuId ? "cu" : "sensor"} />} fileName="Evocs dynamic report.pdf">
                {({ blob, url, loading, erro }) => (loading===false && blob && onLoadPdf) ? handleBlobChange(blob, url, loading): null}
              </PDFDownloadLink>
          </div>
        }
        {!disablePdf && isRotated && onLoadPdf && <LoadingReportGif />}
      </div>
    </>
  );
};

export default DownloadSet;
