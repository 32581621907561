import {Grid, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { FaMicrochip } from "react-icons/fa6";
import { TbDeviceHeartMonitorFilled } from "react-icons/tb";
import { SiMoleculer } from "react-icons/si";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TIME_FORMAT, formatDate } from "../StationHandling/utils";
import { deleteSensor, installSensor, moveSensor, uninstallSensor, updateSensor } from "app/store/evocsSlice/sensors/sensorsActions";
import { deleteCu, installCu, moveCu, uninstallCu } from "app/store/evocsSlice/controlUnits/cuActions";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/system";
import { deleteStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { useDrag, useDrop } from "react-dnd";
import { FaPlugCircleCheck } from "react-icons/fa6";
import { FaPlugCircleXmark } from "react-icons/fa6";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SelectSomething from "../SharingComponents/SelectSomething";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";


const ChildrenCardMenu = ({ enabledActions, unit, parent, onClickItem }) => {
  const { t } = useTranslation("evocs");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleUninstall = async() => {
    try {
      if (unit.type === "cu") await uninstallCu(unit.id, parent.id, Math.floor(new Date().valueOf() / 1000));
      else if (unit.type === "sensor") {
        await uninstallSensor(unit.id, Math.floor(new Date().valueOf() / 1000), parent.id, parent.type);
      } else if (unit.type === "measure") {
        const measures = parent.measures.filter((measureId) => measureId !== unit.id)
        await updateSensor(parent.id, undefined, undefined, undefined, measures);
      }
    } catch (error) {
      setGlobalError(true);
    }
  };

  const handleClickItem = (itemAction, actionCallback = null) => {
    handleClose();
    onClickItem && onClickItem(itemAction, unit, actionCallback);
  };


  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <>
      <IconButton sx={{ marginLeft: "auto" }} onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {unit.type !== "measure" && <MenuItem onClick={() => handleClickItem(`config-${unit.type}`)}> {handleTranslate('CONFIGURE')} </MenuItem>}
        {enabledActions.includes("manage-device") && <MenuItem onClick={() => handleClickItem("uninstall", handleUninstall)}>{unit.type !== "measure" ? handleTranslate('UNINSTALL') : handleTranslate('REMOVE')}</MenuItem>}
      </Menu>
    </>
  )
}

const ParentMenu = ({ enabledActions, unit, onClickItem }) => {
  const { t } = useTranslation("evocs");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleDelete = async () => {
    try {
      switch (unit.type) {
        case "station":
          await deleteStation(unit.id);
          setGlobalSuccess(true, "MODAL_SUCCESS_DELETE_STATION");
          break;
        case "cu":
          await deleteCu(unit.id);
          setGlobalSuccess(true, "MODAL_SUCCESS_DELETE_CU");
          break;
        case "sensor":
          await deleteSensor(unit.id);
          setGlobalSuccess(true, "MODAL_SUCCESS_DELETE_SENSOR");
          break;
      }
    } catch (error) {
      setGlobalError(true);
    }
  };

  const handleClickItem = (itemAction, actionCallback = null) => {
    handleClose();
    onClickItem && onClickItem(itemAction, unit, actionCallback);
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };



  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClickItem(`config-${unit.type}`)}> {handleTranslate('CONFIGURE')} </MenuItem>
        {enabledActions.includes("manage-device") && <MenuItem sx={{ color: "red" }} onClick={() => handleClickItem("delete", handleDelete)}> {handleTranslate('DELETE', true)} </MenuItem>}
      </Menu>
    </>
  )
}

const ChildrenCard = ({ enabledActions, unit, parent, onSelect, onDrag, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const canDrag = useMemo(() => {
    return enabledActions.includes("manage-device");
  });
  const [{ isDragging }, drag] = useDrag({
    type: unit.type,
    item: { unit, parent },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: canDrag
  }, [canDrag]);

  useEffect(() => {
    if (!onDrag) return;
    if (isDragging) onDrag(unit);
    else onDrag(null);
  }, [isDragging])


  const timeFormat = (date) => {
    return formatDate(new Date(date * 1000), TIME_FORMAT.COMPLETE_DATE);
  };

  const handleSelect = () => {
    if (unit.type === "measure") return;
    onSelect && onSelect(unit.type, unit.id);
  };

  const isMenuEnabled = () => {
    if (!enabledActions.includes("manage-device") && !enabledActions.includes("update-info-device")) return false;
    return true;
  };

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <Paper ref={drag} sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", cursor: canDrag ? "grab" : "default" }} onDoubleClick={handleSelect}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        {unit.type === "sensor" ?
          <>
            <FaMicrochip style={{ color: "blue", minWidth: "2.5rem" }} fontSize="2.5rem" />
            <span>{handleTranslate('SENSOR', true)}</span>
          </>
          : unit.type === "cu" ?
            <>
              <TbDeviceHeartMonitorFilled style={{ color: "darkorange", minWidth: "2.5rem" }} fontSize="2.5rem" />
              <span>{handleTranslate('CONTROL_UNIT', true)}</span>
            </>
            :
            <>
              <SiMoleculer style={{ color: "green", minWidth: "2.5rem" }} fontSize="2.5rem" />
              <span>{handleTranslate('MEASURE', true)}</span>
            </>
        }
        {isMenuEnabled() && <ChildrenCardMenu enabledActions={enabledActions} unit={unit} parent={parent} onClickItem={onClickItemMenu} />}
      </div>
      <div>
        <p style={{ fontWeight: "bold" }}> {handleTranslate('SERIAL', true)} : </p>
        <p>{unit.serial || unit.measure}</p>
      </div>
      {unit.type !== "measure" ?
        <div>
          <p style={{ fontWeight: "bold" }}> {handleTranslate('INSTALLATION_DATE')}: </p>
          <p>{unit.deviceParent?.start && timeFormat(unit.deviceParent.start)}</p>
        </div>
        :
        <div>
          <p style={{ fontWeight: "bold" }}> {handleTranslate('NAME', true)}: </p>
          <p>{unit.label}</p>
        </div>
      }
    </Paper>
  );
}

const ChildrensList = ({ enabledActions, unit, childrens, onSelectItem, onDragItem, dropElement = null, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return childrens.length > 0 || dropElement ? (
    <Grid container spacing={2}>
      {childrens.map((element) => (
        <Grid key={element.id} item xs={12} md={6} xl={4}>
          <ChildrenCard enabledActions={enabledActions} unit={element} parent={unit} onSelect={onSelectItem} onDrag={onDragItem} onClickItemMenu={onClickItemMenu} />
        </Grid>
      ))}
      {dropElement &&
        <Grid item xs={12} md={6} xl={4}>
          <Paper sx={{ width: "100%", height: "154px", border: `2px dashed ${dropElement.borderColor}`, background: dropElement.backgroundColor, boxShadow: "none" }} />
        </Grid>
      }
    </Grid>
  ) : (
    <p> {handleTranslate("NO_INSTALLED_DEVICE")}</p>
  )
};

const UnitCard = ({ enabledActions, unit, disabled = false, onSelectChildren, onDeselect, onDragItem, onDropItem, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector((state) => state.configuration.constants.measure);
  const canDrag = useMemo(() => {
    return enabledActions.includes("manage-device");
  }, [enabledActions])
  const [alreadyChildren, setAlreadyChildren] = useState() // all'over controlla se il device trascinato è già installato su questa unità
  const parent = useMemo(() => {
    const deviceParent = unit.deviceParent;
    if (unit.type === "cu") {
      return deviceParent ? { id: deviceParent.device, type: "station" } : null;
    } else if (unit.type === "sensor") {
      if (!deviceParent) return null;
      const newParent = deviceParent.type === "station" ? stations[deviceParent.device] : controlUnits[deviceParent.device];
      return { id: deviceParent.device, type: deviceParent.type, ...newParent }
    }
    return null;
  }, [controlUnits, sensors])
  const childrens = useMemo(() => {
    const list = [];
    if (unit.type === "station") {
      unit.list_cu.forEach((cuId) => list.push({ id: cuId, type: "cu", ...controlUnits[cuId] }));
      unit.list_sensors.forEach((sensorId) => list.push({ id: sensorId, type: "sensor", ...sensors[sensorId] }));
      return list;
    }
    else if (unit.type === "cu") {
      unit.list_sensors.forEach((sensorId) => list.push({ id: sensorId, type: "sensor", ...sensors[sensorId] }));
      return list;
    }
    else if (unit.type === "sensor") {
      unit.measures.forEach((measureId) => list.push({ id: measureId, type: "measure", ...measures[measureId] }));
      return list;
    }
  }, [stations, controlUnits, sensors]);

  const acceptUnitType = useMemo(() => {
    if (unit.type === "station") {
      return ["cu", "sensor"];
    } else if (unit.type === "cu") {
      return "sensor";
    } else if (unit.type === "sensor") {
      return ["measure", "measureList"];
    }
  }, [unit.type])

  const [{ isDragging }, drag] = useDrag({
    type: unit.type,
    item: { unit, parent },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: canDrag
  }, [parent, canDrag]);

  const handleOver = useCallback((item) => {
    if (!item) return;
    if (item.type === "measure") {
      if (unit.measures.includes(item.id)) {
        // setAlreadyInstalled(true);
        setAlreadyChildren(true);
      } else {
        // setAlreadyInstalled(false);
        setAlreadyChildren(false);
      }
      return;
    }

    if (item.deviceParent) {
      // setAlreadyInstalled(true);
      if (item.deviceParent.device === unit.id) {
        setAlreadyChildren(true);
      } else {
        setAlreadyChildren(false);
      }
    } else {
      // setAlreadyInstalled(false);
      setAlreadyChildren(false);
    }
  }, [controlUnits, sensors])

  const handleDrop = useCallback((item, itemParent) => {
    if(!onDropItem) return;
    onDropItem(unit, item, async() => {
      try {
        if (item.type === "measure") {
          if (unit.measures.includes(item.id)) {
            setGlobalError(true, "MODAL_ERROR_ADD_MEASURE");
            return;
          }
          await updateSensor(unit.id, undefined, undefined, undefined, [...unit.measures, item.id]);
        } else if (itemParent) {
          if (itemParent.id !== unit.id) {
            switch (item.type) {
              case "cu":
                await moveCu(item.id, itemParent.id, unit.id, new Date());
                break;
              case "sensor":
                await moveSensor(item.id, itemParent.id, itemParent.type, unit.id, unit.type, new Date());
                break;
            }
          } else setGlobalError(true, item.type === "cu" ? "MODAL_ERROR_INSTALL_CU": "MODAL_ERROR_INSTALL_SENSOR")
        } else {
          switch (item.type) {
            case "cu":
              await installCu([item.id], unit.id);
              break;
            case "sensor":
              await installSensor([item.id], unit.id, unit.type);
              break;
          }
        }
      } catch (error) {
        setGlobalError(true);
      }
    });
  }, [])

  const [{ isOver }, drop] = useDrop(() => ({
    accept: acceptUnitType,
    hover: ({ unit }) => handleOver(unit),
    drop: ({ unit, parent }) => handleDrop(unit, parent),
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }), [handleOver]);

  const dragAndDropRef = useCallback((node) => {
    if (node) {
      unit.type !== "station" && drag(node);
      drop(node);
    }
  }, [drag, drop]);

  useEffect(() => {
    if (!onDragItem) return;
    if (isDragging) onDragItem(unit);
    else onDragItem(null);
  }, [isDragging])


  const getDropElement = useCallback(() => {
    if (!isOver) return null;
    let element = {
      backgroundColor: "",
      borderColor: "",
    }
    if (alreadyChildren) {
      element.backgroundColor = "rgba(255, 0, 0, 0.1)";
      element.borderColor = "red";
    } else {
      element.backgroundColor = "rgba(0, 255, 0, 0.1)";
      element.borderColor = "green";
    }

    return element;
  }, [isOver])

  const getUnitTypeLabel = (type) => {
    if (type === "station") return handleTranslate('STATION', true);
    else if (type === "cu") return handleTranslate('CONTROL_UNIT', true);
    else if (type === "sensor") return handleTranslate('SENSOR', true);
    else if (type === "measure") return handleTranslate('MEASURE', true);
  };

  const isMenuEnabled = () => {
    if (!enabledActions.includes("manage-device") && !enabledActions.includes("update-info-device")) return false;
    return true;
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };


  return unit.type !== "measure" ? (
    <div ref={dragAndDropRef} style={{ border: "1px solid grey", borderRadius: "2rem", padding: "1rem", cursor: unit.type !== "station" && canDrag ? "grab" : "default", opacity: disabled ? 0.3 : 1 }}>
      <div style={{ display: "flex", gap: ".1rem", alignItems: "center", marginBottom: "0.8rem" }}>
        {unit.type !== "station" && canDrag && <DragIndicatorIcon className="drag-icon" fontSize="small" />}
        <h2><b>{getUnitTypeLabel(unit.type)}</b>: {unit.serial || unit.label}</h2>
        {enabledActions.includes("manage-device") && <IconButton onClick={() => onClickItemMenu("add-unit", unit)}><AddCircleIcon /></IconButton>}
        {isMenuEnabled() && <ParentMenu enabledActions={enabledActions} unit={unit} onClickItem={onClickItemMenu} />}
        {unit.type !== "station" &&
          (unit.deviceParent ?
            <FaPlugCircleCheck style={{ minWidth: "20px", minHeight: "20px", color: "green" }} />
            : <FaPlugCircleXmark style={{ minWidth: "20px", minHeight: "20px", color: "red" }} />)
        }
        <IconButton sx={{ marginLeft: "auto" }} onClick={() => onDeselect(unit.type, unit.id)}>
          <CloseIcon />
        </IconButton>
      </div>
      <ChildrensList enabledActions={enabledActions} unit={unit} childrens={childrens} onSelectItem={onSelectChildren} onDragItem={onDragItem} dropElement={getDropElement()} onClickItemMenu={onClickItemMenu} />
      {/* <AddUnitModal unitId={unit.id} unit={unit} type={unit.type} isOpen={openModal} onClose={() => setOpenModal((prev) => !prev)} /> */}
    </div>
  ) : (
    <div style={{ border: "1px solid grey", borderRadius: "2rem", padding: "1rem", cursor: unit.type !== "station" && canDrag ? "grab" : "default" }}>
      <div style={{ display: "flex", gap: ".1rem", alignItems: "center", marginBottom: "0.8rem" }}>
        {canDrag && <DragIndicatorIcon className="drag-icon" fontSize="small" />}
        <h2><b>{getUnitTypeLabel(unit.type)}</b>: {unit.serial || unit.label}</h2>
        <IconButton sx={{ marginLeft: "auto" }} onClick={() => onDeselect(unit.type, unit.id)}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  )
}

const UnitsList = memo(({ enabledActions, list, undroppableUnits, onSelectUnit, onDeselectUnit, onDragItem, onDropItem, onClickItemMenu }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };


  return list?.length > 0 ? list.map((unit) => (
    <UnitCard key={unit.id} enabledActions={enabledActions} disabled={undroppableUnits?.includes(unit.id)} unit={unit} onSelectChildren={onSelectUnit} onDeselect={onDeselectUnit} onDragItem={onDragItem} onDropItem={onDropItem} onClickItemMenu={onClickItemMenu} />
  ))
    :
    <SelectSomething text={handleTranslate("SELECT_UNIT")}></SelectSomething>
});

const InstalledUnits = ({ style, enabledActions, selectedUnits, undroppableUnits, onSelectUnit, onDeselectUnit, onDragItem, onDropItem, onClickItemMenu }) => {
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);

  const list = useMemo(() => {
    return selectedUnits?.map((unit) => {
      switch (unit.type) {
        case "station":
          return { ...unit, ...stations[unit.id] };
        case "cu":
          return { ...unit, ...controlUnits[unit.id] };
        case "sensor":
          return { ...unit, ...sensors[unit.id] };
      }
    })
  }, [stations, controlUnits, sensors, selectedUnits])

  return (
    <Box className="card" sx={{ ...style, display: "flex", flexDirection: "column", gap: "2rem", padding: "1rem", overflowY: "auto" }} marginTop={{ xs: "0", md: "4.4rem" }} height={{ xs: "41vh", md: "80vh" }}>
      <UnitsList enabledActions={enabledActions} list={list} undroppableUnits={undroppableUnits} onSelectUnit={onSelectUnit} onDeselectUnit={onDeselectUnit} onDragItem={onDragItem} onDropItem={onDropItem} onClickItemMenu={onClickItemMenu} />
    </Box>
  )
}

export default InstalledUnits;