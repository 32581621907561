import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Drawer,
  Typography,
  Fade,
  Badge,
  IconButton,
} from "@mui/material";
import { IoIosNotifications } from "react-icons/io";
import { headersNames } from "src/utilities/headersNames";
import AlertCard from "src/components/Alerts/AlertCard";
import { deleteAlertsByUser } from "src/app/store/evocsSlice/user/userAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoNotificationsMessage from "src/components/SharingComponents/NoNotificationsMessage";

const AlertsMenu = ({ style }) => {
  const maxNotifications = 8;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [visibleNotifications, setVisibleNotifications] =
    useState(maxNotifications);
  const userAlerts = useSelector((state) => state?.user?.alerts || []);
  const alertsTypes = useSelector(
    (state) => state?.configuration?.constants?.alert || {}
  );
  const userId = useSelector((state) => state?.user?.id);

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleReadNotification = useCallback(
    (alertId) => {
      deleteAlertsByUser(userId, [alertId]);
    },
    [userId]
  );

  const handleReadMore = () => {
    setVisibleNotifications((prev) =>
      Math.min(totalNotifications.length, prev + maxNotifications)
    );
  };

  const formattedAlerts = useMemo(() => {
    return userAlerts
      .map((alert) => {
        const typeAlert = alertsTypes[alert.alertType]?.alert || "";
        const alertId = alert?._id || "";
        const message = alert?.device || undefined;
        return {
          ...alert,
          typeAlert,
          alertId,
          message,
        };
      })
      .sort((a, b) => b.datetime - a.datetime);
  }, [userAlerts, alertsTypes]);

  const totalNotifications = useMemo(
    () => formattedAlerts.map((item) => item._id),
    [formattedAlerts]
  );

  const handleScroll = (e) => {
    const bottom =
      Math.floor(e.target.scrollHeight - e.target.scrollTop) <=
      e.target.clientHeight;
    if (bottom) handleReadMore();
  };

  const handleMarkAllRead = () => {
    deleteAlertsByUser(userId, totalNotifications)
      .then(() => {
        // setFormattedAlerts([]);
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <>
      {userAlerts.length > 0 ? (
        <Button
          sx={style}
          className="min-h-40 min-w-40"
          onClick={handleToggleDrawer}
          color="inherit"
        >
          <Badge
            sx={{ padding: "0 1rem" }}
            color="secondary"
            variant="dot"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <IoIosNotifications style={{ fontSize: "20px" }} />
          </Badge>
        </Button>
      ) : (
        <Button
          sx={style}
          className="min-h-40 min-w-40"
          onClick={handleToggleDrawer}
          color="inherit"
        >
          <IoIosNotifications style={{ fontSize: "20px" }} />
        </Button>
      )}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleToggleDrawer}
        sx={drawerStyles}
        PaperProps={{
          onScroll: handleScroll, // Attach onScroll event to Paper component
        }}
      >
        <Fade in={openDrawer} easing="ease-out" timeout={150}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              height: "auto",
              marginTop: "1rem",
            }}
          >
            <IconButton
              onClick={handleToggleDrawer}
              sx={{ alignSelf: "flex-end" }}
            >
              <HighlightOffIcon
                sx={{
                  width: "35px",
                  height: "35px",
                  color: "#616161",
                  margin: "0 auto",
                }}
                fontSize="small"
              />
            </IconButton>

            <div
              style={{
                marginBottom: "2rem",
                marginTop: "2rem",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                color="#29384e"
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                {headersNames.ALERTS_VIEW_HEADER}
              </Typography>
              <Button
                variant="contained"
                sx={{ width: "145px", minHeight: "30px" }}
                onClick={handleMarkAllRead}
                style={{ backgroundColor: "#242a30", marginLeft: "1rem" }}
              >
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  Segna tutto come letto
                </p>
              </Button>
            </div>
            {userAlerts.length === 0 && (
              <NoNotificationsMessage
                small
                style={{
                  position: "absolute",
                  top: Math.ceil(window.innerHeight / 2),
                }}
              />
            )}
            {formattedAlerts.slice(0, visibleNotifications).map((alert) => (
              <div key={alert._id}>
                <AlertCard
                  {...alert}
                  handleReadNotification={handleReadNotification}
                  style={{ width: "250px" }}
                  short
                />
              </div>
            ))}
            {totalNotifications.length > visibleNotifications && (
              <Typography
                variant="subtitle1"
                color="#29384e"
                align="center"
                marginBottom={"2rem"}
              >
                Scroll to read more...
              </Typography>
            )}
          </div>
        </Fade>
      </Drawer>
    </>
  );
};

const drawerStyles = {
  width: 300,
  "& .MuiDrawer-paper": {
    background: "#f1f5f9",
    width: 330,
  },
};

export default AlertsMenu;
