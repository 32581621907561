import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const IT = {
    HEADER : "Alerts",
    MARK_ALL_READ : "Segna tutto come letto",
    MARK_READ: "Segna come lette",
    MARK_AS_READ: "Segna come letto",
};

const ALERTS_IT = createLanguageObject(IT, viewNames.ALERTS_VIEW);

export default ALERTS_IT;