import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red, blueGrey, teal } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShareIcon from "@mui/icons-material/Share";
import { Box } from "@mui/system";
import { IoIosNotifications } from "react-icons/io";
import { VscGraphLine } from "react-icons/vsc";
import { BsGraphUpArrow, BsGraphDownArrow } from "react-icons/bs";
import { MdOutlineNotificationsActive } from "react-icons/md";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { TIME_FORMAT, formatDate } from "src/components/StationHandling/utils";
import CardMedia from "@mui/material/CardMedia";
import videoSmau from "src/app/asset/video/smau_milano_tilebytes.mp4";
import { Button } from "@mui/material";
import OverlayCustom from "../SharingComponents/OverlayCustom";
import "./AlertCard.css";
import { FaCheck } from "react-icons/fa";
import { lightGreen } from "@mui/material/colors";
import useMobile from "src/app/hooks/useMobile";
import useTouch from "src/app/hooks/useTouch";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AlertCardContainer = styled(Card)(({ theme }) => ({
  position: "relative",
  perspective: "1000px", // Aggiungi una prospettiva per l'effetto 3D
  "& .card-back": {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#242a30",
    transform: "rotateY(180deg)",
    backfaceVisibility: "hidden",
    transition: "transform 0.4s ease",
    transformStyle: "preserve-3d", // Aggiungi la proprietà per mantenere l'effetto 3D
  },
  "&:hover .card-back": {
    transform: "rotateY(0deg)",
    backgroundColor: "#242a30",
    // background: 'linear-gradient(135deg, #27AF60, #222F3D)',
    boxShadow: "11px 11px 22px #111417, -11px -11px 22px #374049",
    cursor: "pointer",
    zIndex: 999,
  },
}));

const CardBack = ({ text, isChecked }) => {
  return (
    <Box
      className="card-back"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Button className="buttonNeomorphic">
        <p
          className={
            isChecked ? "textWithGradientRed" : "textWithGradientGreen"
          }
        >
          {text}
        </p>
      </Button>
    </Box>
  );
};

const AlertCard = ({
  selectable = false,
  shareIcon = false,
  short = false,
  title,
  datetime,
  message,
  longMeassageTitle,
  categoryId,
  longMeassage,
  alertId,
  typeAlert,
  imgUrl,
  videoUrl,
  handleReadNotification,
  style = {},
  handleCheckedCard,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const validCategories = {
    data: "65f2df23a2a783c7dd07f623",
    maintenance: "65f2df23a2a783c7dd07f622",
    share: "65f2df23a2a783c7dd07f624",
  };
  const maintanceAlertColor = blueGrey[500];
  const dataAlertGenericColor = red[900];
  const dataAlertAboveColor = red[500];
  const dataAlertBelowColor = "#242a30";
  const sharingAlertColor = teal[500];
  const defaultAlertColor = "#92c870";
  const [isChecked, setIsChecked] = React.useState(false);
  let cardBackText = isChecked ? "Deseleziona" : "Seleziona";

  const getIconBgColor = (categoryId) => {
    let color;
    if (categoryId === validCategories["maintenance"])
      color = maintanceAlertColor;
    else if (categoryId === validCategories["share"]) color = sharingAlertColor;
    else if (categoryId === validCategories["data"]) {
      if (typeAlert === "aboveThreshold") color = dataAlertAboveColor;
      else if (typeAlert === "belowThreshold") color = dataAlertBelowColor;
      else color = dataAlertGenericColor;
    } else color = defaultAlertColor;
    return color;
  };

  const CategoryIcon = ({ categoryId, typeAlert }) => {
    let iconComponent;
    if (categoryId === validCategories["maintenance"]) {
      iconComponent = (
        <FuseSvgIcon className="text-48" size={25}>
          heroicons-outline:cog
        </FuseSvgIcon>
      );
    } else if (categoryId === validCategories["share"]) {
      iconComponent = <ShareIcon />;
    } else if (categoryId === validCategories["data"]) {
      if (typeAlert === "aboveThreshold") {
        iconComponent = <BsGraphUpArrow />;
      } else if (typeAlert === "belowThreshold") {
        iconComponent = <BsGraphDownArrow />;
      } else {
        iconComponent = <VscGraphLine />;
      }
    } else {
      iconComponent = <MdOutlineNotificationsActive />;
    }
    return iconComponent;
  };

  const ImageHeader = ({ imgUrl }) => {
    let imgTitle = "";
    if (categoryId === validCategories["maintenance"])
      imgTitle = "Maintenance alert image";
    else if (categoryId === validCategories["share"])
      imgTitle = "Sharing alert image";
    else if (categoryId === validCategories["data"])
      imgTitle = "Data alert image";
    else imgTitle = "Generic alert image";

    return (
      <CardMedia
        sx={{ height: `${short ? "60px" : "140px"}` }}
        image={imgUrl}
        title={imgTitle}
      />
    );
  };

  const VideoHeader = ({ videoUrl }) => {
    let videoTitle = "";
    if (categoryId === validCategories["maintenance"])
      videoTitle = "Maintenance alert video";
    else if (categoryId === validCategories["share"])
      videoTitle = "Sharing alert video";
    else if (categoryId === validCategories["data"])
      videoTitle = "Data alert video";
    else videoTitle = "Generic alert video";

    return (
      <CardMedia
        sx={{
          height: 140,
          width: "100%",
          objectFit: "cover",
          margin: 0,
          padding: 0,
        }}
        component="video"
        image={videoUrl}
        title={videoTitle}
        autoPlay
        muted
      />
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
    handleCheckedCard(alertId);
  };

  const cardContentStyle = short ? { paddingTop: "0px", paddingBottom: "0px" } : { paddingBottom: "10px" };

  const [isTouchDevice] = useTouch();

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ALERTS_VIEW);
  };

  return (
    <AlertCardContainer
      sx={{
        ...style,
        bgcolor: "white",
        color: "#29384e",
        marginBottom: "1rem",
      }}
      onClick={selectable ? handleChecked : null}
    >
      {!short && selectable && (
        <OverlayCustom
          visible={selectable}
          hexBackColor={isChecked ? "#e8e8e8" : "#242a30"}
          opacity={isChecked ? 0.2 : 0.3}
        >
          {isChecked && <FaCheck fontSize={60} color={lightGreen[700]} />}
        </OverlayCustom>
      )}
      {!short && selectable && !isTouchDevice && (
        <CardBack
          text={cardBackText}
          isChecked={isChecked}
        />
      )}
      {!short ? (
        <CardHeader
          avatar={
            <Box
              sx={{
                bgcolor: getIconBgColor(categoryId),
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "22px",
              }}
            >
              <CategoryIcon categoryId={categoryId} typeAlert={typeAlert} />
            </Box>
          }
          title={title}
          subheader={formatDate(
            new Date(datetime * 1000),
            TIME_FORMAT.COMPLETE_DATE
          )}
        />
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "1rem",
              padding: "1.5rem",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                bgcolor: getIconBgColor(categoryId),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "14px",
              }}
            >
              <CategoryIcon categoryId={categoryId} typeAlert={typeAlert} />
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
              }}
            >
              <Typography align="center" sx={{ fontSize: "14px" }}>
                {title}
              </Typography>
              <Typography align="left" sx={{ fontSize: "12px" }}>
                {formatDate(
                  new Date(datetime * 1000),
                  TIME_FORMAT.COMPLETE_DATE
                )}
              </Typography>
            </div>
          </Box>
        </>
      )}
      {/* ############### IMAGE VIDEO SECTION ###############  */}
      {imgUrl && <ImageHeader imgUrl={imgUrl} categoryId={categoryId} />}
      {videoUrl && <VideoHeader videoUrl={videoUrl} categoryId={categoryId} />}

      {message &&
        <CardContent sx={cardContentStyle}>
          <Typography
            // variant={short ? "h6" : "body2"} 
            color={short ? "black" : "text.secondary"}
            textAlign={short ? "center" : "left"}
            fontSize={short ? "16px" : "14px"}
          >
            {message}
          </Typography>
        </CardContent>
      }
      <CardActions disableSpacing>
        <Button size="small" onClick={() => handleReadNotification(alertId)}>
          <IoIosNotifications fontSize={short ? 16 : 18} />
          {handleTranslate("MARK_AS_READ")}
        </Button>
        {shareIcon && (
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
        )}
        {longMeassage && (
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
      </CardActions>

      {/* ############### COLLAPSE SECTION ###############  */}
      {longMeassage && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            sx={{
              maxHeight: "500px",
              overflow: "auto",
              padding: "16px",
              marginBottom: "16px",
            }}
          >
            <Typography paragraph>{longMeassageTitle}</Typography>
            <Typography paragraph>{longMeassage}</Typography>
          </CardContent>
        </Collapse>
      )}
    </AlertCardContainer>
  );
};

export default AlertCard;
