import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const SensorTypeSelect = ({ style, value, defaultValue, onChange, required = false, multiple = false, disabled = false }) => {
  const sensorType = useSelector((state) => state.configuration.constants?.sensor_type);
  const [val, setVal] = useState(defaultValue ? defaultValue : value !== undefined ? value : multiple ? [] : "");

  useLayoutEffect(() => {
    value !== undefined && setVal(value);
  }, [value]);

  const handleChange = (selected) => {
    setVal(selected);
    onChange && onChange(selected)
  };


  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  return (
    <FormControl disabled={disabled} sx={{ ...style, width: "100%", maxWidth: "300px" }}>
      <InputLabel id="sensor-type-select-label">{required ? `${handleTranslate('SENSOR_TYPE', true)}*` : `${handleTranslate('SENSOR_TYPE', true)}`}</InputLabel>
      <Select
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((typeId) => sensorType?.[typeId].sensor_type).join(", ") : sensorType?.[selected].sensor_type}
        label={required ? `${handleTranslate('SENSOR_TYPE', true)}*` : `${handleTranslate('SENSOR_TYPE', true)}`}
        onChange={(e) => handleChange(e.target.value)}
      >
        {sensorType && Object.keys(sensorType).map((typeId, index) => (
          <MenuItem key={index} value={typeId}>
            {multiple && <Checkbox checked={val?.indexOf(typeId) > -1} />}
            {sensorType[typeId].sensor_type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SensorTypeSelect;