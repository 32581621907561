import { useEffect, useState } from "react";
import { Modal, Button, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocationInput from "../SharingComponents/Inputs/LocationInput";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { createStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { createBaseLocation } from "app/store/configurationSlice/configurationActions";
import StationTypeSelect from "../SharingComponents/Inputs/StationTypeSelect";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import LoadingGif from "../FileManager/LoadingGif";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { useSelector } from "react-redux";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";

const requiredInputs = ["serial", "owner"];

const FormCreation = ({ onPending, onFinally, onChangeInputs }) => {
  const controlUnits = useSelector((state) => state.controlUnits.uninstalled);
  const sensors = useSelector((state) => state.sensors.uninstalled);
  const [inputs, setInputs] = useState({
    serial: "",
    owner: "",
    type: "",
    available: false,
    controlUnits: [],
    sensors: [],
    position: null,
    positionName: "",
    lat: "",
    lon: "",
  });
  const [changedInputs, setChangedInputs] = useState([]);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  useEffect(() => {
    const disabled = Object.keys(inputs).some((key) => (requiredInputs.includes(key) && (inputs[key] === "" || inputs[key]?.length === 0)));
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs]);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setChangedInputs(prev => [...prev, key]);
  }

  const handleSaveLocation = () => {
    createBaseLocation(inputs.lat, inputs.lon, inputs.positionName).then((payload) => {
      handleChangeInput("position", payload.id);
      setInputs((prev) => ({ ...prev, positionName: "", lat: "", lon: "" }));
    });
  }

  const handleConfirm = () => {
    onPending();
    const types = inputs.type ? [inputs.type] : [];
    createStation(inputs.serial, inputs.owner, types, null, null, inputs.controlUnits, inputs.sensors, inputs.position, null, inputs.available)
    .then(() => setGlobalSuccess(true, "MODAL_SUCCESS_CREATION_STATION"))
    .catch(() => setGlobalError(true))
    .finally(onFinally)
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <div style={{ display: "flex", gap: "5rem", alignItems: "center", justifyContent: "space-between" }}>
        <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_STATION")}</h2>
        <SwitchComponent label={handleTranslate("ACTIVE", true)} defaultChecked={inputs.available} checked={inputs.available} onChange={(value) => handleChangeInput("available", value)} />
      </div>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={handleTranslate("SERIAL", true)}
        required
        className="rounded-2xl"
        value={inputs.serial}
        onChange={(e) => handleChangeInput("serial", e.target.value)}
      />
      <TenantSelect required value={inputs.owner} onChange={(value) => handleChangeInput("owner", value)} />
      <StationTypeSelect disableRadioButtons stationType={inputs.type} onChangeType={(value) => handleChangeInput("type", value)} />
      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <span style={{ minWidth: "100px" }}> {handleTranslate("INSTALL", true)}: </span>
        <CuSelect disablePortal multiple limitTags={2} cusId={controlUnits} value={inputs.controlUnits} onChange={(value) => handleChangeInput("controlUnits", value)} />
      </div>
      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <span style={{ minWidth: "100px" }}> {handleTranslate("INSTALL", true)}: </span>
        <SensorAutocomplete disablePortal multiple limitTags={2} sensorsId={sensors} value={inputs.sensors} onChange={(value) => handleChangeInput("sensors", value)} />
      </div>
      <AccordionComponent title={handleTranslate('ADD_DEFAULT_POSITION')}>
        <LocationInput
          disablePortal position={inputs.position} positionName={inputs.positionName} lat={inputs.lat} lon={inputs.lon}
          onChangePosition={(value) => handleChangeInput("position", value)} onChangePositionName={(value) => handleChangeInput("positionName", value)} onChangeLat={(value) => handleChangeInput("lat", value)} onChangeLon={(value) => handleChangeInput("lon", value)}
          onSave={handleSaveLocation} />
      </AccordionComponent>
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={disableConfirm} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};


const CreateStationModal = ({ isOpen = false, onFinally, onClose }) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);

  useEffect(() => {
    !isOpen && setChangedInputs([]);
  }, [isOpen])

  const handleFinally = () => {
    setUpdateLoading(false);
    onFinally && onFinally();
  }


  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  };


  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormCreation onChangeInputs={setChangedInputs} onPending={() => setUpdateLoading(true)} onFinally={handleFinally} />
          {updateLoading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

export default CreateStationModal;
