import { Modal, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const containerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "1rem",
    borderRadius: "1rem",
    height: "50vh",
    width: "95%",
    maxWidth: "600px",
  }

const ServerErrorModal = ({isOpen}) => {
  

  return (
    <Modal open={isOpen}>
      <div style={containerStyle}>
        <div style={{display: "flex", flexDirection: "column",  height: "100%",  alignItems: "center", justifyContent: "center", gap: "3rem"}}>
          <Typography variant='h1' sx={{ textAlign: "center", fontWeight: "bold", marginBottom: "1rem"}}>500 :(</Typography>
          <h3 style={{ textAlign: "center", fontWeight: "bold" }}>Something went wrong!</h3>
          <p style={{ textAlign: "center" }}>Server error 500. Our Staff has been notified, thank you for your understanding.</p>
          <a href="/dashboard" >Refresh</a>
          <br></br>
          <a href="/sign-in" >Login</a>
        </div>
      </div>
    </Modal>
  )
}

export default ServerErrorModal