import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { lighten } from "@mui/material/styles";
import FileItem from "./FileItem";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMonthCSV } from "app/store/evocsSlice/fileSlice/fileSlice";
import { useState } from "react";
import { Grid, Paper } from "@mui/material";
import { getAutoReportsFileNames } from "app/store/evocsSlice/reportsSlice";
import { getFilesNames, getDayReportsFileNames } from "app/store/evocsSlice/fileSlice/fileActions";
import { fileTypes } from "app/store/evocsSlice/fileSlice/utils";

/* CodeInfo: Componente per il render della lista di File di cui fare il download, dati mockati, da agganciare i dati reali */

const FileManagerList = ({ sharedType }) => {
  const dispatch = useDispatch();
  const monthCSV = useSelector((state) => state.file.monthCSV);
  // const autoReports = useSelector((state) => state.reports.autoReports);
  const autoReports = useSelector((state) => state.file.dailyReports);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (sharedType === 'monthly'){
      dispatch(getMonthCSV());
    } else if (sharedType === 'daily'){
      // getFilesNames('REPORT', fileTypes.DAILY_REPORT, true );
      getDayReportsFileNames();
    }
  }, []);


  useEffect(() => {
    if (sharedType !== 'monthly') return;
    if(!monthCSV || monthCSV.length === 0) return;

    const monthCSVFiles = [];
    monthCSV.forEach((data, id) => {
      const split = data.split(".");
      const fileType = split[split.length - 1];
      monthCSVFiles.push({
        name: data,
        type: fileType,
      });
    });
    setFiles(monthCSVFiles);
  }, [monthCSV])
  
  useEffect(() => {
    console.log('autoReports useEffect ');
    if (sharedType !== 'daily') return;
    if(!autoReports || autoReports.length === 0) return;
    const autoReportsFiles = [];
    autoReports.forEach((data, id) => {
      const split = data.split(".");
      const fileType = split[split.length - 1];
      autoReportsFiles.push({
        name: data,
        type: fileType,
      });
    });
    setFiles(autoReportsFiles);
  }, [autoReports])
    
  return(
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <div>
        <Grid container spacing={2}>
        {files.map((item, index) => (
          <Grid item xs={6} sm={4} lg={3}>     
            <FileItem key={index} id={index} item={item} sharedType={sharedType} />
          </Grid>
        ))}
        </Grid>
      </div>
    </Box>
  );
};

export default FileManagerList;
