
const CustomPageCarded = ({ header, content, footer }) => {
  return (
    <div style={{ position: "relative", display: "flex", flexDirection: "column", height: "100%" }}>
      <div>
        {header}
      </div>
      <div style={{ height: "100%" }}>
        {content}
      </div>
      {footer &&
        <div
          style={{
            position: "static", background: "#242a30", width: "100%", bottom: 0,
            height: "100%",
            minHeight: "300px"
          }}>
          {footer}
        </div>
      }
    </div>
  )
}

export default CustomPageCarded