import { Preview } from "@mui/icons-material";
import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Multimedia:",
    PREVIW_NOT_AVAILABLE : "Anteprime non disponibili",
    VIDEO_ENCODING : "Codifica video",
    RECORDING_ID : "ID registrazione",
    DISK_ID : "ID disco",
};

const SINGLE_MULTIMEDIA_IT = createLanguageObject(IT, viewNames.SINGLE_MULTIMEDIA_VIEW);

export default SINGLE_MULTIMEDIA_IT;