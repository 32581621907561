import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import RangeTimeFilter_v2 from '../SharingComponents/RangeTimeFilter_v2';
import { useSelector } from 'react-redux';
import { viewNames } from 'app/configs/routesNames';
import { setFilter, setTimeRangeFilter } from 'app/store/evocsSlice/filters/filtersActions';
import StationSelect from '../SharingComponents/Inputs/StationSelect';
import MeasureAutocomplete from '../SharingComponents/Inputs/MeasureAutocomplete';
import { IconButton } from '@mui/material';
import { calcIntervalDates } from '../StationHandling/utils';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/system';

const viewName = viewNames.STATIONS_VIEW;

const StationHandlingContentHeader = ({userActions, filtersInView, stationsMeasures}) => {
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const user = useSelector((state) => state.user);
  const timeRange = useSelector((state) => state.configuration.constants?.timeRange);
  const loadingDatasets = useSelector((state) => state.dataset.loading)
  const timeRangeInView = filtersInView?.timeRange;
  const stationSelect = filtersInView?.stationName;
  const measureSelect = filtersInView?.measure;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const disableFilters = useMemo(() => {
    if(!userActions) return true;
    return !userActions.find((action) => action.name === "show-filters");
  }, [userActions])

  const {enabledIntervals, defaultInterval} = useMemo(() => { 
    const intervals = [];
    let defaultId = null;
    timeRangeInView?.list.forEach((element) => {
    if(element.active===1) intervals.push(element._id);
    if(element.default===1) defaultId = element._id;
    });
    defaultId = timeRangeInView.selected ? timeRangeInView.selected : defaultId;
    return {enabledIntervals: intervals, defaultInterval: defaultId}
  }, [user.selectedTenant, timeRangeInView?.list])

  const getStationsOptions = useCallback(() => {
    const measures = measureSelect?.selected || [];
    if(measures.length === 0) return availableStations; 
    else return availableStations.filter((stationId) => measures.every((measureId) => stationsMeasures[stationId].includes(measureId)));
  }, [stationsMeasures, measureSelect?.selected])

  const getMeasuresOptions = useCallback(() => {
    let selected = [];
    if(!stationSelect.selected || stationSelect.selected.length === 0) selected = availableStations;
    else selected = stationSelect.selected;
    return [...new Set(selected.map((stationId) => stationsMeasures[stationId]).flat())];
  }, [stationsMeasures, stationSelect?.selected])

  // useEffect(() => {
  //   if(!defaultInterval) return;
  //   const range = calcIntervalDates(defaultInterval);
  //   setTimeRangeFilter(viewName, timeRangeInView.id, defaultInterval, range.start, range.end);
  // }, [defaultInterval]);

  useEffect(() => {
    const stationsOptions = getStationsOptions();
    const selectedStations = stationSelect.selected?.filter((stationId) => stationsOptions.includes(stationId));
    setFilter(viewName, stationSelect.id, selectedStations, stationsOptions);
  }, [getStationsOptions])

  useEffect(() => {
    setFilter(viewName, measureSelect.id, undefined, getMeasuresOptions());
  }, [getMeasuresOptions])
  
  const handleChangeFilter = (filterId, value) => {
    console.log("station");
    setFilter(viewName, filterId, value);
  };

  const handleChangeInterval = (intervalId, startDate, endDate) => {
    console.log("interval");
    setTimeRangeFilter(viewName, timeRangeInView.id, intervalId, startDate, endDate);
  };
  
  const handleChangeSlider = (startDate, endDate, value) => {
    console.log("slider");
    setTimeRangeFilter(viewName, timeRangeInView.id, undefined, startDate.valueOf(), endDate.valueOf());
  };

  const handleRefresh = () => {
    let {start, end} = calcIntervalDates(timeRange[timeRangeInView.selected].timeRange);
    if(!start || !end) {
      start = timeRangeInView.startDate+1; end = timeRangeInView.endDate+1;
    }
    setTimeRangeFilter(viewName, timeRangeInView.id, undefined, start, end);
    setStartDate(new Date(start))
    setEndDate(new Date(end))
  };
  

  return !disableFilters && (
    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "1rem", marginBottom: "2rem"}}>
      <div style={{flexGrow: 1}}>
        <StationSelect multiple stationsId={stationSelect?.list || []} limitTags={2} value={stationSelect?.selected || []} onChange={(value) => handleChangeFilter(stationSelect.id, value)}/>
      </div>
      <div style={{flexGrow: 1}}>
        <MeasureAutocomplete multiple limitTags={1} measuresId={measureSelect?.list || []} value={measureSelect.selected || []} onChange={(value) => handleChangeFilter(measureSelect.id, value)}/>
      </div>
      <Box sx={{display: "flex", gap: "1rem", flexGrow: 1}} alignItems={{xs: "end", md: "center"}}>
        <RangeTimeFilter_v2 withDatePicker intervals={enabledIntervals} 
        startDate={startDate} endDate={endDate} onChangeInterval={handleChangeInterval} onChangeSlider={handleChangeSlider}
        selectedInterval={timeRangeInView.selected} defaultInterval={defaultInterval}
        />
        <IconButton disabled={loadingDatasets} sx={{height: "fit-content", color: "black"}} onClick={handleRefresh}>
          <RefreshIcon fontSize='large'/>
        </IconButton>
      </Box>
    </div>  
  )
}

export default StationHandlingContentHeader;