import { Paper } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import TableComponent from '../SharingComponents/TableComponent'
import { useSelector } from 'react-redux';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import NoDataMessage from '../SharingComponents/NoDataMessage';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";



const OdorReportsTable = ({userActions, reports, onClickRow, downloadFileProps, onDeleteReport}) => {
  const { t } = useTranslation("evocs");
  const odoursType = useSelector((state) => state.configuration.constants?.odour_type);
  const odoursLevel = useSelector((state) => state.configuration.constants?.odour_level);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchedText, setSearchedText] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ODOR_REPORTS_VIEW);
 };

  const columns = useMemo(() => {
    return {
      date: handleTranslate("DATE", true),
      odour_level: handleTranslate("ODOUR_LEVEL", true),
      odour_type: handleTranslate("TYPE", true)
    };
  }, [t]);



 useEffect(() => {
  console.log("reports: ", reports);
  setData(reports.map((element) => {
    const newElement = {id: element._id, ...element}
    newElement.odour_type =  handleTranslate(`ODOUR_TYPE_${odoursType[element.type]?.odour_type.toUpperCase()}`, true);
    newElement.odour_level = handleTranslate(`ODOUR_LEVEL_${odoursLevel[element.level]?.odour_level.toUpperCase()}`, true);
    delete newElement.lat; delete newElement.lon; delete newElement.type; delete newElement.level;
    return newElement;
  }));
 }, [t, reports])

 useEffect(() => {
  if(!data || data.length === 0) {
    setTableData([]);
    return;
  };
  if(!searchedText || searchedText === "") {
    setTableData(data);
    return;
  }
  setTableData(() => {
    return data.filter((element) => Object.entries(element).find(([key, value]) => value.toString().toLowerCase().includes(searchedText.toLowerCase())));
  });
  
}, [data, searchedText])

  

  return reports?.length > 0 ? (
    <TableComponent 
      data={tableData} 
      columns={columns} 
      clickable 
      selectable
      userActions={userActions}
      selectedRows={selectedRows} 
      onSelectRows={setSelectedRows} 
      onClickRow={onClickRow} 
      onDeleteRows={onDeleteReport}
      downloadFileProps={downloadFileProps}
      tableTitle={handleTranslate("ODOUR_REPORTS_DETECTED")} 
      onSearch={setSearchedText}
      />
  ):(
    <Paper sx={{display: "flex", gap: "1rem", padding: "1rem", alignItems: "center", justifyContent: "center"}}>
      <NoDataMessage />
    </Paper>
  )
}

export default OdorReportsTable