import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const EN = {
    ENTER_LATITUDE: "Enter latitude",
    ENTER_LONGITUDE: "Enter longitude",
    ENTER_VALID_COORDINATES: "Enter a valid coordinate",
    LOCATION_NAME : "Location Name",
    ENTER_LOCATION_NAME: "Enter the location name",
    ADD_NEW_LOCATION: "Add a new location",
};

const LOCATION_INPUT_COMPONENT_EN = createLanguageObject(EN, sharingComponentNames.LOCATION_INPUT_COMPONENT);

export default LOCATION_INPUT_COMPONENT_EN;