import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import { IconButton, Stack } from "@mui/material";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const SingleStationHandlingHeader = () => {
  const location = useLocation();
  const { serial } = useParams();
  const navigate = useNavigate();


  const handleClickBackArrow = () => {
    const path = location.state.backView ? `${location.state.backView}` : "/stations";
    navigate(path);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.SINGLE_STATION_VIEW);
  };

  return (
    <div className='view-header'>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconButton onClick={handleClickBackArrow}><ArrowBack /></IconButton>
        <span className='title'>{`${handleTranslate('HEADER')} ${serial}`}</span>
      </Stack>
    </div>
  );
};
export default SingleStationHandlingHeader;
