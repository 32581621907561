import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const CategoryAllertSelect = ({style, categoryIds, defaultValue, value, onChange, required=false, multiple=false, disabled=false, nullValue=false}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };
  const categoryAlert = useSelector((state) => state?.configuration.constants?.categoryAlert);
  const options = useMemo(() => {
    if(categoryIds) {
      return nullValue ?  ["", ...categoryIds] : categoryIds;
    }else {
      return nullValue ? ["", ...Object.keys(categoryAlert)] : Object.keys(categoryAlert);
    }
  }, [categoryIds, nullValue]);
  const [val, setValue] = useState(defaultValue || multiple ? [] : "");

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const getCategoryLabel = (categoryId) => {
    if(categoryId === "") return "Tutte";
    return categoryAlert[categoryId]?.label;
  };

  return(
    <FormControl disabled={disabled} sx={{...style, width: "100%", minWidth: "100px", maxWidth: "300px"}}>
      <InputLabel id="category-allert-select-label">{required ? `${handleTranslate('NOTIF_TYPE')}*`: `${handleTranslate('NOTIF_TYPE')}`}</InputLabel>
      <Select
        labelId="category-allert-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((categoryId) => getCategoryLabel(categoryId)).join(", ") : getCategoryLabel(selected)}
        label={required ? `${handleTranslate('NOTIF_TYPE')}*`: `${handleTranslate('NOTIF_TYPE')}`}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((categoryId, index) => (
        <MenuItem key={index} value={categoryId}>
          {multiple && <Checkbox checked={val?.indexOf(categoryId) > -1} /> }
          {getCategoryLabel(categoryId)}
        </MenuItem>
        ))}
      </Select>
    </FormControl>     
  )
}

export default CategoryAllertSelect;