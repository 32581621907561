import React, { useCallback, useLayoutEffect } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import LanguageSelect from "../SharingComponents/Inputs/LanguageSelect";
import { updateUser } from "app/store/evocsSlice/user/userAction";
import AvatarImage from "../SharingComponents/Inputs/AvatarImage";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";
import RoleSelect from "../SharingComponents/Inputs/RoleSelect";

const defaultInputs = {
  proPic: "",
  email: "",
  username: "",
  roleId: "",
  language: ""
};

const requiredInputs = ["email", "username", "roleId", "language"];

const ConfigurationChip = ({ user, onPending, onFinally, onChangeInputs}) => {
  const { t } = useTranslation("evocs");
  const [inputs, setInputs] = useState(defaultInputs);
  const [disableEdit, setDisableEdit] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [changedInputs, setChangedInputs] = useState([]);
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const getDefaultInputs = useCallback(() => {
    let newInputs = { ...inputs };
    Object.keys(newInputs).forEach((key) => {newInputs[key] = user[key];});
    return newInputs;
  }, [user]);

  useLayoutEffect(() => {
   setInputs(getDefaultInputs());
  }, [user]);

  useEffect(() => {
    if (changedInputs.length === 0) { setDisableConfirm(true); return; }
    const disabled = requiredInputs.some((key) => (!inputs[key] || inputs[key] === "" || inputs[key].length === 0));
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs]);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setChangedInputs(prev => [...prev, key]);
  };

  const handleCancelChanges = () => {
    setChangedInputs([]);
    setDisableEdit(true);
    setInputs(getDefaultInputs());
    onChangeInputs([]);
  };

  const handleConfirm = () => {
    onPending();
    updateUser(user.sqlId, inputs.language, inputs.proPic)
    .then(() => setGlobalSuccess(true, "Utente aggiornato con successo"))
    .catch(() => setGlobalError(true))
    .finally((res) => {
      // let updatedUser = {};
      // Object.keys(inputs).forEach((key) => updatedUser[key] = inputs[key].value);
      // onFinally && onFinally(updatedUser);
      onFinally(res);
    })
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem"}}>
      <h2 style={{ fontWeight: "bold" }}>
        Utente: {user.username}
        {disableEdit && <IconButton sx={{ ml: "1rem" }} onClick={() => setDisableEdit(false)}><EditIcon /></IconButton>}
      </h2>
      <AvatarImage disabled={disableEdit} value={inputs.proPic} onChange={(value) => handleChangeInput("proPic", value)}/>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        disabled={disableEdit}
        label="Email"
        required
        value={inputs.email}
        onChange={(e) => handleChangeInput("email", e.target.value)}
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        disabled={disableEdit}
        label="Username"
        required
        value={inputs.username}
        onChange={(e) => handleChangeInput("username", e.target.value)}
      />
      <LanguageSelect disabled={disableEdit} required value={inputs.language} onChange={(value) => handleChangeInput("language", value)}/>
      {/* <RoleSelect disabled required value={inputs.roleId} onChange={(value) => handleChangeInput("roleId", value)}/> */}
      <RoleSelect disabled={disableEdit} required value={inputs.roleId} onChange={(value) => handleChangeInput("roleId", value)}/>
      {!disableEdit &&
        <div style={{ position: "absolute", padding: "1rem 2rem", left: 0, bottom: 0, width: "100%", display: "flex", gap: ".5rem", background: "white" }}>
          <Button sx={{ color: "red" }} onClick={handleCancelChanges}> {handleTranslate("CANCEL", true)} </Button>
          <Button disabled={disableConfirm} onClick={handleConfirm}> {handleTranslate("SAVE", true)} </Button>
        </div>
      }
    </div>
  );
};

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};

const UserConfigurationModal = ({isOpen = false, user, onClose, onFinally}) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);

  useEffect(() => {
    return () => setChangedInputs([]);
  }, [isOpen])

  const handleFinally = () => {
    setUpdateLoading(false);
    onFinally && onFinally();
  }

  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  }

  return (
    user && (
      <Modal open={isOpen}>
        <div style={styles.containerStyle}>
          <div style={{ display: "flex" }}>
            <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
              <CloseIcon fontSize="small" sx={{ color: "white" }} />
            </IconButton>
          </div>
          <div style={{position: "relative", background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto"}}>
            <ConfigurationChip user={user} onPending={() => setUpdateLoading(true)} onFinally={handleFinally} onChangeInputs={setChangedInputs}/>
            {updateLoading &&
              <div style={styles.loadingPopup}>
                <LoadingGif />
              </div>
            }
          </div>
        </div>
      </Modal>
    )
  );
};

export default UserConfigurationModal;
