import React from 'react'
import { MdOutlineAdsClick } from "react-icons/md";
// import useMobile from 'src/app/hooks/useMobile';
import { TbHandClick } from "react-icons/tb";
import useTouch from 'src/app/hooks/useTouch';

const SelectSomething = ({style, text }) => {
  const [isTouchDevice] = useTouch();

  return (
    <div style={{...style, display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        {/* {xs || isTouchDevice ?  */}
        {isTouchDevice ? 
        <TbHandClick fontSize={"40px"} />
        :
        <MdOutlineAdsClick fontSize={"40px"} />
        }
        <label> {text} </label>
      </div>
    </div>
  )
}

export default SelectSomething