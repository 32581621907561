import FusePageCarded from "@fuse/core/FusePageCarded";
import WithAuth from "./WithAuth";
import SingleStationHandlingContent from "src/components/SingleStationHandling/SingleStationHandlingContent";
import { viewNames } from "app/configs/routesNames";
import SingleStationHandlingHeader from "src/components/SingleStationHandling/SingleStationHandlingHeader";

const SingleStationHandling = () => {

  return (
    <WithAuth
      viewName={viewNames.STATIONS_VIEW}
      component={
        <FusePageCarded
          header={<SingleStationHandlingHeader />}
          content={<SingleStationHandlingContent />}
        />
      }
    />
  );
};

export default SingleStationHandling;
