

const FILTER_CATEGORY_SELECT_EN = {
    INPUTS_TYPE_FILTER: "Type filter",    
};

const CONSTANT_SELECT_EN = {
    INPUTS_CONSTANT_TYPE: "Constant type",    
};

const NO_DATA_MESSAGE_EN = {
    INPUTS_NO_DATA: "No data available",
};

const INFO_BUTTON_EN = {
    INPUTS_INSERT_INFO : "Enter your info here",
};

const CATEGORY_ALERT_SELECT_EN = {
    INPUTS_NOTIF_TYPE: "Notification type", 
};

const GROUP_ACTION_SELECT_EN = {
    INPUTS_GROUP_ACTION: "Actions categories", 
};

const ALERT_CATEGORY_SELECT_EN = {
    INPUTS_NOTIFICATION_CATEGORY: "Notifications categories", 
};

const INPUT_COMPONENTS_EN = {
    ...FILTER_CATEGORY_SELECT_EN,
    ...CONSTANT_SELECT_EN,
    ...NO_DATA_MESSAGE_EN,
    ...INFO_BUTTON_EN,
    ...CATEGORY_ALERT_SELECT_EN,
    ...GROUP_ACTION_SELECT_EN,
    ...ALERT_CATEGORY_SELECT_EN,
};

export default INPUT_COMPONENTS_EN;