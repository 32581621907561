import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Il mio profilo",
    NEW_PASSWORD: "Nuova password",
    PASSWORD_NOT_MATCH: "Le password non coincidono",
    PASSWORD_ERROR_LENGTH: "La password deve essere almeno di 8 caratteri",
    CHANGE_PASSWORD: "Cambia password",
};

const USER_PROFILE_IT = createLanguageObject(IT, viewNames.USER_PROFILE_VIEW);

export default USER_PROFILE_IT;