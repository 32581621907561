import React, { useState, useEffect, useRef } from 'react';
import defaultThumb from 'src/app/asset/images/TilebytesCopertina.png';
import LoadingGif from '../FileManager/LoadingGif';
import { Divider, IconButton, Stack, Tooltip, styled } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RecIconButton from './RecIconButton';
import SliderPlayer from './SliderPlayer';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LiveText from './LiveText';

const LightIconButton = styled(IconButton)(({theme}) => ({
  color: "white",
  borderRadius: "0px",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.25)"
  }
}));


const AxisRecording = ({url, live, defaultPlay=false, defaultRecording=false, isLoading=false, disableRec=false, duration, play, recording, onPlay, onPause, onRecording, height="fit-content"}) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoDuration, setVideoDuration] = useState(duration || 0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playVideo, setPlayVideo] = useState(defaultPlay);
  const [isRecording, setRecording] = useState(defaultRecording)
  const [isFullScreen, setFullScreen] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    setVideoUrl(url);

    return () => {
      setCurrentTime(0);
    }
  }, [url])

  useEffect(() => {
    duration !== undefined && setVideoDuration(duration);
  }, [duration])


  //   const handleLoadedMetadata = (e) => {
  //     console.log(e)
  //     setVideoDuration(videoRef.current.duration);
  //   };

  //   // const handleTimeUpdate = () => {
  //   //   setSliderValue(video.currentTime);
  //   // };

  //   //videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
  //   videoRef.current.addEventListener('durationchange', handleLoadedMetadata);

  //   return () => {
  //     //videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
  //     videoRef.current.removeEventListener('durationchange', handleLoadedMetadata);
  //   };
  // }, []);

  useEffect(() => {
    play !== undefined && setPlayVideo(play);
  }, [play])

  useEffect(() => {
    recording !== undefined && setRecording(recording);
  }, [recording])


  useEffect(() => {
    if(videoUrl) {
      if (playVideo) {
        if(Math.round(currentTime) === duration) setCurrentTime(0);
        videoRef.current.play().catch((error) => null);
      } else {
        videoRef.current.pause();
      }
    }else {
      if (!playVideo) {
        videoRef.current.load();        
      }
    }
  }, [playVideo, videoUrl])

  useEffect(() => {
    const handleExitFullscreen = () => {
      setFullScreen(false);
    }

    videoRef.current.addEventListener('exitFullscreen', handleExitFullscreen);

    return () => {
      videoRef.current?.removeEventListener('exitFullscreen', handleExitFullscreen);
    };
  }, []);


  const handlePlay = () => {
    setPlayVideo(true);
    onPlay && onPlay();
  }

  const handlePause = () => {
    setPlayVideo(false);
    onPause && onPause();
  }

  const handleRecording = (value) => {
    setRecording(value);
    onRecording && onRecording(value);
  }

  const formatDuration = (value) => {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.round(value) - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handleChangeSlider = (value) => {
    videoRef.current.currentTime = value;
    setCurrentTime(value);
  }

  const handleFullScreenToggle = () => {
    videoRef.current.requestFullscreen();
    setFullScreen(true);
  }

  return (
    <div style={{position: "relative", width: "100%"}}>
      <div style={{display: "flex", flexDirection: "column", background: "black", height: height}}>
        <div style={{height: "87%"}}>
          <video
            ref={videoRef}
            src={videoUrl}
            onClick={!live ? (!playVideo ? handlePlay : handlePause):null}
            style={{ width: '100%', height: "100%", borderRadius: "1rem"}}
            onPlay={handlePlay}
            onPause={handlePause}
            poster={defaultThumb}
            muted
            autoPlay={false}
            onEnded={handlePause}
            onTimeUpdate={(e) => setCurrentTime(Math.round(e.currentTarget.currentTime * 10) / 10)}
          />
        </div>
        {videoUrl &&
        <div style={{display: "flex", flexDirection: "column", justifyContent: "end", height: "13%"}}>
          {!live && <SliderPlayer videoDuration={videoDuration} value={currentTime} onChange={handleChangeSlider} disableLabels={true}/>}
          <Stack direction={"row"} sx={{color: "white"}} alignItems={"center"}>
            {!live && (
              !playVideo ?
                <LightIconButton onClick={handlePlay}><PlayArrowIcon /></LightIconButton>
              :
                <LightIconButton onClick={handlePause}><PauseIcon /></LightIconButton>
            )}
            {!disableRec && live && <RecIconButton recording={isRecording} onRecording={handleRecording}/>}
            <span style={{color: "white", opacity: .6, marginLeft: "0.5rem"}}>
              {formatDuration(currentTime)}
              {duration && `/${formatDuration(duration)}`}
              </span>
            <LightIconButton sx={{ml: "auto"}} onClick={handleFullScreenToggle}><FullscreenIcon /></LightIconButton>
          </Stack>
        </div>
        }
      </div>
      {live && <LiveText style={{position: "absolute", top: "1rem", right: "1rem"}}/>}
      {isLoading &&
      <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: "rgba(255, 255, 255, 0.8)"}}>
        <LoadingGif />
      </div>
      }
    </div>
  );
};

export default AxisRecording;
