import { viewNames } from "app/configs/routesNames";
import { ITALY_COORDS, TARANTO_COORDS } from "./utils";
import axios from "axios";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const findAlertsPositions = createAsyncThunk(
    'maps/findAlertsPositions',
    async (payload) => {
        const { alertIds, userId } = payload;
        const data = await axios.post("/api/py/findAlertsPositions", {
            alertIds: alertIds,
            userId: userId,
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return data;
    }
);

const mapsSlice = createSlice({
    name: "maps",
    initialState: {
        [viewNames.ODOR_REPORTS_VIEW]: {
            defaultOptions: {
                center: TARANTO_COORDS,
                zoom: 12,
                scrollWheelZoom: true
            },
            setOptions: {
                center: null,
                zoom: null,
            },
            markers: []
        },
        [viewNames.DASHBOARD_VIEW]: {
            defaultOptions: {
                center: { lat: 40.481720, lng: 17.210582 },
                zoom: 12,
                scrollWheelZoom: true
            },
            setOptions: {
                center: null,
                zoom: null,
            },
            markers: []
        },
        [viewNames.MAPS_VIEW]: {
            defaultOptions: {
                center: { lat: 40.233412, lng: 17.374719 },
                zoom: 8,
                scrollWheelZoom: true
            },
            setOptions: {
                center: null,
                zoom: null,
            },
            markers: []
        },
        [viewNames.SINGLE_STATION_VIEW]: {
            display: false,
            route: false,
        },
        [viewNames.OPEN_DATA_VIEW]: {
            defaultOptions: {
                center: ITALY_COORDS,
                zoom: 5,
                scrollWheelZoom: true
            },
            setOptions: {
                center: null,
                zoom: null,
            },
            markers: []
        }
    },
    reducers: {
        setMapDisplay: (state, action) => {
            const { viewName, display } = action.payload;
            state[viewName].display = display;
        },
        setMapRoute: (state, action) => {
            const { viewName, enabled } = action.payload;
            state[viewName].route = enabled;
        },
        setMapOptions: (state, action) => {
            const { viewName, center = TARANTO_COORDS, zoom, scrollWheelZoom } = action.payload;
            state[viewName].setOptions = {
                center: center,
                zoom: zoom,
                scrollWheelZoom: scrollWheelZoom
            }
        },
        setMapMarkers: (state, action) => {
            // console.log('setMapMarkers');
            const { viewName, markers, stationId } = action.payload;
            if (viewName === viewNames.SINGLE_STATION_VIEW) {
                const oldStationsMarkers = state[viewName].markers;
                state[viewName].markers = {
                    ...oldStationsMarkers,
                    [stationId]: markers
                }
                // state[viewName].setOptions.zoom = 14;
                return;
            }
            state[viewName].markers = markers;
            // state[viewName].options = {
            //     ...state[viewName].options,
            //     center: TARANTO_COORDS,
            //     zoom: 13
            // }
        },
        addMapMarkers: (state, action) => {
            const { viewName, markers } = action.payload;
            state[viewName].markers = [
                ...state[viewName].markers,
                ...markers
            ]
        },
        removeMapMarkers: (state, action) => {
            const { viewName, stationId } = action.payload;
            if (viewName === viewNames.SINGLE_STATION_VIEW) {
                if (!state[viewName].markers[stationId]) {
                    return;
                }
                const oldStationsMarkers = state[viewName].markers;
                state[viewName].markers = {
                    ...oldStationsMarkers,
                    [stationId]: []
                }
                state[viewName].setOptions.zoom = 14;
                return;
            }
            state[viewName].markers = [];
        },
        toggleStationMap: (state, action) => {
            const { type, payload } = action;
            state[payload.key].display = !state[payload.key].display;
            if (payload.key === viewNames.SINGLE_STATION_VIEW) {
                if (state[viewNames.SINGLE_STATION_VIEW].display) {
                    if (state[viewNames.SINGLE_STATION_VIEW].route) {
                        state[viewNames.SINGLE_STATION_VIEW].route = !state[viewNames.SINGLE_STATION_VIEW].route;
                    }
                }
            }
        },
        toggleStationMapRoute: (state, action) => {
            state[viewNames.SINGLE_STATION_VIEW].route = !state[viewNames.SINGLE_STATION_VIEW].route;
            if (state[viewNames.SINGLE_STATION_VIEW].route) {
                if (state[viewNames.SINGLE_STATION_VIEW].display)
                    state[viewNames.SINGLE_STATION_VIEW].display = !state[viewNames.SINGLE_STATION_VIEW].display;
            }
        },
    }
});

export const { setMapOptions, setMacenter, setMapMarkers, addMapMarkers, toggleStationMap, toggleStationMapRoute, removeMapMarkers } = mapsSlice.actions;
export const mapsSliceActions = {
    ...mapsSlice.actions,
    findAlertsPositions
}
export default mapsSlice.reducer;