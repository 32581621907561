import { useEffect, useState } from 'react'
import { Button, Modal } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const containerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "1rem",
  borderRadius: "1rem",
  height: "fit-content",
  width: "95%",
  maxWidth: "400px",
  display: "flex",
  flexDirection: "column",
  gap: "3rem"
};


const ErrorModal = ({ style, title, isOpen, onClose, onConfirm, confirmText = "Ok" }) => {
  const { t } = useTranslation("evocs");
  const [titleText, setTitleText] = useState(title);
  const handleTranslate = (textId) => {
    return translate(t, textId);
  };

  useEffect(() => {
    title ? setTitleText(title) : setTitleText("MODAL_ERROR_GENERAL_MESSAGE");
  }, [title])


  return (
    <Modal open={isOpen}>
      <div style={{ ...style, ...containerStyle }}>
        <ErrorIcon sx={{ width: "80px", height: "80px", color: "red", margin: "0 auto" }} fontSize='large' />
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}> {handleTranslate("ERROR")} </h2>
        <p style={{ textAlign: "center" }}>{handleTranslate(titleText)}</p>
        <Button fullWidth onClick={onConfirm}>{confirmText}</Button>
      </div>
    </Modal>
  )
}

export default ErrorModal;