// services/jwtService.js
import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";
import { refresh, logout, setSessionExpired, isSessionExpired } from "app/store/evocsSlice/login/loginActions";
import { routesNames } from "app/configs/routesNames";

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {

  constructor() {
    super();
    this.isRefreshing = false;
    this.refreshQueue = [];
  };

  init() {
    // console.log('JwtService init()');
    this.handleAuthentication();
    this.setTokenRefreshInterceptor();
  };


  setTokenRefreshInterceptor = () => {
    axios.interceptors.response.use(
      response => {
        // Non fare nulla in caso di risposta di successo
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
  
        if (error.response && !isSessionExpired()) {
          if (error.response.status === 401 && (!originalConfig._retry || originalConfig._retry === false)) {
            if (!this.isRefreshing) {
              try {
                this.isRefreshing = true;
                originalConfig._retry = true;
  
                if (!this.existTokens()) return Promise.reject(error);
                const newAccessToken = await this.refreshToken();
                if (!newAccessToken) return Promise.reject(error);
  
                originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;
  
                this.refreshQueue.forEach(({ resolve }) => resolve(newAccessToken));
                this.refreshQueue = [];
  
                return axios(originalConfig);
              } catch (error) {
                logout();
                throw error;
              } finally {
                this.isRefreshing = false;
              }
            } else {
              if (originalConfig.url.includes('refreshtoken')) {
                this.isRefreshing = false;
                setSessionExpired(true);
              } else {
                return new Promise((resolve, reject) => {
                  this.refreshQueue.push({
                    resolve: (token) => {
                      originalConfig.headers.Authorization = `Bearer ${token}`;
                      resolve(axios(originalConfig));
                    },
                    reject: (err) => {
                      reject(err);
                    }
                  });
                });
              }
            }
          } else if (error.response.status === 401 && originalConfig._retry) {
            logout();
          }
        }
  
        return Promise.reject(error);
      }
    );
  };
  


  handleAuthentication = () => {
    try {
      if (this.existTokens() === true) {
        const access_token = this.getAccessToken();
        if (this.isTokenExpired(access_token) === true) refresh();
        else {
          this.setBearerToken(access_token);
          refresh(false);
        }
      } else {
        // console.log('JwtService: no tokens found, trying to logout');
        if (!window.location.href.includes(routesNames.SIGN_IN_VIEW)) logout();
      }
    } catch (error) {
      // console.log('JwtService: error during handling authentication: ', error);
      throw new Error(error);
    }
  };

  refreshToken = async () => {
    // console.log('JwtService: trying to refresh token');
    try {
      // console.log('JwtService: trying to get refresh token');
      const refresh_token = this.getRefreshToken();
      // console.log('JwtService: the refresh token retrieved is as follows --> ', refresh_token);
      // this.removeBearerToken();
      if (refresh_token === undefined || refresh_token === null) {
        // console.log('JwtService: no refresh token found, impossible to refresh')
        throw new Error('JwtService: no refresh token found, impossible to refresh');
      }
      return await axios
        .post(jwtServiceConfig.refreshToken, {
          refreshToken: refresh_token,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.accessToken && response?.data?.refreshToken) {
              // console.log('JwtService: new tokens successfully recovered. Saving of new tokens in local storage.');
              this.saveToken(response.data.accessToken, response.data.refreshToken);
              // console.log('JwtService: emitting onRefresh event');
              // this.emit("onRefresh");
              // console.log('JwtService: try to empty the requests queue');

              //FIXME: Migliorare la gestione della coda di refresh
              const retryQueue = [...this.refreshQueue];
              this.refreshQueue = [];
              // console.log('JwtService: try to resolve all the promises');
              retryQueue.forEach(({ resolve }) => { resolve(response.data.accessToken); });
              return response.data.accessToken;
            } else {
              throw new Error('JwtService: no access token or refresh token found in response with code: ', response.status);
            }
          }
        })
        .catch((error) => {
          // console.log('JwtService: errore nel try più interno della refresh');
          // console.log('ERROR: ', error);
          this.refreshQueue = [];
          if (error.response.status === 401 || (error.response.status === 500 && error.response.data.code === -401)) {
            // console.log('JwtService: sessione scaduta');
            if (!window.location.href.includes(routesNames.SIGN_IN_VIEW)) setSessionExpired(true);
          }
          else throw (error);
        });
    } catch (error) {
      // console.log('JwtService: errore nel try generale della refresh');
      // console.log(error);
      // if (this.existTokens() === true) this.removeTokens();
      // console.log('JwtService: trying to logout');
      // logout();
      // throw (error);
    }
  };


  signInWithEmailAndPassword = async (email, password) => {
    // console.log(`JwtService: trying to sign in with email ${email} and password ${password}`);
    try {

      if (this.existTokens() === true) {
        // console.log('JwtService: removing tokens');
        this.removeTokens();
      }
      return await axios
        .post(jwtServiceConfig.signIn, {
          username: email,
          password: password,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.accessToken && response?.data?.refreshToken) {
              try {
                this.saveToken(response.data.accessToken, response.data.refreshToken);
                this.saveSqlId(response.data.id);
                // getMyUser(response.data.id);

                // console.log('JwtService: emitting onLogin event');
                // this.emit("onLogin");
              } catch (error) {
                throw (error)
              }
            } else {
              throw new Error('No access/refresh token found in response even if the status code is 200');
            }
          }
        })
        .catch((error) => {
          throw (error)
        });
    } catch (error) {
      throw (error)
    }
  };

  setBearerToken = (access_token) => {
    // console.log('JwtService: trying to set bearer');
    try {
      axios.defaults.headers.common.Authorization = `${access_token}`;
      // console.log('JwtService: bearer correctly set');
    } catch (error) {
      throw new Error("JwtService: error during bearer token setting");
    }
  };

  removeBearerToken = () => {
    try {
      delete axios.defaults.headers.common.Authorization;
    } catch (error) {
      throw new Error("JwtService: error during bearer token removing");
    }
  };

  saveToken = (access_token, refresh_token) => {
    // console.log('JwtService: trying to set bearer and saving access/refresh token');
    if (access_token && refresh_token) {
      try {
        this.setBearerToken(access_token);
        localStorage.setItem("jwt_access_token", access_token);
        localStorage.setItem("jwt_refresh_token", refresh_token);
      } catch (error) {
        // console.log('JwtService: error during saving token');
        throw new Error("JwtService: error during saving token");
      }
    } else {
      // console.log('Access toker or refresh token not found');
      throw new Error("Access token or refresh token not found");
    }
  };

  saveSqlId = (sqlId) => {
    if (sqlId) {
      try {
        localStorage.setItem("sql_id", sqlId);
      } catch (error) {
        throw new Error("JwtService: error during saving sqlId");
      }
    } else throw new Error("JwtService: sqlid not found");
  };

  removeTokens = () => {
    try {
      this.removeBearerToken();
      localStorage.removeItem("jwt_access_token");
      localStorage.removeItem("jwt_refresh_token");
      localStorage.removeItem("selectedTenant");
      localStorage.removeItem("roleId");
    } catch (error) {
      throw new Error(error);
    }
  };
  removeSqlId = () => {
    try {
      localStorage.removeItem("sql_id");
    } catch (error) {
      throw new Error(error);
    }
  };

  logout = async () => {
    // console.log('JwtService: trying to logout');
    try {
      this.removeTokens();
      this.removeSqlId();
      // console.log('JwtService: tokens removed correctly');
      // console.log('JwtService: emitting onLogout event');
      // this.emit("onLogout", "Logged out");
    } catch (error) {
      throw new Error("JwtService: error during logout");
    }
  };

  isTokenExpired = (access_token) => {
    // console.log('JwtService: checking if access token is expired');
    try {
      const decoded = jwtDecode(access_token);
      // console.log('JwtService: decoded', decoded);
      const currentTime = Math.floor(Date.now() / 1000);
      const expDate = new Date(decoded.exp * 1000);
      const curDate = new Date(currentTime * 1000);
      // console.log('JwtService: currentTime', curDate);
      // console.log('JwtService: token decoded.exp', expDate);
      // if (decoded.exp < currentTime) console.log('JwtService: access token is expired');
      // else console.log('JwtService: access token NOT expired');
      return decoded.exp < currentTime;
    } catch (error) {
      throw new Error("JwtService: error while checking if access token is expired");
    }
  };

  getAccessToken = () => {
    // console.log('JwtService: trying to get access token');
    const accessToken = window.localStorage.getItem("jwt_access_token");
    // console.log('JwtService: inside getAccessToken, accessToken', accessToken);
    return accessToken;
    // return window.localStorage.getItem("jwt_access_token");
  };

  getRefreshToken = () => {
    // console.log('JwtService: trying to get refresh token');
    const refreshToken = window.localStorage.getItem("jwt_refresh_token");
    // console.log('JwtService: inside getRefreshToken, refreshToken', refreshToken);
    return refreshToken;
    // return window.localStorage.getItem("jwt_refresh_token");
  };

  existTokens = () => {
    try {
      // console.log('JwtService: checking if tokens exist');
      const access_token = this.getAccessToken();
      const refresh_token = this.getRefreshToken();
      if (!access_token || !refresh_token) {
        // console.log('JwtService: tokens not exist');
        return false;
      }
      // console.log('JwtService: tokens exist');
      return true
    } catch (error) {
      // console.log("JwtService: error while checking if tokens exist: " + error);
      throw new Error(error);
    }
  };

  getInfoFromToken = () => {
    try {
      const user = jwtDecode(this.getAccessToken());
      return { username: user.sub, language: user.language };
    } catch (error) {
      // console.log("JwtService: error while getting logged user: " + error);
      throw new Error(error);
    }
  };
};





const instance = new JwtService();

export default instance;
