import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const RoleSelect = ({ style, rolesList, defaultValue, placeholder, value, required = false, onChange, multiple = false, disabled = false, tenantId}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  tenantId = tenantId ?? user.tenantId;
  let roles = tenants?.[tenantId]?.roles
  const options = useMemo(() => {
    if (rolesList) return rolesList;
    return roles.map((role) => role?.["_id"]);
  }, [rolesList, tenantId]);

  roles = roles.reduce((acc, { _id, ...rest }) => {
    acc[_id] = { ...rest };
    return acc;
  }, {});
  const [val, setVal] = useState(defaultValue ? defaultValue : multiple ? [] : "");

  useLayoutEffect(() => {
    value !== undefined && setVal(value);
  }, [value]);


  const handleChange = (selected) => {
    setVal(selected);
    if (onChange) onChange(selected)
  };

  placeholder = placeholder ?? handleTranslate('ROLE', true);

  return (
    <FormControl disabled={disabled} sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="owner-select-label">{required ? `${placeholder}*` : placeholder}</InputLabel>
      <Select
        labelId="owner-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((roleId) => roles?.[roleId]?.roleName).join(", ") : roles?.[selected]?.roleName}
        label={required ? `${placeholder}*` : placeholder}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options.map((roleId, index) => (
          <MenuItem key={index} value={roleId}>
            {multiple && <Checkbox checked={val?.indexOf(roleId) > -1} />}
            {roles?.[roleId]?.roleName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RoleSelect;