import FOOTER_IT from "./Footer/it";
import LOCATION_INPUT_COMPONENT_IT from "./LocationInput/it";
import NO_NOTIFICATION_MESSAGE_IT from "./No notification message/it";

const SHARING_COMPONENTS_IT = {
    ...FOOTER_IT,
    ...LOCATION_INPUT_COMPONENT_IT,
    ...NO_NOTIFICATION_MESSAGE_IT,
};

export default SHARING_COMPONENTS_IT;