import { useEffect, useState } from "react";
import { Box, Button, Modal, Stack } from "@mui/material";
import { updateCookies } from "app/store/evocsSlice/user/userAction";
import { useSelector } from "react-redux";

const containerStyle = {
  position: "absolute",
  display: "flex", 
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "1rem",
  borderRadius: "1rem",
  height: "80vh",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column"
}

const CookiesModal = ({show, onClose}) => {
  const cookies = useSelector((state) => state.user.cookies);
  const [isOpen, setOpen] = useState(show || false);
  const [customize, setCustomize] = useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show])

  // useEffect(() => {
  //   setOpen(customize);
  // }, [customize])


  const handleConfirmAll = () => {
    const keys = Object.keys(cookies);
    const dictUpdate = {...cookies};
    dictUpdate[keys[0]] = true;
    updateCookies(dictUpdate);
    handleClose();
  }

  const handleRejectAll = () => {
    const keys = Object.keys(cookies);
    const dictUpdate = {...cookies};
    dictUpdate[keys[0]] = false;
    updateCookies(dictUpdate);
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  }

  return (
    <Modal open={isOpen} onClose={customize ? handleClose : null}>
      {!customize ? 
      <Box sx={{position: "absolute", bottom: 0, width: "100%", background: "white"}} padding={{xs: "1rem", md: "2rem"}}>
        <h2 style={{fontWeight: "bold"}}>Diamo valore alla tua privacy</h2>
        <label>Utilizziamo i cookie per migliorare la tua esperienza di navigazione, offrirti pubblicità o contenuti personalizzati e analizzare il nostro traffico. Cliccando “Accetta tutti”, acconsenti al nostro utilizzo dei cookie.</label>
        <Stack sx={{margin: "2rem auto 0 auto", width: "fit-content"}} direction={"row"} spacing={1}>
          {/* <Button onClick={() => setCustomize(true)}>Personalizza</Button> */}
          <Button onClick={handleRejectAll}>Rifiuta tutto</Button>
          <Button variant="contained" onClick={handleConfirmAll}>Accetta tutto</Button>
        </Stack>
      </Box>
      :
      <div style={containerStyle}>
        <h2>Cookies</h2>
      </div> 
      }
    </Modal>
  )
}

export default CookiesModal