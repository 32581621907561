import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
	LOST_PASSWORD: 'Password dimenticata?',
	LOG_AS_GUEST_1: 'Non hai un account?',
	LOG_AS_GUEST_2: 'Entra come guest',
	USER_MAIL : 'Username o email',
	BACK : 'Indietro',
};

const SIGN_IN_IT = createLanguageObject(IT, viewNames.SIGN_IN_VIEW);

export default SIGN_IN_IT;