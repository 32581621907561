import themesConfig from "app/configs/themesConfig";
import i18n from "../../i18n";
import { authRoles } from "../auth";
import {routesNames} from 'src/app/configs/routesNames';

const settingsConfig = {
  layout: {
    style: "layout1", // layout1 layout2 layout3
    config: {}, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: i18n.dir(i18n.options.lng) || "ltr", // rtl, ltr
  theme: {
    main: themesConfig.default,
    navbar: themesConfig.default,
    toolbar: themesConfig.defaultDark,
    footer: themesConfig.defaultDark,
  },

  defaultAuth: authRoles.user,

  loginRedirectUrl: routesNames.STATIONS_VIEW,
};

export default settingsConfig;
