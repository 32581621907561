import React, { useState } from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  View,
} from "@react-pdf/renderer";
import CoverImg from "src/app/asset/images/TilebytesCopertina.png";
import EvocsLogo from "src/app/asset/images/logoEvocsBlack.png";

const style = StyleSheet.create({
  pdfViewer: {
    width: "100%",
    height: "100%",
    // width: window.innerWidth,
    // height: window.innerHeight,
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontfamily: "Helvetica, sans-serif",
  },
  text: {
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  firstTitle: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20,
    textAlign: "left",
  },
  secondTitle: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
    textAlign: "left",
  },
  paragraph: {
    fontSize: 12,
    fontWeight: "normal",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
  },
  line: {
    border: "0.5px solid black",
    borderRadius: "1px",
  },
});

const CoverPage = ({ reportTitle }) => {
  const coverStyle = StyleSheet.create({
    headerTitle: {
      marginTop: 10,
      fontSize: 24,
      marginBottom: 15,
      textAlign: "center",
      color: "242A30",
      fontWeight: "bold",
    },
    coverContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    coverSubOne: {
      fontSize: 15,
      marginVertical: 10,
    },
    coverSubTwo: {
      fontSize: 11,
    },
    coverImage: {
      marginVertical: 25,
      width: "100%",
      // height: 350
    },
    coverDiv: {
      marginTop: 10,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  return (
    <View>
      <Text style={coverStyle.headerTitle}>{reportTitle}</Text>
      <View style={coverStyle.coverContainer}>
        <Image style={coverStyle.coverImage} src={CoverImg} />
      </View>
    </View>
  );
};

const DescriptionSection = ({ descrSectiontitle, descrParagraph }) => {
  const descrStyle = StyleSheet.create({});

  return (
    <View>
      <Text style={style.firstTitle}>{descrSectiontitle}</Text>
      <Text style={style.paragraph}>{descrParagraph}</Text>
    </View>
  );
};

const ImageSection = ({
  graphSectionTitle,
  captionParagraph,
  srcImg,
  imageCaption,
  imgWidth = 600,
  imgHeight = 320,
}) => {
  const graphStyle = StyleSheet.create({
    graphImage: {
      paddingTop: 5,
      width: imgWidth,
      height: imgHeight,
      margin: 0,
    },
    graphContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    imgCap: {
      fontSize: 11,
      textAlign: "center",
      color: "grey",
    },
  });

  return (
    <View>
      <Text style={style.firstTitle}> {graphSectionTitle} </Text>
      <Text style={style.paragraph}>{captionParagraph}</Text>
      <View style={graphStyle.graphContainer}>
        <Image src={srcImg} style={graphStyle.graphImage} />
        <Text style={graphStyle.imgCap}>{imageCaption}</Text>
      </View>
    </View>
  );
};

const NoDataSection = () => {
  return (
    <Page>
      <Text>No data</Text>
    </Page>
  );
};

const SensorSection = ({ sensorData }) => {
  if (Object.keys(sensorData).length === 0) return <NoDataSection />;
  const {
    data,
    year,
    start_date_str,
    end_date_str,
    descr_title,
    sensor,
    titolo_analisi,
    descrizione,
    images,
    positionTitle,
    positionParagraph,
    stationImg,
    positionInfo,
  } = sensorData;
  const { descr, img, caption } = images;
  const locationImg =
    positionTitle && stationImg && positionParagraph && positionInfo;
  return (
    <Page style={style.page} wrap>
      {/* {locationImg && (
        <ImageSection
          imgHeight={360}
          imgWidth={520}
          graphSectionTitle={positionTitle}
          captionParagraph={positionParagraph}
          srcImg={stationImg}
          imageCaption={positionInfo}
        />
      )} */}
      <DescriptionSection
        descrSectiontitle={descr_title}
        descrParagraph={descrizione}
      />
      <ImageSection
        graphSectionTitle={titolo_analisi}
        captionParagraph={descr}
        sensorName={sensor}
        srcImg={img}
        imageCaption={caption}
      />
      <Footer />
    </Page>
  );
};

const StationReport = ({ reportsData }) => {
  if (!reportsData) return <></>;
  const { sensors } = reportsData;
  if (!sensors) return <NoDataSection/>;
  return (
    <>
      {Object.keys(sensors).map((key, index) => {
        return <SensorSection key={key} sensorData={sensors[key]} />;
      })}
    </>
  );
};
const CuReport = ({ reportsData }) => {
  if (!reportsData) return <></>;
  const { sensors } = reportsData;
  if (!sensors) return <NoDataSection/>;
  return (
    <>
      {Object.keys(sensors).map((key, index) => {
        return <SensorSection key={key} sensorData={sensors[key]} />;
      })}
    </>
  );
};

const SensorsReport = ({ sensorsData }) => {
  return !sensorsData ? (
    <></>
  ) : (
    Object.keys(sensorsData).map((key) => (
      <SensorSection key={key} sensorData={sensorsData[key]} />
    ))
  );
};

const DeviceLocation = ({ deviceData}) => {
  if (!deviceData || Object.keys(deviceData).length === 0) return <></>;
  let positionTitle, positionParagraph, stationImg, positionInfo;
  try{
    const keysToCheck = ['positionTitle', 'positionParagraph', 'stationImg', 'positionInfo'];
    let hasAllKeys = keysToCheck.every(key => deviceData.hasOwnProperty(key));
    if (!hasAllKeys) deviceData = deviceData[Object.keys(deviceData)[0]];
    hasAllKeys = keysToCheck.every(key => deviceData.hasOwnProperty(key));
    if (!hasAllKeys) return <></>;
    positionTitle = deviceData['positionTitle'];
    positionParagraph = deviceData['positionParagraph'];
    stationImg = deviceData['stationImg'];
    positionInfo = deviceData['positionTitle'];
    return (
      <Page style={style.page} wrap>
      <ImageSection
        imgHeight={360}
        imgWidth={520}
        graphSectionTitle={positionTitle}
        captionParagraph={positionParagraph}
        srcImg={stationImg}
        imageCaption={positionInfo}
      />
      <Footer />
    </Page>
    )
  }
  catch(err){
    console.log('err: ', err);
    return <></>
  }
}

const Footer = () => {
  const footerStyle = StyleSheet.create({
    footerContainer: {
      fontSize: 11,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0 35px",
    },
    footerLogo: {
      width: 60,
      height: 13,
      marginRight: 10,
    },
    pageText: {
      textAlign: "center",
      fontSize: 10,
      color: "black",
    },
  });
  return (
    <View style={style.footer} fixed>
      <View style={footerStyle.footerContainer}>
        <Image src={EvocsLogo} style={footerStyle.footerLogo} />
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          style={footerStyle.pageText}
        />
      </View>
    </View>
  );
};

const DynamicReportPDF = ({
  reportWith,
  reportHeight,
  reportsData,
  type = "sensor",
}) => {
  const containerStyle = StyleSheet.create({
    pdfViewer: {
      width: reportWith ? reportHeight : window.innerWidth,
      height: reportHeight ? reportHeight : window.innerHeight,
    },
  });

  return (
    <>
      {reportsData && (
        <Document>
          <Page style={style.page} wrap>
            <CoverPage reportTitle={reportsData.report_title} />
            <Footer />
          </Page>
          
          <DeviceLocation deviceData={type === "sensor" ? reportsData.sensors : reportsData} />

          {type === "station" && <StationReport reportsData={reportsData} />}
          {type === "cu" && <CuReport reportsData={reportsData} />}
          {type === "sensor" && (
            <SensorsReport sensorsData={reportsData.sensors} />
          )}
        </Document>
      )}
    </>
  );
};

export default DynamicReportPDF;
