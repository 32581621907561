import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { inputsComponentsNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const ConstantSelect = ({ style, constantsId, value, placeholder, required = false, onChange, multiple = false }) => {
  const constants = useSelector((state) => state.configuration.constants);
  const [options, setOptions] = useState(constantsId);
  const [val, setVal] = useState(value !== undefined ? value : multiple ? [] : "");
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, inputsComponentsNames.INPUTS);
  };


  useEffect(() => {
    if (constantsId) setOptions(constantsId);
    else setOptions(Object.keys(constants));
  }, [constantsId])

  useEffect(() => {
    value !== undefined && setVal(value);
  }, [value]);


  const handleChange = (selected) => {
    setVal(selected);
    if (onChange) onChange(selected)
  };

  placeholder = placeholder ?? handleTranslate("CONSTANT_TYPE");
  
  return (
    <FormControl sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="constant-type-select-label">{required ? `${placeholder}*` : placeholder}</InputLabel>
      <Select
        sx={{ maxWidth: "300px" }}
        labelId="constant-type-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((constantId) => constantId).join(", ") : selected}
        label={required ? `${placeholder}*` : placeholder}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options?.map((constantId, index) => (
          <MenuItem key={index} value={constantId}>
            {multiple && <Checkbox checked={val?.indexOf(constantId) > -1} />}
            {constantId}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ConstantSelect;