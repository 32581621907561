import { sharingComponentNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    MONDAY_SHORT: "Mon",
    FRIDAY_SHORT: "Fri",
    OPENING_HOURS: "09:00 AM - 05:00 PM",
    COLUMN_1_TITLE: "About us",
    COLUMN_2_TITLE: "Contacts",
    COLUMN_3_TITLE: "Support",
    COLUMN_4_TITLE: "Follow us on social media",
    COLUMN_1_TEXT: "Registered in the Register of Innovative \
    Startups and SMEs since 2019, we provide innovative solutions to \
    support decision making through environmental big data obtained \
    via our sensor network.",
};

const FOOTER_EN = createLanguageObject(EN, sharingComponentNames.FOOTER_COMPONENT);

export default FOOTER_EN;