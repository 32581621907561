import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";



const MeasureAutocomplete = ({ style, measuresId, value, defaultValue, onChange, multiple = false, required = false, disabled = false, limitTags = undefined, disablePortal=false }) => {
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const options = useMemo(() => {
    if (measuresId) {
      return measuresId;
    } else {
      return Object.keys(measures);
    }
  }, [measures, measuresId]);
  const [val, setValue] = useState(defaultValue !== undefined ? defaultValue : multiple ? [] : null);

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
     if (general) return translate(t, textId);
     return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  return (
    <Autocomplete
      disablePortal={disablePortal}
      id="measure-autocomplete"
      sx={{ ...style, width: "100%", maxWidth: "300px" }}
      disabled={disabled}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      limitTags={limitTags}
      onChange={(_, selected) => handleChange(selected)}
      options={options}
      value={val}
      getOptionLabel={(option) => handleTranslate(`MEASURE_${measures[option]?.measure.toUpperCase()}`, true)}
      // getOptionLabel={(option) => measures[option]?.label}
      renderInput={(params) => <TextField {...params} label={required ? `${handleTranslate('MEASURE', true)}*` : ` ${handleTranslate('MEASURE', true)}`} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && <Checkbox checked={selected} />}
          {handleTranslate(`MEASURE_${measures[option]?.measure.toUpperCase()}`, true)}
        </li>
      )}
    />
  )
}

export default MeasureAutocomplete;


