import { useEffect, useState, useMemo } from "react";

const useTouch = () => {

  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const checkTouchDevice = useMemo(() => {
    return () => {
      setIsTouchDevice("ontouchstart" in window);
    };
  }, []);

  useEffect(() => {
    checkTouchDevice();
  }, [checkTouchDevice]);

  return [isTouchDevice];
};

export default useTouch;
