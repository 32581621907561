import { viewNames } from "app/configs/routesNames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { getNationFlag, getPortTraffic, getVessels } from "app/store/evocsSlice/vessels/vesselsActions";
import VesselsTrafficGraph from "./VesselsTrafficGraph";
import VesselsTrafficTable from "./VesselsTrafficTable";
import VesselsTrafficMap from "./VesselsTrafficMap";
import LoadingGif from "../FileManager/LoadingGif";
import useActions from "src/app/hooks/useActions";
import { LuFlagOff } from "react-icons/lu";
import mkrIcon0 from "src/app/asset/markers/Blue2.png";
import mkrIcon1 from "src/app/asset/markers/Orange.png";
import mkrIcon2 from "src/app/asset/markers/Brown.png";
import mkrIcon3 from "src/app/asset/markers/PinkFluo.png";
import mkrIcon4 from "src/app/asset/markers/DarkPink.png";
import mkrIcon5 from "src/app/asset/markers/DarkViolet.png";
import mkrIcon6 from "src/app/asset/markers/DefaultMarker.png";
import mkrIcon7 from "src/app/asset/markers/Granata.png";
import mkrIcon8 from "src/app/asset/markers/Yellow.png";
import mkrIcon9 from "src/app/asset/markers/GreenAlpine.png";
import mkrIcon10 from "src/app/asset/markers/Red.png";

const viewName = viewNames.VESSELS_VIEW;
const graphBackgroundColor = [
  "rgba(2, 140, 223, 0.7)",
  "rgba(246, 151, 49, 0.7)",
  "rgba(104, 39, 0, 0.7)",
  "rgba(255, 89, 183, 0.7)",
  "rgba(235, 125, 127, 0.7)",
  "rgba(92, 56, 107, 0.7)",
  "rgba(146, 200, 112, 0.7)",
  "rgba(162, 51, 54, 0.7)",
  "rgba(242, 223, 53, 0.7)",
  "rgba(20, 66, 60, 0.7)",
  "rgba(223, 22, 40, 0.7)",
];
const graphBorderColor = [
  "rgb(2, 140, 223)",
  "rgb(246, 151, 49)",
  "rgb(104, 39, 0)",
  "rgb(255, 89, 183)",
  "rgb(235, 125, 127)",
  "rgb(92, 56, 107)",
  "rgb(146, 200, 112)",
  "rgb(162, 51, 54)",
  "rgb(242, 223, 53)",
  "rgb(20, 66, 60)",
  "rgb(223, 22, 40)",
];

const getVesselTypeMarker = (colorIndex) => {
  switch(colorIndex) {
    case 0: 
      return mkrIcon0;
    case 1:
      return mkrIcon1;
    case 2: 
      return mkrIcon2;
    case 3:
      return mkrIcon3;
    case 4: 
      return mkrIcon4;
    case 5:
      return mkrIcon5;
    case 6: 
      return mkrIcon6;
    case 7:
      return mkrIcon7;
    case 8: 
      return mkrIcon8;
    case 9:
      return mkrIcon9;
    case 10: 
      return mkrIcon10;
    default: 
    return mkrIcon9;
  }
}


const VesselsTrafficContent = () => {
  const userActions = useActions(viewName);
  const [loading, setLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const [vessels, setVessels] = useState();
  const [portTraffic, setPortTraffic] = useState();
  const [selectedVessel, setSelectedVessel] = useState();
  const [vesselsTypeMarkers, setVesselsTypeMarker] = useState([]);
  const enabledActions = useMemo(() => {
    return userActions?.map((action) => action?.name);
  }, [userActions]);

  const getVesselsAndFlag = useCallback(async(startDate, endDate) => {
    const data = await getVessels(startDate, endDate, null, null, true);
    const allNationsCode = [...new Set(data.map((element) => element.mmsi.toString().slice(0, 3)))];
    const flags = await getNationFlag(allNationsCode);

    return data.map((element) => {
      const flag = flags.find((e) => String(e.nationCode) === element.mmsi.toString().slice(0, 3));
      let newElement = { ...element };
      newElement.flag = (
        flag.icon ?
        <img
          src={"data:image/png;base64," + flag.icon}
          alt={"flag image"}
          width={20}
          height={20}
        />
        :
        <LuFlagOff size={20} />
      )
      return newElement;
    });
  }, []);
 

  useEffect(() => {
    setLoading(true);
    const endDate = new Date().valueOf();
    const startDate = endDate - 3600000*48;
    const fetchData = [
      getPortTraffic(startDate, endDate).then((res) => {
        let colors = {};
        Object.keys(res.shipCounter).forEach((vesselTypeId, index) => {
          colors[vesselTypeId] = getVesselTypeMarker(index);
        });
        setVesselsTypeMarker(colors);
        setPortTraffic(res);
      }),
      getVesselsAndFlag(startDate, endDate).then((res) => {
        setVessels(res);
      })
    ];
    Promise.all(fetchData).then(() => setLoading(false));
  }, [])


  const handleClickRow = (row, rowIndex) => {
    setMapCenter([row.lat, row.lon]);
    setSelectedVessel(vessels.findIndex((e) => e.name === row.name));
  }


  return(
    <Box padding={{ xs: "1rem", sm: "2rem 5rem"}}>
      {!loading ? 
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <VesselsTrafficMap enabledActions={enabledActions} vessels={vessels} vesselsTypeMarkers={vesselsTypeMarkers} center={mapCenter} selectedMarker={selectedVessel}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <VesselsTrafficGraph portTraffic={portTraffic} graphBackgroundColor={graphBackgroundColor} graphBorderColor={graphBorderColor}/>
        </Grid>
         <Grid item xs={12}>
         { vessels && <VesselsTrafficTable vessels={vessels} onClickRow={handleClickRow}/>}
        </Grid>
      </Grid>
      :
      <LoadingGif />
      }
    </Box>
  )
}

export default VesselsTrafficContent;