import FusePageCarded from "@fuse/core/FusePageCarded";
import StationHandlingContent from "src/components/StationHandling/StationHandlingContent";
import WithAuth from "./WithAuth";
import { viewNames } from "app/configs/routesNames";
import StationHandlingHeader from "src/components/StationHandling/StationHandlingHeader";

const StationHandling = () => {
  return (
    <WithAuth
      viewName={viewNames.STATIONS_VIEW}
      component={
        <FusePageCarded
          header={<StationHandlingHeader />}
          content={<StationHandlingContent/>}
        />
      }
    />
  );
};

export default StationHandling;
