import React from 'react'
import TestPageHeader from 'src/components/TestPage/TestPageHeader';
import TestPageContent from 'src/components/TestPage/TestPageContent';
import WithAuth from './WithAuth';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { viewNames } from 'app/configs/routesNames';

const TestPage = () => {
    return (
      <WithAuth
      viewName={viewNames.TEST_PAGE_VIEW}
      component={
        <FusePageCarded
          header={<TestPageHeader />}
          content={<TestPageContent />}
        />
      }
      />
    )
}

export default TestPage