import React, { useEffect, useMemo, useState } from "react";
import { Divider, Grid, Paper, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import LoadingGif from "../FileManager/LoadingGif";
import SelectSomething from "../SharingComponents/SelectSomething";
import SwitchDisplayStack from "./SwitchDisplayStack";
import TableComponent from "../SharingComponents/TableComponent";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";


const MeasureCard = ({columns, data}) => {
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
     if (general) return translate(t, textId);
     return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const filteredColumns = useMemo(() => {
    return columns && Object.keys(columns).filter((columnId) => columnId !== "measure" && columnId !== "cu");
  }, [columns])

  return (
    <Paper sx={{ padding: "1rem", height: "100%" }}>
      <h3 style={{ marginBottom: "1rem"}}> {data.measure} </h3>
        <Grid container spacing={1}>
          {filteredColumns?.map((columnId) => (
            <Grid key={columnId} item xs={12} sm={6}>
              <span>
                <b>{columns[columnId]}:</b> {data[columnId]}
              </span>
            </Grid>
          ))}
        </Grid>
        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",}}>
          <CloudOffIcon fontSize="medium" />
          <span style={{ fontSize: "1.5rem" }}> {handleTranslate('NO_DATA', true)}</span>
        </div> */}
    </Paper>
  );
};

const StationInfoChip = ({ stationId, loadingMinMax, maxMinMeasures }) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector((state) => state.configuration.constants.measure);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tabularDisplay, setTabularDisplay] = useState(true);
  const [searchText, setSearchText] = useState("");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const columns = useMemo(() => {
    return {
      measure: handleTranslate("MEASURE", true),
      min: "Min",
      max: "Max",
      avg: handleTranslate("AVERAGE", true),
      last: handleTranslate("LAST_RELEVATION", true),
      sensor: handleTranslate("SENSOR", true),
      cu: handleTranslate("CONTROL_UNIT", true),
    }
  }, [t]);

  const allCusParent = useMemo(() => {
    const cus = [];
    filteredData?.forEach((element) => {
      if(!cus.includes(element.cuParent)) cus.push(element.cuParent);
    });
    return cus;
  }, [filteredData]);


  useEffect(() => {
    setData(
      maxMinMeasures.map((element) => ({
        ...element,
        measure: handleTranslate(`MEASURE_${measures[element.measureId]?.measure.toUpperCase()}`, true),
        sensor: sensors[element.sensorParent].serial,
        cu: controlUnits[element.cuParent]?.serial,
      }))
    );
  }, [maxMinMeasures, t])

  useEffect(() => {
    if(!data) {
      setFilteredData([]);
      return;
    }
    if(!searchText || searchText === "") {
      setFilteredData(data);
      return;
    }

    const timeoutId = setTimeout(() => {
      setFilteredData(() => {
        return data.filter((element) => Object.keys(element).some((key) => element[key]?.toString().toLowerCase().includes(searchText.toLowerCase())));
      })

    }, 700);

    return () => clearTimeout(timeoutId);
  }, [data, searchText])


  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "1rem", width: "100%", height: "100%"}}>
      <div style={{ display: "flex", justifyContent: "space-between"}}>
        <h2 style={{ marginBottom: "1rem" }}>{stations[stationId]?.serial ? stations[stationId]?.serial : ""}</h2>
        <SwitchDisplayStack tabular={tabularDisplay} onChange={setTabularDisplay} />
      </div>
      {stationId && data?.length > 0 && !loadingMinMax && 
        <TextField sx={{width: "100%", maxWidth: "300px"}} placeholder={handleTranslate("SEARCH",true)} value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
      }
      {stationId ? (
        data.length > 0 ? (
          loadingMinMax ? (
            <LoadingGif />
          ) : !tabularDisplay ? (
            <div>
              <div style={{display: "flex", flexDirection: "column", gap: "2rem", paddingBottom: "1rem"}}>
                {allCusParent.map((cuId, index) => (
                  <div key={index}>
                    <h3 style={{marginBottom: "0.5rem"}}><strong>{controlUnits[cuId] ? `${handleTranslate("CONTROL_UNIT", true)}: ` + controlUnits[cuId]?.serial : "Sensori installati"}</strong></h3>
                    <Grid container spacing={1}>
                      {filteredData.map((element, j) => element.cuParent === cuId && (
                        <Grid key={j} item xs={12} sm={6} md={4} lg={3}>
                          <MeasureCard columns={columns} data={element}/>
                        </Grid>
                      ))}
                    </Grid>
                    {index < allCusParent.length -1 && <Divider sx={{m: "1rem 0"}}/>}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <TableComponent
              style={{ overflowY: "visible" }}
              disabledSearch
              data={filteredData}
              columns={columns}
              rowsForPage={10}
              paper={false}
            />
          )
        ) : (
          <span>{handleTranslate("NO_SENSORS_INSTALLED", true)}</span>
        )
      ) : (
        <SelectSomething text={handleTranslate("SELECT_STATION", true)} />
      )}
    </div>
  );
};

export default StationInfoChip;
