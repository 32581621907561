import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Dashboard",
    PIE_LEGEND_1: 'Il grafico rappresnta lo stato attuale delle stazioni di monitoraggio Evocs: ',
    PIE_LEGEND_2: 'tutti i sensori della stazione sono attivi e funzionanti',
    PIE_LEGEND_3: 'alcuni sensori della stazione sono non funzionanti',
    PIE_LEGEND_4: 'tutti i sensori della stazione sono non attivi o non funzionanti',
    PIE_LEGEND_5: 'la stazione non ha sensori installati',
    PIE_LEGEND_6: 'Un sensore è considerato non funzionante se non è in grado di inviare dati alla piattaforma.',
};

const DASHBOARD_IT = createLanguageObject(IT, viewNames.DASHBOARD_VIEW);

export default DASHBOARD_IT;