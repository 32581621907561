import React, { useRef } from 'react'
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useMobile from 'src/app/hooks/useMobile';

const SensorsStateGraph = ({data=[], labels=[], backgroundColor=[], borderColor=[], onClickPie, onDoubleClickPie, title=""}) => {
  const chartRef = useRef();
  const [xs, sm] = useMobile();

  const handleClickPie = (index) => {
    onClickPie && onClickPie(index);
  }

  const handleDoubleClick = (e) => {
    const points = chartRef.current.getElementsAtEventForMode(e, "nearest", {intersect: "true"}, true);
    const id = chartRef.current.data.labels[points[0]?.index];
    onDoubleClickPie && onDoubleClickPie(id);
  }

  return (
    <Pie 
    ref={chartRef}
    onDoubleClick={handleDoubleClick}
    data={{
        labels: labels,
        datasets: [
          {
            data: data,
            borderWidth: 1,
            backgroundColor: backgroundColor,
            borderColor: borderColor
          },
        ],
    }} 
    plugins={[ChartDataLabels]}
    options={{
        maintainAspectRatio: false,
        onClick: (e, elements) => {
          if(!e.chart.data.labels[elements[0]?.index]) return;
          handleClickPie(e.chart.data.labels[elements[0]?.index]);
        },
        hoverOffset: 30,
        plugins: {
          tooltip: {
            enabled: false
          },
          title: {
            display: true,
            color: "black",
            text: title,
            font: {
              size: "20px"
            }
          },
          legend: {
            display: false
          },
          datalabels: {
            anchor: "end",
            align: "center",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, .6)",
            font: {
              size: `${xs? 8 : sm ? 10 : 12}px`,
              weight: "bold"
            },
            formatter: (value, ctx) => {      
              return ctx.chart.data.labels[ctx.dataIndex]
            }
          }
        },
        layout: {
          padding: {
            left: 25,
            right: 25,
            top: 10,
            bottom: 10
          }
        }
    }} 
    />
  )
}

export default SensorsStateGraph