import React from "react";
import { IoIosNotificationsOff } from "react-icons/io";
import { Typography } from "@mui/material";

const NoNotificationsMessage = ({ style, small, positionFixed, message }) => {
  const defaultMessage = "Non ci sono nuove notifiche";

  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <IoIosNotificationsOff fontSize={small ? "35px" : "40px"} color="#232b30"/>
        <Typography variant={small ? "h7" : "h5"} color={'#232b30'}> {message || defaultMessage} </Typography>
      </div>
    </div>
  );
};

// const NoNotificationsMessage = ({ style, small, positionFixed, message }) => {
//   const positionFixedStyle = {
//     position: "fixed",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     textAlign: "center",
//   };
//   const defaultMessage = "Non ci sono nuove notifiche";
//   return (
//     <Box sx={positionFixed ? positionFixedStyle: style}>
//       <Box display="flex" flexDirection="column" alignItems="center">
//         <IoIosNotificationsOff fontSize={small ? "35px" : "40px"} color="#232b30"/>
//         <Typography variant={small ? "h7" : "h5"} color={'#232b30'}> {message || defaultMessage} </Typography>
//       </Box>
//     </Box>
//   );
// };

export default NoNotificationsMessage;
