import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";



const updateViewsConstants = createAsyncThunk(
    "views/updateViewsConstants",
    async(payload) => {
        const {tenantId, viewId, viewsConstants} = payload;
        const data = await axios
        .post(`/api/py/updateView`, {
            dictFeatures: {
                tenantId: tenantId,
                ...viewsConstants
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return {tenantId, viewId, data};
    }
)

const viewsSlice = createSlice({
    name:"views",
    initialState: {
        list: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateViewsConstants.fulfilled, (state, action) => {
            const {tenantId, viewId, data} = action.payload;
            state[tenantId][viewId] = {
                ...state[tenantId][viewId],
                ...data[tenantId][viewId]
            }
        })
    }
});

export const viewsSliceAction = {
    ...viewsSlice.actions,
    updateViewsConstants
}

export default viewsSlice.reducer;