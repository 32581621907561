import { Divider, Stack } from "@mui/material"
import ToggleButton from "../SharingComponents/Inputs/ToggleButton"
import ReorderIcon from '@mui/icons-material/Reorder';
import { PiGridFourFill } from "react-icons/pi";


const SwitchDisplayStack = ({tabular=false, onChange}) => {

  const handleChange = (value) => {
    onChange && onChange(value)
  }

  return (
    <Stack direction="row" alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
      <ToggleButton enabled={tabular} onChange={() => handleChange(true)}><ReorderIcon /></ToggleButton>
      <ToggleButton enabled={!tabular} onChange={() => handleChange(false)}><PiGridFourFill /></ToggleButton>
    </Stack>
  )
}

export default SwitchDisplayStack