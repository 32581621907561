import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "errorSlice",
  initialState: {
    serverError: false,
    globalError: false,
    globalSuccess: false,
    globalWarning: false,
    globalMessage: "",
  },
  reducers: {
    setServerError: (state, action) => {
      state.serverError = action.payload;
    },
    setGlobalError: (state, action) => {
      state.globalError = action.payload;
    },
    setGlobalSuccess: (state, action) => {
      state.globalSuccess = action.payload;
    },
    setGlobalWarning: (state, action) => {
      state.globalWarning = action.payload;
    },
    setGlobalMessage: (state, action) => {
      state.globalMessage = action.payload;
    },
  },
});

export default errorSlice.reducer;

export const errorSliceActions ={
  ...errorSlice.actions
};