import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from "@mui/material";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const OdourLevelSelect = ({ style, odourLevelsId, value, defaultValue, onChange, multiple = false, required = false, nullLabel = true }) => {
  const odourLevels = useSelector((state) => state.configuration.constants?.odour_level);
  const options = useMemo(() => {
    if (odourLevelsId) {
      return odourLevelsId;
    } else {
      return Object.keys(odourLevels);
    }
  }, [odourLevelsId])
  const [val, setValue] = useState(defaultValue ? defaultValue : value !== undefined ? value : multiple ? [] : "");

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  return (
    <FormControl sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="odour-level-select-label">{required ? `${handleTranslate('LEVEL', true)}*` : `${handleTranslate('LEVEL', true)}`}</InputLabel>
      <Select
        labelId="odour-level-select-label"
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((odourLevelId) => 
          handleTranslate(`ODOUR_LEVEL_${odourLevels[odourLevelId]?.odour_level?.toUpperCase()}`, true))
          .join(", ") : handleTranslate(`ODOUR_LEVEL_${odourLevels[selected]?.odour_level?.toUpperCase()}`, true)}
        label={required ? `${handleTranslate('LEVEL', true)}*` : `${handleTranslate('LEVEL', true)}`}
        onChange={(e) => handleChange(e.target.value)}
      >
        {!multiple && nullLabel &&
          <MenuItem value={""}>
            <em>{handleTranslate('ALL', true)}</em>
          </MenuItem>
        }
        {options?.map((odourLevelId, index) => (
          <MenuItem key={index} value={odourLevelId}>
            {multiple && <Checkbox checked={val?.indexOf(odourLevelId) > -1} />}
            {handleTranslate(`ODOUR_LEVEL_${odourLevels[odourLevelId]?.odour_level?.toUpperCase()}`, true)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default OdourLevelSelect;