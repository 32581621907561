import { useCallback, useLayoutEffect, useMemo } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingGif from "../FileManager/LoadingGif";
import TabsComponent from "../SharingComponents/TabsComponent";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import SensorTypeSelect from "../SharingComponents/Inputs/SensorTypeSelect";
import MeasureAutocomplete from "../SharingComponents/Inputs/MeasureAutocomplete";
import { updateSensor } from "app/store/evocsSlice/sensors/sensorsActions";
import SensorParentSelect from "../SharingComponents/Inputs/SensorParentSelect";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import EditIcon from "@mui/icons-material/Edit";
import ThresholdCard from "../SharingComponents/ThresholdCard";
import SensorHistoryChip from "./SensorHistoryChip";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { setGlobalError, setGlobalSuccess } from "app/store/evocsSlice/globalError/errorActions";

const styles = {
  containerStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "1200px",
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};

const requiredInputs = ["serial", "owner", "type", "measures"];

const ThresholdsConfiguration = ({ thresholds, disabled = false, measuresOptions, measuresValue, onChangeOptions, onChangeValues }) => {
  const { t } = useTranslation("evocs");
  const [thresholdsValues, setThresholds] = useState({});
  const [options, setOptions] = useState([]);
  const [measures, setMeasures] = useState([]);

  useEffect(() => {
    thresholds && setThresholds(thresholds);
  }, [thresholds])

  useEffect(() => {
    measuresValue && setMeasures(measuresValue);
  }, [measuresValue])

  useEffect(() => {
    handleChangeOptions(measuresValue.filter((measure) => measuresOptions.includes(measure)), false);
    measuresOptions && setOptions(measuresOptions);
  }, [measuresOptions])

  useEffect(() => {
    const newThresholds = { ...thresholdsValues };
    const filterMeasures = Object.keys(newThresholds).filter((measureId) => !measures.includes(measureId));
    if (filterMeasures.length > 0) {
      filterMeasures.forEach((measureId) => delete newThresholds[measureId]);
      setThresholds(newThresholds);
      onChangeValues && onChangeValues(newThresholds);
    }
  }, [measures])


  const handleChangeOptions = (value, changed = true) => {
    setMeasures(value);
    onChangeOptions && onChangeOptions(value, changed);
  };

  const handleChangeValues = (measureId, key, value) => {
    const newThresholds = { ...thresholdsValues, [measureId]: { ...thresholdsValues[measureId], [key]: value } };
    setThresholds(newThresholds);
    onChangeValues && onChangeValues(newThresholds);
  };



  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  return (
    <AccordionComponent title={handleTranslate("THRESHOLDS_MANAGEMENT")}>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginBottom: "1rem" }}>
        <label style={{ minWidth: "100px" }}> {handleTranslate("SET_THRESHOLDS_ON")} </label>
        <MeasureAutocomplete disablePortal multiple limitTags={2} disabled={disabled} measuresId={options} value={measures} onChange={handleChangeOptions} />
      </div>
      <Grid container spacing={1}>
        {measures.map((measureId, index) => (
          <Grid key={measureId} item xs={12} sm={6} md={4}>
            <ThresholdCard enableUpdate={!disabled} withUpdateIcons={false} defaultLocked={false} measureId={measureId} min={thresholdsValues[measureId]?.min} max={thresholdsValues[measureId]?.max} onChange={handleChangeValues} />
          </Grid>
        ))}
      </Grid>
    </AccordionComponent>
  )
}

const ConfigurationChip = ({enabledActions, sensorId, onPending, onFinally, onChangeInputs }) => {
  const { t } = useTranslation("evocs");
  const sensors = useSelector((state) => state.sensors.list);
  const [disableEdit, setDisableEdit] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [changedInputs, setChangedInputs] = useState([]);
  const [showThresholds, setShowThresholds] = useState(false);
  const deviceParent = sensors[sensorId]?.deviceParent;
  const getDefaultInputs = useCallback(() => {
    if (!sensors[sensorId]) return {};
    return {
      serial: sensors[sensorId].serial || "",
      available: sensors[sensorId].available === 1 ? true : false,
      parentType: deviceParent?.type || "",
      cuParent: deviceParent?.type === "cu" ? deviceParent.device : null,
      stationParent: deviceParent?.type === "station" ? deviceParent.device : null,
      owner: sensors[sensorId].owner || "",
      type: sensors[sensorId].sensor_type || "",
      measures: sensors[sensorId].measures,
      thresholdMeasures: Object.keys(sensors[sensorId].threshold),
      thresholdValues: { ...sensors[sensorId].threshold }
    }
  }, []);
  const [inputs, setInputs] = useState(getDefaultInputs());
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  useEffect(() => {
    if (changedInputs.length === 0) { setDisableConfirm(true); return; }
    const disabled = Object.keys(inputs).some((key) => (requiredInputs.includes(key) && (inputs[key] === "" || inputs[key]?.length === 0)));
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs])

  useEffect(() => {
    if (inputs?.measures?.length > 0) setShowThresholds(true);
    else setShowThresholds(false);
  }, [inputs?.measures])

  const handleChangeInput = (key, value, changed = true) => {
    setInputs({ ...inputs, [key]: value });
    changed && setChangedInputs(prev => [...prev, key]);
  };


  const handleCancelChanges = () => {
    setChangedInputs([]);
    setDisableEdit(true);
    setInputs(getDefaultInputs());
    onChangeInputs([]);
  };

  const handleConfirm = () => {
    onPending();
    let selectedParent = undefined, stationParent = undefined, cuParent = undefined, uninstall;
    if (inputs.parentType === undefined) {
      if (deviceParent) {
        if (deviceParent.type === "station") {
          selectedParent = inputs.stationParent;
          stationParent = inputs.stationParent;
        } else {
          selectedParent = inputs.cuParent;
          cuParent = inputs.cuParent;
        }
      }
    } else if (inputs.parentType === "station") {
      selectedParent = inputs.stationParent;
      stationParent = inputs.stationParent;
    } else {
      selectedParent = inputs.cuParent;
      cuParent = inputs.cuParent;
    }

    if (deviceParent) {
      if (selectedParent === null) uninstall = true;
      else uninstall = false;
    } else {
      uninstall = false;
    }
    let changed = { ...inputs };
    Object.keys(changed).forEach((key) => !changedInputs.includes(key) && (delete changed[key]));;
    updateSensor(sensorId, changed.serial, changed.owner, changed.type, changed.measures, changed.available, stationParent, cuParent, uninstall, changed.thresholdValues)
    .then(() => setGlobalSuccess(true, "MODAL_SUCCESS_UPDATE_SENSOR"))
    .catch(() => setGlobalError(true))
    .finally(onFinally);
  };


  return inputs && (
    <>
      <div style={{ position: "relative", display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem", paddingBottom: "7rem" }}>
        <div style={{ display: "flex", gap: "5rem", alignItems: "center", justifyContent: "space-between" }}>
          <h2 style={{ fontWeight: "bold" }}> {handleTranslate("SENSOR", true)}: {sensors[sensorId]?.serial}
            {disableEdit && <IconButton sx={{ ml: "1rem" }} onClick={() => setDisableEdit(false)}><EditIcon /></IconButton>}
          </h2>
          {enabledActions?.includes("manage-device") && <SwitchComponent label={handleTranslate("ACTIVE", true)} disabled={disableEdit} checked={inputs.available} onChange={(value) => handleChangeInput("available", value)} />}
        </div>
        {enabledActions?.includes("update-info-device") &&
          <>
            <TextField
              sx={{ width: "100%", maxWidth: "300px" }}
              required
              disabled={disableEdit}
              label={`${handleTranslate("SERIAL", true)}`}
              className="rounded-2xl"
              value={inputs.serial}
              onChange={(e) => handleChangeInput("serial", e.target.value)}
            />
            <TenantSelect required disabled={disableEdit} value={inputs.owner} onChange={(value) => handleChangeInput("owner", value)} />
            <SensorTypeSelect required disabled={disableEdit} value={inputs.type} onChange={(value) => handleChangeInput("type", value)} />
          </>
        }
        {enabledActions?.includes("manage-device") &&
          <>
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <span style={{ minWidth: "100px" }}> {handleTranslate("INSTALL_ON", true)} </span>
              <SensorParentSelect disablePortal disabled={disableEdit} stationValue={inputs.stationParent} cuValue={inputs.cuParent} typeValue={inputs.parentType}
                onChangeStation={(value) => handleChangeInput("stationParent", value)} onChangeCu={(value) => handleChangeInput("cuParent", value)} onChangeType={(value) => handleChangeInput("parentType", value)} />
            </div>
            <MeasureAutocomplete disablePortal required disabled={disableEdit} multiple value={inputs.measures} onChange={(value) => handleChangeInput("measures", value)} />
            {showThresholds &&
              <ThresholdsConfiguration thresholds={inputs.thresholdValues} disabled={disableEdit} measuresOptions={inputs.measures} measuresValue={inputs.thresholdMeasures}
                onChangeOptions={(value, changed) => handleChangeInput("thresholdMeasures", value, changed)} onChangeValues={(value) => handleChangeInput("thresholdValues", value)} />}
          </>
        }
      </div>
      {!disableEdit &&
        <div style={{ position: "absolute", padding: "1rem 2rem", left: 0, bottom: 0, width: "100%", display: "flex", gap: ".5rem", background: "white", zIndex: 20 }}>
          <Button sx={{ color: "red" }} onClick={handleCancelChanges}>Annulla</Button>
          <Button disabled={disableConfirm} onClick={handleConfirm}>Salva</Button>
        </div>
      }
    </>
  );
}



const SensorConfigurationModal = ({ enabledActions, isOpen = false, sensorId, onFinally, onClose }) => {
  const { t } = useTranslation("evocs");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);

  const handleFinally = () => {
    setUpdateLoading(false);
    onFinally && onFinally();
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const tabs = useMemo(() => {
    return {
      generals: {
        header: `${handleTranslate("GENERAL", true)}`,
        content: <ConfigurationChip enabledActions={enabledActions} sensorId={sensorId} onPending={() => setUpdateLoading(true)} onFinally={handleFinally} onChangeInputs={setChangedInputs} />
      },
      history: {
        header: `${handleTranslate("HISTORY", true)}`,
        content: <SensorHistoryChip sensorId={sensorId} />
      }
    }
  }, [sensorId, enabledActions, t]);

  useLayoutEffect(() => {
    !isOpen && setChangedInputs([]);
  }, [isOpen])

  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  }

  return (
    <Modal open={isOpen}>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ position: "relative", height: "calc(100% - 36px)"}}>
          <TabsComponent tabs={tabs} unselectTextColor={"white"} />
          {updateLoading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

export default SensorConfigurationModal;
