import EventIcon from "@mui/icons-material/Event";
import { Slider } from "@mui/material";
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { formatDate } from "./utils";
import { useLayoutEffect, useState } from "react";

/* CodeInfo: Componente che renderizza il doppio slider previsto delle seguenti prop
        - timeRangeValue: Array di minValue,maxValue ossia i due valori assunti dai "pallini" dello slider 
        - startingDate: Data iniziale, serve a capire da quanto si può estendere lo slider
        - endingDate: Data finale, serve a capire fino a quanto si può estendere lo slider
        - maxRangeValue: Valore numerico massimo che può assumere lo slider
        - withDatePicker: Booleano per il render dei datePicker (nella scelta personalizzata delle date)
        - customDate: Data custom inserita
        - isHourFormatted: Booleano per capire se la fascia inserita è oraria e non giornaliera
  */

        
const TimeRangeSlider = ({
  withDatePicker=false,
  startDate,
  endDate,
  sliderStart,
  sliderEnd,
  onChangeSlider,
  onChangeCalendar,
  timeFormat
}) => {

  const [startingDate, setStartingDate] = useState(startDate || new Date(new Date() - 86400000));
  const [endingDate, setEndingDate] = useState(endDate || new Date());
  const [sliderStarting, setSliderStart] = useState(startingDate);
  const [sliderEnding, setSliderEnd] = useState(endingDate);
  const [sliderRangeValue, setSliderRangeValue] = useState([0, 0]); //array per gli step dello slider
  const [maxSliderRangeValue, setMaxSliderRangeValue] = useState(0);

  const [openStartCalendar, setOpenStartCalendar] = useState(false);
  const [openEndCalendar, setOpenEndCalendar] = useState(false);
  const [calendarStartDate, setCalendarStartDate] = useState(sliderStarting);
  const [calendarEndDate, setCalendarEndDate] = useState(sliderEnding);

  useLayoutEffect(() => {
    if(!startDate || !endDate) return;
    setStartingDate(startDate);
    setEndingDate(endDate);
    //calcSliderSteps(startDate, endDate);
  }, [startDate, endDate]);

  useLayoutEffect(() => {
    //calcoliamo il max range value dell'intervallo personalizzato calcolando la differenza tra le due date
    // 86400000 sono i millesecondi in un giorno
    //intervallo personalizzato con slider solo sui giorni
    if(!sliderStart || !sliderEnd) return;
    setSliderStart(sliderStart);
    setSliderEnd(sliderEnd);
  }, [sliderStart, sliderEnd]);

  useLayoutEffect(() => {
    calcSliderSteps(startingDate, endingDate);
  }, [startingDate, endingDate]);


  useLayoutEffect(() => {
    const start = new Date(endingDate - maxSliderRangeValue * 3600000 + sliderRangeValue[0] * 3600000);
    const end = new Date(endingDate - (maxSliderRangeValue - sliderRangeValue[1]) * 3600000);
    setSliderStart(start);
    setSliderEnd(end);
    setCalendarStartDate(start);
    setCalendarEndDate(end);
  }, [sliderRangeValue]);


  const calcSliderSteps = (newStartDate, newEndDate) => {
    const start = newStartDate.setSeconds(0, 0);
    const end = newEndDate.setSeconds(0, 0);
    const dif = end.valueOf() - start.valueOf();
    const max = dif / 3600000;
    setMaxSliderRangeValue(Math.floor(max));
    setSliderRangeValue([0, Math.floor(max)])   
  }

  const handleSliderChange = (sliderArray) => {
    setSliderRangeValue(sliderArray);
  }

  const handleChangeCommitted = (value) => {
    onChangeSlider && onChangeSlider(sliderStarting, sliderEnding, value);
  }

  const handleAccept = () => {
    setStartingDate(calendarStartDate);
    setEndingDate(calendarEndDate);
    handleClose();
    onChangeCalendar && onChangeCalendar(calendarStartDate, calendarEndDate);
  }

  const handleClose = () => {
    setOpenStartCalendar(false);
    setOpenEndCalendar(false);
  }

  return (
    <div style={{display: "flex", gap: "1.8rem"}}>
      {withDatePicker &&
      <>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}} onClick={() => setOpenStartCalendar(true)}>
          <EventIcon />
          <label style={{textAlign: "center", cursor: "pointer"}}>{formatDate(sliderStarting, timeFormat)}</label>
        </div>
        <MobileDateTimePicker 
          sx={{display: "none"}}
          open={openStartCalendar}
          value={calendarStartDate}
          onChange={setCalendarStartDate}
          onAccept={handleAccept}
          onClose={handleClose}
          disableFuture
          />
      </>
      }
      <Slider
        value={sliderRangeValue}
        onChangeCommitted={(e, value) => handleChangeCommitted(value)}
        onChange={(e) => handleSliderChange(e.target.value)}
        max={maxSliderRangeValue}
        getAriaLabel={() => "Intervallo temporale"}
        sx={{width: "100%", minWidth: "100px", maxWidth: "400px", color: '#2d3740'}}
      />
      {withDatePicker &&
      <>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}} onClick={() => setOpenEndCalendar(true)}>
          <EventIcon />
          <label style={{textAlign: "center", cursor: "pointer"}}>{formatDate(sliderEnding, timeFormat)}</label>
        </div>
        <MobileDateTimePicker 
          sx={{display: "none"}}
          open={openEndCalendar}
          value={calendarEndDate}
          onChange={setCalendarEndDate}
          onAccept={handleAccept}
          onClose={handleClose}
          disableFuture
        />
      </>
      }  
    </div>
  )
};

export default TimeRangeSlider;
