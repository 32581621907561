import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import React, { useLayoutEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const EditCard = ({ style, children, title = "", loading=false, editMode, disableSave = false, disableEdit = false, disableDelete = false, isClosable = true, onSave, onChangeEdit, onClose, onDelete, onCancelChanges }) => {
  const [edit, setEdit] = useState(false);
  const [canSave, setCanSave] = useState(false);

  useLayoutEffect(() => {
    editMode !== undefined && setEdit(editMode);
  }, [editMode])

  useLayoutEffect(() => {
    disableSave !== undefined && setCanSave(!disableSave);
  }, [disableSave])


  const handleEdit = () => {
    setEdit(true);
    onChangeEdit && onChangeEdit(true);
  };

  const handleSave = () => {
    setEdit(false);
    onChangeEdit && onChangeEdit(false);
    onSave && onSave();
  };

  const handleCancelChanges = () => {
    setEdit(false);
    onCancelChanges && onCancelChanges();
    onChangeEdit && onChangeEdit(false);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <div className="card" style={{ ...style, padding: "1rem" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
        <h3 style={{ fontWeight: "bold" }}>{title}</h3>
        {!disableEdit && !loading &&
          (!edit ?
            <Tooltip title="Edit">
              <IconButton onClick={handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            :
            <Button onClick={handleCancelChanges}> {handleTranslate("CANCEL_CHANGES", true)} </Button>
          )}
        {isClosable &&
          <IconButton sx={{ ml: "auto" }} onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      </div>
      {children}
      {!loading ? 
        edit &&
        <>
          <Button disabled={!canSave} onClick={handleSave}> {handleTranslate("SAVE", true)} </Button>
          {!disableDelete && <Button sx={{ color: "red" }} onClick={onDelete}> {handleTranslate("DELETE", true)} </Button>}
        </>
        :
        <Button endIcon={loading && <CircularProgress size={15} />}> {handleTranslate('SAVING', true)} </Button>
      }
    </div>
  )
}

export default EditCard